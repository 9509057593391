import { Component, ViewChild } from '@angular/core';
import { UploaderComponent, UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { UploadPath } from '../../shared/attachment-uploader.component';
import { environment } from '../../../environments/environment';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { OrderFlowAuthService } from '../../auth/order-flow-auth.service';
import { OrdersService } from '../orders.service';
import {
  FlattenedOrderToAddOrUpdateWithRowNumber,
  InsertFlatOrderResponse
} from '../flattened-order-to-add-or-update.model';
import { EditService, Grid, GridModule, IEditCell, ResizeService, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { JsonPipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { concat, Observable, tap } from 'rxjs';
import { showSuccessSnackbar } from '../../shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import {
  EXECUTION_INSTRUCTION_VALUES,
  EXECUTION_METHOD_VALUES,
  ORDER_TYPE_VALUES,
  QUANTITY_TYPE_VALUES,
  TIF_VALUES,
  TRANSACTION_TYPE_VALUES
} from '../../shared/code-name-value.model';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { BrokersService } from '../../brokers/brokers.service';
import { CustodiansService } from '../../custodians/custodians.service';
import { CheckBoxModule, RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ApiErrorComponent } from '../../shared/api-error.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {
  FX_FORWARD_SECURITY_TYPE,
  FX_SPOT_SECURITY_TYPE as FX_SECURITY_TYPE,
  FX_SPOT_SECURITY_TYPE,
  ROLE_FX_ORDER_WRITER,
  ROLE_FX_ORDER_WRITER_RESTRICTED,
  ROLE_ORDER_WRITER
} from '../../shared/constants';

@Component({
  selector: 'of-bulk-upload-fx-orders',
  imports: [
    ApiErrorComponent,
    CheckBoxModule,
    GridModule,
    JsonPipe,
    MatProgressSpinnerModule,
    RouterModule,
    UploaderModule,
    RadioButtonModule
  ],
  templateUrl: './bulk-upload-fx-orders.component.html',
  providers: [EditService, ResizeService, ToolbarService]
})
export class BulkUploadFxOrdersComponent {
  private accessToken: string | null = null;
  public path: UploadPath = environment.tempFileUploadPath;
  public form: FormGroup = new FormGroup({});
  public parsedOrders: FlattenedOrderToAddOrUpdateWithRowNumber[] | null = null;
  public importResults: InsertFlatOrderResponse[] = [];
  public environment = environment.env;
  public submitting = false;
  public submitted = false;
  public securityType = FX_SPOT_SECURITY_TYPE;
  public brokerEditParams: IEditCell | null = null;
  public custodianEditParams: IEditCell | null = null;
  public FX_SPOT_SECURITY_TYPE = FX_SPOT_SECURITY_TYPE;
  public FX_FORWARD_SECURITY_TYPE = FX_FORWARD_SECURITY_TYPE;

  public getFileName(): string {
    if (!this.isRestrictedOrderWriter()) {
      return 'fx-orders-bulk-upload.template.csv';
    }
    return 'simple-fx-orders-bulk-upload.template.csv';
  }

  public transactionTypeEditParams: IEditCell = {
    params: {
      dataSource: new DataManager(Object.values(TRANSACTION_TYPE_VALUES)),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  public quantityTypeEditParams: IEditCell = {
    params: {
      dataSource: new DataManager(Object.values(QUANTITY_TYPE_VALUES)),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  public executionInstructionEditParams: IEditCell = {
    params: {
      dataSource: new DataManager(EXECUTION_INSTRUCTION_VALUES),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  public executionMethodEditParams: IEditCell = {
    params: {
      dataSource: new DataManager(Object.values(EXECUTION_METHOD_VALUES)),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  public orderTypeEditParams: IEditCell = {
    params: {
      dataSource: new DataManager(Object.values(ORDER_TYPE_VALUES)),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  public tifEditParams: IEditCell = {
    params: {
      dataSource: new DataManager(Object.values(TIF_VALUES)),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  @ViewChild(UploaderComponent, { static: true })
  public uploader: UploaderComponent | null = null;

  @ViewChild('gridParsedOrders')
  public gridParsedOrders: Grid | null = null;

  @ViewChild('gridImportResults')
  public gridImportResults: Grid | null = null;

  constructor(
    private authService: OrderFlowAuthService,
    private fb: FormBuilder,
    private ordersService: OrdersService,
    private snackBar: MatSnackBar,
    private brokersService: BrokersService,
    private custodiansService: CustodiansService
  ) {}

  public ngOnInit(): void {
    this.form = this.fb.group({
      bulkOrdersFileTempPath: new FormControl<string | null>(null, Validators.required)
    });

    this.authService.getAccessToken$().subscribe((accessToken) => (this.accessToken = accessToken));

    this.brokersService.listBrokers$(false).subscribe((brokers) => {
      this.brokerEditParams = {
        params: {
          dataSource: new DataManager(brokers),
          fields: { text: 'name', value: 'code' },
          query: new Query(),
          actionComplete: () => false
        }
      };
    });

    this.custodiansService.listCustodians$(false).subscribe((custodians) => {
      this.custodianEditParams = {
        params: {
          dataSource: new DataManager(custodians),
          fields: { text: 'name', value: 'code' },
          query: new Query(),
          actionComplete: () => false
        }
      };
    });
  }

  public setSecurityType(args: any): void {
    this.securityType = args.value;
  }
  public addHeaders(args: any): void {
    args.currentRequest.setRequestHeader('Authorization', `Bearer ${this.accessToken}`);

    if (this.form.get('bulkOrdersFileTempPath')?.value) {
      args.currentRequest.setRequestHeader('X-TempPath', this.form.get('bulkOrdersFileTempPath')?.value);
    }
  }

  public onSuccess(event: any): void {
    if (event.operation === 'upload') {
      const tempPath = JSON.parse(event.e.currentTarget.response).path;

      if (tempPath) {
        this.form.get('bulkOrdersFileTempPath')?.setValue(tempPath);
      }
    } else if (event.operation === 'remove') {
      this.form.get('bulkOrdersFileTempPath')?.reset();
    }
  }

  public parse(): void {
    const parseMethod = !this.isRestrictedOrderWriter()
      ? this.ordersService.parseBulkFxOrdersFile$(this.form.get('bulkOrdersFileTempPath')?.value)
      : this.ordersService.parseBulkSimpleFxOrdersFile$(this.form.get('bulkOrdersFileTempPath')?.value);

    parseMethod.subscribe((parsedOrders) => {
      this.form?.reset();
      this.uploader?.clearAll();
      this.parsedOrders = parsedOrders;
    });
  }

  public isRestrictedOrderWriter(): boolean {
    return this.authService.hasRole(ROLE_FX_ORDER_WRITER_RESTRICTED);
  }

  public downloadTemplate(): void {
    if (!this.isRestrictedOrderWriter()) {
      this.ordersService.getBulkFxOrdersFileTemplate$().subscribe((blob) => {
        saveAs(blob, 'fx-orders-bulk-upload.template.csv');
      });
    } else {
      this.ordersService.getBulkSimpleFxOrdersFileTemplate$().subscribe((blob) => {
        saveAs(blob, 'simple-fx-orders-bulk-upload.template.csv');
      });
    }
  }

  public importOrders(): void {
    if (!this.parsedOrders) {
      return;
    }

    const ordersToImport = this.parsedOrders.filter((order) => {
      const previousResult = this.importResults.find((r) => r.rowNumber === order.rowNumber);
      return !previousResult || (previousResult && !previousResult.success);
    });

    if (!ordersToImport.length) return;

    this.submitting = true;

    if (this.gridParsedOrders) {
      this.gridParsedOrders.editSettings = { allowEditing: false, allowAdding: false, allowDeleting: false };
    }

    const processOrder = (
      order: any,
      rowNumber: number,
      serviceMethod: (order: any, rowNumber: number) => Observable<any>
    ) => {
      return serviceMethod(order, rowNumber).pipe(
        tap((result) => {
          const existingResult = this.importResults.find((r) => r.rowNumber === result.rowNumber);
          if (existingResult) {
            existingResult.success = result.success;
            existingResult.orderId = result.orderId;
            existingResult.error = result.error;
          } else {
            this.importResults.push(result);
          }

          this.gridImportResults?.refresh();
        })
      );
    };

    const observables$ = ordersToImport.map((order) => {
      order.securityName =
        order.transactionType === 'Purchase'
          ? `${order.fxBuyCurrency}/${order.fxSellCurrency}`
          : `${order.fxSellCurrency}/${order.fxBuyCurrency}`;

      order.securityType = this.securityType;
      order.broker = order.broker ? order.broker : order.custodian;

      return processOrder(
        order,
        order.rowNumber,
        !this.isRestrictedOrderWriter()
          ? this.ordersService.insertFlatFxOrder$.bind(this.ordersService)
          : this.ordersService.insertRestrictedFlatFxOrder$.bind(this.ordersService)
      );
    });

    concat(...observables$).subscribe({
      next: () => {
        showSuccessSnackbar(this.snackBar, `All orders were imported. Please check the results in the table.`);
        this.submitting = false;
        this.submitted = !this.importResults.some((r) => !r.success);

        if (this.gridParsedOrders) {
          this.gridParsedOrders.editSettings = { allowEditing: true, allowAdding: false, allowDeleting: false };
        }
      },
      error: () => {
        this.submitting = false;
      }
    });
  }
}
