import { Component } from '@angular/core';
import { OrderFlowAuthService } from '../../auth/order-flow-auth.service';
import { ROLE_ORDER_WRITER } from '../../shared/constants';

@Component({
    selector: 'of-can-create-order',
    imports: [],
    template: `
    @if (canCreate) {
      <ng-content></ng-content>
    }
  `
})
export class CanCreateOrderComponent {
  public canCreate: boolean | null = null;

  constructor(private authService: OrderFlowAuthService) {}

  ngOnInit(): void {
    this.canCreate = this.authService.hasRole(ROLE_ORDER_WRITER);
  }
}
