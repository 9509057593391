import { JsonPipe, KeyValuePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ApiError } from '../api-errror.model';

@Component({
    selector: 'of-api-error',
    imports: [JsonPipe, KeyValuePipe],
    template: `
    @if (data) {
      @switch (data.status) {
        @case (400) {
          <!-- HTTP 400 -->
          <strong>Please fix the following validation errors:</strong>
          @if (jsonError) {
            @if (jsonError.errors) {
              <ul>
                @for (member of jsonError.errors | keyvalue; track $index) {
                  <li>
                    <strong>{{ member.key }}:</strong> {{ member.value | json }}
                  </li>
                }
              </ul>
            } @else {
              <p>{{ jsonError | json }}</p>
            }
          } @else if (textError) {
            <p>{{ textError }}</p>
          }
        }
        @case (429) {
          <!-- HTTP 429 -->
          <strong>Limit exceeded:</strong>
          @if (data.message) {
            <p>{{ data.message }}</p>
          } @else {
            <p>{{ data.error || data | json }}</p>
          }
        }
        @default {
          <!-- Other Errors -->
          <strong>HTTP {{ data.status }}:</strong> {{ textError || data.error || data | json }}
        }
      }
    }
  `
})
export class ApiErrorComponent implements OnInit {
  public jsonError: any;
  public textError: string | null = null;

  @Input()
  public data: ApiError | null = null;

  public ngOnInit(): void {
    if (this.data?.error) {
      if (typeof this.data.error === 'string') {
        try {
          this.jsonError = JSON.parse(this.data.error);
        } catch (error) {
          console.log('Error parsing JSON:', error);
          this.textError = this.data.error;
        }
      } else {
        this.jsonError = this.data.error;
      }
    } else {
      this.textError = JSON.stringify(this.data);
    }
  }
}
