import { Component, Input, ViewChild } from '@angular/core';
import { ToastComponent as SyncToastComponent, ToastModule } from '@syncfusion/ej2-angular-notifications';

@Component({
  selector: 'of-toast',
  standalone: true,
  imports: [ToastModule],
  template: `
    <ejs-toast #toast [position]="{ X: 'Center', Y: 'Bottom' }" [timeOut]="timeout" [showCloseButton]="showCloseButton">
      <ng-template #title>
        <ng-content select="#title"></ng-content>
      </ng-template>
      <ng-template #content>
        <ng-content select="#content"></ng-content>
      </ng-template>
    </ejs-toast>
  `
})
export class ToastComponent {
  @ViewChild('toast')
  public toast: SyncToastComponent | null = null;

  @Input()
  public title: string | null = null;

  @Input()
  public timeout = 0;

  @Input()
  public showCloseButton = true;

  public show() {
    this.toast?.show();
  }

  public hide() {
    this.toast?.hide();
  }
}
