import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { OrderPermissionsService } from '../orders/order-permissions.service';
import { map } from 'rxjs';

export const canCheckOrderGuard: CanActivateFn = (route, state) => {
  return inject(OrderPermissionsService)
    .canCheckOrder$(route.params['id'])
    .pipe(map((canCheck) => canCheck.isAuthorized));
};
