import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { OrderPermissionsService } from '../order-permissions.service';
import { map } from 'rxjs';
import { OrderFlowAuthService } from '../../auth/order-flow-auth.service';
import { ROLE_ORDER_WRITER } from '../../shared/constants';

export const canDeleteOrderGuard: CanActivateFn = (route, state) => {
  return (
    inject(OrderFlowAuthService).hasRole(ROLE_ORDER_WRITER) &&
    inject(OrderPermissionsService)
      .canDeleteOrder$(route.params['id'])
      .pipe(map((canDelete) => canDelete.isAuthorized))
  );
};
