import { Component, OnInit, ViewChild } from '@angular/core';
import { Order } from './order.model';
import { ORDER_STATUS_VALUES } from '../shared/code-name-value.model';
import { EmailSentEventDetails, OrdersService, OrderStatusUpdatedEventDetails } from './orders.service';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { YesNoDialogComponent } from '../shared/yes-no-dialog.component';
import { AsyncPipe, CommonModule, DecimalPipe, NgClass, formatNumber } from '@angular/common';
import { map, Observable } from 'rxjs';
import { PreviewPdfComponent } from './preview-pdf.component';
import { Router, RouterLink } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { FormsModule } from '@angular/forms';
import { AvatarCardComponent } from '../shared/avatar-card.component';
import { OrderStatusBadgeComponent } from '../shared/order-status-badge.component';
import { DateRangePickerModule, RangeEventArgs } from '@syncfusion/ej2-angular-calendars';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import {
  ExcelExportService,
  FilterService,
  GridComponent,
  GridModule,
  PageService,
  PdfExportService
} from '@syncfusion/ej2-angular-grids';
import { CanApproveOrderComponent } from '../approve/can-approve-order.component';
import { CanCheckOrderComponent } from '../check/can-check-order.component';
import { CanCreateOrderComponent } from './create-order/can-create-order.component';
import { CanDeleteOrderComponent } from './delete-order/can-delete-order.component';
import { CanSendOrderComponent } from './order-details/can-send-order.component';
import { CanEditOrderComponent } from './edit-order/can-edit-order.component';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { BulkSendOrdersDialogComponent } from './bulk-send-orders-dialog/bulk-send-orders-dialog.component';
import { CanWriteOrdersComponent } from './can-write-orders.component';
import { PdfOrdersByBroker } from './pdf-order.model';
import { ToastComponent } from '../shared/toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { AckOrderDialogComponent } from '../ack-order/ack-order-dialog.component';
import { canAckOrder, CanAckOrderComponent } from '../ack-order/can-ack-order.component';
import { canCancelOrder, CanCancelOrderComponent } from '../cancel-order/can-cancel-order.component';
import { CancelOrderDialogComponent } from '../cancel-order/cancel-order-dialog.component';
import { CancelMultipleOrdersComponent } from '../cancel-order/cancel-multiple-orders.component';
import { DeleteMultipleOrdersRequest, UpdateMultipleOrdersStatusRequest } from '../shared/comments.model';
import { AckMultipleOrdersComponent } from '../ack-order/ack-multiple-orders.component';
import {
  CLONE_TYPE_WITH_ALLOCATION,
  FX_FORWARD_SECURITY_TYPE,
  FX_SPOT_SECURITY_TYPE,
  ROLE_FX_ORDER_READER_RESTRICTED,
  ROLE_FX_ORDER_WRITER_RESTRICTED,
  ROLE_ORDER_WRITER
} from '../shared/constants';
import { CanPreviewOrderComponent } from './order-details/can-preview-order.component';
import { CanRecordPartialExecutionComponent } from '../record-partial-execution/can-record-partial-execution.component';
import { RecordPartialExecutionDialogComponent } from '../record-partial-execution/record-partial-execution-dialog.component';
import { canExecuteOrder, isDeletableStatus } from '../shared/utils';
import { BulkExecuteOrdersService } from './bulk-execute-orders/bulk-execute-orders.service';
import { OrderPermissionsService } from './order-permissions.service';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import { DeleteMultipleOrdersComponent } from './bulk-delete-orders/delete-multiple-orders.component';

interface OrderForDisplay extends Order {
  portfolioWithAccount: string;
}

@Component({
  selector: 'of-orders',
  imports: [
    AsyncPipe,
    AvatarCardComponent,
    CanAckOrderComponent,
    CanApproveOrderComponent,
    CanCancelOrderComponent,
    CanCreateOrderComponent,
    CanDeleteOrderComponent,
    CanEditOrderComponent,
    CanPreviewOrderComponent,
    CanRecordPartialExecutionComponent,
    CanSendOrderComponent,
    CanWriteOrdersComponent,
    DateRangePickerModule,
    DecimalPipe,
    FormsModule,
    GridModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    NgClass,
    OrderStatusBadgeComponent,
    RouterLink,
    SwitchModule,
    TextBoxModule,
    ToastComponent,
    CommonModule
  ],
  providers: [ExcelExportService, FilterService, PageService, PdfExportService],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.css'
})
export class OrdersComponent implements OnInit {
  public orders$: Observable<OrderForDisplay[]> | null = null;

  public loading = true;
  public gridActionsEnabled = true;
  public showInactive = false;
  public cloneTypeWithAllocation = CLONE_TYPE_WITH_ALLOCATION;

  public emailsSentCount: number | null = null;
  public emailsSentTotal: number | null = null;
  public emailsSentMessage: string | null = null;

  public ordersStatusUpdatedCount: number | null = null;
  public ordersStatusUpdatedTotal: number | null = null;
  public ordersStatusUpdatedMessage: string | null = null;

  public ordersDeletedCount: number | null = null;
  public ordersDeletedTotal: number | null = null;
  public ordersDeletedMessage: string | null = null;

  private startDate: Date | null = null;
  private endDate: Date | null = null;
  public searchString: string | null = null;
  public searching = false;
  public searched = false;

  @ViewChild('grdOrders')
  public grdOrders: GridComponent | null = null;

  @ViewChild('toastEmailSentProgress')
  public toastEmailSentProgress: ToastComponent | null = null;

  @ViewChild('toastOrdersStatusUpdatedProgress')
  public toastOrdersStatusUpdatedProgress: ToastComponent | null = null;

  @ViewChild('toastOrdersDeletedProgress')
  public toastOrdersDeletedProgress: ToastComponent | null = null;

  constructor(
    private ordersService: OrdersService,
    private yesNoDialog: MatDialog,
    private pdfViewerDialog: MatDialog,
    private logger: NGXLogger,
    private bulkSendDialog: MatDialog,
    private ackOrderDialog: MatDialog,
    private bulkAckOrderDialog: MatDialog,
    private cancelOrderDialog: MatDialog,
    private bulkCancelOrderDialog: MatDialog,
    private bulkExecuteOrdersService: BulkExecuteOrdersService,
    private recordPartialExecutionDialog: MatDialog,
    private router: Router,
    private permissionsService: OrderPermissionsService,
    private authService: OrderFlowAuthService
  ) {}

  public ngOnInit(): void {
    this.setOrders();

    this.ordersService.orderUpdated.subscribe(() => {
      this.logger.info('Order updated');
      this.setOrders();
    });

    this.ordersService.emailSent.subscribe((details: EmailSentEventDetails) => {
      if (!this.emailsSentCount) this.emailsSentCount = 0;
      this.emailsSentCount++;
      this.emailsSentMessage = `Sent email to ${details.broker} (orders ${details.orderIds.join(', ')}).`;
    });

    this.ordersService.orderStatusUpdated.subscribe((details: OrderStatusUpdatedEventDetails) => {
      if (!this.ordersStatusUpdatedCount) this.ordersStatusUpdatedCount = 0;
      this.ordersStatusUpdatedCount++;
      this.ordersStatusUpdatedMessage = `Updated status of order ${details.orderId} to ${details.status.name}.`;
    });
  }

  public sendEmailToBroker(order: Order): void {
    const dialogRef = this.yesNoDialog.open(YesNoDialogComponent, {
      minHeight: '400px',
      data: {
        body: `Email order ${order.id} "${order.transactionType} ${formatNumber(order.quantity, 'en-US')} ${
          order.security.name
        }" to broker ${order.broker.code} (${order.broker.emails.join(', ')})?`,
        noButtonLabel: 'CANCEL',
        yesButtonLabel: 'PROCEED'
      }
    });

    dialogRef.afterClosed().subscribe((proceed: boolean) => {
      if (proceed) {
        this.gridActionsEnabled = false;
        this.emailsSentTotal = 1;
        this.emailsSentCount = 0;
        this.emailsSentMessage = 'Sending email...';
        this.toastEmailSentProgress?.show();

        this.ordersService
          .sendEmailToBroker$(order.id)
          .subscribe({ next: () => this.handleAfterSend(true), error: () => this.handleAfterSend(false) });
      }
    });
  }

  public get selectedOrders(): Order[] {
    return this.grdOrders?.getSelectedRecords() as Order[];
  }

  public get canSendMultipleOrders(): boolean {
    return (
      this.selectedOrders &&
      this.selectedOrders.length > 1 &&
      this.selectedOrders.every((o) => o.status.code === ORDER_STATUS_VALUES['executable'].code)
    );
  }

  public get canAckMultipleOrders(): boolean {
    return (
      this.selectedOrders && this.selectedOrders.length > 1 && this.selectedOrders.every((o) => canAckOrder(o.status))
    );
  }

  public get canExecuteMultipleOrders(): boolean {
    return (
      this.selectedOrders &&
      this.selectedOrders.length > 1 &&
      this.selectedOrders.every((o) => canExecuteOrder(o.status))
    );
  }

  public get canCancelMultipleOrders(): boolean {
    return (
      this.selectedOrders &&
      this.selectedOrders.length > 0 &&
      this.selectedOrders.every((o) => canCancelOrder(o.status))
    );
  }

  public canDeleteOrder(id: number): Observable<boolean> {
    return this.permissionsService.canDeleteOrder$(id).pipe(map((r) => r.isAuthorized));
  }

  public get canDeleteMultipleOrders(): boolean {
    return (
      this.selectedOrders &&
      this.selectedOrders.length > 0 &&
      this.selectedOrders.every((o) => this.canDeleteOrder(o.id)) &&
      this.selectedOrders.every((o) => isDeletableStatus(o.status)) &&
      this.authService.hasRole(ROLE_ORDER_WRITER)
    );
  }

  public sendMultipleOrdersEmailToBroker(): void {
    if (!this.canSendMultipleOrders) {
      return;
    }

    const dialogRef = this.bulkSendDialog.open(BulkSendOrdersDialogComponent, {
      minWidth: '900px',
      data: this.selectedOrders
    });

    dialogRef.afterClosed().subscribe((ordersByBrokers: PdfOrdersByBroker[] | null) => {
      if (ordersByBrokers) {
        this.grdOrders?.clearSelection();

        this.gridActionsEnabled = false;
        this.emailsSentTotal = ordersByBrokers.flatMap((o) => o.orders).length;
        this.emailsSentCount = 0;
        this.emailsSentMessage = 'Sending emails...';
        this.toastEmailSentProgress?.show();

        this.ordersService.sendMultipleOrdersEmailToBroker$(ordersByBrokers).subscribe({
          next: () => this.handleAfterSend(true),
          error: () => this.handleAfterSend(false)
        });
      }
    });
  }

  public ackOrder(order: Order): void {
    this.ackOrderDialog.open(AckOrderDialogComponent, {
      minWidth: '400px',
      data: order
    });
  }

  public ackMultipleOrders(): void {
    if (!this.canAckMultipleOrders) {
      return;
    }

    const dialogRef = this.bulkAckOrderDialog.open(AckMultipleOrdersComponent, {
      minWidth: '900px',
      data: this.selectedOrders
    });

    dialogRef.afterClosed().subscribe((request: UpdateMultipleOrdersStatusRequest | null) => {
      if (request) {
        this.grdOrders?.clearSelection();

        this.gridActionsEnabled = false;
        this.ordersStatusUpdatedTotal = request.orderIds.length;
        this.ordersStatusUpdatedCount = 0;
        this.ordersStatusUpdatedMessage = 'Updating orders status...';
        this.toastOrdersStatusUpdatedProgress?.show();

        this.ordersService.updateMultipleOrdersStatus$(request).subscribe({
          next: () => this.handleAfterBulkOrderStatusUpdates(true),
          error: () => this.handleAfterBulkOrderStatusUpdates(false)
        });
      }
    });
  }

  public recordPartialExecution(order: Order): void {
    this.recordPartialExecutionDialog.open(RecordPartialExecutionDialogComponent, {
      minWidth: '400px',
      data: order
    });
  }

  public executeMultipleOrders(): void {
    if (!this.canExecuteMultipleOrders) {
      return;
    }

    this.bulkExecuteOrdersService.setOrdersToExecute(this.selectedOrders);

    this.router.navigate(['/orders/bulk-execute']);
    // dialogRef.afterClosed().subscribe((request: UpdateMultipleOrdersStatusRequest | null) => {
    //   if (request) {
    //     this.grdOrders?.clearSelection();

    //     this.gridActionsEnabled = false;
    //     this.ordersStatusUpdatedTotal = request.orderIds.length;
    //     this.ordersStatusUpdatedCount = 0;
    //     this.ordersStatusUpdatedMessage = 'Updating orders status...';
    //     this.toastOrdersStatusUpdatedProgress?.show();

    //     this.ordersService.updateMultipleOrdersStatus$(request).subscribe({
    //       next: () => this.handleAfterBulkOrderStatusUpdates(true),
    //       error: () => this.handleAfterBulkOrderStatusUpdates(false)
    //     });
    //   }
    // });
  }

  public cancelOrder(order: Order): void {
    this.cancelOrderDialog.open(CancelOrderDialogComponent, {
      minWidth: '400px',
      data: order
    });
  }

  public deleteSelectedOrders(): void {
    if (!this.canDeleteMultipleOrders) {
      return;
    }

    const dialogRef = this.bulkCancelOrderDialog.open(DeleteMultipleOrdersComponent, {
      minWidth: '900px',
      data: this.selectedOrders
    });

    dialogRef.afterClosed().subscribe((request: DeleteMultipleOrdersRequest | null) => {
      if (request) {
        this.grdOrders?.clearSelection();

        this.gridActionsEnabled = false;
        this.ordersDeletedTotal = request.orderIds.length;
        this.ordersDeletedCount = 0;
        this.ordersDeletedMessage = 'Deleting orders...';
        this.toastOrdersDeletedProgress?.show();

        this.ordersService.deleteMultipleOrders$(request).subscribe({
          next: () => this.handleAfterBulkDeleteOrders(true),
          error: () => this.handleAfterBulkDeleteOrders(false)
        });
      }
    });
  }

  public cancelMultipleOrders(): void {
    if (!this.canCancelMultipleOrders) {
      return;
    }

    const dialogRef = this.bulkCancelOrderDialog.open(CancelMultipleOrdersComponent, {
      minWidth: '900px',
      data: this.selectedOrders
    });

    dialogRef.afterClosed().subscribe((request: UpdateMultipleOrdersStatusRequest | null) => {
      if (request) {
        this.grdOrders?.clearSelection();

        this.gridActionsEnabled = false;
        this.ordersStatusUpdatedTotal = request.orderIds.length;
        this.ordersStatusUpdatedCount = 0;
        this.ordersStatusUpdatedMessage = 'Updating orders status...';
        this.toastOrdersStatusUpdatedProgress?.show();

        this.ordersService.updateMultipleOrdersStatus$(request).subscribe({
          next: () => this.handleAfterBulkOrderStatusUpdates(true),
          error: () => this.handleAfterBulkOrderStatusUpdates(false)
        });
      }
    });
  }

  public viewPdf(orderId: number): void {
    this.pdfViewerDialog.open(PreviewPdfComponent, {
      minWidth: '860px',
      data: { orderId }
    });
  }

  public dateRangeSelected(range: RangeEventArgs): void {
    this.startDate = range.startDate || null;
    this.endDate = range.endDate || null;
  }

  public clearDateRange(): void {
    this.startDate = null;
    this.endDate = null;
  }

  public onShowDeletedChange(event: any): void {
    this.showInactive = event.checked;
    this.setOrders();
  }

  public search(): void {
    this.searching = true;
    this.searched = true;
    this.orders$ = this.ordersService
      .searchOrders$({
        startDate: this.startDate,
        endDate: this.endDate,
        searchString: this.searchString
      })
      .pipe(map((orders) => this.enrichOrders(orders)));

    setTimeout(() => {
      this.searching = false;
    }, 500);
  }

  public resetSearch(): void {
    this.startDate = null;
    this.endDate = null;
    this.searchString = null;
    this.searched = false;
    this.setOrders();
  }

  private handleAfterSend(success: boolean): void {
    this.grdOrders?.clearSelection();
    this.setOrders();
    this.gridActionsEnabled = true;

    if (success) {
      this.emailsSentMessage = `Emails sent to ${this.emailsSentTotal} brokers.`;
      setTimeout(() => {
        this.resetToasts();
      }, 2000);
    } else {
      this.resetToasts();
    }
  }

  private handleAfterBulkOrderStatusUpdates(success: boolean): void {
    this.grdOrders?.clearSelection();
    this.setOrders();
    this.gridActionsEnabled = true;

    if (success) {
      this.ordersStatusUpdatedMessage = `Updated status of ${this.ordersStatusUpdatedTotal} orders.`;
      setTimeout(() => {
        this.resetToasts();
      }, 2000);
    } else {
      this.resetToasts();
    }
  }

  public isRestrictedUser(): boolean {
    return this.authService.hasOneOfRoles([ROLE_FX_ORDER_READER_RESTRICTED, ROLE_FX_ORDER_WRITER_RESTRICTED]);
  }

  private handleAfterBulkDeleteOrders(success: boolean): void {
    this.grdOrders?.clearSelection();
    this.setOrders();
    this.gridActionsEnabled = true;

    if (success) {
      this.ordersDeletedMessage = `Deleted ${this.ordersStatusUpdatedTotal} orders.`;
      setTimeout(() => {
        this.resetToasts();
      }, 2000);
    } else {
      this.ordersDeletedMessage = `Unable to delete orders. Please confirm your permissions`;
      setTimeout(() => {
        this.resetToasts();
      }, 2000);
    }
  }

  private resetToasts() {
    this.toastEmailSentProgress?.hide();
    this.emailsSentCount = null;
    this.emailsSentTotal = null;
    this.emailsSentMessage = null;

    this.toastOrdersStatusUpdatedProgress?.hide();
    this.ordersStatusUpdatedCount = null;
    this.ordersStatusUpdatedTotal = null;
    this.ordersStatusUpdatedMessage = null;

    this.toastOrdersDeletedProgress?.hide();
    this.ordersDeletedCount = null;
    this.ordersDeletedTotal = null;
    this.ordersDeletedMessage = null;
  }

  private setOrders(): void {
    this.orders$ = this.ordersService.listOrders$(this.showInactive).pipe(map((orders) => this.enrichOrders(orders)));
  }

  private enrichOrders(orders: Order[] | null): OrderForDisplay[] {
    return orders?.map((o) => ({ ...o, portfolioWithAccount: `${o.portfolio.shortName} / ${o.account.name}` })) || [];
  }
}
