import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { DetailedOrder } from '../order.model';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { OrdersService } from '../orders.service';
import { AsyncPipe } from '@angular/common';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';

@Component({
  selector: 'of-delete-order',
  standalone: true,
  imports: [AsyncPipe, ReactiveFormsModule, RouterModule, TextBoxModule],
  template: `
    @if (order$ | async; as order) {
      <form [formGroup]="form">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">DELETE ORDER {{ order.id }}</h4>
            <div class="card-subtitle">
              {{ order.transactionType }} {{ order.quantity }} {{ order.security.name }} (account:
              {{ order.portfolio.shortName }})
            </div>
          </div>
          <div class="card-body d-flex flex-column gap-3">
            <a [routerLink]="['/orders', order.id]" target="_blank">Order Details</a>
            <div>
              <ejs-textbox
                formControlName="comments"
                placeholder="Comments (optional)"
                multiline="true"
                floatLabelType="Auto"
                style="height: 100px"
              ></ejs-textbox>
            </div>
            <div class="btn-group w-50">
              <button class="btn btn-danger" [disabled]="!form.valid" (click)="submit(order.id)">DELETE</button>
              <a class="btn btn-outline-secondary border-0" [routerLink]="['/orders']">BACK</a>
            </div>
          </div>
        </div>
      </form>
    }
  `
})
export class DeleteOrderComponent implements OnInit {
  public form: FormGroup = new FormGroup({});
  public order$: Observable<DetailedOrder> | null = null;
  public comments: string | null = null;

  constructor(
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    private fb: FormBuilder,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      comments: this.fb.control<string | null>(null)
    });

    this.route.paramMap.subscribe((params) => {
      const orderId = Number.parseInt(params.get('id') || '-1', 10);

      if (orderId > 0) {
        this.order$ = this.ordersService.getOrder$(orderId);
      }
    });
  }

  public submit(orderId: number): void {
    if (this.form) {
      this.form.disable();
      this.ordersService.deleteOrder$(orderId, this.form.value).subscribe(() => this.router.navigate(['/orders']));
    }
  }
}
