import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import { ROLE_ORDER_READER, ROLE_ORDER_WRITER, ROLE_PARTIAL_EXECUTIONS_READER } from '../shared/constants';

export const canViewPartialExecutionsGuard: CanActivateFn = (route, state) => {
  return inject(OrderFlowAuthService).hasOneOfRoles([
    ROLE_PARTIAL_EXECUTIONS_READER,
    ROLE_ORDER_READER,
    ROLE_ORDER_WRITER
  ]);
};
