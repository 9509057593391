import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrdersService } from './orders.service';
import { PdfViewerComponent, PdfViewerModule, PrintService, ToolbarService } from '@syncfusion/ej2-angular-pdfviewer';
import { map, of } from 'rxjs';
import { PdfOrder } from './pdf-order.model';

export interface PreviewPdfData {
  aggregatedOrder?: PdfOrder;
  orderId?: number;
  base64Content?: string;
}

@Component({
  selector: 'of-preview-pdf',
  standalone: true,
  imports: [PdfViewerModule],
  providers: [PrintService, ToolbarService],
  template: `
    <ejs-pdfviewer
      #pdfViewer
      [resourceUrl]="resourceUrl"
      style="height:860px; display: block"
      [enableNavigationToolbar]="false"
      [enablePrint]="true"
      [enableDownload]="true"
      [toolbarSettings]="toolbarSettings"
    >
    </ejs-pdfviewer>
  `
})
export class PreviewPdfComponent implements AfterViewInit {
  public resourceUrl: string;
  public toolbarSettings = { showTooltip: true, toolbarItems: ['DownloadOption', 'PrintOption'] };

  @ViewChild('pdfViewer')
  public pdfViewer: PdfViewerComponent | null = null;

  constructor(
    private ordersService: OrdersService,
    public dialogRef: MatDialogRef<PreviewPdfComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PreviewPdfData
  ) {
    this.resourceUrl = `${window.location.origin}/assets/ej2-pdfviewer-lib`;
  }

  public ngAfterViewInit(): void {
    // Set an extra timeout to allow the assets/ej2-pdfviewer-lib to load
    setTimeout(() => {
      const observable$ = this.data.base64Content
        ? of(this.data.base64Content)
        : this.data.orderId
          ? this.ordersService.previewOrder$(this.data.orderId)
          : this.data.aggregatedOrder
            ? this.ordersService.previewAggregatedOrder$(this.data.aggregatedOrder)
            : of('');

      observable$
        .pipe(map((response: string) => `data:application/pdf;base64,${response.replace(/"/g, '')}`))
        .subscribe((base64Content: string) => this.pdfViewer?.load(base64Content, ''));
    }, 1500);
  }
}
