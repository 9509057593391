import { Component, Input } from '@angular/core';
import { AvatarCardComponent } from '../../shared/avatar-card.component';
import { AuditTrailPoint } from '../../shared/audit-trail-point.model';
import { OrderStatusBadgeComponent } from '../../shared/order-status-badge.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'of-order-audit-trail-card',
  standalone: true,
  imports: [AvatarCardComponent, DatePipe, OrderStatusBadgeComponent],
  template: `
    @if (auditTrailPoints) {
      <div class="card mt-3">
        <div class="card-header px-2">
          <h5 class="card-title m-0">AUDIT TRAIL</h5>
        </div>
        <div class="card-body p-0">
          <table class="table m-0">
            <thead>
              <tr>
                <th style="min-width: 180px;">Timestamp</th>
                <th>User</th>
                <th>Status</th>
                <th>Details</th>
              </tr>
            </thead>
            <tbody>
              @for (point of auditTrailPoints; track $index) {
                <tr>
                  <td class="align-middle">{{ point.timestamp | date: 'dd/MM/yyyy HH:mm' }}</td>
                  <td class="align-middle">
                    @if (point.user) {
                      <of-avatar-card [user]="point.user"></of-avatar-card>
                    } @else {
                      {{ point.username }}
                    }
                  </td>
                  <td class="align-middle">
                    <of-order-status-badge [status]="point.status" [useShortFormat]="true"></of-order-status-badge>
                  </td>
                  <td class="align-middle">{{ point.message }}</td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    }
  `,
  styles: ``
})
export class OrderAuditTrailCardComponent {
  @Input()
  public auditTrailPoints: AuditTrailPoint[] | null = null;
}
