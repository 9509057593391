import { Component, Input } from '@angular/core';
import { CodeNameItem } from '../../shared/code-name-value.model';
import { isPreviewableStatus } from '../../shared/utils';

@Component({
  selector: 'of-can-preview-order',
  standalone: true,
  imports: [],
  template: `
    @if (canPreview) {
      <ng-content></ng-content>
    }
  `
})
export class CanPreviewOrderComponent {
  public canPreview: boolean | null = null;

  @Input()
  public orderStatus: CodeNameItem | null = null;

  ngOnInit(): void {
    this.canPreview = isPreviewableStatus(this.orderStatus);
  }
}
