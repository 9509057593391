import { Component } from '@angular/core';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import { ROLE_ORDER_READER, ROLE_ORDER_WRITER, ROLE_PARTIAL_EXECUTIONS_READER } from '../shared/constants';

@Component({
    selector: 'of-can-view-partial-executions',
    imports: [],
    template: `
    @if (canView) {
      <ng-content></ng-content>
    }
  `
})
export class CanViewPartialExecutionsComponent {
  public canView: boolean | null = null;

  constructor(private authService: OrderFlowAuthService) {}

  ngOnInit(): void {
    this.canView = this.authService.hasOneOfRoles([
      ROLE_PARTIAL_EXECUTIONS_READER,
      ROLE_ORDER_READER,
      ROLE_ORDER_WRITER
    ]);
  }
}
