import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NumericTextBoxModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { OrdersService } from '../orders.service';
import { PartialExecution } from '../../record-partial-execution/partial-execution.model';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import {
  BulkApproveExecutionsModel,
  OrderExecutionMappingModel,
  OrderWithDetailedExecutionInfo
} from './order-with-execution-info.model';

@Component({
  selector: 'of-record-partial-execution-dialog',
  imports: [RadioButtonModule, ReactiveFormsModule, NumericTextBoxModule, TextBoxModule],
  template: `
    @if (data) {
      <form [formGroup]="form">
        <div class="d-flex flex-column gap-3 p-3">
          <h5 mat-dialog-title class="m-0">{{ this.computeAction() }} EXECUTIONS</h5>
          <div mat-dialog-content>
            <ejs-textbox
              formControlName="comments"
              placeholder="Comments (optional)"
              multiline="true"
              floatLabelType="Auto"
              style="height: 100px"
            ></ejs-textbox>
          </div>
          <div mat-dialog-actions class="d-flex justify-content-end">
            <div class="btn-group">
              <button class="btn btn-secondary" (click)="onCloseClick()">CLOSE</button>
              <button class="btn btn-primary" [disabled]="!form.valid" (click)="onExecuteClick()" cdkFocusInitial>
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </form>
    }
  `
})
export class BulkApprovePartialExecutionDialogComponent {
  public form: FormGroup = new FormGroup({});
  public submitting = false;
  public executionsApprovedCount: number | null = null;
  public executionsApprovedTotal: number | null = null;
  public executionsApprovedMessage: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<BulkApprovePartialExecutionDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { ordersWithExecutions: OrderWithDetailedExecutionInfo[]; isApproval: boolean },
    private ordersService: OrdersService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      comments: this.fb.control<string | null>(null)
    });
  }

  public computeAction(): string {
    return this.data.isApproval ? 'APPROVE' : 'REJECT';
  }

  public onExecuteClick(): void {
    this.form.disable();

    this.submitting = true;
    var comments = this.form.get('comments')?.value;
    var ordersMapping: OrderExecutionMappingModel[] = [];

    this.data.ordersWithExecutions.forEach((pe) => {
      ordersMapping.push({ orderId: pe.id, executionIds: pe.partialExecutions.map((e) => e.id) });
    });

    var orders: BulkApproveExecutionsModel = {
      ordersToRestrict: ordersMapping,
      comments: comments
    };

    if (this.data.isApproval) {
      this.ordersService.bulkApprovePartialExecution$(orders).subscribe({
        next: () => {
          this.submitting = false;
          this.dialogRef.close({ refresh: true });
        },
        error: () => (this.submitting = false)
      });
    } else {
      this.ordersService.bulkRejectPartialExecution$(orders).subscribe({
        next: () => {
          this.submitting = false;
          this.dialogRef.close({ refresh: true });
        },
        error: () => (this.submitting = false)
      });
    }

    this.dialogRef.close();
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
