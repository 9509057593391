import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'of-yes-no-dialog',
    imports: [MatDialogModule],
    template: `
    <div mat-dialog-content>
      <p>{{ data.body }}</p>
    </div>
    <div mat-dialog-actions class="d-flex justify-content-end">
      <div class="btn-group">
        <button class="btn btn-secondary" (click)="onNoClick()">{{ data.noButtonLabel }}</button>
        <button class="btn btn-danger" (click)="onYesClick()">{{ data.yesButtonLabel }}</button>
      </div>
    </div>
  `
})
export class YesNoDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<YesNoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: YesNoDialogData
  ) {
    if (!data.noButtonLabel) {
      data.noButtonLabel = 'NO';
    }

    if (!data.yesButtonLabel) {
      data.yesButtonLabel = 'YES';
    }
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }
}

export interface YesNoDialogData {
  body: string;
  noButtonLabel: string;
  yesButtonLabel: string;
}
