import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ChangeEventArgs, DropDownList, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { map, Observable } from 'rxjs';
import { Broker } from '../brokers/broker.model';
import { BrokersService } from '../brokers/brokers.service';
import { AsyncPipe, DecimalPipe, NgClass, PercentPipe } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PortfolioWithAccounts } from '../portfolios/portfolio-with-accounts.model';
import { PortfoliosService } from '../portfolios/portfolios.service';
import { Account } from '../portfolios/account.model';
import { NumericTextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ValidationErrorComponent } from '../shared/validation-error.component';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { FxRatesService } from '../core/fx-rates.service';
import { ProgressBarModule } from '@syncfusion/ej2-angular-progressbar';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { HintComponent } from '../shared/hint.component';
import { HoldingsService } from '../holdings/holdings.service';
import { BasicHolding, BasicHoldingWithBasicPortfolio } from '../holdings/basic-holding-with-basic-portfolio.model';
import { QUANTITY_TYPE_VALUES, TRANSACTION_TYPE_VALUES } from '../shared/code-name-value.model';
import { floor } from 'lodash-es';
import { getBrokerDestinationName } from '../shared/utils';

@Component({
    selector: 'of-allocation-form',
    imports: [
        AsyncPipe,
        CheckBoxModule,
        DecimalPipe,
        DropDownListModule,
        HintComponent,
        NgClass,
        NumericTextBoxModule,
        PercentPipe,
        ProgressBarModule,
        ReactiveFormsModule,
        ValidationErrorComponent
    ],
    template: `
    <form [formGroup]="form">
      <div class="row" [ngClass]="{ ' border-primary-subtle border-bottom pb-2': showSeparator }">
        <div class="col">
          <ejs-dropdownlist
            #ddPortfolio
            placeholder="Portfolio"
            [dataSource]="portfoliosWithAccounts"
            [fields]="{ text: 'portfolio.internalName', value: 'portfolio.internalName' }"
            floatLabelType="Auto"
            [allowFiltering]="true"
            (filtering)="onFilteringPortfolios($event)"
            width="300px"
            formControlName="portfolioStr"
            (change)="onPortfolioSelected($event)"
          >
            <ng-template #valueTemplate="" let-data="">
              <div class="d-flex justify-content-between align-items-center m-1">
                <div>{{ data.portfolio.internalName }}</div>
                @if (data.portfolio.externalName) {
                  <div>
                    <small>{{ data.portfolio.externalName }}</small>
                  </div>
                }
              </div>
            </ng-template>
            <ng-template #itemTemplate="" let-data="">
              <div
                class="d-flex justify-content-between"
                [ngClass]="{ 'e-disabled': isPortfolioExcluded(data.portfolio.internalName) }"
              >
                <div>{{ data.portfolio.internalName }}</div>
                <div class="d-flex flex-column align-items-end">
                  <small>{{ data.portfolio.shortName }}</small>
                  @if (data.portfolio.externalName) {
                    <small>{{ data.portfolio.externalName }}</small>
                  }
                </div>
              </div>
            </ng-template>
          </ejs-dropdownlist>
          <of-validation-error [form]="form" field="portfolioStr" validationRule="required"
            >Portfolio is required</of-validation-error
          >
          <table class="table table-sm mt-3">
            @if (currentHolding) {
              <tr>
                <td style="font-size: 12px;">Cur holding:</td>
                <td class="text-end" style="font-size: 12px;">
                  {{ currentHolding.amount | number }} units ({{ currentHolding.marketValue | number }}
                  {{ portfolioRefCurrency }})
                </td>
              </tr>
            }
            @if (portfolioMarketValue) {
              <tr>
                <td style="font-size: 12px;">Cur mkt value:</td>
                <td class="text-end" style="font-size: 12px;">
                  {{ portfolioMarketValue | number }} {{ portfolioRefCurrency }}
                </td>
              </tr>
            }
          </table>
        </div>
        <div class="col">
          <ejs-dropdownlist
            placeholder="Broker"
            [dataSource]="brokers$ | async"
            [fields]="{ groupBy: 'destination', text: 'code', value: 'code' }"
            floatLabelType="Auto"
            [allowFiltering]="true"
            formControlName="broker"
            (change)="onBrokerSelected($event)"
          ></ejs-dropdownlist>
          <of-validation-error [form]="form" field="broker" validationRule="required"
            >Broker is required</of-validation-error
          >
          <ejs-dropdownlist
            placeholder="Account"
            [dataSource]="accounts"
            [fields]="{ text: 'name', value: 'name' }"
            floatLabelType="Auto"
            [allowFiltering]="true"
            (change)="onAccountSelected($event)"
            formControlName="accountStr"
          ></ejs-dropdownlist>
          <of-validation-error [form]="form" field="accountStr" validationRule="required"
            >Account is required</of-validation-error
          >
        </div>
        <div class="col">
          <div class="d-flex align-items-end gap-2">
            <div class="flex-fill">
              <ejs-numerictextbox
                formControlName="fxRate"
                [placeholder]="lblFxRate"
                floatLabelType="Auto"
                [showSpinButton]="false"
                format="#,###.00000"
              ></ejs-numerictextbox>
            </div>
            @if (fxRateLoading) {
              <ejs-progressbar
                id="indeterminate"
                type="Circular"
                height="36"
                value="20"
                [isIndeterminate]="true"
                [animation]="{ enable: true, duration: 500, delay: 0 }"
                style="width: 60px;"
              >
              </ejs-progressbar>
            }
          </div>
          <of-validation-error [form]="form" field="fxRate" validationRule="required"
            >FX rate is required</of-validation-error
          >
          <div class="mt-4">
            <ejs-checkbox label="EAM account" formControlName="isEamAccount"></ejs-checkbox>
          </div>
          <div>
            <ejs-checkbox label="Product knowledge" formControlName="passedProductKnowledgeCheck"></ejs-checkbox>
          </div>
        </div>
        <div class="col">
          <!-- Quantity -->
          <ejs-numerictextbox
            formControlName="quantity"
            [placeholder]="quantityTypeLabel"
            floatLabelType="Auto"
            [showSpinButton]="false"
          ></ejs-numerictextbox>
          @if (approxQuantityValue) {
            <of-hint className="text-success">~{{ approxQuantityValue | number }} {{ securityCurrency }}</of-hint>
          } @else if (approxNominalValue) {
            <of-hint className="text-success">~{{ approxNominalValue | number }} units</of-hint>
          }
          <of-validation-error [form]="form" field="quantity" validationRule="required"
            >{{ quantityTypeLabel }} is required</of-validation-error
          >

          <!-- Quantity (%) -->
          <ejs-numerictextbox
            formControlName="quantityPct"
            [placeholder]="quantityTypeLabelPct"
            floatLabelType="Auto"
            [showSpinButton]="false"
            format="p2"
          ></ejs-numerictextbox>
          <of-validation-error [form]="form" field="quantityPct" validationRule="required"
            >{{ quantityTypeLabelPct }} is required</of-validation-error
          >
        </div>
        <div class="col">
          @if (currentHolding) {
            @if (transactionType === sell) {
              <ejs-numerictextbox
                formControlName="trimPositionPct"
                placeholder="Trim position (%)"
                floatLabelType="Auto"
                [showSpinButton]="false"
                format="p2"
                [min]="0"
                [max]="1"
              ></ejs-numerictextbox>
            } @else if (transactionType === purchase) {
              <ejs-numerictextbox
                formControlName="topupPositionPct"
                placeholder="Top up position (%)"
                floatLabelType="Auto"
                [showSpinButton]="false"
                format="p2"
                [min]="0"
              ></ejs-numerictextbox>
            } @else {
              <ejs-numerictextbox
                placeholder="-"
                floatLabelType="Auto"
                [showSpinButton]="false"
                [enabled]="false"
              ></ejs-numerictextbox>
            }
          } @else if (transactionType === purchase) {
            <ejs-numerictextbox
              formControlName="quantityPctOfPtfAum"
              placeholder="% of ptf AUM"
              floatLabelType="Auto"
              [showSpinButton]="false"
              format="p2"
              [min]="0"
            ></ejs-numerictextbox>
          } @else {
            <ejs-numerictextbox
              placeholder="-"
              floatLabelType="Auto"
              [showSpinButton]="false"
              [enabled]="false"
            ></ejs-numerictextbox>
          }
          <table class="table table-sm mt-3">
            <tr>
              <td style="font-size: 12px;">New alloc:</td>
              <td class="text-end" style="font-size: 12px;">
                @if (newAllocPct) {
                  {{ newAllocPct | percent: '1.2-2' }} of AUM
                } @else {
                  -
                }
              </td>
            </tr>
            <tr>
              <td style="font-size: 12px;">New quantity:</td>
              <td class="text-end" style="font-size: 12px;">
                @if (newQuantity) {
                  {{ newQuantity | number }} shares / units
                } @else {
                  -
                }
              </td>
            </tr>
            <tr>
              <td style="font-size: 12px;">New nominal:</td>
              <td class="text-end" style="font-size: 12px;">
                @if (newNominal) {
                  {{ newNominal | number }} {{ securityCurrency }}
                } @else {
                  -
                }
              </td>
            </tr>
          </table>
        </div>
        <div class="col d-flex align-items-center" style="max-width: 60px">
          <button class="btn btn-outline-danger border-0" (click)="removeAllocation()" [disabled]="!canRemove">
            <i class="bi bi-dash-circle"></i>
          </button>
        </div>
      </div>
    </form>
  `
})
export class AllocationFormComponent implements OnInit, OnChanges {
  public brokers$: Observable<Broker[]> | null = null;
  public portfoliosWithAccounts: PortfolioWithAccounts[] = [];
  public accounts: Account[] = [];
  public lblFxRate: string = 'FX rate';
  public fxRateLoading: boolean = false;
  public portfolioMarketValue: number | null = null;
  public portfolioMarketValueLoading: boolean = false;
  public portfolioRefCurrency: string | null = null;
  public currentHolding: BasicHolding | null = null;
  private brokerExcludedPortfolios: string[] | null = null;
  public sell = TRANSACTION_TYPE_VALUES['sell'].code;
  public purchase = TRANSACTION_TYPE_VALUES['purchase'].code;
  public approxQuantityValue: number | null = null;
  public approxNominalValue: number | null = null;
  private portfolioRefCurrencyToSecurityCurrencyFxRate: number | null = null;

  public newAllocPct: number | null = null;
  public newQuantity: number | null = null;
  public newNominal: number | null = null;

  @Input()
  public form: FormGroup = new FormGroup({});

  @Input()
  public canRemove: boolean = true;

  @Input()
  public showSeparator: boolean = false;

  @Input()
  public quantityTypeLabel: string | null = null;

  @Input()
  public securityCurrency: string | null | undefined = null;

  @Input()
  public securityPrice: number | null | undefined = null;

  @Input()
  public custodianExcludedPortfolios: string[] | null = null;

  @Input()
  public portfoliosHoldingSecurity: BasicHoldingWithBasicPortfolio[] | null | undefined = null;

  @Input()
  public transactionType: string | null | undefined = null;

  @Input()
  public totalQuantityOrNominal: number | null | undefined = null;

  @Output()
  public removeRequested = new EventEmitter<void>();

  @Output()
  public ready = new EventEmitter<void>();

  @ViewChild('ddPortfolio')
  public ddPortfolio: DropDownList | null = null;

  constructor(
    private brokerService: BrokersService,
    private portfoliosService: PortfoliosService,
    private fxRatesService: FxRatesService,
    private holdingsService: HoldingsService
  ) {}

  public ngOnInit(): void {
    this.brokers$ = this.brokerService
      .listBrokers$(false)
      .pipe(map((brokers) => brokers.map((b) => ({ ...b, destination: getBrokerDestinationName(b.destination) }))));
    this.portfoliosService.listPortfolios$().subscribe((portfolios) => {
      this.portfoliosWithAccounts = portfolios;
      this.ready.emit();
    });

    this.form.get('portfolioStr')?.valueChanges.subscribe((portfolioStr: string) => {
      this.currentHolding = null;
      this.portfolioMarketValue = null;
      this.portfolioRefCurrency = null;
      this.portfolioRefCurrencyToSecurityCurrencyFxRate = null;

      const selectedPortfolio = this.portfoliosWithAccounts.find((p) => p.portfolio.internalName === portfolioStr);

      if (selectedPortfolio) {
        this.accounts = selectedPortfolio.accounts;
        this.form?.get('portfolio')?.setValue(selectedPortfolio.portfolio);

        if (selectedPortfolio.portfolio.externalName) {
          this.form?.get('isEamAccount')?.enable();
        } else {
          this.form?.get('isEamAccount')?.setValue(null);
          this.form?.get('isEamAccount')?.disable();
        }

        this.form?.get('accountStr')?.enable();
        this.setPortfolioMarketValue(selectedPortfolio.portfolio.id);
        this.portfolioRefCurrency = selectedPortfolio.portfolio.currency;
        this.currentHolding = this.portfoliosHoldingSecurity?.find(
          (h) => h.portfolio.id === selectedPortfolio.portfolio.id
        ) as BasicHolding;
        this.setPortfolioRefCurrencyToSecurityCurrencyFxRate();
      } else {
        this.form?.get('portfolio')?.setValue(null);
        this.form?.get('account')?.setValue(null);
        this.form?.get('accountStr')?.setValue(null);
        this.form?.get('accountStr')?.disable();
        this.form?.get('isEamAccount')?.setValue(null);
        this.form?.get('isEamAccount')?.disable();
      }
    });

    this.form.get('quantity')?.valueChanges.subscribe((quantity: number) => {
      this.approxQuantityValue =
        this.isQuantity() && this.securityPrice && quantity ? quantity * this.securityPrice : null;
      this.approxNominalValue =
        !this.isQuantity() && this.securityPrice && this.securityPrice > 0 && quantity
          ? quantity / this.securityPrice
          : null;

      this.newQuantity =
        (this.currentHolding?.amount ?? 0) +
        (this.isSell() ? -1 : 1) * floor(this.isQuantity() ? quantity : (this.approxNominalValue ?? 0));
      this.newNominal =
        (this.currentHolding?.marketValue ?? 0) +
        (this.isSell() ? -1 : 1) * (!this.isQuantity() ? quantity : (this.approxQuantityValue ?? 0));
      this.newAllocPct =
        this.portfolioMarketValue &&
        this.portfolioMarketValue > 0 &&
        this.portfolioRefCurrencyToSecurityCurrencyFxRate &&
        this.newNominal
          ? this.newNominal / (this.portfolioMarketValue * this.portfolioRefCurrencyToSecurityCurrencyFxRate)
          : null;
    });

    this.form.get('quantityPctOfPtfAum')?.valueChanges.subscribe((quantityPctOfPtfAum: number) => {
      if (!!quantityPctOfPtfAum && quantityPctOfPtfAum >= 0) {
        this.calculateQuantityFromPtfAumPct(quantityPctOfPtfAum);
      }
    });

    this.form.get('topupPositionPct')?.valueChanges.subscribe((topUpPositionPct: number) => {
      if (!!topUpPositionPct && topUpPositionPct >= 0) {
        this.calculateQuantityFromChangedPosition(topUpPositionPct);
      }
    });

    this.form.get('trimPositionPct')?.valueChanges.subscribe((trimPositionPct: number) => {
      if (!!trimPositionPct && trimPositionPct >= 0 && trimPositionPct <= 1) {
        this.calculateQuantityFromChangedPosition(trimPositionPct);
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['custodianExcludedPortfolios']?.currentValue !== changes['custodianExcludedPortfolios']?.previousValue
    ) {
      this.refreshDdPortfolio();
    }

    if (changes['quantityTypeLabel']?.currentValue !== changes['quantityTypeLabel']?.previousValue) {
      if (this.form.get('quantity')?.value && this.securityPrice) {
        const curQuantity = this.form.get('quantity')?.value;
        const newQuantity = this.isQuantity()
          ? floor(curQuantity / this.securityPrice)
          : curQuantity * this.securityPrice;
        this.form.get('quantity')?.setValue(newQuantity);
      }
    }

    if (changes['securityCurrency']?.currentValue !== changes['securityCurrency']?.previousValue) {
      this.setPortfolioRefCurrencyToSecurityCurrencyFxRate();
    }
  }

  private refreshDdPortfolio() {
    this.ddPortfolio?.refresh();

    if (this.isPortfolioExcluded(this.form?.get('portfolioStr')?.value as string)) {
      this.form?.get('portfolioStr')?.setValue(null);
    }
  }

  public onBrokerSelected(event: ChangeEventArgs): void {
    this.brokerExcludedPortfolios = (event.itemData as Broker).excludedPortfolios;
    this.refreshDdPortfolio();
  }

  public onPortfolioSelected(event: ChangeEventArgs): void {
    const ptfInternalName = event.value as string;

    if (
      this.custodianExcludedPortfolios?.includes(ptfInternalName) ||
      this.brokerExcludedPortfolios?.includes(ptfInternalName)
    ) {
      this.form?.get('portfolioStr')?.setValue(null);
    }

    this.form?.get('trimPositionPct')?.reset();
    this.form?.get('topupPositionPct')?.reset();
    this.form?.get('quantityPctOfPtfAum')?.reset();

    this.newAllocPct = null;
    this.newQuantity = null;
    this.newNominal = null;
  }

  public onAccountSelected(event: ChangeEventArgs): void {
    this.form?.get('account')?.setValue(event.itemData);

    this.setFxRate();
  }

  public removeAllocation(): void {
    this.removeRequested.emit();
  }

  public get quantityTypeLabelPct(): string {
    return `${this.quantityTypeLabel} (%)`;
  }

  public onFilteringPortfolios(e: any): void {
    e.preventDefaultAction = true;

    const predicate = new Predicate('portfolio.internalName', 'contains', e.text, true, true)
      .or('portfolio.shortName', 'contains', e.text, true, true)
      .or('portfolio.externalName', 'contains', e.text, true, true);

    let query = new Query();
    query = e.text != '' ? query.where(predicate) : query;

    e.updateData(this.portfoliosWithAccounts, query);
  }

  public isPortfolioExcluded(portfolioInternalName: string | null | undefined): boolean {
    return (
      portfolioInternalName !== null &&
      portfolioInternalName !== undefined &&
      ((this.custodianExcludedPortfolios?.includes(portfolioInternalName) ?? false) ||
        (this.brokerExcludedPortfolios?.includes(portfolioInternalName) ?? false))
    );
  }

  private setFxRate(): void {
    const accountCurrency = this.form.get('account')?.value?.currency;

    if (accountCurrency && this.securityCurrency) {
      if (accountCurrency === this.securityCurrency) {
        this.lblFxRate = `${accountCurrency}/${this.securityCurrency} FX rate`;
        this.form.get('fxRate')?.setValue(1);
        this.form.get('fxRate')?.disable();
      } else {
        this.fxRateLoading = true;
        this.lblFxRate = 'Loading...';
        this.form.get('fxRate')?.disable();
        this.fxRatesService.getFxRate$(accountCurrency, this.securityCurrency).subscribe((fxRate) => {
          this.form.get('fxRate')?.setValue(fxRate);
          this.form.get('fxRate')?.enable();
          this.lblFxRate = `${accountCurrency}/${this.securityCurrency} FX rate`;
          this.fxRateLoading = false;
        });
      }
    }
  }

  private setPortfolioMarketValue(portfolioId: number): void {
    if (portfolioId) {
      this.portfolioMarketValueLoading = true;
      this.holdingsService.getMarketValueOfPortfolio$(portfolioId).subscribe((marketValue) => {
        this.portfolioMarketValue = marketValue;

        if (marketValue) {
          this.form.get('quantityPctOfPtfAum')?.enable();
        } else {
          this.form.get('quantityPctOfPtfAum')?.disable();
          this.form.get('quantityPctOfPtfAum')?.setValue(null);
        }

        this.portfolioMarketValueLoading = false;
      });
    } else {
      this.portfolioMarketValue = null;
      this.form.get('quantityPctOfPtfAum')?.disable();
      this.form.get('quantityPctOfPtfAum')?.setValue(null);
    }
  }

  private calculateQuantityFromChangedPosition(positionChangePct: number): void {
    if (this.currentHolding) {
      const newQuantity = this.isQuantity()
        ? floor(this.currentHolding.amount * positionChangePct)
        : this.currentHolding.marketValue * positionChangePct;
      this.form.get('quantity')?.setValue(newQuantity);
    }
  }

  private calculateQuantityFromPtfAumPct(quantityPctOfPtfAum: number): void {
    if (this.portfolioMarketValue && this.portfolioRefCurrencyToSecurityCurrencyFxRate) {
      let newQuantity: number | null = null;

      if (this.isQuantity()) {
        if (this.securityPrice) {
          newQuantity = floor(
            (this.portfolioMarketValue * this.portfolioRefCurrencyToSecurityCurrencyFxRate * quantityPctOfPtfAum) /
              this.securityPrice
          );
        }
      } else {
        newQuantity =
          this.portfolioMarketValue * this.portfolioRefCurrencyToSecurityCurrencyFxRate * quantityPctOfPtfAum;
      }

      if (newQuantity && newQuantity > 0) {
        this.form.get('quantity')?.setValue(newQuantity);
      }
    }
  }

  private isQuantity(): boolean {
    return this.quantityTypeLabel === QUANTITY_TYPE_VALUES['quantity'].code;
  }

  private isSell(): boolean {
    return this.transactionType === this.sell;
  }

  private setPortfolioRefCurrencyToSecurityCurrencyFxRate(): void {
    if (this.portfolioRefCurrency && this.securityCurrency) {
      this.fxRatesService.getFxRate$(this.portfolioRefCurrency, this.securityCurrency).subscribe((fxRate) => {
        this.portfolioRefCurrencyToSecurityCurrencyFxRate = fxRate;
      });
    }
  }
}
