import { Component, Input, OnInit } from '@angular/core';
import { BrokersService } from '../brokers.service';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { BrokerToAddOrUpdate, BrokerToAddOrUpdateFormConfig } from '../broker.model';
import { MatError, MatFormFieldModule, MatHint } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { JsonPipe } from '@angular/common';
import { ValidationErrorComponent } from '../../shared/validation-error.component';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { environment } from '../../../environments/environment';
import { PortfolioWithGroupKey } from '../../portfolios/portfolio.model';
import { PortfoliosService } from '../../portfolios/portfolios.service';
import { CheckBoxSelectionService, DropDownListModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { FormControlLabelComponent } from '../../shared/form-control-label.component';
import { BROKER_DESTINATION_VALUES } from '../../shared/code-name-value.model';
import {
  BROKER_DESTINATION_EMAIL_CODE as BROKER_DESTINATION_EMAIL_CODE,
  CIGP_AM_PORTFOLIO_LONG_NAME
} from '../../shared/constants';
import { RxwebValidators } from '@rxweb/reactive-form-validators';

export interface EmailFormConfig {
  email: FormControl<string | null>;
}

@Component({
  selector: 'of-broker-form',
  imports: [
    DropDownListModule,
    FormControlLabelComponent,
    JsonPipe,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MultiSelectModule,
    ReactiveFormsModule,
    RouterModule,
    SwitchModule,
    TextBoxModule,
    ValidationErrorComponent
  ],
  template: `
    <form [formGroup]="brokerForm">
      <div class="card mb-3">
        <div class="card-header d-flex justify-content-between">
          @if (brokerId && brokerId > 0) {
            <h5 class="card-title m-0">UPDATE BROKER {{ brokerId }}</h5>
          } @else {
            <h5 class="card-title m-0">NEW BROKER</h5>
          }

          <!-- Hidden -->
          <div class="d-flex align-items-center gap-2">
            <ejs-switch formControlName="hidden"></ejs-switch>
            <div class="text-danger">Hidden</div>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex mb-3 gap-3 align-items-start">
            <!-- Code -->
            <div class="flex-fill">
              <ejs-textbox placeholder="Code *" floatLabelType="Auto" formControlName="code"></ejs-textbox>
              <of-validation-error [form]="brokerForm" field="code" validationRule="required">
                Code is required</of-validation-error
              >
            </div>

            <!-- Name -->
            <div class="flex-fill">
              <ejs-textbox placeholder="Name *" floatLabelType="Auto" formControlName="name"></ejs-textbox>
              <of-validation-error [form]="brokerForm" field="name" validationRule="required">
                Name is required</of-validation-error
              >
            </div>

            <!-- Destination -->
            <div style="width: 210px">
              <ejs-dropdownlist
                placeholder="Destination *"
                [dataSource]="brokerDestinations"
                [fields]="{ text: 'name', value: 'code' }"
                floatLabelType="Auto"
                formControlName="destination"
              ></ejs-dropdownlist>
              <of-validation-error [form]="brokerForm" field="destination" validationRule="required">
                Destination is required</of-validation-error
              >
            </div>

            <div style="width: 610px">
              <ejs-textbox
                placeholder="CIGP Account Number"
                floatLabelType="Auto"
                formControlName="cigpAccountNumber"
              ></ejs-textbox>
              <small class="text-muted" style="font-size: 80%;"> Shown on PDF Order </small>
              <of-validation-error [form]="brokerForm" field="destination" validationRule="required">
                CIGP Account Number is required</of-validation-error
              >
            </div>

            <!-- Hide Preparer Signature -->
            <div class="d-flex align-items-center gap-2 mt-4">
              <ejs-switch formControlName="hidePreparerSignature"></ejs-switch>
              <div>Hide Preparer Signature</div>
            </div>
          </div>
          <div class="d-flex gap-3 mb-3">
            <!-- Emails -->
            <div class="card flex-fill" formArrayName="emails">
              <div class="card-header d-flex justify-content-between align-items-center">
                <div class="card-title m-0">EMAILS</div>
                <button class="btn btn-lg btn-outline-primary border-0" (click)="addEmail()">
                  <i class="bi bi-plus-circle"></i>
                </button>
              </div>
              <div class="card-body">
                @for (email of emails.controls; track $index) {
                  <div
                    [formGroup]="getEmailAsFormGroup(email)"
                    class="d-flex justify-content-between align-items-center gap-3"
                  >
                    <div class="flex-fill">
                      <ejs-textbox
                        [placeholder]="computeEmailPlaceholder($index)"
                        floatLabelType="Auto"
                        formControlName="email"
                      ></ejs-textbox>
                      <of-validation-error [form]="getEmailAsFormGroup(email)" field="email" validationRule="required">
                        Emails is required</of-validation-error
                      >
                      <of-validation-error [form]="getEmailAsFormGroup(email)" field="email" validationRule="email">
                        Invalid email address</of-validation-error
                      >
                    </div>
                    <button class="btn btn-outline-danger border-0" (click)="removeEmail($index)">
                      <i class="bi bi-dash-circle"></i>
                    </button>
                  </div>
                }
              </div>
            </div>

            <!-- Override Approvers Emails -->
            <div class="card flex-fill" formArrayName="overrideApproversEmails">
              <div class="card-header d-flex justify-content-between align-items-center">
                <div class="card-title m-0">OVERRIDE APPROVERS EMAILS</div>
                <button class="btn btn-lg btn-outline-primary border-0" (click)="addOverrideApproverEmail()">
                  <i class="bi bi-plus-circle"></i>
                </button>
              </div>
              <div class="card-body">
                @for (email of overrideApproversEmails.controls; track $index) {
                  <div
                    [formGroup]="getOverrideApproverEmailAsFormGroup(email)"
                    class="d-flex justify-content-between align-items-center gap-3"
                  >
                    <div class="flex-fill">
                      <ejs-textbox
                        [placeholder]="computeEmailPlaceholder($index)"
                        floatLabelType="Auto"
                        formControlName="email"
                      ></ejs-textbox>
                      <of-validation-error
                        [form]="getOverrideApproverEmailAsFormGroup(email)"
                        field="email"
                        validationRule="required"
                      >
                        Emails is required</of-validation-error
                      >
                      <of-validation-error
                        [form]="getOverrideApproverEmailAsFormGroup(email)"
                        field="email"
                        validationRule="email"
                      >
                        Invalid email address</of-validation-error
                      >
                    </div>
                    <button class="btn btn-outline-danger border-0" (click)="removeOverrideApproverEmail($index)">
                      <i class="bi bi-dash-circle"></i>
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
          <div class="d-flex flex-column gap-1">
            <of-form-control-label>Excluded portfolios</of-form-control-label>
            <ejs-multiselect
              mode="CheckBox"
              [enableGroupCheckBox]="true"
              [dataSource]="portfolios"
              [fields]="{ text: 'shortName', value: 'internalName', groupBy: 'groupKey' }"
              formControlName="excludedPortfolios"
            >
            </ejs-multiselect>
          </div>
        </div>
        <div class="card-footer">
          <a class="btn btn-outline" [routerLink]="['/brokers']">BACK</a>
          <button
            type="submit"
            class="btn btn-outline-primary"
            [disabled]="submitting || !brokerForm.valid"
            (click)="saveBroker()"
          >
            SAVE
            @if (submitting) {
              <mat-spinner diameter="20"></mat-spinner>
            }
          </button>
        </div>
      </div>
    </form>

    @if (environment === 'development') {
      <div class="card my-3 text-warning-emphasis bg-warning-subtle">
        <div class="card-body font-monospace">
          <small>
            {{ brokerForm.getRawValue() | json }}
          </small>
        </div>
      </div>
    }
  `,
  providers: [CheckBoxSelectionService]
})
export class BrokerFormComponent implements OnInit {
  public brokerForm: FormGroup = new FormGroup({});
  public submitting = false;
  public environment = environment.env;
  public portfolios: PortfolioWithGroupKey[] | null = null;
  public brokerDestinations = BROKER_DESTINATION_VALUES;

  @Input()
  public brokerId: number | null = null;

  constructor(
    private brokersService: BrokersService,
    private fb: FormBuilder,
    private portfoliosService: PortfoliosService
  ) {}

  public ngOnInit(): void {
    this.brokerForm = this.fb.group<BrokerToAddOrUpdateFormConfig>({
      code: this.fb.control<string | null>(null, Validators.required),
      destination: this.fb.control<string | null>(null, Validators.required),
      emails: this.fb.array<string>([], {
        validators: [
          RxwebValidators.required({
            conditionalExpression: () => this.brokerForm?.get('destination')?.value === BROKER_DESTINATION_EMAIL_CODE
          })
        ]
      }),
      excludedPortfolios: this.fb.control<string[] | null>(null),
      hidden: this.fb.control<boolean | null>(null),
      hidePreparerSignature: this.fb.control<boolean | null>(null),
      name: this.fb.control<string | null>(null),
      cigpAccountNumber: this.fb.control<string | null>(CIGP_AM_PORTFOLIO_LONG_NAME, Validators.required),
      overrideApproversEmails: this.fb.array<string>([])
    });

    this.brokerForm.get('destination')?.valueChanges.subscribe((destination) => {
      if (destination === BROKER_DESTINATION_EMAIL_CODE) {
        this.brokerForm.get('emails')?.enable();
      } else {
        this.brokerForm.get('emails')?.disable();
        this.brokerForm.get('emails')?.reset();
      }
    });

    this.portfoliosService.listPortfolios$().subscribe((portfolios) => {
      this.portfolios = portfolios.map((p) => ({
        ...p.portfolio,
        groupKey: p.portfolio.shortName.split('_')[0]
      }));

      this.addEmail();

      if (this.brokerId && this.brokerId > 0) {
        this.brokersService.getBrokerForUpdate$(this.brokerId).subscribe((broker) => {
          if (broker) {
            this.setInitialValues(broker);
          }
        });
      }
    });
  }

  public saveBroker(): void {
    this.submitting = true;

    const observable$ =
      this.brokerId && this.brokerId > 0
        ? this.brokersService.updateBroker$(this.brokerId, this.prepareBroker())
        : this.brokersService.insertBroker$(this.prepareBroker());

    observable$.subscribe({
      next: () => (this.submitting = false),
      error: () => (this.submitting = false)
    });
  }

  private prepareBroker(): BrokerToAddOrUpdate {
    const broker = this.brokerForm.getRawValue();

    if (broker.emails) {
      broker.emails = broker.emails.filter((email: { email: string }) => !!email && !!email.email);

      if (broker.emails.length === 0) {
        broker.emails = null;
      }
    }

    return broker;
  }

  public get emails(): FormArray {
    return this.brokerForm?.get('emails') as FormArray;
  }

  public addEmail(): void {
    this.emails?.push(this.createEmailForm());
  }

  public removeEmail(index: number): void {
    this.emails?.removeAt(index);
  }

  public getEmailAsFormGroup(email: AbstractControl): FormGroup<EmailFormConfig> {
    return email as FormGroup<EmailFormConfig>;
  }

  public get overrideApproversEmails(): FormArray {
    return this.brokerForm?.get('overrideApproversEmails') as FormArray;
  }

  public addOverrideApproverEmail(): void {
    this.overrideApproversEmails?.push(this.createEmailForm());
  }

  public removeOverrideApproverEmail(index: number): void {
    this.overrideApproversEmails?.removeAt(index);
  }

  public getOverrideApproverEmailAsFormGroup(email: AbstractControl): FormGroup<EmailFormConfig> {
    return email as FormGroup<EmailFormConfig>;
  }

  public computeEmailPlaceholder(index: number): string {
    return `Email ${index + 1}`;
  }

  private setInitialValues(broker: BrokerToAddOrUpdate): void {
    if (broker.emails && broker.emails.length > 1) {
      for (let index = 0; index < broker.emails.length - 1; index++) {
        this.addEmail();
      }
    }

    if (broker.overrideApproversEmails && broker.overrideApproversEmails.length > 0) {
      for (let index = 0; index < broker.overrideApproversEmails.length; index++) {
        this.addOverrideApproverEmail();
      }
    }

    this.brokerForm.get('code')?.disable();
    this.brokerForm.patchValue(broker);
  }

  private createEmailForm(): FormGroup<EmailFormConfig> {
    return this.fb.group<EmailFormConfig>({
      email: this.fb.control<string | null>(null, {
        validators: [Validators.required, Validators.email]
      })
    });
  }
}
