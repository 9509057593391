import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgModel, ReactiveFormsModule, Validators } from '@angular/forms';
import { ChangeEventArgs, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { Observable } from 'rxjs';
import { Custodian } from '../../custodians/custodian.model';
import {
  CURRENCY_VALUES,
  EXECUTION_INSTRUCTION_VALUES,
  EXECUTION_METHOD_VALUES,
  ORDER_TYPE_VALUES,
  QUANTITY_TYPE_VALUES,
  TIF_VALUES,
  TRANSACTION_TYPE_VALUES,
  FX_NET_RATE,
  FX_SECURITY_TYPE_VALUES
} from '../../shared/code-name-value.model';
import { environment } from '../../../environments/environment';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { NumericTextBoxModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { AsyncPipe, CommonModule, JsonPipe } from '@angular/common';
import { AllocationsFormComponent } from '../../allocations-form/allocations-form.component';
import { AttachmentUploaderComponent } from '../../shared/attachment-uploader.component';
import { CustodiansService } from '../../custodians/custodians.service';
import { Router } from '@angular/router';
import {
  AllocationToAddOrUpdate,
  OrderToAddOrUpdate,
  OrderWithAllocationsToAddOrUpdate,
  FxOrderToAddOrUpdateFormConfig
} from '../order-with-allocations-to-add-or-update.model';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import moment, { Moment } from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { OrderCloningParams } from '../create-order/create-order.component';
import {
  CLONE_TYPE_WITH_ALLOCATION,
  FX_FORWARD_SECURITY_TYPE,
  FX_SPOT_SECURITY_TYPE,
  ROLE_FX_ORDER_WRITER_RESTRICTED
} from '../../shared/constants';
import { OrdersService } from '../orders.service';
import { FxRatesService } from '../../core/fx-rates.service';
import { OrderFlowAuthService } from '../../auth/order-flow-auth.service';
import _ from 'lodash';

@Component({
  selector: 'of-fx-order-form',
  imports: [
    AllocationsFormComponent,
    AsyncPipe,
    AttachmentUploaderComponent,
    DatePickerModule,
    DropDownListModule,
    JsonPipe,
    MatProgressSpinnerModule,
    NumericTextBoxModule,
    RadioButtonModule,
    ReactiveFormsModule,
    TextBoxModule,
    CommonModule
  ],
  templateUrl: './fx-order-form.component.html'
})
export class FxOrderFormComponent implements OnInit {
  public fxForm: FormGroup = new FormGroup({});
  public custodians$: Observable<Custodian[]> | null = null;
  public submitting = false;
  public lblQuantityNominalValue = 'Total (shared by all allocations) *';
  public executionInstructionValues = EXECUTION_INSTRUCTION_VALUES;
  public executionMethodValues = Object.values(EXECUTION_METHOD_VALUES);
  public orderTypeValues = Object.values(ORDER_TYPE_VALUES);
  public quantityTypeValues = Object.values(QUANTITY_TYPE_VALUES);
  public tifValues = Object.values(TIF_VALUES);
  public transactionTypeValues = Object.values(TRANSACTION_TYPE_VALUES);
  public currencies = Object.values(CURRENCY_VALUES);
  public netRates = Object.values(FX_NET_RATE);
  public securityTypes = Object.values(FX_SECURITY_TYPE_VALUES);
  public environment = environment.env;
  public excludePortfolios: string[] = [];
  public lblQuantityType: string | null | undefined = null;
  public ntbLimitPriceFormat = '#,###.#####';
  public lblFxRate: string = 'FX rate';
  public fxRateLoading: boolean = false;
  public securityName: string = '';
  public FX_SPOT_SECURITY_TYPE = FX_SPOT_SECURITY_TYPE;
  public FX_FORWARD_SECURITY_TYPE = FX_FORWARD_SECURITY_TYPE;

  @ViewChild(AllocationsFormComponent, { static: true })
  public allocationsForm: AllocationsFormComponent | null = null;

  @Input()
  public orderToUpdateId: number | null = null;

  @Input()
  public cloningParams: OrderCloningParams | null = null;

  constructor(
    private fb: FormBuilder,
    private custodiansService: CustodiansService,
    private ordersService: OrdersService,
    private router: Router,
    private fxRatesService: FxRatesService,
    private authService: OrderFlowAuthService
  ) {}

  setFxRate(): void {
    const quantity = this.fxForm.get('quantity')?.value;
    const sellCurrency = this.fxForm.get('fxSellCurrency')?.value;
    const buyCurrency = this.fxForm.get('fxBuyCurrency')?.value;
    const transactionType = this.fxForm.get('transactionType')?.value;
    const formattedQuantity = new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(quantity);

    if (buyCurrency && sellCurrency && transactionType != '') {
      if (buyCurrency === sellCurrency) {
        this.lblFxRate = `${buyCurrency}/${sellCurrency} FX rate = 1`;
        this.fxForm.get('fxRate')?.setValue(1);
        this.fxForm.get('fxRate')?.disable();
      } else {
        this.fxRateLoading = true;
        this.lblFxRate = 'Loading...';
        this.fxForm.get('fxRate')?.disable();
        if (transactionType == 'Sell') {
          this.fxRatesService.getFxRate$(sellCurrency, buyCurrency).subscribe((fxRate) => {
            this.fxForm.get('fxRate')?.setValue(fxRate);
            this.fxForm.get('fxRate')?.enable();
            const formattedTotal = new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(quantity * fxRate);

            const formattedFxRate = new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4
            }).format(fxRate);

            this.lblFxRate = `Sell ${formattedQuantity} ${sellCurrency} and buy ~${formattedTotal} ${buyCurrency} (indicative rate: ${formattedFxRate})`;
            this.fxRateLoading = false;
          });
        } else {
          this.fxRatesService.getFxRate$(buyCurrency, sellCurrency).subscribe((fxRate) => {
            this.fxForm.get('fxRate')?.setValue(fxRate);
            this.fxForm.get('fxRate')?.enable();
            const formattedTotal = new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }).format(quantity * fxRate);

            const formattedFxRate = new Intl.NumberFormat('en-US', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 4
            }).format(fxRate);

            this.lblFxRate = `Purchase ${formattedQuantity} ${buyCurrency} and sell ~${formattedTotal} ${sellCurrency} (indicative rate: ${formattedFxRate})`;
            this.fxRateLoading = false;
          });
        }
      }
    }
  }

  public ngOnInit(): void {
    this.custodians$ = this.custodiansService.listCustodians$(false);
    this.setInitialData();

    this.fxForm = this.fb.group<FxOrderToAddOrUpdateFormConfig>({
      additionalInformation: this.fb.control<string | null>(null),
      additionalInternalInformation: this.fb.control<string | null>(null, Validators.required),
      attachmentTempPath: this.fb.control<string | null>(null),
      custodian: this.fb.control<string | null>(null, Validators.required),
      executionInstruction: this.fb.control<string | null>(null, Validators.required),
      executionMethod: this.fb.control<string | null>(null, Validators.required),
      limitPrice: this.fb.control<number | null>(null),
      orderType: this.fb.control<string | null>(null, Validators.required),
      quantity: this.fb.control<number | null>(null, Validators.required),
      quantityType: this.fb.control<string | null>(null, Validators.required),
      tif: this.fb.control<string | null>(null, Validators.required),
      transactionDate: this.fb.control<Date | null>(null, Validators.required),
      transactionType: this.fb.control<string | null>(null, Validators.required),
      fxSellCurrency: this.fb.control<number | null>(null, Validators.required),
      fxBuyCurrency: this.fb.control<number | null>(null, Validators.required),
      fxNetRate: this.fb.control<number | null>(null, Validators.required),
      fxBrokerageFee: this.fb.control<number | null>(null, Validators.required),
      fxSecurityType: this.fb.control<string | null>(FX_SPOT_SECURITY_TYPE, Validators.required),
      expiryDate: this.fb.control<Date | null>(null, Validators.required),
      valueDate: this.fb.control<Date | null>(null),
      fxFinalCalculatedPrice: this.fb.control<number | null>(null, Validators.required)
    });

    this.fxForm.get('orderType')?.valueChanges.subscribe((orderType) => {
      if (orderType === ORDER_TYPE_VALUES['market'].code) {
        this.fxForm.get('limitPrice')?.disable();
        this.fxForm.get('limitPrice')?.reset();
      } else {
        this.fxForm.get('limitPrice')?.enable();
      }
    });

    this.fxForm.get('quantityType')?.setValue(QUANTITY_TYPE_VALUES['quantity'].code);
    this.fxForm.get('executionQuantityType')?.setValue(QUANTITY_TYPE_VALUES['quantity'].code);

    this.fxForm.get('sellCurrency')?.valueChanges.subscribe(() => {
      this.setFxRate();
      this.calculateFinalPrice();
    });

    this.fxForm.get('buyCurrency')?.valueChanges.subscribe(() => {
      this.setFxRate();
      this.calculateFinalPrice();
    });

    this.fxForm.get('transactionType')?.valueChanges.subscribe(() => {
      this.setFxRate();
      this.calculateFinalPrice();
    });

    this.fxForm.get('quantity')?.valueChanges.subscribe(() => {
      this.setFxRate();
      this.calculateFinalPrice();
    });

    this.fxForm.get('fxBrokerageFee')?.valueChanges.subscribe(() => {
      this.calculateFinalPrice();
    });

    this.fxForm.get('limitPrice')?.valueChanges.subscribe(() => {
      this.calculateFinalPrice();
    });
  }

  public submit(): void {
    this.submitting = true;
    this.fxForm.disable();
    this.allocationsForm?.form.disable();

    let instructionDate: Moment | null = moment(this.fxForm.get('instructionDate')?.value);
    instructionDate = instructionDate.isValid() ? instructionDate : null;

    let transactionDate: Moment | null = moment(this.fxForm.get('transactionDate')?.value);
    transactionDate = transactionDate.isValid() ? transactionDate : null;

    let valueDate: Moment | null = moment(this.fxForm.get('valueDate')?.value);
    valueDate = valueDate.isValid() ? valueDate : null;

    let fxOrder = this.fxForm.getRawValue() as OrderToAddOrUpdate;

    if (fxOrder.tif === 'GTD') {
      fxOrder.valueDate = null;
    }

    const order: OrderWithAllocationsToAddOrUpdate = {
      order: {
        ...fxOrder,
        transactionDate: transactionDate?.format('YYYY-MM-DD') || null,
        valueDate: valueDate?.format('YYYY-MM-DD') || null,
        instructionDate: instructionDate?.format('YYYY-MM-DD') || null
      },
      allocations: this.allocationsForm?.allocations.getRawValue() as AllocationToAddOrUpdate[]
    };

    const observable$ = this.orderToUpdateId
      ? this.ordersService.updateFxOrder$(this.orderToUpdateId, order)
      : !this.isRestrictedOrderWriter()
        ? this.ordersService.insertFxOrder$(order)
        : this.ordersService.insertRestrictedFxOrder$(order);

    observable$.subscribe({
      next: () => {
        this.submitting = false;
        this.router.navigate(['/orders']);
      },
      error: () => {
        this.submitting = false;
        this.fxForm.enable();
        this.allocationsForm?.form.enable();
      }
    });
  }

  public isRestrictedOrderWriter(): boolean {
    return this.authService.hasRole(ROLE_FX_ORDER_WRITER_RESTRICTED);
  }

  public reset(): void {
    this.fxForm.reset();
    this.allocationsForm?.form.reset();
    this.fxForm.enable();
  }

  public onCustodianSelected(event: ChangeEventArgs): void {
    this.excludePortfolios = (event.itemData as Custodian)?.excludedPortfolios;
  }

  public isLimitOrderWithNetRateOrFxForward(): boolean {
    var orderType = this.fxForm.get('orderType')?.value;
    var fxNetRate = this.fxForm.get('fxNetRate')?.value;
    var fxSecurityType = this.fxForm.get('fxSecurityType')?.value;

    return (orderType === 'LIMIT' && fxNetRate === 'net') || fxSecurityType === FX_FORWARD_SECURITY_TYPE;
  }

  public get tif(): string {
    return this.fxForm.get('tif')?.value;
  }

  public calculateFinalPrice(): void {
    var brokerageFeePct = this.fxForm.get('fxBrokerageFee')?.value;
    var brokerageFee = _.divide(brokerageFeePct, 100);
    var limitPrice = this.fxForm.get('limitPrice')?.value;
    var transactionType = this.fxForm.get('transactionType')?.value;
    var fxFinalCalculatedPrice = 0;

    if (this.isLimitOrderWithNetRateOrFxForward() && limitPrice && brokerageFee && transactionType) {
      if (transactionType == 'Purchase') {
        //Round down to nearest 4dp
        fxFinalCalculatedPrice = _.floor(_.multiply(limitPrice, _.subtract(1, brokerageFee)), 4);
      } else {
        //Round up to nearest 4dp
        fxFinalCalculatedPrice = _.ceil(_.multiply(limitPrice, _.add(1, brokerageFee)), 4);
      }
    }
    this.fxForm.get('fxFinalCalculatedPrice')?.setValue(fxFinalCalculatedPrice);
  }

  private setInitialData(): void {
    if (this.orderToUpdateId) {
      this.setOrderToEditInitialData();
    } else if (this.cloningParams) {
      if (this.cloningParams.cloneType === CLONE_TYPE_WITH_ALLOCATION) {
        this.setOrderToCloneWithAllocationInitialData();
      } else {
        this.setOrderToCloneInitialData();
      }
    }
  }

  private setOrderToCloneInitialData() {
    this.ordersService
      .getOrderForCloning$(this.cloningParams!.orderId)
      .subscribe((orderToClone: OrderToAddOrUpdate) => {
        if (orderToClone) {
          this.fxForm.patchValue(orderToClone);

          this.fxForm.get('instructionDate')?.reset();
          this.fxForm.get('transactionDate')?.reset();

          if (orderToClone.instructionDate) {
            this.fxForm.get('instructionDate')?.setValue(moment(orderToClone.instructionDate).toDate());
          }

          if (orderToClone.transactionDate) {
            this.fxForm.get('transactionDate')?.setValue(moment(orderToClone.transactionDate).toDate());
          }
        }
      });
  }

  private setOrderToCloneWithAllocationInitialData() {
    this.ordersService
      .getOrderForCloningWithAllocation$(this.cloningParams!.orderId)
      .subscribe((orderToClone: OrderWithAllocationsToAddOrUpdate) => {
        this.initializeOrderFormWithOrderWithAllocationData(orderToClone);
      });
  }

  private setOrderToEditInitialData() {
    this.ordersService
      .getFxOrderForUpdate$(this.orderToUpdateId!)
      .subscribe((orderWithAllocationsToUpdate: OrderWithAllocationsToAddOrUpdate) => {
        this.initializeOrderFormWithOrderWithAllocationData(orderWithAllocationsToUpdate);
      });
  }

  private initializeOrderFormWithOrderWithAllocationData(
    orderWithAllocationsToUpdate: OrderWithAllocationsToAddOrUpdate
  ) {
    if (orderWithAllocationsToUpdate) {
      this.fxForm.patchValue(orderWithAllocationsToUpdate.order);
      this.fxForm.get('instructionDate')?.reset();
      this.fxForm.get('transactionDate')?.reset();

      if (orderWithAllocationsToUpdate.order.instructionDate) {
        this.fxForm
          .get('instructionDate')
          ?.setValue(moment(orderWithAllocationsToUpdate.order.instructionDate).toDate());
      }

      if (orderWithAllocationsToUpdate.order.transactionDate) {
        this.fxForm
          .get('transactionDate')
          ?.setValue(moment(orderWithAllocationsToUpdate.order.transactionDate).toDate());
      }

      this.fxForm.get('fxSellCurrency')?.setValue(orderWithAllocationsToUpdate.order.fxSellCurrency);
      this.fxForm.get('fxBuyCurrency')?.setValue(orderWithAllocationsToUpdate.order.fxBuyCurrency);

      this.fxForm.get('quantity')?.setValue(orderWithAllocationsToUpdate.allocations[0].quantity);

      this.allocationsForm?.allocations.patchValue(orderWithAllocationsToUpdate.allocations);

      this.allocationsForm?.allocationFormReady$.subscribe((index) => {
        if (orderWithAllocationsToUpdate && index === 0) {
          this.allocationsForm
            ?.getAllocationForm(0)
            .get('portfolio')
            ?.valueChanges.subscribe(() => {
              if (orderWithAllocationsToUpdate) {
                this.allocationsForm
                  ?.getAllocationForm(0)
                  .get('account')
                  ?.setValue(orderWithAllocationsToUpdate.allocations[0].account);
                this.allocationsForm
                  ?.getAllocationForm(0)
                  .get('accountStr')
                  ?.setValue(orderWithAllocationsToUpdate.allocations[0].account.name);

                if (orderWithAllocationsToUpdate.allocations[0].fxRate) {
                  this.allocationsForm?.getAllocationForm(0).get('fxRate')?.enable();
                }
              }
            });

          this.allocationsForm
            ?.getAllocationForm(0)
            .get('portfolioStr')
            ?.setValue(orderWithAllocationsToUpdate?.allocations[0].portfolio.internalName);
        }
      });
    }
  }
}
