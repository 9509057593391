import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { Directive, ContentChild, ContentChildren, Component, ChangeDetectionStrategy, NgModule } from '@angular/core';
import { ComplexBase, setValue, Template, ArrayBase, ComponentBase, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { ProgressBar, ProgressAnnotation, ProgressTooltip } from '@syncfusion/ej2-progressbar';
const _c0 = ["content"];
export * from '@syncfusion/ej2-progressbar';
import { CommonModule } from '@angular/common';
let input$1 = ['annotationAngle', 'annotationRadius', 'content'];
let outputs$2 = [];
/**
 * ProgressBarAnnotations Directive
 * ```html
 * <e-progressbar-annotations>
 * <e-progressbar-annotation></e-progressbar-annotation>
 * </e-progressbar-annotations>
 * ```
 */
class ProgressBarAnnotationDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$2);
    this.directivePropList = input$1;
  }
}
ProgressBarAnnotationDirective.ɵfac = function ProgressBarAnnotationDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ProgressBarAnnotationDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ProgressBarAnnotationDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ProgressBarAnnotationDirective,
  selectors: [["e-progressbar-annotation"]],
  contentQueries: function ProgressBarAnnotationDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
    }
  },
  inputs: {
    annotationAngle: "annotationAngle",
    annotationRadius: "annotationRadius",
    content: "content"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
__decorate([Template()], ProgressBarAnnotationDirective.prototype, "content", void 0);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressBarAnnotationDirective, [{
    type: Directive,
    args: [{
      selector: 'e-progressbar-annotations>e-progressbar-annotation',
      inputs: input$1,
      outputs: outputs$2,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    content: [{
      type: ContentChild,
      args: ['content']
    }]
  });
})();
/**
 * ProgressBarAnnotation Array Directive
 * @private
 */
class ProgressBarAnnotationsDirective extends ArrayBase {
  constructor() {
    super('annotations');
  }
}
ProgressBarAnnotationsDirective.ɵfac = function ProgressBarAnnotationsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ProgressBarAnnotationsDirective)();
};
ProgressBarAnnotationsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ProgressBarAnnotationsDirective,
  selectors: [["e-progressbar-annotations"]],
  contentQueries: function ProgressBarAnnotationsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ProgressBarAnnotationDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressBarAnnotationsDirective, [{
    type: Directive,
    args: [{
      selector: 'ej-progressbar>e-progressbar-annotations',
      queries: {
        children: new ContentChildren(ProgressBarAnnotationDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
let input = ['color', 'end', 'start'];
let outputs$1 = [];
class RangeColorDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$1);
    this.directivePropList = input;
  }
}
RangeColorDirective.ɵfac = function RangeColorDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RangeColorDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
RangeColorDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RangeColorDirective,
  selectors: [["e-rangecolor"]],
  inputs: {
    color: "color",
    end: "end",
    start: "start"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RangeColorDirective, [{
    type: Directive,
    args: [{
      selector: 'e-rangecolors>e-rangecolor',
      inputs: input,
      outputs: outputs$1,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * RangeColor Array Directive
 * @private
 */
class RangeColorsDirective extends ArrayBase {
  constructor() {
    super('rangecolors');
  }
}
RangeColorsDirective.ɵfac = function RangeColorsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RangeColorsDirective)();
};
RangeColorsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RangeColorsDirective,
  selectors: [["e-rangecolors"]],
  contentQueries: function RangeColorsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, RangeColorDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RangeColorsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-progressbar>e-rangecolors',
      queries: {
        children: new ContentChildren(RangeColorDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs = ['animation', 'annotations', 'cornerRadius', 'enablePersistence', 'enablePieProgress', 'enableProgressSegments', 'enableRtl', 'endAngle', 'gapWidth', 'height', 'innerRadius', 'isActive', 'isGradient', 'isIndeterminate', 'isStriped', 'labelOnTrack', 'labelStyle', 'locale', 'margin', 'maximum', 'minimum', 'progressColor', 'progressThickness', 'radius', 'rangeColors', 'role', 'secondaryProgress', 'secondaryProgressColor', 'secondaryProgressThickness', 'segmentColor', 'segmentCount', 'showProgressValue', 'startAngle', 'theme', 'tooltip', 'trackColor', 'trackThickness', 'type', 'value', 'width'];
const outputs = ['animationComplete', 'load', 'loaded', 'mouseClick', 'mouseDown', 'mouseLeave', 'mouseMove', 'mouseUp', 'progressCompleted', 'textRender', 'tooltipRender', 'valueChanged'];
const twoWays = [''];
/**
 * ProgressBar Component
 * ```html
 * <ejsprogressbar></ejsprogressbar>
 * ```
 */
let ProgressBarComponent = class ProgressBarComponent extends ProgressBar {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['annotations', 'rangeColors'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    try {
      let mod = this.injector.get('ProgressBarProgressAnnotation');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    try {
      let mod = this.injector.get('ProgressBarProgressTooltip');
      if (this.injectedModules.indexOf(mod) === -1) {
        this.injectedModules.push(mod);
      }
    } catch {}
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.context = new ComponentBase();
  }
  ngOnInit() {
    this.context.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.context.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.context.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childAnnotations;
    if (this.childRangeColors) {
      this.tagObjects[1].instance = this.childRangeColors;
    }
    this.context.ngAfterContentChecked(this);
  }
};
ProgressBarComponent.ɵfac = function ProgressBarComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ProgressBarComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
ProgressBarComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ProgressBarComponent,
  selectors: [["ejs-progressbar"]],
  contentQueries: function ProgressBarComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ProgressBarAnnotationsDirective, 5);
      i0.ɵɵcontentQuery(dirIndex, RangeColorsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childAnnotations = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childRangeColors = _t.first);
    }
  },
  inputs: {
    animation: "animation",
    annotations: "annotations",
    cornerRadius: "cornerRadius",
    enablePersistence: "enablePersistence",
    enablePieProgress: "enablePieProgress",
    enableProgressSegments: "enableProgressSegments",
    enableRtl: "enableRtl",
    endAngle: "endAngle",
    gapWidth: "gapWidth",
    height: "height",
    innerRadius: "innerRadius",
    isActive: "isActive",
    isGradient: "isGradient",
    isIndeterminate: "isIndeterminate",
    isStriped: "isStriped",
    labelOnTrack: "labelOnTrack",
    labelStyle: "labelStyle",
    locale: "locale",
    margin: "margin",
    maximum: "maximum",
    minimum: "minimum",
    progressColor: "progressColor",
    progressThickness: "progressThickness",
    radius: "radius",
    rangeColors: "rangeColors",
    role: "role",
    secondaryProgress: "secondaryProgress",
    secondaryProgressColor: "secondaryProgressColor",
    secondaryProgressThickness: "secondaryProgressThickness",
    segmentColor: "segmentColor",
    segmentCount: "segmentCount",
    showProgressValue: "showProgressValue",
    startAngle: "startAngle",
    theme: "theme",
    tooltip: "tooltip",
    trackColor: "trackColor",
    trackThickness: "trackThickness",
    type: "type",
    value: "value",
    width: "width"
  },
  outputs: {
    animationComplete: "animationComplete",
    load: "load",
    loaded: "loaded",
    mouseClick: "mouseClick",
    mouseDown: "mouseDown",
    mouseLeave: "mouseLeave",
    mouseMove: "mouseMove",
    mouseUp: "mouseUp",
    progressCompleted: "progressCompleted",
    textRender: "textRender",
    tooltipRender: "tooltipRender",
    valueChanged: "valueChanged"
  },
  standalone: false,
  features: [i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function ProgressBarComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
ProgressBarComponent = __decorate([ComponentMixins([ComponentBase])], ProgressBarComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressBarComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-progressbar',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childAnnotations: new ContentChild(ProgressBarAnnotationsDirective),
        childRangeColors: new ContentChild(RangeColorsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the ProgressBar component.
 */
class ProgressBarModule {}
ProgressBarModule.ɵfac = function ProgressBarModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ProgressBarModule)();
};
ProgressBarModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ProgressBarModule
});
ProgressBarModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressBarModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ProgressBarComponent, ProgressBarAnnotationDirective, ProgressBarAnnotationsDirective, RangeColorDirective, RangeColorsDirective],
      exports: [ProgressBarComponent, ProgressBarAnnotationDirective, ProgressBarAnnotationsDirective, RangeColorDirective, RangeColorsDirective]
    }]
  }], null, null);
})();
const ProgressAnnotationService = {
  provide: 'ProgressBarProgressAnnotation',
  useValue: ProgressAnnotation
};
const ProgressTooltipService = {
  provide: 'ProgressBarProgressTooltip',
  useValue: ProgressTooltip
};
/**
 * NgModule definition for the ProgressBar component with providers.
 */
class ProgressBarAllModule {}
ProgressBarAllModule.ɵfac = function ProgressBarAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ProgressBarAllModule)();
};
ProgressBarAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ProgressBarAllModule
});
ProgressBarAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [ProgressAnnotationService, ProgressTooltipService],
  imports: [[CommonModule, ProgressBarModule], ProgressBarModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressBarAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ProgressBarModule],
      exports: [ProgressBarModule],
      providers: [ProgressAnnotationService, ProgressTooltipService]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ProgressAnnotationService, ProgressBarAllModule, ProgressBarAnnotationDirective, ProgressBarAnnotationsDirective, ProgressBarComponent, ProgressBarModule, ProgressTooltipService, RangeColorDirective, RangeColorsDirective };
