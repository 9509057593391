import { Component } from '@angular/core';
import { CannotApproveOrderService } from './cannot-approve-order.service';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'of-cannot-approve-order',
    imports: [MatCardModule, RouterModule],
    template: `
    <mat-card appearance="outlined">
      <mat-card-title>Forbidden</mat-card-title>
      <mat-card-content>
        @if (message) {
          <p>{{ message }}</p>
        }
      </mat-card-content>
      <mat-card-actions>
        <a mat-button [routerLink]="['/']">BACK</a>
      </mat-card-actions>
    </mat-card>
  `
})
export class CannotApproveOrderComponent {
  public message: string;

  constructor(cannotApproveService: CannotApproveOrderService) {
    this.message = cannotApproveService.message;
  }
}
