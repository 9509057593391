import { Component, Inject } from '@angular/core';
import { ORDER_STATUS_VALUES } from '../shared/code-name-value.model';
import { OrdersService } from '../orders/orders.service';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Order } from '../orders/order.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { showSuccessSnackbar } from '../shared/utils';
import { CommentsFormConfig } from '../shared/comments.model';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'of-cancel-order-dialog',
  standalone: true,
  imports: [ReactiveFormsModule, RouterModule, TextBoxModule],
  template: `
    @if (data) {
      <form [formGroup]="form">
        <h5 mat-dialog-title class="p-3">CANCEL ORDER {{ data.id }}</h5>
        <div mat-dialog-content class="px-3 pb-3 d-flex flex-column gap-2">
          <div>
            {{ data.transactionType }} {{ data.quantity }} {{ data.security.name }} (account:
            {{ data.portfolio.shortName }})
          </div>
          <a [routerLink]="['/orders', data.id]" target="_blank">Order Details</a>
          <div>
            <ejs-textbox
              formControlName="comments"
              placeholder="Comments (optional)"
              multiline="true"
              floatLabelType="Auto"
              style="height: 100px"
            ></ejs-textbox>
          </div>
          <div mat-dialog-actions class="d-flex justify-content-end">
            <div class="btn-group">
              <button class="btn btn-secondary" (click)="onCloseClick()">CLOSE</button>
              <button class="btn btn-danger" [disabled]="!form.valid" (click)="onCancelClick()" cdkFocusInitial>
                CANCEL ORDER
              </button>
            </div>
          </div>
        </div>
      </form>
    }
  `
})
export class CancelOrderDialogComponent {
  public form: FormGroup = new FormGroup({});
  public comments: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<CancelOrderDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Order,
    private ordersService: OrdersService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group<CommentsFormConfig>({
      comments: this.fb.control<string | null>(null)
    });
  }

  public onCancelClick(): void {
    if (this.form) {
      this.form.disable();
      this.ordersService
        .updateOrderStatus$(this.data.id, {
          ...this.form.value,
          orderId: this.data.id,
          newStatus: ORDER_STATUS_VALUES['cancelled']
        })
        .subscribe(() => showSuccessSnackbar(this.snackBar, `Order ${this.data.id} sucessfully cancelled.`));
    }

    this.dialogRef.close();
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
