import { Component, Input, OnInit } from '@angular/core';
import { APPROVAL_STATUS_VALUES, CodeNameItem } from './code-name-value.model';
import { UpperCasePipe } from '@angular/common';

@Component({
  selector: 'of-approval-status-badge',
  standalone: true,
  imports: [UpperCasePipe],
  template: `
    @if (status) {
      <span [class]="styleClass" [title]="status.name">{{ status.name | uppercase }}</span>
    }
  `
})
export class ApprovalStatusBadgeComponent implements OnInit {
  public styleClass = '';

  @Input()
  public status: CodeNameItem | null = null;

  ngOnInit(): void {
    if (this.status) {
      switch (this.status.code) {
        case APPROVAL_STATUS_VALUES['approved'].code:
          this.styleClass = 'badge text-success-emphasis bg-success-subtle';
          break;
        case APPROVAL_STATUS_VALUES['noLongerRequired'].code:
          this.styleClass = 'badge text-secondary-emphasis bg-secondary-subtle';
          break;
        case APPROVAL_STATUS_VALUES['pending'].code:
          this.styleClass = 'badge text-warning-emphasis bg-warning-subtle';
          break;
        case APPROVAL_STATUS_VALUES['rejected'].code:
          this.styleClass = 'badge text-danger-emphasis bg-danger-subtle';
          break;
        default:
          this.styleClass = '';
          break;
      }
    }
  }
}
