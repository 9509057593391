import { Injectable } from '@angular/core';
import { OrderWithExecutionInfo } from './order-with-execution-info.model';
import { Order } from '../order.model';
import { QUANTITY_TYPE_VALUES } from '../../shared/code-name-value.model';

@Injectable({
  providedIn: 'root'
})
export class BulkExecuteOrdersService {
  private _ordersToExecute: OrderWithExecutionInfo[] | null = null;

  public get ordersToExecute(): OrderWithExecutionInfo[] | null {
    return this._ordersToExecute;
  }

  public setOrdersToExecute(orders: Order[]) {
    this._ordersToExecute = orders
      ? orders.map((order) => ({
          ...order,
          executedQuantity: null,
          executedQuantityType: QUANTITY_TYPE_VALUES['quantity'].code,
          comments: null,
          transactionRef: null,
          useOrderInfo: false
        }))
      : null;
  }

  public clearOrdersToExecute() {
    this._ordersToExecute = null;
  }
}
