import { Component } from '@angular/core';

@Component({
  selector: 'of-app-unavailable',
  standalone: true,
  imports: [],
  template: `
    <div class="d-flex flex-column justify-content-around align-items-center">
      <h4>Service Unavailable</h4>
      <p>
        The application is currently unavailable. Please try again later or contact
        <a href="mailto:it-support@cigp.com">IT</a> if the problem persists.
      </p>
    </div>
  `
})
export class AppUnavailableComponent {}
