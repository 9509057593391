import { Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import { AvatarCardComponent } from './avatar-card.component';
import { User } from './user.model';
import { RouterModule } from '@angular/router';
import { BrokersNavMenuComponent } from '../brokers/brokers-nav-menu.component';
import { CustodiansNavMenuComponent } from '../custodians/custodians-nav-menu.component';
import { OrdersNavMenuComponent } from '../orders/orders-nav-menu.component';
import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { UsersService } from './users.service';
import { Observable } from 'rxjs';
import { MatMenuModule } from '@angular/material/menu';
import { SettingsNavMenuComponent } from './settings-nav-menu.component';

@Component({
  selector: 'of-nav-menu',
  standalone: true,
  imports: [
    AsyncPipe,
    AvatarCardComponent,
    BrokersNavMenuComponent,
    CustodiansNavMenuComponent,
    MatMenuModule,
    OrdersNavMenuComponent,
    RouterModule,
    SettingsNavMenuComponent,
    UpperCasePipe
  ],
  template: `
    <nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
      @if (loggedIn) {
        <div class="container-fluid d-flex justify-content-between">
          <a class="navbar-brand" [routerLink]="['/']">
            <img src="assets/cigp-logo.png" style="width: 40px" alt="CIGP" />
            <span class="mx-3">Order Flow</span>
            @if (environment !== 'production') {
              <span class="badge text-success-emphasis bg-success-subtle">{{ environment | uppercase }}</span>
            }
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav d-flex align-items-center">
              <of-orders-nav-menu></of-orders-nav-menu>
              <of-brokers-nav-menu></of-brokers-nav-menu>
              <of-custodians-nav-menu></of-custodians-nav-menu>
              <of-settings-nav-menu></of-settings-nav-menu>
            </ul>
            <div class="me-auto"></div>
            <ul class="navbar-nav d-flex align-items-center">
              @if (feedbackForm) {
                <li class="nav-item">
                  <a class="nav-link" [href]="feedbackForm" target="_blank" style="width: 120px" class="text-black-50"
                    >FEEDBACK</a
                  >
                </li>
              }
              @if (userManual) {
                <li class="nav-item">
                  <a class="nav-link" [href]="userManual" target="_blank"
                    ><span class="bi bi-question-circle" aria-hidden="true"></span
                  ></a>
                </li>
              }

              <of-avatar-card [user]="me$ | async" [matMenuTriggerFor]="userMenu"></of-avatar-card>
              <mat-menu #userMenu="matMenu">
                <button mat-menu-item (click)="logout()"><i class="bi bi-box-arrow-right"></i> LOGOUT</button>
              </mat-menu>
            </ul>
          </div>
        </div>
      }
    </nav>
  `
})
export class NavMenuComponent implements OnInit {
  public me$: Observable<User> | null = null;
  public feedbackForm = environment.feedbackForm;
  public userManual = environment.userManual;
  public environment = environment.env;
  public loggedIn = false;

  constructor(
    private orderFlowAuthService: OrderFlowAuthService,
    private usersService: UsersService
  ) {
    this.loggedIn = this.orderFlowAuthService.loggedIn;

    if (!this.loggedIn) {
      const intervalId = setInterval(() => {
        console.log('Checking for login');
        this.loggedIn = this.orderFlowAuthService.loggedIn;
        if (this.loggedIn) {
          clearInterval(intervalId);
        }
      }, 1000);
    }
  }

  public ngOnInit(): void {
    // this.loggedIn = this.orderFlowAuthService.loggedIn;

    if (this.orderFlowAuthService.account) {
      this.me$ = this.usersService.getUser$(this.orderFlowAuthService.account.username);
    }
  }

  public logout(): void {
    this.orderFlowAuthService.logout$().subscribe();
  }
}
