import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import {
  AllocationToAddOrUpdate,
  AllocationToAddOrUpdateFormConfig
} from '../orders/order-with-allocations-to-add-or-update.model';
import { Account } from '../portfolios/account.model';
import { Portfolio } from '../portfolios/portfolio.model';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { AllocationsFormConfig } from './allocations-form-config.model';
import { AllocationFormComponent } from './allocation-form.component';
import { sumBy } from 'lodash-es';
import { DecimalPipe, JsonPipe, PercentPipe } from '@angular/common';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';
import { BasicHoldingWithBasicPortfolio } from '../holdings/basic-holding-with-basic-portfolio.model';

@Component({
  selector: 'of-allocations-form',
  standalone: true,
  imports: [AllocationFormComponent, DecimalPipe, JsonPipe, PercentPipe, RadioButtonModule, ReactiveFormsModule],
  template: `
    <div class="card my-3" [formGroup]="form">
      <div class="card-header d-flex justify-content-between align-items-center">
        <div class="d-flex gap-3">
          <div>
            <div class="card-title">ALLOCATIONS</div>
            <div class="card-subtitle">
              <small><i>The system will generate one order for each allocation</i></small>
            </div>
          </div>
        </div>
        <div class="d-flex align-items-center gap-3">
          <ejs-radiobutton
            label="Value"
            value="value"
            name="radAllocateBy"
            formControlName="allocateBy"
          ></ejs-radiobutton>
          <ejs-radiobutton
            label="Percentage"
            value="percentage"
            name="radAllocateBy"
            formControlName="allocateBy"
          ></ejs-radiobutton>
          <button
            class="btn btn-outline-secondary border-0"
            (click)="updateAllocationsQuantities()"
            [disabled]="!totalQuantityOrNominal"
          >
            <i class="bi bi-arrow-repeat"></i>
          </button>

          <div class="btn-group">
            <button class="btn btn-sm btn-outline-secondary" (click)="uppdateTotalQuantityOrNominal()">
              <i class="bi bi-arrow-up"></i>
              UPDATE TOTAL QUANTITY
            </button>
          </div>
        </div>
      </div>
      <div class="card-body" formArrayName="allocations">
        @for (allocation of allocations.controls; track $index) {
          <of-allocation-form
            [form]="getAllocationAsFormGroup(allocation)"
            [quantityTypeLabel]="quantityTypeLabel"
            (removeRequested)="removeAllocation($index)"
            [canRemove]="allocations.controls.length > 1"
            [securityCurrency]="securityCurrency"
            [securityPrice]="securityPrice"
            [custodianExcludedPortfolios]="excludedPortfolios"
            [portfoliosHoldingSecurity]="portfoliosHoldingSecurity"
            [transactionType]="transactionType"
            (ready)="onAllocationFormReady($index)"
            [showSeparator]="$index < allocations.controls.length - 1"
          ></of-allocation-form>
        }
      </div>
      <div class="card-footer">
        <div class="row d-flex align-items-center">
          <div class="col">
            <button class="btn btn-outline-primary border-0" (click)="addAllocation()">
              <i class="bi bi-plus-circle"></i>
            </button>
          </div>
          <div class="col" style="min-width: 320px;"></div>
          <div class="col"><i>Total</i></div>
          <div class="col">
            <i>
              {{ totalQuantity | number }} <small>(exp {{ totalQuantityOrNominal || 0 | number }})</small>
            </i>
          </div>
          <div class="col">
            <i> {{ totalQuantityPct | percent }} <small>(exp 100%)</small></i>
          </div>
          <div class="col" style="max-width: 60px"></div>
        </div>
      </div>
    </div>

    @if (environment === 'development') {
      <div class="card my-3 text-warning-emphasis bg-warning-subtle">
        <div class="card-body font-monospace">
          <small> {{ form.getRawValue() | json }}</small>
        </div>
      </div>
      <div class="card my-3 text-warning-emphasis bg-warning-subtle">
        <div class="card-body font-monospace">
          <small> {{ portfoliosHoldingSecurity | json }}</small>
        </div>
      </div>
    }
  `
})
export class AllocationsFormComponent implements OnInit, OnChanges {
  public form: FormGroup = new FormGroup({});
  public environment = environment.env;

  @Input()
  public quantityTypeLabel: string | null = null;

  @Input()
  public totalQuantityOrNominal: number | null = null;

  @Output()
  public totalQuantityOrNominalChanged: EventEmitter<number> = new EventEmitter<number>();

  @Input()
  public securityCurrency: string | null | undefined = null;

  @Input()
  public securityPrice: number | null | undefined = null;

  @Input()
  public portfoliosHoldingSecurity: BasicHoldingWithBasicPortfolio[] | null | undefined = null;

  @Input()
  public excludedPortfolios: string[] | null = null;

  @Input()
  public transactionType: string | null | undefined = null;

  public allocationFormReady$ = new BehaviorSubject<number>(-1);

  constructor(private fb: FormBuilder) {}

  public ngOnInit(): void {
    this.form = this.fb.nonNullable.group<AllocationsFormConfig>({
      allocateBy: this.fb.control<string>('percentage'),
      allocations: this.fb.array<AllocationToAddOrUpdateFormConfig>([])
    });

    this.addAllocation();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['totalQuantityOrNominal']?.currentValue !== changes['totalQuantityOrNominal']?.previousValue) {
      setTimeout(() => {
        if (this.totalQuantityOrNominal && this.totalQuantityOrNominal > 0) {
          for (let index = 0; index < this.allocations.length; index++) {
            const allocationForm = this.getAllocationForm(index);

            allocationForm
              .get('quantityPct')
              ?.setValue((allocationForm.get('quantity')?.value || 0) / this.totalQuantityOrNominal);
          }
        }
      }, 200);
    }
  }

  public get allocations(): FormArray {
    return this.form?.get('allocations') as FormArray;
  }

  public get allocations2(): AllocationToAddOrUpdate[] {
    return this.form?.get('allocations')?.getRawValue() as AllocationToAddOrUpdate[];
  }

  public getAllocationForm(index: number): FormGroup<AllocationToAddOrUpdateFormConfig> {
    return this.allocations.at(index) as FormGroup<AllocationToAddOrUpdateFormConfig>;
  }

  public addAllocation(): void {
    this.allocations?.push(this.createAllocationForm());
  }

  public removeAllocation(index: number): void {
    this.allocations?.removeAt(index);
  }

  public updateAllocationsQuantities(): void {
    if (!this.totalQuantityOrNominal || this.totalQuantityOrNominal <= 0) return;

    const allocations = this.allocations.value as AllocationToAddOrUpdateFormConfig[];

    if (this.form.get('allocateBy')?.value === 'percentage') {
      const emptyAllocations = allocations.filter((a) => !a.quantityPct);

      let avgPct: number | null = null;
      if (emptyAllocations.length > 0) {
        avgPct = (1 - sumBy(allocations, 'quantityPct')) / emptyAllocations.length;
      }

      for (let index = 0; index < this.allocations.length; index++) {
        const allocationForm = this.getAllocationForm(index);
        if (allocationForm.get('quantityPct')?.value) {
          // 1. Compute allocations for which a percentage is already input
          allocationForm
            .get('quantity')
            ?.setValue((allocationForm.get('quantityPct')?.value || 0) * this.totalQuantityOrNominal);
        } else if (avgPct) {
          // 2. Assign remaining allocations: divide the remaining quantity by the number of empty allocations
          allocationForm.get('quantityPct')?.setValue(avgPct);
          allocationForm.get('quantity')?.setValue(avgPct * this.totalQuantityOrNominal);
        }
      }
    } else {
      const emptyAllocations = allocations.filter((a) => !a.quantity);

      let avgQty: number | null = null;
      if (emptyAllocations.length > 0) {
        avgQty = (this.totalQuantityOrNominal - sumBy(allocations, 'quantity')) / emptyAllocations.length;
      }

      for (let index = 0; index < this.allocations.length; index++) {
        const allocationForm = this.getAllocationForm(index);
        if (allocationForm.get('quantity')?.value) {
          allocationForm
            .get('quantityPct')
            ?.setValue((allocationForm.get('quantity')?.value || 0) / this.totalQuantityOrNominal);
        } else if (avgQty) {
          // 2. Assign remaining allocations: divide the remaining quantity by the number of empty allocations
          allocationForm.get('quantityPct')?.setValue(avgQty / this.totalQuantityOrNominal);
          allocationForm.get('quantity')?.setValue(avgQty);
        }
      }
    }
  }

  public get totalQuantity(): number {
    return sumBy(this.allocations?.controls, (control) => control.get('quantity')?.value ?? 0);
  }

  public get totalQuantityPct(): number {
    return sumBy(this.allocations?.controls, (control) => control.get('quantityPct')?.value ?? 0);
  }

  public getAllocationAsFormGroup(allocation: AbstractControl): FormGroup<AllocationsFormConfig> {
    return allocation as FormGroup<AllocationsFormConfig>;
  }

  public get value(): AllocationToAddOrUpdate[] {
    return this.form.value.allocations;
  }

  public onAllocationFormReady(index: number) {
    this.allocationFormReady$.next(index);
  }

  public uppdateTotalQuantityOrNominal(): void {
    this.totalQuantityOrNominalChanged.emit(sumBy(this.value, 'quantity'));
  }

  private createAllocationForm(): FormGroup<AllocationToAddOrUpdateFormConfig> {
    const form = this.fb.group<AllocationToAddOrUpdateFormConfig>({
      account: this.fb.control<Account | null>(null, Validators.required),
      accountStr: this.fb.control<string | null>(null, Validators.required),
      broker: this.fb.control<string | null>(null, Validators.required),
      fxRate: this.fb.control<number | null>(null),
      isEamAccount: this.fb.control<boolean | null>(null),
      passedProductKnowledgeCheck: this.fb.control<boolean | null>(null),
      portfolio: this.fb.control<Portfolio | null>(null, Validators.required),
      portfolioStr: this.fb.control<string | null>(null, Validators.required),
      quantity: this.fb.control<number | null>(null, Validators.required),
      quantityPct: this.fb.control<number | null>(null, Validators.required),
      quantityPctOfPtfAum: this.fb.control<number | null>(null),
      topupPositionPct: this.fb.control<number | null>(null),
      trimPositionPct: this.fb.control<number | null>(null)
    });

    form.get('accountStr')?.disable();
    form.get('fxRate')?.disable();
    form.get('isEamAccount')?.disable();
    form.get('quantityPctOfPtfAum')?.disable();

    return form;
  }
}
