import { Component, Input } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { OrderPermissionsService } from '../order-permissions.service';
import { AsyncPipe } from '@angular/common';
import { CodeNameItem } from '../../shared/code-name-value.model';
import { isSendableStatus } from '../../shared/utils';
import { OrderFlowAuthService } from '../../auth/order-flow-auth.service';
import { ROLE_FX_ORDER_READER_RESTRICTED, ROLE_FX_ORDER_WRITER, ROLE_FX_ORDER_WRITER_RESTRICTED, ROLE_ORDER_APPROVER, ROLE_ORDER_WRITER } from '../../shared/constants';

@Component({
  selector: 'of-can-send-order',
  imports: [AsyncPipe],
  template: `
    @if (canSend$ | async) {
      <ng-content></ng-content>
    }
  `
})
export class CanSendOrderComponent {
  public canSend$: Observable<boolean> | null = null;

  @Input()
  public orderId: number | null = null;

  @Input()
  public orderStatus: CodeNameItem | null = null;

  constructor(
    private permissionsService: OrderPermissionsService,
    private authService: OrderFlowAuthService
  ) {}

  ngOnInit(): void {
    this.canSend$ =
      this.orderId &&
      isSendableStatus(this.orderStatus) &&
      this.authService.hasOneOfRoles([ROLE_ORDER_WRITER, ROLE_FX_ORDER_WRITER, ROLE_ORDER_APPROVER])
        ? this.permissionsService.canSendOrder$(this.orderId).pipe(map((res) => res.isAuthorized))
        : of(false);
  }
}
