import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AvatarCardComponent } from '../shared/avatar-card.component';
import { User } from '../shared/user.model';
import { DatePipe } from '@angular/common';
import { CanCheckOrderComponent } from './can-check-order.component';
import { CheckStatusBadgeComponent } from './check-status-badge.component';
import { CodeNameItem, ORDER_STATUS_VALUES } from '../shared/code-name-value.model';
import { OrdersService } from '../orders/orders.service';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ToastComponent } from '../shared/toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'of-check-order',
  standalone: true,
  imports: [
    AvatarCardComponent,
    CanCheckOrderComponent,
    CheckStatusBadgeComponent,
    DatePipe,
    MatProgressSpinnerModule,
    TextBoxModule,
    ToastComponent
  ],
  template: `
    <div class="card mb-3">
      <div class="card-header d-flex justify-content-between px-2">
        <h5 class="card-title m-0">ORDER CHECK</h5>
        <of-check-status-badge [checkPassed]="checkPassed"></of-check-status-badge>
      </div>
      @if (checked) {
        <div class="card-body d-flex justify-content-between align-items-end">
          <div class="d-flex align-items-start gap-3">
            <of-avatar-card [user]="checker"></of-avatar-card>
            @if (comments) {
              <div class="d-block" [innerHTML]="comments"></div>
            }
          </div>
          <small>{{ checked | date: 'dd/MM/yyyy HH:mm' }}</small>
        </div>
      } @else {
        <of-can-check-order [orderId]="orderId" [orderStatus]="orderStatus">
          <div class="card-body p-0">
            <ejs-textbox
              placeholder="Comments"
              [(value)]="comments"
              [multiline]="true"
              style="height: 100px"
              [enabled]="!submitting"
            ></ejs-textbox>
            <div class="card-footer px-2">
              <div class="btn-group btn-group-sm">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  (click)="markOrderAsChecked()"
                  [disabled]="submitting"
                >
                  MARK AS CHECKED
                </button>
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  (click)="sendOrderBackForRevision()"
                  [disabled]="submitting"
                >
                  SEND BACK FOR REVISION
                </button>
              </div>
            </div>
          </div>
        </of-can-check-order>
      }
    </div>

    <of-toast #toastCheckProcessingProgress [showCloseButton]="false">
      <div id="title" class="d-flex justify-content-between">
        <div>PROCESSING</div>
      </div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          <div>Processing...</div>
        </div>
      </div>
    </of-toast>
  `
})
export class CheckOrderComponent implements OnInit {
  public checkPassed: string | null = null;
  public submitting = false;

  @Input()
  public checked: Date | null = null;

  @Input()
  public checker: User | null = null;

  @Input()
  public comments: string | null = null;

  @Input()
  public orderStatus: CodeNameItem | null = null;

  @Input()
  public orderId = -1;

  @ViewChild('toastCheckProcessingProgress')
  public toastCheckProcessingProgress: ToastComponent | null = null;

  constructor(private ordersService: OrdersService) {}

  public ngOnInit(): void {
    if (this.orderStatus?.code === ORDER_STATUS_VALUES['pendingCheck'].code) {
      this.checked = null;
      this.checker = null;
      this.comments = null;
    } else if (this.checked) {
      if (
        this.orderStatus?.code !== ORDER_STATUS_VALUES['revising'].code &&
        this.orderStatus?.code !== ORDER_STATUS_VALUES['pendingPreTradeCheck'].code
      ) {
        this.checkPassed = 'Passed';
      } else {
        this.checkPassed = 'Failed';
      }
    } else this.checkPassed = null;
  }

  public markOrderAsChecked(): void {
    this.updateOrderCheckStatus(true);
  }

  public sendOrderBackForRevision(): void {
    this.updateOrderCheckStatus(false);
  }

  private updateOrderCheckStatus(checkPassed: boolean): void {
    this.submitting = true;
    this.toastCheckProcessingProgress?.show();

    this.ordersService
      .updateOrderCheckStatus$(this.orderId, { checkPassed, comments: this.comments || undefined })
      .subscribe({
        next: () => {
          this.submitting = false;
          this.toastCheckProcessingProgress?.hide();
        },
        error: () => {
          this.submitting = false;
          this.toastCheckProcessingProgress?.hide();
        }
      });
  }
}
