import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { SecuritiesService } from '../securities/securities.service';
import { PortfoliosService } from '../portfolios/portfolios.service';
import { combineLatest, map, of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';

export const systemReadyGuard: CanActivateFn = (route, state) => {
  const authService = inject(OrderFlowAuthService);
  const securitiesService = inject(SecuritiesService);
  const portfoliosService = inject(PortfoliosService);
  const router = inject(Router);
  const logger = inject(NGXLogger);

  logger.log('Checking if system is ready');

  return authService.canViewFundamentalData()
    ? combineLatest([securitiesService.securitiesSet$, portfoliosService.portfoliosSet$]).pipe(
        map(([securitiesSet, portfoliosSet]) => {
          if (securitiesSet && portfoliosSet) {
            logger.log('System is ready');
            return true;
          } else {
            logger.log('System is not ready. Redirecting to landing page.');
            return router.parseUrl('/');
          }
        })
      )
    : of(true);
};
