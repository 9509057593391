import { Component, Input, OnInit } from '@angular/core';
import { CodeNameValueItem, PRETRADE_CHECK_STATUS_VALUES } from './code-name-value.model';
import { UpperCasePipe } from '@angular/common';

@Component({
    selector: 'of-pretrade-check-status-badge',
    imports: [UpperCasePipe],
    template: `
    @if (status) {
      <span [class]="styleClass" [title]="status.name">{{ status.name | uppercase }}</span>
    }
  `
})
export class PretradeCheckStatusBadgeComponent implements OnInit {
  public styleClass = '';

  @Input()
  public status: CodeNameValueItem | null | undefined = null;

  public ngOnInit(): void {
    if (this.status?.code === PRETRADE_CHECK_STATUS_VALUES['passed'].code)
      this.styleClass = 'badge text-success-emphasis bg-success-subtle';
    else if (this.status?.code === PRETRADE_CHECK_STATUS_VALUES['breached'].code)
      this.styleClass = 'badge text-danger-emphasis bg-danger-subtle';
    else if (
      this.status?.code === PRETRADE_CHECK_STATUS_VALUES['pending'].code ||
      this.status?.code === PRETRADE_CHECK_STATUS_VALUES['warning'].code
    )
      this.styleClass = 'badge text-warning-emphasis bg-warning-subtle';
    else if (this.status?.code === PRETRADE_CHECK_STATUS_VALUES['na'].code)
      this.styleClass = 'badge text-secondary-emphasis bg-secondary-subtle';
    else this.styleClass = '';
  }
}
