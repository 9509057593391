<div class="card mb-3">
  <div class="card-header">
    <h5 class="card-title m-0">ORDERS BULK UPLOAD</h5>
  </div>
  <div class="card-body d-flex flex-column gap-3">
    <div class="d-flex gap-3 align-items-center">
      <p class="m-0">You can use this template file:</p>
      <button class="btn btn-outline-primary border-0" (click)="downloadTemplate()">
        <i class="bi bi-filetype-csv"></i> orders-bulk-upload.template.csv
      </button>
    </div>
    <div class="d-flex align-items-center gap-3">
      <div style="width: 300px">
        <ejs-uploader
          allowedExtensions=".csv"
          maxFileSize="5000000"
          multiple="false"
          [asyncSettings]="path"
          (uploading)="addHeaders($event)"
          (removing)="addHeaders($event)"
          (success)="onSuccess($event)"
        ></ejs-uploader>
      </div>
      <div>
        <button class="btn btn-primary" (click)="parse()" [disabled]="form.invalid">PARSE FILE</button>
      </div>
    </div>
  </div>
</div>

@if (parsedOrders) {
  <div class="card mb-3">
    <div class="card-body p-0">
      <ejs-grid
        #gridParsedOrders
        [dataSource]="parsedOrders"
        [allowResizing]="true"
        [editSettings]="{ allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal' }"
        [toolbar]="['Add', 'Edit', 'Delete', 'Update', 'Cancel']"
      >
        <e-columns>
          <e-column field="rowNumber" headerText="#" [isPrimaryKey]="true" width="40"></e-column>
          <e-column
            field="broker"
            headerText="Broker"
            width="160"
            editType="dropdownedit"
            [edit]="brokerEditParams"
          ></e-column>
          <e-column
            field="custodian"
            headerText="Custodian"
            width="120"
            editType="dropdownedit"
            [edit]="custodianEditParams"
          >
          </e-column>
          <e-column field="portfolioInternalName" headerText="Portfolio" width="200"></e-column>
          <e-column field="accountName" headerText="Account" width="100"></e-column>
          <e-column field="isEamAccount" headerText="EAM account" editType="booleanedit">
            <ng-template #template let-data>
              <ejs-checkbox [checked]="data.isEamAccount" [disabled]="true"></ejs-checkbox
            ></ng-template>
          </e-column>
          <e-column
            field="instructionDate"
            headerText="Instruction date"
            format="dd/MM/yyyy"
            type="Date"
            width="130"
            editType="datepickeredit"
          ></e-column>
          <e-column
            field="transactionDate"
            headerText="Transaction date"
            format="dd/MM/yyyy"
            type="Date"
            width="130"
            editType="datepickeredit"
          ></e-column>
          <e-column
            field="transactionType"
            headerText="Purchase or sell"
            width="140"
            editType="dropdownedit"
            [edit]="transactionTypeEditParams"
          ></e-column>
          <e-column field="faSecurityCode" headerText="Security"></e-column>
          <e-column
            field="tdMaturityDate"
            headerText="TD Maturity"
            format="dd/MM/yyyy"
            type="Date"
            width="130"
            editType="datepickeredit"
          ></e-column>
          <e-column
            field="tdStartDate"
            headerText="TD Start Date"
            format="dd/MM/yyyy"
            type="Date"
            width="130"
            editType="datepickeredit"
          ></e-column>
          <e-column field="tdTenor" headerText="TD Tenor"></e-column>
          <e-column field="tdRate" headerText="TD Rate" format="P4" editType="numericedit"></e-column>
          <e-column field="tdBestRate" headerText="TD Best Rate" editType="booleanedit">
            <ng-template #template let-data>
              <ejs-checkbox [checked]="data.tdBestRate" [disabled]="true"></ejs-checkbox
            ></ng-template>
          </e-column>
          <e-column field="fxRate" headerText="FX rate" format="N5" editType="numericedit"></e-column>
          <e-column field="quantity" headerText="Quantity / nominal" format="N5" editType="numericedit"></e-column>
          <e-column
            field="quantityType"
            headerText="Quantity type"
            editType="dropdownedit"
            [edit]="quantityTypeEditParams"
          >
          </e-column>
          <e-column
            field="executionInstruction"
            headerText="Execution instruction"
            editType="dropdownedit"
            [edit]="executionInstructionEditParams"
          >
          </e-column>
          <e-column
            field="executionMethod"
            headerText="Execution method"
            editType="dropdownedit"
            [edit]="executionMethodEditParams"
          ></e-column>
          <e-column
            field="orderType"
            headerText="Order type"
            editType="dropdownedit"
            [edit]="orderTypeEditParams"
          ></e-column>
          <e-column field="limitPrice" headerText="Limit price" format="N5" editType="numericedit"></e-column>
          <e-column field="tif" headerText="TIF" editType="dropdownedit" [edit]="tifEditParams"></e-column>
          <e-column
            field="expiryDate"
            headerText="Expiry Date"
            format="dd/MM/yyyy"
            type="Date"
            width="130"
            editType="datepickeredit"
          ></e-column>
          <e-column field="passedProductKnowledgeCheck" headerText="Product knowledge" editType="booleanedit">
            <ng-template #template let-data>
              <ejs-checkbox [checked]="data.passedProductKnowledgeCheck" [disabled]="true"></ejs-checkbox
            ></ng-template>
          </e-column>
          <e-column field="additionalInternalInformation" headerText="Additional information (Int.)"></e-column>
          <e-column field="additionalInformation" headerText="Additional information (Ext.)"></e-column>
        </e-columns>
      </ejs-grid>
    </div>
    <div class="card-footer d-flex gap-3">
      <button
        class="btn btn-primary"
        (click)="importOrders()"
        [disabled]="!parsedOrders.length || submitting || submitted"
      >
        IMPORT ORDERS
      </button>
      @if (submitting) {
        <mat-spinner diameter="40"></mat-spinner>
      }
    </div>
  </div>
}

@if (environment === 'development') {
  <div class="card my-3 text-warning-emphasis bg-warning-subtle">
    <div class="card-body font-monospace">
      <small>
        {{ parsedOrders | json }}
      </small>
    </div>
  </div>
}

@if (importResults.length > 0) {
  <div class="card mb-3">
    <div class="card-body p-0">
      <ejs-grid #gridImportResults [dataSource]="importResults" [allowTextWrap]="true">
        <e-columns>
          <e-column field="rowNumber" headerText="#" [isPrimaryKey]="true" width="40"></e-column>
          <e-column field="success" headerText="Status" width="100">
            <ng-template #template let-data>
              @if (data.success) {
                <span class="badge text-success-emphasis bg-success-subtle" title="Success">OK</span>
              } @else {
                <span class="badge text-danger-emphasis bg-danger-subtle" title="Failed">FAILED</span>
              }
            </ng-template>
          </e-column>
          <e-column field="orderId" headerText="Order ID" width="100">
            <ng-template #template let-data>
              <a [routerLink]="['../', data.orderId]" target="_blank">{{ data.orderId }}</a>
            </ng-template>
          </e-column>
          <e-column field="error" headerText="Error">
            <ng-template #template let-data>
              <of-api-error [data]="data.error"></of-api-error>
            </ng-template>
          </e-column>
        </e-columns>
      </ejs-grid>
    </div>
  </div>
}
