import { Component, Input } from '@angular/core';

@Component({
  selector: 'of-hint',
  standalone: true,
  imports: [],
  template: ` <span [className]="className" style="font-size: 12px;"><ng-content></ng-content></span> `
})
export class HintComponent {
  @Input()
  public className: string = '';
}
