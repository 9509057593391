import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map } from 'rxjs';
import { BrokerFormComponent } from './broker-form.component';

@Component({
    selector: 'of-edit-broker',
    imports: [AsyncPipe, BrokerFormComponent],
    template: ` <of-broker-form [brokerId]="brokerId$ | async"></of-broker-form> `
})
export class EditBrokerComponent implements OnInit {
  public brokerId$: Observable<number> | null = null;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.brokerId$ = this.route.paramMap.pipe(map((params) => Number.parseInt(params.get('id') || '-1', 10)));
  }
}
