<h5 mat-dialog-title class="px-3 py-0">SEND {{ data.length }} ORDERS IN BULK</h5>
<div mat-dialog-content class="p-3">
  @for (byBroker of aggregatedOrders; track byBroker) {
    @for (byAccountNumber of byBroker.byAccountNumbers; track byAccountNumber) {
      <div class="card mb-3">
        <div class="card-header d-flex justify-content-between">
          <div class="card-title m-0">{{ byBroker.broker.name }} | {{ byAccountNumber.accountNumber }}</div>
          <small class="text-muted"
            ><i>{{ byBroker.broker.emails.join(', ') }}</i></small
          >
        </div>
        <div class="card-body p-0 d-flex flex-column gap-2">
          <div>
            <ol class="list-group list-group-numbered">
              @for (byPreparer of byAccountNumber.byPreparers; track byPreparer) {
                @for (byApprover of byPreparer.byApprovers; track byApprover) {
                  @for (bySecurity of byApprover.bySecurities; track bySecurity) {
                    @for (byTd of bySecurity.byTds; track byTd) {
                      @for (byQuantityType of byTd.byQuantityTypes; track byQuantityType) {
                        @for (byTransactionType of byQuantityType.byTransactionTypes; track byTransactionType) {
                          @for (byOrderType of byTransactionType.byOrderTypes; track byOrderType) {
                            @for (byLimitPrice of byOrderType.byLimitPrices; track byLimitPrice) {
                              @for (byTif of byLimitPrice.byTifs; track byTif) {
                                @for (
                                  byRandomAccountNumber of byTif.byRandomAccountNumbers;
                                  track byRandomAccountNumber
                                ) {
                                  <li
                                    class="list-group-item d-flex justify-content-between align-items-center border-start-0"
                                  >
                                    <div class="ms-2 me-auto">
                                      <div>
                                        {{ byRandomAccountNumber.order.transactionType }}
                                        {{ byRandomAccountNumber.order.quantity | number }}
                                        <small>({{ byRandomAccountNumber.order.quantityType.name[0] }})</small>
                                        {{ byRandomAccountNumber.order.security.name }} &#64;
                                        {{ byRandomAccountNumber.order.orderType }}

                                        @if (byRandomAccountNumber.order.orderType === 'LIMIT') {
                                          {{ byRandomAccountNumber.order.limitPrice }}
                                        }

                                        ({{ byRandomAccountNumber.order.tif }})
                                      </div>
                                    </div>
                                    <div class="d-flex gap-3">
                                      <small class="d-flex align-items-center gap-2">
                                        @for (order of byRandomAccountNumber.orders; track order) {
                                          <a [routerLink]="['orders', order.id]" target="_blank">{{ order.id }}</a>
                                        }
                                      </small>
                                      <of-avatar-card
                                        [user]="byPreparer.preparer"
                                        titlePrefix="Prepared by"
                                      ></of-avatar-card>
                                      <of-avatar-card
                                        [user]="byApprover.approver"
                                        titlePrefix="Approved by"
                                      ></of-avatar-card>
                                      <button
                                        class="btn btn-sm btn-outline-secondary border-0"
                                        (click)="viewPdf(byRandomAccountNumber.order)"
                                      >
                                        <i class="bi bi-eye"></i>
                                      </button>
                                    </div>
                                  </li>
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            </ol>
          </div>
        </div>
      </div>
    }
  }

  @if (environment === 'development') {
    <div class="card my-3 text-warning-emphasis bg-warning-subtle">
      <div class="card-body font-monospace">
        <small>
          {{ aggregatedOrders | json }}
        </small>
      </div>
    </div>
  }
</div>
<div mat-dialog-actions class="p-3 d-flex justify-content-end">
  <div class="btn-group">
    <button class="btn btn-secondary" (click)="onCancelClick()">CANCEL</button>
    <button class="btn btn-danger" (click)="onSendClick()">SEND</button>
  </div>
</div>
