import { Component, Input, ViewChild } from '@angular/core';
import { PreTradeCheckLimit } from '../../../shared/pretrade-check.model';
import { CodeNameItem, CodeNameValueItem } from '../../../shared/code-name-value.model';
import { DatePipe, PercentPipe } from '@angular/common';
import { PretradeCheckStatusBadgeComponent } from '../../../shared/pretrade-check-status-badge.component';
import { DetailRowService, GridModule } from '@syncfusion/ej2-angular-grids';
import { OrdersService } from '../../orders.service';
import { ToastComponent } from '../../../shared/toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CanExecuteOrderPretradeChecksComponent } from './can-execute-order-pretrade-checks.component';

@Component({
    selector: 'of-order-pretrade-checks-card',
    imports: [
        CanExecuteOrderPretradeChecksComponent,
        DatePipe,
        GridModule,
        MatProgressSpinnerModule,
        PercentPipe,
        PretradeCheckStatusBadgeComponent,
        ToastComponent
    ],
    providers: [DetailRowService],
    template: `
    <div class="card mb-3">
      <div class="card-header d-flex justify-content-between align-items-center px-2">
        <div class="d-flex gap-2 align-items-center">
          <h5 class="card-title m-0">PRE-TRADE CHECKS</h5>
          <of-can-execute-order-pretrade-checks [orderStatus]="orderStatus">
            <button
              type="button"
              class="btn btn-outline-primary btn-sm border-0"
              (click)="executeOrderPreTradeCheck()"
              [disabled]="submitting"
            >
              <span title="Execute pre-trade check">
                <i class="bi bi-shield-check"></i>
              </span>
            </button>
          </of-can-execute-order-pretrade-checks>
        </div>
        <of-pretrade-check-status-badge [status]="preTradeCheckStatus"></of-pretrade-check-status-badge>
      </div>
      <div class="card-body p-0">
        <ejs-grid #grdPreTradeCheckLimits [dataSource]="preTradeCheckLimits">
          <e-columns>
            <e-column field="name" headerText="Limit"></e-column>
            <e-column
              field="lowerBreachedBound"
              textAlign="Right"
              headerText="Lower Bound"
              type="number"
              format="P2"
              width="120"
            ></e-column>
            <e-column
              field="upperBreachedBound"
              textAlign="Right"
              headerText="Upper Bound"
              type="number"
              format="P2"
              width="120"
            ></e-column>
            <e-column
              field="value"
              textAlign="Right"
              headerText="Value"
              type="number"
              format="P2"
              width="120"
            ></e-column>
            <e-column field="status" textAlign="Center" headerText="Status" width="110">
              <ng-template #template let-data>
                <of-pretrade-check-status-badge [status]="data.status"></of-pretrade-check-status-badge>
              </ng-template>
            </e-column>
          </e-columns>
          <ng-template #detailTemplate let-data>
            <ejs-grid [dataSource]="data.items">
              <e-columns>
                <e-column field="name" headerText="Name">
                  <ng-template #template let-data>
                    @if (data.name) {
                      <span>{{ data.name }} </span>
                      @if (data.code) {
                        <span>({{ data.code }})</span>
                      }
                    } @else {
                      <span>Total</span>
                    }
                  </ng-template>
                </e-column>
                <e-column
                  field="date"
                  headerText="Date"
                  textAlign="Right"
                  type="datetime"
                  format="dd/MM/yyyy"
                  width="120"
                ></e-column>
                <e-column
                  field="value"
                  textAlign="Right"
                  headerText="Value"
                  type="number"
                  format="P2"
                  width="120"
                ></e-column>
                <e-column field="status" textAlign="Center" headerText="Status" width="110">
                  <ng-template #template let-data>
                    <of-pretrade-check-status-badge [status]="data.status"></of-pretrade-check-status-badge>
                  </ng-template>
                </e-column>
              </e-columns>
            </ejs-grid>
          </ng-template>
        </ejs-grid>
      </div>
    </div>

    <of-toast #toastPreTradeCheckExecutionProgress [showCloseButton]="false">
      <div id="title" class="d-flex justify-content-between">
        <div>PRE-TRADE CHECK</div>
      </div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          <div>Processing...</div>
        </div>
      </div>
    </of-toast>
  `
})
export class OrderPretradeChecksCardComponent {
  public submitting = false;

  @Input()
  public preTradeCheckLimits: PreTradeCheckLimit[] | null | undefined = null;

  @Input()
  public preTradeCheckStatus: CodeNameValueItem | null | undefined = null;

  @Input()
  public orderStatus: CodeNameItem | null | undefined = null;

  @Input()
  public orderId: number | null | undefined = null;

  @ViewChild('toastPreTradeCheckExecutionProgress')
  public toastPreTradeCheckExecutionProgress: ToastComponent | null = null;

  constructor(private ordersService: OrdersService) {}

  public executeOrderPreTradeCheck(): void {
    this.submitting = true;
    this.toastPreTradeCheckExecutionProgress?.show();

    if (this.orderId) {
      this.ordersService.executeOrderPreTradeCheck$(this.orderId).subscribe({
        next: () => {
          this.submitting = false;
          this.toastPreTradeCheckExecutionProgress?.hide();
        },
        error: () => {
          this.submitting = false;
          this.toastPreTradeCheckExecutionProgress?.hide();
        }
      });
    }
  }
}
