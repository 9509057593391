import { CanActivateFn } from '@angular/router';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import {
  ROLE_FX_ORDER_READER,
  ROLE_FX_ORDER_READER_RESTRICTED,
  ROLE_FX_ORDER_WRITER,
  ROLE_FX_ORDER_WRITER_RESTRICTED,
  ROLE_ORDER_APPROVER,
  ROLE_ORDER_READER,
  ROLE_ORDER_WRITER
} from '../shared/constants';
import { inject } from '@angular/core';

export const canViewOrdersGuard: CanActivateFn = (route, state) => {
  return inject(OrderFlowAuthService).hasOneOfRoles([
    ROLE_ORDER_APPROVER,
    ROLE_ORDER_READER,
    ROLE_ORDER_WRITER,
    ROLE_FX_ORDER_READER,
    ROLE_FX_ORDER_READER_RESTRICTED,
    ROLE_FX_ORDER_WRITER,
    ROLE_FX_ORDER_WRITER_RESTRICTED
  ]);
};
