import { Component, Input, ViewChild } from '@angular/core';
import { ToastComponent } from '../shared/toast.component';
import { OrdersService } from '../orders/orders.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CanAckOrderComponent } from './can-ack-order.component';
import { User } from '../shared/user.model';
import { CodeNameItem, ORDER_STATUS_VALUES } from '../shared/code-name-value.model';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { DatePipe } from '@angular/common';
import { AvatarCardComponent } from '../shared/avatar-card.component';
import { AckStatusBadgeComponent } from './ack-status-badge.component';

@Component({
    selector: 'of-ack-order',
    imports: [
        AckStatusBadgeComponent,
        AvatarCardComponent,
        CanAckOrderComponent,
        DatePipe,
        MatProgressSpinnerModule,
        TextBoxModule,
        ToastComponent
    ],
    template: `
    <div class="card mb-3">
      <div class="card-header d-flex justify-content-between px-2">
        <h5 class="card-title m-0">ORDER ACKNOWLEDGEMENT</h5>
        <of-ack-status-badge [acknowledged]="acknowledged"></of-ack-status-badge>
      </div>
      @if (acknowledged) {
        <div class="card-body d-flex justify-content-between align-items-end">
          <div class="d-flex align-items-start gap-3">
            <of-avatar-card [user]="acknowledger"></of-avatar-card>
            @if (comments) {
              <div class="d-block" [innerHTML]="comments"></div>
            }
          </div>
          <small>{{ acknowledged | date: 'dd/MM/yyyy HH:mm' }}</small>
        </div>
      } @else {
        <of-can-ack-order [orderStatus]="orderStatus">
          <div class="card-body p-0">
            <ejs-textbox
              placeholder="Comments"
              [(value)]="comments"
              [multiline]="true"
              style="height: 100px"
              [enabled]="!submitting"
            ></ejs-textbox>
            <div class="card-footer px-2">
              <div class="btn-group btn-group-sm">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  (click)="markOrderAsAcknowledged()"
                  [disabled]="submitting"
                >
                  ACKNOWLEDGE
                </button>
              </div>
            </div>
          </div>
        </of-can-ack-order>
      }
    </div>

    <of-toast #toastAckProcessingProgress [showCloseButton]="false">
      <div id="title" class="d-flex justify-content-between">
        <div>PROCESSING</div>
      </div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          <div>Processing...</div>
        </div>
      </div>
    </of-toast>
  `
})
export class AckOrderComponent {
  public submitting = false;

  @Input()
  public acknowledged: Date | null = null;

  @Input()
  public acknowledger: User | null = null;

  @Input()
  public comments: string | null = null;

  @Input()
  public orderStatus: CodeNameItem | null = null;

  @Input()
  public orderId = -1;

  @ViewChild('toastAckProcessingProgress')
  public toastAckProcessingProgress: ToastComponent | null = null;

  constructor(private ordersService: OrdersService) {}

  public markOrderAsAcknowledged(): void {
    this.submitting = true;
    this.toastAckProcessingProgress?.show();

    this.ordersService
      .updateOrderStatus$(this.orderId, {
        comments: this.comments || undefined,
        newStatus: ORDER_STATUS_VALUES['acknowledged'],
        orderId: this.orderId
      })
      .subscribe({
        next: () => {
          this.submitting = false;
          this.toastAckProcessingProgress?.hide();
        },
        error: () => {
          this.submitting = false;
          this.toastAckProcessingProgress?.hide();
        }
      });
  }
}
