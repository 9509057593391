import { Component } from '@angular/core';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import { ROLE_ORDER_READER, ROLE_ORDER_WRITER, ROLE_PARTIAL_EXECUTIONS_READER } from '../shared/constants';

@Component({
  selector: 'of-can-view-only-partial-executions',
  standalone: true,
  imports: [],
  template: `
    @if (canView) {
      <ng-content></ng-content>
    }
  `
})
export class CanViewOnlyPartialExecutionsComponent {
  public canView: boolean | null = null;

  constructor(private authService: OrderFlowAuthService) {}

  ngOnInit(): void {
    this.canView = canViewOnlyPartialExecutions(this.authService);
  }
}

export function canViewOnlyPartialExecutions(authService: OrderFlowAuthService): boolean {
  return (
    !authService.hasOneOfRoles([ROLE_ORDER_READER, ROLE_ORDER_WRITER]) &&
    authService.hasRole(ROLE_PARTIAL_EXECUTIONS_READER)
  );
}
