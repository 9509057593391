<form [formGroup]="fxForm">
  <div class="card">
    <div class="card-header">
      <div class="card-title m-0">TRANSACTION</div>
    </div>
    <div class="card-body gap-5">
      @if (!isRestrictedOrderWriter()) {
        <div class="d-flex flex-row gap-2 align-items-center ms-3 mt-4">
          <ejs-radiobutton
            label="Forward"
            name="securityType"
            [value]="FX_FORWARD_SECURITY_TYPE"
            formControlName="fxSecurityType"
          >
          </ejs-radiobutton>

          <ejs-radiobutton
            label="Spot"
            name="securityType"
            [value]="FX_SPOT_SECURITY_TYPE"
            [checked]="true"
            formControlName="fxSecurityType"
          >
          </ejs-radiobutton>
        </div>
      }
      <div class="me-5" style="flex-shrink: 0">
        <div class="d-flex flex-row justify-content-start align-items-end gap-3">
          <!-- Sale Currency -->
          <div style="width: 130px">
            <ejs-dropdownlist
              placeholder="Sell *"
              [dataSource]="currencies"
              [fields]="{ value: 'code' }"
              floatLabelType="Auto"
              [allowFiltering]="true"
              formControlName="fxSellCurrency"
            >
            </ejs-dropdownlist>
          </div>

          <div class="d-flex flex-row gap-2 align-items-center">
            <ejs-radiobutton label="Sell" name="transactionType" value="Sell" formControlName="transactionType">
            </ejs-radiobutton>

            <ejs-radiobutton label="Purchase" name="transactionType" value="Purchase" formControlName="transactionType">
            </ejs-radiobutton>
          </div>

          <!-- Buy Currency -->
          <div style="width: 130px">
            <ejs-dropdownlist
              placeholder="Purchase *"
              [dataSource]="currencies"
              [fields]="{ value: 'code', text: 'name' }"
              floatLabelType="Auto"
              [allowFiltering]="true"
              formControlName="fxBuyCurrency"
            >
            </ejs-dropdownlist>
          </div>

          <div style="width: 210px">
            <ejs-numerictextbox
              formControlName="quantity"
              [format]="'n2'"
              placeholder="Nominal"
              floatLabelType="Auto"
              [showSpinButton]="false"
            >
            </ejs-numerictextbox>
          </div>
        </div>

        <div class="d-flex justify-content-middle align-items-end gap-3">
          <!-- Sale Amount -->
          <div class="mt-2">
            <!-- FX Rate Display -->
            <span class="text-primary" style="font-size: 15px">
              {{ lblFxRate }}
            </span>
          </div>
        </div>
      </div>
      <div>
        <div class="d-flex justify-content-start align-items-end gap-3">
          <!-- Transaction Date -->
          <div style="width: 210px">
            <ejs-datepicker
              placeholder="Transaction Date *"
              formControlName="transactionDate"
              format="dd/MM/yyyy"
            ></ejs-datepicker>
          </div>
          @if (!isRestrictedOrderWriter()) {
            <div style="width: 210px">
              <ejs-datepicker
                placeholder="Value Date *"
                formControlName="valueDate"
                format="dd/MM/yyyy"
              ></ejs-datepicker>
            </div>
          }
        </div>

        <!-- TRADING FIELDS -->
        <div class="d-flex justify-content-start align-items-end gap-3">
          <!-- Custodian -->
          <div style="width: 210px">
            <ejs-dropdownlist
              placeholder="Custodian *"
              [dataSource]="custodians$ | async"
              [fields]="{ text: 'code', value: 'code' }"
              floatLabelType="Auto"
              [allowFiltering]="true"
              formControlName="custodian"
              (change)="onCustodianSelected($event)"
            ></ejs-dropdownlist>
          </div>
          @if (!isRestrictedOrderWriter()) {
            <!-- Execution method -->
            <div style="width: 210px">
              <ejs-dropdownlist
                placeholder="Execution method *"
                [dataSource]="executionMethodValues"
                [fields]="{ text: 'name', value: 'code' }"
                floatLabelType="Auto"
                [allowFiltering]="true"
                formControlName="executionMethod"
              ></ejs-dropdownlist>
            </div>

            <!-- Order type -->
            <div style="width: 210px">
              <ejs-dropdownlist
                placeholder="Order type *"
                [dataSource]="orderTypeValues"
                [fields]="{ text: 'name', value: 'code' }"
                floatLabelType="Auto"
                [allowFiltering]="true"
                formControlName="orderType"
              ></ejs-dropdownlist>
            </div>

            <!-- Limit Price -->
            <div style="width: 210px">
              <ejs-numerictextbox
                formControlName="limitPrice"
                [format]="ntbLimitPriceFormat"
                placeholder="Limit Price"
                floatLabelType="Auto"
                [showSpinButton]="false"
              ></ejs-numerictextbox>
            </div>
          }
        </div>
        @if (!isRestrictedOrderWriter()) {
          <div class="d-flex justify-content-start align-items-end gap-3">
            <!-- Execution Instruction -->
            <div style="width: 210px">
              <ejs-dropdownlist
                placeholder="Execution instruction *"
                [dataSource]="executionInstructionValues"
                [fields]="{ text: 'name', value: 'code' }"
                floatLabelType="Auto"
                [allowFiltering]="true"
                formControlName="executionInstruction"
              ></ejs-dropdownlist>
            </div>

            <!-- TIF -->
            <div style="width: 210px">
              <ejs-dropdownlist
                placeholder="TIF *"
                [dataSource]="tifValues"
                [fields]="{ text: 'name', value: 'code' }"
                floatLabelType="Auto"
                [allowFiltering]="true"
                formControlName="tif"
              ></ejs-dropdownlist>
            </div>
            @if (this.fxForm.get('tif')?.value === 'GTD') {
              <div style="width: 210px">
                <ejs-datepicker
                  placeholder="Expiry Date *"
                  formControlName="expiryDate"
                  format="dd/MM/yyyy"
                ></ejs-datepicker>
              </div>
            }
            <!-- Net Rate -->
            <div style="width: 210px">
              <ejs-dropdownlist
                placeholder="Net Rate"
                [dataSource]="netRates"
                [fields]="{ text: 'name', value: 'code' }"
                floatLabelType="Auto"
                [allowFiltering]="true"
                formControlName="fxNetRate"
              ></ejs-dropdownlist>
            </div>

            <!-- Brokerage Fee -->
            <div style="width: 210px">
              <ejs-numerictextbox
                formControlName="fxBrokerageFee"
                placeholder="Brokerage Fee"
                floatLabelType="Auto"
                [showSpinButton]="false"
              ></ejs-numerictextbox>
            </div>
            @if (isLimitOrderWithNetRateOrFxForward()) {
              <div style="width: 210px">
                <ejs-numerictextbox
                  formControlName="fxFinalCalculatedPrice"
                  [format]="ntbLimitPriceFormat"
                  placeholder="Calculated Final Price"
                  floatLabelType="Auto"
                  [showSpinButton]="false"
                ></ejs-numerictextbox>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>

  <of-allocations-form
    [quantityTypeLabel]="fxForm.get('quantityType')?.value"
    [totalQuantityOrNominal]="fxForm.get('quantity')?.value"
    [excludedPortfolios]="excludePortfolios"
    [transactionType]="fxForm.get('transactionType')?.value"
    [isFx]="true"
    [parentForm]="this.fxForm"
  ></of-allocations-form>

  <div class="card mb-3">
    <div class="card-header">
      <div class="card-title m-0">ADDITIONAL INFORMATION (INTERNAL)</div>
    </div>
    <div class="card-body">
      <ejs-textbox
        placeholder="Additional information (internal)"
        formControlName="additionalInternalInformation"
        [multiline]="true"
        floatLabelType="Auto"
        style="height: 100px"
      ></ejs-textbox>
    </div>
  </div>

  <of-attachment-uploader [form]="fxForm"></of-attachment-uploader>
</form>

@if (environment === 'development') {
  <div class="card my-3 text-warning-emphasis bg-warning-subtle">
    <div class="card-body font-monospace">
      <small>
        {{ fxForm.getRawValue() | json }}
      </small>
    </div>
  </div>
}

<div class="d-flex gap-3">
  <div class="btn-group">
    <a routerLink="/orders" class="btn btn-link">BACK</a>
    <button type="button" class="btn btn-light" (click)="reset()" [disabled]="submitting">RESET</button>
    <button type="button" class="btn btn-primary" (click)="submit()">SUBMIT</button>
  </div>
  @if (submitting) {
    <mat-spinner diameter="20"></mat-spinner>
  }
</div>
