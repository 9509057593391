import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Broker } from './broker.model';
import { BrokersService } from './brokers.service';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { AsyncPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { BROKER_DESTINATION_EMAIL_CODE } from '../shared/constants';
import { BrokerDestinationBadgeComponent } from './broker-destination-badge.component';

@Component({
  selector: 'of-brokers',
  standalone: true,
  imports: [AsyncPipe, BrokerDestinationBadgeComponent, GridModule, RouterModule, SwitchModule],
  templateUrl: './brokers.component.html',
  styleUrl: './brokers.component.css'
})
export class BrokersComponent implements OnInit {
  public brokers$: Observable<Broker[]> | null = null;
  public showHidden = false;
  public destinationEmail = BROKER_DESTINATION_EMAIL_CODE;

  constructor(private brokersService: BrokersService) {}

  public ngOnInit(): void {
    this.setBrokers();
  }

  public onShowHiddenChange(event: any): void {
    this.showHidden = event.checked;
    this.setBrokers();
  }

  private setBrokers() {
    this.brokers$ = this.brokersService.listBrokers$(this.showHidden, true);
  }
}
