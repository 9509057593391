import { Component, Input } from '@angular/core';

@Component({
  selector: 'of-check-status-badge',
  standalone: true,
  imports: [],
  template: `
    @if (checkPassed === 'Passed') {
      <span class="badge text-success-emphasis bg-success-subtle" title="Passed">CHECKED</span>
    } @else if (checkPassed) {
      <span class="badge text-danger-emphasis bg-danger-subtle" title="Sent back for revision">REVISING</span>
    } @else {
      <span class="badge text-secondary-emphasis bg-secondary-subtle" title="N/A">PENDING</span>
    }
  `
})
export class CheckStatusBadgeComponent {
  @Input()
  public checkPassed: string | null = null;
}
