import { Component, Input } from '@angular/core';
import { CodeNameItem, ORDER_STATUS_VALUES } from '../shared/code-name-value.model';

@Component({
  selector: 'of-can-cancel-order',
  standalone: true,
  imports: [],
  template: `
    @if (canCancel) {
      <ng-content></ng-content>
    }
  `
})
export class CanCancelOrderComponent {
  public canCancel = false;

  @Input()
  public orderStatus: CodeNameItem | null = null;

  ngOnInit(): void {
    this.canCancel = canCancelOrder(this.orderStatus);
  }
}

export function canCancelOrder(orderStatus: CodeNameItem | null): boolean {
  return (
    orderStatus?.code === ORDER_STATUS_VALUES['sent'].code ||
    orderStatus?.code === ORDER_STATUS_VALUES['acknowledged'].code ||
    orderStatus?.code === ORDER_STATUS_VALUES['executable'].code
  );
}
