import { Component } from '@angular/core';

@Component({
    selector: 'of-form-control-label',
    imports: [],
    template: `
    <label>
      <ng-content></ng-content>
    </label>
  `,
    styles: `label { font-size: small; }`
})
export class FormControlLabelComponent {}
