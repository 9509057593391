import { Component, Input } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { OrderPermissionsService } from '../order-permissions.service';
import { OrderFlowAuthService } from '../../auth/order-flow-auth.service';
import { ROLE_ORDER_WRITER } from '../../shared/constants';
import { AsyncPipe } from '@angular/common';
import { CodeNameItem } from '../../shared/code-name-value.model';
import { isEditableStatus } from '../../shared/utils';

@Component({
  selector: 'of-can-edit-order',
  standalone: true,
  imports: [AsyncPipe],
  template: `
    @if (canEdit$ | async) {
      <ng-content></ng-content>
    }
  `
})
export class CanEditOrderComponent {
  public canEdit$: Observable<boolean> | null = null;

  @Input()
  public orderId: number | null = null;

  @Input()
  public orderStatus: CodeNameItem | null = null;

  constructor(
    private permissionsService: OrderPermissionsService,
    private authService: OrderFlowAuthService
  ) {}

  ngOnInit(): void {
    this.canEdit$ =
      this.orderId && isEditableStatus(this.orderStatus) && this.authService.hasRole(ROLE_ORDER_WRITER)
        ? this.permissionsService.canEditOrder$(this.orderId).pipe(map((res) => res.isAuthorized))
        : of(false);
  }
}
