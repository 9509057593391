import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalService
} from '@azure/msal-angular';
import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './auth/msal.utils';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { environment } from '../environments/environment';
import { LOCALE_ID } from '@angular/core';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { STORE_PORTFOLIOS, STORE_SECURITIES } from './shared/constants';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    { provide: LOCALE_ID, useValue: 'en-GB' },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    importProvidersFrom(
      LoggerModule.forRoot({
        level: environment.env === 'production' ? NgxLoggerLevel.OFF : NgxLoggerLevel.LOG,
        serverLogLevel: NgxLoggerLevel.OFF,
        enableSourceMaps: environment.env !== 'production'
      })
    ),
    importProvidersFrom(
      NgxIndexedDBModule.forRoot({
        name: 'OrderFlowDb',
        version: 2,
        objectStoresMeta: [
          {
            store: STORE_SECURITIES,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
              { name: 'currency', keypath: 'currency', options: { unique: false } },
              { name: 'eodCode', keypath: 'eodCode', options: { unique: false } },
              { name: 'faSecurityCode', keypath: 'faSecurityCode', options: { unique: true } },
              { name: 'isin', keypath: 'isin', options: { unique: false } },
              { name: 'name', keypath: 'name', options: { unique: false } },
              { name: 'type', keypath: 'type', options: { unique: false } }
            ]
          },
          {
            store: STORE_PORTFOLIOS,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
              { name: 'accounts', keypath: 'accounts', options: { unique: false } },
              { name: 'portfolio', keypath: 'portfolio', options: { unique: false } }
            ]
          }
        ]
      })
    )
  ]
};
