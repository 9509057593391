import { Component, Input, OnInit } from '@angular/core';
import { CodeNameItem, ORDER_STATUS_VALUES } from './code-name-value.model';
import { UpperCasePipe } from '@angular/common';

@Component({
    selector: 'of-order-status-badge',
    imports: [UpperCasePipe],
    template: `
    @if (status) {
      <span [class]="styleClass" [title]="status.name">
        @if (useShortFormat) {
          {{ shortValue | uppercase }}
        } @else {
          {{ status.name | uppercase }}
        }
      </span>
    }
  `
})
export class OrderStatusBadgeComponent implements OnInit {
  public styleClass = '';
  public shortValue: string | null = '';

  @Input()
  public status: CodeNameItem | null = null;

  @Input()
  public useShortFormat = false;

  public ngOnInit(): void {
    if (this.status) {
      this.shortValue = Object.values(ORDER_STATUS_VALUES).find((s) => s.code === this.status!.code)?.shortName || null;

      switch (this.status.code) {
        case ORDER_STATUS_VALUES['acknowledged'].code:
        case ORDER_STATUS_VALUES['partiallyExecuted'].code:
        case ORDER_STATUS_VALUES['executed'].code:
          this.styleClass = 'badge text-success-emphasis bg-success-subtle';
          break;
        case ORDER_STATUS_VALUES['deleted'].code:
        case ORDER_STATUS_VALUES['rejected'].code:
        case ORDER_STATUS_VALUES['revising'].code:
        case ORDER_STATUS_VALUES['cancelled'].code:
        case ORDER_STATUS_VALUES['faUploadFailed'].code:
          this.styleClass = 'badge text-danger-emphasis bg-danger-subtle';
          break;
        case ORDER_STATUS_VALUES['pendingApproval'].code:
        case ORDER_STATUS_VALUES['executable'].code:
        case ORDER_STATUS_VALUES['sent'].code:
          this.styleClass = 'badge text-warning-emphasis bg-warning-subtle';
          break;
        case ORDER_STATUS_VALUES['pendingFaUpload'].code:
        case ORDER_STATUS_VALUES['pendingPreTradeCheck'].code:
        case ORDER_STATUS_VALUES['pendingCheck'].code:
          this.styleClass = 'badge text-secondary-emphasis bg-secondary-subtle';
          break;
        default:
          this.styleClass = '';
          break;
      }
    }
  }
}
