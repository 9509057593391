import { MatDialog } from '@angular/material/dialog';
import { ApiError } from '../api-errror.model';
import { ApiErrorDialogComponent } from './api-error-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CIGP_COLORS, FX_FORWARD_SECURITY_TYPE, FX_SPOT_SECURITY_TYPE } from './constants';
import {
  BROKER_DESTINATION_VALUES,
  CodeNameItem,
  ORDER_STATUS_VALUES,
  PARTIAL_EXECUTION_STATUS_VALUES
} from './code-name-value.model';

export function showErrorDialog(apiErrorDialog: MatDialog, error: ApiError): void {
  apiErrorDialog.open(ApiErrorDialogComponent, {
    width: '800px',
    data: error
  });
}

export function showSuccessSnackbar(snackBar: MatSnackBar, message: string): void {
  if (message) {
    snackBar.open(message, undefined, {
      duration: 2000
    });
  }
}

export function showInitials(value: string | null | undefined): string {
  if (!value) {
    return '';
  }

  return value
    .split(' ')
    .map((name) => name.charAt(0).toUpperCase())
    .join('');
}

export function randomCigpColor(): string {
  return CIGP_COLORS[Math.floor(Math.random() * CIGP_COLORS.length)];
}

export function isApprovableStatus(status: CodeNameItem | null): boolean {
  return status !== null && status.code === ORDER_STATUS_VALUES['pendingApproval'].code;
}

export function isCheckableStatus(status: CodeNameItem | null): boolean {
  return status !== null && status.code === ORDER_STATUS_VALUES['pendingCheck'].code;
}

export function isPretradeCheckableStatus(status: CodeNameItem | null | undefined): boolean {
  return (
    status !== null &&
    status !== undefined &&
    (status.code === ORDER_STATUS_VALUES['pendingPreTradeCheck'].code ||
      status.code === ORDER_STATUS_VALUES['pendingCheck'].code ||
      status.code === ORDER_STATUS_VALUES['pendingApproval'].code)
  );
}

export function isDeletableStatus(status: CodeNameItem | null): boolean {
  return (
    status !== null &&
    (status.code === ORDER_STATUS_VALUES['revising'].code ||
      status.code === ORDER_STATUS_VALUES['pendingPreTradeCheck'].code ||
      status.code === ORDER_STATUS_VALUES['pendingCheck'].code ||
      status.code === ORDER_STATUS_VALUES['pendingApproval'].code ||
      status.code === ORDER_STATUS_VALUES['faUploadFailed'].code)
  );
}

export function isEditableStatus(status: CodeNameItem | null): boolean {
  return status !== null && status.code === ORDER_STATUS_VALUES['revising'].code;
}

export function isSpotOrForwardFxOrder(type: string): boolean {
  return type == FX_SPOT_SECURITY_TYPE || type == FX_FORWARD_SECURITY_TYPE;
}

export function isSendableStatus(status: CodeNameItem | null): boolean {
  return status !== null && status.code === ORDER_STATUS_VALUES['executable'].code;
}

export function isPreviewableStatus(status: CodeNameItem | null): boolean {
  return (
    status !== null &&
    (status.code === ORDER_STATUS_VALUES['executable'].code ||
      status.code === ORDER_STATUS_VALUES['sent'].code ||
      status.code === ORDER_STATUS_VALUES['acknowledged'].code ||
      status.code === ORDER_STATUS_VALUES['executed'].code ||
      status.code === ORDER_STATUS_VALUES['partiallyExecuted'].code ||
      status.code === ORDER_STATUS_VALUES['cancelled'].code)
  );
}

export function getSecurityPriceNtbFormat(multiplier: number | null): string {
  return multiplier === 100 ? 'p4' : '#,###.#####';
}

export function getBrokerDestinationName(destinationCode: string | null): string | null {
  return BROKER_DESTINATION_VALUES.find((item) => item.code === destinationCode)?.name || null;
}

export function canExecuteOrder(orderStatus: CodeNameItem | null): boolean {
  return (
    orderStatus?.code === ORDER_STATUS_VALUES['acknowledged'].code ||
    orderStatus?.code === ORDER_STATUS_VALUES['partiallyExecuted'].code
  );
}

export function canApproveExecutions(orderStatus: CodeNameItem | null): boolean {
  return (
    orderStatus?.code === ORDER_STATUS_VALUES['partiallyExecuted'].code
  );
}

export function getPartialExecutionStatusShortName(status: string): string | null {
  return Object.values(PARTIAL_EXECUTION_STATUS_VALUES).find((s) => s.code === status)?.shortName || null;
}

export function getStatusClass(status: string): string {
  if (status === 'Approved') {
    return 'badge text-success-emphasis bg-success-subtle';
  } else if (status === 'PendingApproval') {
    return 'badge text-warning-emphasis bg-warning-subtle';
  } else if (status === 'Rejected') {
    return 'badge text-danger-emphasis bg-danger-subtle';
  }
  return 'badge';
}
