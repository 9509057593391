import { Component, OnInit } from '@angular/core';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import { canViewOnlyPartialExecutions } from '../partial-executions/can-view-only-partial-executions.component';
import { Router } from '@angular/router';

@Component({
    selector: 'of-home',
    imports: [],
    template: ``
})
export class HomeComponent implements OnInit {
  constructor(
    private authService: OrderFlowAuthService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    if (canViewOnlyPartialExecutions(this.authService)) {
      this.router.navigate(['/partial-executions']);
    } else {
      this.router.navigate(['/orders']);
    }
  }
}
