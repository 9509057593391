<div class="card">
  <div class="card-header d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <h4 class="card-title m-0">BROKERS</h4>
      <div class="btn-group btn-group-sm ms-2">
        <of-can-create-broker>
          <button
            type="button"
            class="btn btn-sm btn-outline-danger border-0"
            title="Portfolio Restrictions"
            (click)="openBulkExcludeDialog()"
          >
            <i class="bi bi-sign-stop"></i>
          </button>
        </of-can-create-broker>
      </div>
    </div>
    <div class="d-flex align-items-center gap-2">
      <ejs-switch (change)="onShowHiddenChange($event)"></ejs-switch>
      <div>Show hidden</div>
    </div>
  </div>
  <div class="card-body p-0">
    <ejs-grid [dataSource]="brokers$ | async">
      <e-columns>
        <e-column field="id" headerText="#" width="20">
          <ng-template #template let-data>
            <span class="me-2">{{ data.id }}</span>
            @if (data.hidden) {
              <i class="bi bi-ban text-danger" title="Hidden"></i>
            }
          </ng-template>
        </e-column>
        <e-column field="code" headerText="Code" width="50"></e-column>
        <e-column field="name" headerText="Name" width="65">
          <ng-template #template let-data>
            <div>
              {{ data.name }}
              <of-broker-destination-badge [destinationCode]="data.destination"></of-broker-destination-badge>
            </div>
          </ng-template>
        </e-column>
        <e-column field="cigpAccountNumber" headerText="CIGP Account Number" width="90"></e-column>
        <e-column field="emails" headerText="Emails" width="120">
          <ng-template #template let-data>
            @if (data.destination === destinationEmail) {
              <ul class="m-0 ps-3">
                @for (email of data.emails; track $index) {
                  <li>{{ email }}</li>
                }
              </ul>
            } @else {
              <div>N/A</div>
            }
          </ng-template>
        </e-column>
        <e-column width="20">
          <ng-template #template let-data>
            <a class="btn btn-outline-secondary border-0" [routerLink]="['/brokers', data.id, 'edit']"
              ><i class="bi bi-pencil-square"></i
            ></a>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>
  <of-toast #toastBrokerPortfolioRestrictionsProgress [showCloseButton]="false">
    <div id="title" class="d-flex justify-content-between">
      <div>UPDATING BROKERS WITH NEW RESTRICTIONS</div>
    </div>
    <div id="content" class="d-flex gap-2">
      <mat-spinner [diameter]="20"></mat-spinner>
      <div>
        {{ brokerPortfolioRestrictionsMessage }}
      </div>
    </div>
  </of-toast>
</div>
