import { Component } from '@angular/core';
import { CanWriteCustodianComponent } from './custodian-form/can-write-custodian.component';
import { RouterModule } from '@angular/router';

@Component({
    selector: 'of-custodians-nav-menu',
    imports: [CanWriteCustodianComponent, RouterModule],
    template: `
    <of-can-write-custodian>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          CUSTODIANS
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" [routerLink]="['/custodians']"><i class="bi bi-list"></i> LIST</a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/custodians', 'create']"><i class="bi bi-plus-lg"></i> NEW</a>
          </li>
        </ul>
      </li>
    </of-can-write-custodian>
  `
})
export class CustodiansNavMenuComponent {}
