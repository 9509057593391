import { Injectable } from '@angular/core';
import { Broker, BrokerToAddOrUpdate, DetailedBroker } from './broker.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';
import { showErrorDialog, showSuccessSnackbar } from '../shared/utils';

@Injectable({
  providedIn: 'root'
})
export class BrokersService {
  private brokers: Broker[] | null = null;
  private apiEndpoint = `${environment.apiEndpoint}/api/brokers`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  public deleteBroker$(id: number): Observable<void> {
    return this.http.delete(`${this.apiEndpoint}/${id}`).pipe(
      map(() => showSuccessSnackbar(this.snackBar, 'Broker successfully deleted')),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public listBrokers$(includeHidden: boolean, forceRefresh = false): Observable<Broker[]> {
    return this.brokers && !forceRefresh
      ? of(this.brokers.filter((b) => includeHidden || !b.hidden))
      : this.http.get<Broker[]>(this.apiEndpoint, { params: { includeHidden } }).pipe(
          tap((brokers) => (this.brokers = brokers)),
          catchError((error: any) => {
            this.logger.error(error);
            showErrorDialog(this.errorDialog, error);
            return throwError(() => new Error(error));
          })
        );
  }

  public getBroker$(id: number): Observable<DetailedBroker> {
    return this.http.get<DetailedBroker>(`${this.apiEndpoint}/${id}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getBrokerForUpdate$(id: number): Observable<BrokerToAddOrUpdate> {
    return this.http.get<BrokerToAddOrUpdate>(`${this.apiEndpoint}/${id}/for-update`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public insertBroker$(broker: BrokerToAddOrUpdate): Observable<DetailedBroker> {
    return this.http.post<DetailedBroker>(this.apiEndpoint, broker).pipe(
      tap(() => showSuccessSnackbar(this.snackBar, 'Broker successfully created')),
      tap(() => this.listBrokers$(true).subscribe()),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public updateBroker$(id: number, broker: BrokerToAddOrUpdate): Observable<DetailedBroker> {
    return this.http.put<DetailedBroker>(`${this.apiEndpoint}/${id}`, broker).pipe(
      tap(() => showSuccessSnackbar(this.snackBar, 'Broker successfully updated')),
      tap(() => this.listBrokers$(true).subscribe()),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }
}
