import { Component, Input, OnInit } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { OrderPermissionsService } from '../orders/order-permissions.service';
import { AsyncPipe } from '@angular/common';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import { ROLE_ORDER_APPROVER } from '../shared/constants';
import { CodeNameItem } from '../shared/code-name-value.model';
import { isApprovableStatus } from '../shared/utils';

@Component({
  selector: 'of-can-approve-order',
  standalone: true,
  imports: [AsyncPipe],
  template: `
    @if (canApprove$ | async) {
      <ng-content></ng-content>
    }
  `
})
export class CanApproveOrderComponent implements OnInit {
  public canApprove$: Observable<boolean> | null = null;

  @Input()
  public orderId: number | null = null;

  @Input()
  public orderStatus: CodeNameItem | null = null;

  constructor(
    private permissionsService: OrderPermissionsService,
    private authService: OrderFlowAuthService
  ) {}

  ngOnInit(): void {
    this.canApprove$ =
      this.orderId && isApprovableStatus(this.orderStatus) && this.authService.hasRole(ROLE_ORDER_APPROVER)
        ? this.permissionsService.canApproveOrder$(this.orderId).pipe(map((res) => res.isAuthorized))
        : of(false);
  }
}
