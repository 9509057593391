import { Component, Input, OnInit } from '@angular/core';
import { getBrokerDestinationName } from '../shared/utils';

@Component({
  selector: 'of-broker-destination-badge',
  standalone: true,
  imports: [],
  template: ` <span class="badge text-bg-light" [title]="destination">{{ destination }}</span> `
})
export class BrokerDestinationBadgeComponent implements OnInit {
  public destination: string | null = null;

  @Input()
  public destinationCode: string | null = null;

  public ngOnInit(): void {
    this.destination = getBrokerDestinationName(this.destinationCode);
  }
}
