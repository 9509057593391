import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Order } from '../orders/order.model';
import { NumericTextBoxModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { OrdersService } from '../orders/orders.service';
import { PartialExecutionToAddFormConfig } from './partial-execution.model';
import { UpperCasePipe } from '@angular/common';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { QUANTITY_TYPE_VALUES } from '../shared/code-name-value.model';
import { TYPE_TD } from '../shared/constants';

@Component({
    selector: 'of-record-partial-execution-dialog',
    imports: [RadioButtonModule, ReactiveFormsModule, NumericTextBoxModule, TextBoxModule, UpperCasePipe],
    template: `
    @if (data) {
      <form [formGroup]="form">
        <div class="d-flex flex-column gap-3 p-3">
          <h5 mat-dialog-title class="m-0">RECORD {{ executionType | uppercase }} EXECUTION FOR ORDER {{ data.id }}</h5>
          <div mat-dialog-content>
            <div>
              <!-- Quantity Type -->
              <div style="width: 320px" class="d-flex gap-3 mb-2 align-items-center">
                <small>Order quantity type:</small>
                @for (quantityType of quantityTypeValues; track $index) {
                  <ejs-radiobutton
                    [label]="quantityType.name"
                    [value]="quantityType.code"
                    name="radQuantityType"
                    formControlName="quantityType"
                  ></ejs-radiobutton>
                }
              </div>
            </div>
            <div class="d-flex gap-2">
              <!-- Execution Type -->
              <div style="width: 230px" class="d-flex gap-2 mt-4 align-items-center">
                <small>Execution type:</small>
                <ejs-radiobutton
                  label="Partial"
                  [value]="false"
                  name="radIsFinalExecution"
                  formControlName="isFinalExecution"
                ></ejs-radiobutton>
                <ejs-radiobutton
                  label="Final"
                  [value]="true"
                  name="radIsFinalExecution"
                  formControlName="isFinalExecution"
                ></ejs-radiobutton>
              </div>
              <div class="d-flex gap-2 align-items-end">
                <ejs-numerictextbox
                  formControlName="quantity"
                  format="#,###.#####"
                  [placeholder]="lblQuantity"
                  floatLabelType="Auto"
                  [showSpinButton]="false"
                  [min]="0"
                ></ejs-numerictextbox>
                <small>{{ suffixQuantity }}</small>
              </div>
            </div>

            <ejs-textbox
              formControlName="transactionRefNumber"
              placeholder="Transaction ref number (optional)"
              floatLabelType="Auto"
            ></ejs-textbox>
            <ejs-textbox
              formControlName="comments"
              placeholder="Comments (optional)"
              multiline="true"
              floatLabelType="Auto"
              style="height: 100px"
            ></ejs-textbox>
          </div>
          <div mat-dialog-actions class="d-flex justify-content-end">
            <div class="btn-group">
              <button class="btn btn-secondary" (click)="onCloseClick()">CLOSE</button>
              <button class="btn btn-primary" [disabled]="!form.valid" (click)="onExecuteClick()" cdkFocusInitial>
                RECORD {{ executionType | uppercase }} EXECUTION
              </button>
            </div>
          </div>
        </div>
      </form>
    }
  `
})
export class RecordPartialExecutionDialogComponent {
  public form: FormGroup = new FormGroup({});
  public submitting = false;
  public lblQuantity: string | null = null;
  public suffixQuantity: string | null = null;
  public executionType = 'partial';
  public quantityTypeValues = Object.values(QUANTITY_TYPE_VALUES);

  constructor(
    public dialogRef: MatDialogRef<RecordPartialExecutionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Order,
    private ordersService: OrdersService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group<PartialExecutionToAddFormConfig>({
      comments: this.fb.control<string | null>(null),
      isFinalExecution: this.fb.control<boolean | null>(false),
      quantity: this.fb.control<number | null>(null, {
        validators: [Validators.required, Validators.min(0)]
      }),
      quantityType: this.fb.control<string | null>(null, Validators.required),
      transactionRefNumber: this.fb.control<string | null>(null)
    });

    this.form.get('isFinalExecution')?.valueChanges.subscribe((isFinalExecution) => {
      this.executionType = isFinalExecution ? 'final' : 'partial';
    });

    this.form.get('quantityType')?.valueChanges.subscribe((quantityType) => {
      this.lblQuantity = quantityType === QUANTITY_TYPE_VALUES['nominal'].code ? 'Nominal *' : 'Quantity *';
      this.suffixQuantity =
        quantityType === QUANTITY_TYPE_VALUES['nominal'].code ? data.account.currency : 'shares / units';
    });

    if (data.security.type === TYPE_TD) {
      this.form.get('quantity')?.setValue(data.quantity);
      this.form.get('isFinalExecution')?.setValue(true);
    }

    if (data.executionQuantityType) {
      this.form.get('quantityType')?.setValue(data.executionQuantityType.code);
      this.form.get('quantityType')?.disable();
    } else {
      this.form.get('quantityType')?.setValue(data.quantityType.code);
    }
  }

  public onExecuteClick(): void {
    this.form.disable();

    this.submitting = true;

    this.ordersService.recordPartialExecution$(this.data.id, this.form.getRawValue()).subscribe({
      next: () => (this.submitting = false),
      error: () => (this.submitting = false)
    });

    this.dialogRef.close();
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
