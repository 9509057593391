import { Component, Input, OnInit } from '@angular/core';
import { UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import { FormGroup } from '@angular/forms';
import { environment } from '../../environments/environment';

export interface UploadPath {
  removeUrl: string;
  saveUrl: string;
}

@Component({
    selector: 'of-attachment-uploader',
    imports: [UploaderModule],
    template: `
    <div class="card mb-3">
      <div class="card-header">
        <div class="card-title">ATTACHMENT</div>
        <div class="card-subtitle">
          <small><i>Optional</i></small>
        </div>
      </div>
      <div class="card-body p-0">
        <ejs-uploader
          allowedExtensions=".pdf"
          maxFileSize="5000000"
          multiple="false"
          [asyncSettings]="path"
          (uploading)="addHeaders($event)"
          (removing)="addHeaders($event)"
          (success)="onSuccess($event)"
        ></ejs-uploader>
      </div>
    </div>
  `
})
export class AttachmentUploaderComponent implements OnInit {
  private accessToken: string | null = null;
  public path: UploadPath = environment.tempFileUploadPath;

  @Input()
  public form: FormGroup = new FormGroup({});

  constructor(private authService: OrderFlowAuthService) {}

  public ngOnInit(): void {
    this.authService.getAccessToken$().subscribe((accessToken) => (this.accessToken = accessToken));
  }

  public addHeaders(args: any): void {
    args.currentRequest.setRequestHeader('Authorization', `Bearer ${this.accessToken}`);

    if (this.form.get('attachmentTempPath')?.value) {
      args.currentRequest.setRequestHeader('X-TempPath', this.form.get('attachmentTempPath')?.value);
    }
  }

  public onSuccess(event: any): void {
    if (event.operation === 'upload') {
      const tempPath = JSON.parse(event.e.currentTarget.response).path;

      if (tempPath) {
        this.form.get('attachmentTempPath')?.setValue(tempPath);
      }
    } else if (event.operation === 'remove') {
      this.form.get('attachmentTempPath')?.reset();
    }
  }
}
