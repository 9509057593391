import { Component, Input } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { OrderPermissionsService } from '../order-permissions.service';
import { AsyncPipe } from '@angular/common';
import { CodeNameItem } from '../../shared/code-name-value.model';
import { isSendableStatus } from '../../shared/utils';

@Component({
  selector: 'of-can-send-order',
  standalone: true,
  imports: [AsyncPipe],
  template: `
    @if (canSend$ | async) {
      <ng-content></ng-content>
    }
  `
})
export class CanSendOrderComponent {
  public canSend$: Observable<boolean> | null = null;

  @Input()
  public orderId: number | null = null;

  @Input()
  public orderStatus: CodeNameItem | null = null;

  constructor(private permissionsService: OrderPermissionsService) {}

  ngOnInit(): void {
    this.canSend$ =
      this.orderId && isSendableStatus(this.orderStatus)
        ? this.permissionsService.canSendOrder$(this.orderId).pipe(map((res) => res.isAuthorized))
        : of(false);
  }
}
