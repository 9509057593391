import * as i0 from '@angular/core';
import { EventEmitter, inject, DestroyRef, Directive, ElementRef, numberAttribute, Input, InjectionToken, Injectable, Inject, booleanAttribute, Output, makeEnvironmentProviders } from '@angular/core';
import { NgControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent, debounceTime, map, Observable, filter, Subscription } from 'rxjs';
import { filter as filter$1 } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

/**
 * string Guard
 */
const isString = value => {
  return typeof value === 'string';
};

/**
 * Directive handling `ReactiveForms` binding.
 *
 * @usageNotes
 *
 * ### Example binding a text input
 *
 * ```html
 * <input
 *   type="text"
 *   id="demo-text-input"
 *   controlValueAccessor
 *   initFromStorage
 *   prefix="demo"
 *   [formControl]="textControl"
 * />
 * ```
 */
class ControlValueAccessorDirective {
  constructor() {
    /**
     * Emitter for value changes.
     */
    this.valueChange = new EventEmitter();
    /**
     * The control to bind to.
     */
    this.ngControl = inject(NgControl, {
      optional: true,
      self: true
    });
    this.destroyRef = inject(DestroyRef);
  }
  /**
   * Sets up event observer for value changes.
   */
  ngOnInit() {
    this.ngControl?.valueChanges?.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.valueChange.emit(value);
    });
  }
  /**
   * Writes the value to the control.
   * @param value Value to write
   * @param falsyTransformer *optional* transformer handling falsy values
   */
  writeValue(value, falsyTransformer) {
    const transformedValue = (!value || isString(value) && value === 'false') && !!falsyTransformer ? falsyTransformer() : value;
    this.ngControl?.valueAccessor?.writeValue(transformedValue);
  }
  /**
   * Gets the current control value.
   */
  getValue() {
    return this.ngControl?.value;
  }
  static {
    this.ɵfac = function ControlValueAccessorDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ControlValueAccessorDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: ControlValueAccessorDirective,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ControlValueAccessorDirective, [{
    type: Directive,
    args: [{
      standalone: true
    }]
  }], null, null);
})();

/**
 * Returns an array representation of the input value.
 *
 * @param value - the input value
 * @return array representation of the input value
 */
function valuePathAttribute(value) {
  if (Array.isArray(value)) {
    return value;
  } else if (value != null) {
    return value.toString().split(',');
  } else {
    return [];
  }
}

/**
 * Gets an objects property based on its path.
 * @param object Object to access
 * @param path Path to the property
 */
const getPropByPath = (object, path) => {
  const checkProperty = (o, key) => typeof o === 'object' ? o[key] : undefined;
  return path.slice(0).reduce((prev, curr, _i, arr) => {
    if (prev === undefined) {
      arr.splice(1);
    }
    return checkProperty(prev, curr);
  }, object);
};
/**
* Sets an objects property based on its path.
* @param object Object whose value to set
* @param path Path to the property
* @param value Value to set
* @param falsyTransformer optional transformer handling falsy values
*/
const setPropByPath = (object, path, value, falsyTransformer) => {
  const checkProperty = (o, key) => typeof o === 'object' ? o[key] : undefined;
  path.slice(0).reduce((prev, curr, i, arr) => {
    if (prev === undefined) {
      arr.splice(1);
    }
    // last path segment
    if (i === path.length - 1) {
      object[curr] = (!value || isString(value) && value === 'false') && !!falsyTransformer ? falsyTransformer() : value;
      arr.splice(1);
    }
    return checkProperty(prev, curr);
  }, object);
};

/**
 * Directive handling native control binding.
 *
 * @usageNotes
 *
 * ### Example binding a text input
 *
 * ```html
 * <input
 *   type="text"
 *   id="demo-text-input"
 *   ngxLocalStorage="native-text-input"
 *   initFromStorage
 *   prefix="demo"
 *   forEvent="input"
 *   valuePath="value"
 * />
 * ```
 *
 */
class NativeValueAccessorDirective {
  constructor() {
    /**
     * Provides a path to access the bound elements value property.
     */
    this.valuePath = [];
    /**
     * An optional debounce for storage write access after value changes.
     */
    this.storageDebounce = 0;
    /**
     * Emitter for value changes.
     */
    this.valueChange = new EventEmitter();
    this.elementRef = inject(ElementRef);
    this.destroyRef = inject(DestroyRef);
  }
  /**
   * Sets up event observer for value changes.
   */
  ngAfterViewInit() {
    if (this.forEvent) {
      fromEvent(this.elementRef.nativeElement, this.forEvent).pipe(takeUntilDestroyed(this.destroyRef), debounceTime(this.storageDebounce), map(() => this.getValue())).subscribe(this.valueChange);
    }
  }
  /**
   * Writes the value to the control.
   * @param value Value to write
   * @param falsyTransformer *optional* transformer handling falsy values
   */
  writeValue(value, falsyTransformer) {
    setPropByPath(this.elementRef.nativeElement, this.getValuePath(), value, falsyTransformer);
  }
  /**
   * Gets the current control value.
   */
  getValue() {
    return getPropByPath(this.elementRef.nativeElement, this.getValuePath());
  }
  getValuePath() {
    return this.valuePath.length ? this.valuePath : ['value'];
  }
  static {
    this.ɵfac = function NativeValueAccessorDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || NativeValueAccessorDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NativeValueAccessorDirective,
      inputs: {
        forEvent: "forEvent",
        valuePath: [2, "valuePath", "valuePath", valuePathAttribute],
        storageDebounce: [2, "storageDebounce", "storageDebounce", numberAttribute]
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NativeValueAccessorDirective, [{
    type: Directive,
    args: [{
      standalone: true
    }]
  }], null, {
    forEvent: [{
      type: Input
    }],
    valuePath: [{
      type: Input,
      args: [{
        transform: valuePathAttribute
      }]
    }],
    storageDebounce: [{
      type: Input,
      args: [{
        transform: numberAttribute
      }]
    }]
  });
})();
const NGX_LOCAL_STORAGE_DEFAULT_CONFIG = () => {
  return {
    allowNull: true,
    storageType: 'localStorage',
    delimiter: '_'
  };
};
/**
 * Provides an injection token for the service configuration.
 */
const NGX_LOCAL_STORAGE_CONFIG = new InjectionToken('NgxLocalstorageConfiguration', {
  providedIn: 'root',
  factory: NGX_LOCAL_STORAGE_DEFAULT_CONFIG
});

/**
 * Provides a default serialization mechanism using JSON.
 */
class DefaultSerializer {
  /**
   * @inheritdoc
   */
  serialize(value) {
    return JSON.stringify(value);
  }
  /**
   * @inheritdoc
   */
  deserialize(storedValue) {
    return JSON.parse(storedValue);
  }
  static {
    this.ɵfac = function DefaultSerializer_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || DefaultSerializer)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: DefaultSerializer,
      factory: DefaultSerializer.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DefaultSerializer, [{
    type: Injectable
  }], null, null);
})();

/**
 * Provides an injection token for the services serializer.
 */
const NGX_LOCAL_STORAGE_SERIALIZER = new InjectionToken('StorageSerializer', {
  providedIn: 'root',
  factory: () => new DefaultSerializer()
});

/**
 * Provides an abstraction to WINDOW.
 */
const WINDOW = new InjectionToken('Abstraction token for global window access', {
  factory: () => {
    const {
      defaultView
    } = inject(DOCUMENT);
    if (!defaultView) {
      throw new Error('Window is not available');
    }
    return defaultView;
  }
});

/**
 * Provides information if localstorage is available.
 */
const LOCALSTORAGE_SUPPORT = new InjectionToken('Token providing information if localStorage is available', {
  factory: () => !!inject(LOCALSTORAGE)
});
/**
 * Provides access to localstorage.
 */
const LOCALSTORAGE = new InjectionToken('Token for accessing localStorage', {
  factory: () => inject(WINDOW).localStorage
});

/**
 * Provides information if sessionstorage is available.
 */
const SESSIONSTORAGE_SUPPORT = new InjectionToken('Token providing information if sessionStorage is available', {
  factory: () => !!inject(SESSIONSTORAGE)
});
/**
 * Provides access to sessionstorage.
 */
const SESSIONSTORAGE = new InjectionToken('Token for accessing sessionStorage', {
  factory: () => inject(WINDOW).sessionStorage
});

/**
 * Provides information if choosen stoarge is available.
 */
const STORAGE_SUPPORT = new InjectionToken('Token providing information is choosen storage is available', {
  factory: () => {
    const {
      storageType
    } = inject(NGX_LOCAL_STORAGE_CONFIG);
    return storageType === 'sessionStorage' ? inject(SESSIONSTORAGE_SUPPORT) : inject(LOCALSTORAGE_SUPPORT);
  }
});
/**
 * Provides choosen storage.
 */
const STORAGE = new InjectionToken('Token providing choosen storage', {
  factory: () => {
    const {
      storageType
    } = inject(NGX_LOCAL_STORAGE_CONFIG);
    return storageType === 'sessionStorage' ? inject(SESSIONSTORAGE) : inject(LOCALSTORAGE);
  }
});

/**
 * Constructs the storage key based on a prefix - if given - and the key itself
 */
const constructKey = (key, prefix, configuredPrefix, delimiter) => {
  const prefixToUse = prefix || configuredPrefix;
  if (prefixToUse) {
    return `${prefixToUse}${delimiter || ''}${key}`;
  }
  return key;
};
const defaultConfig = NGX_LOCAL_STORAGE_DEFAULT_CONFIG();
/**
 * Provides a service to access the localstorage.
 */
class LocalStorageService extends Observable {
  /**
   * Creates a new instance.
   */
  constructor(_config) {
    super(subscriber => {
      if (!this.storageSupport) {
        subscriber.error(new Error(`Choosen storage '${this.config?.storageType}' is not available`));
      }
      if (this.window) {
        this.subscriptions.add(fromEvent(this.window, 'storage').pipe(filter(event => !!event)).subscribe(event => subscriber.next(event)));
      }
      this.subscriptions.add(this.onError.subscribe(error => subscriber.error(error)));
    });
    this.onError = new EventEmitter();
    this.subscriptions = new Subscription();
    this.serializer = inject(NGX_LOCAL_STORAGE_SERIALIZER);
    this.storageSupport = inject(STORAGE_SUPPORT);
    this.storage = inject(STORAGE, {
      optional: true
    });
    this.window = inject(WINDOW, {
      optional: true
    });
    this.config = {
      ...defaultConfig,
      ..._config
    };
  }
  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
  /**
   * Gets the number of entries in the applications storage.
   */
  count() {
    try {
      return this.storage?.length;
    } catch (error) {
      this.error(error);
      return undefined;
    }
  }
  /**
   * Returns the nth (defined by the index parameter) key in the storage.
   * The order of keys is user-agent defined, so you should not rely on it.
   * @param index An integer representing the number of the key you want to get the name of. This is a zero-based index.
   */
  getKey(index) {
    if (index < 0) {
      this.error(new Error('index has to be 0 or greater'));
    }
    try {
      return this.storage?.key(index);
    } catch (error) {
      this.error(error);
      return undefined;
    }
  }
  /**
   * Adds the value with the given key or updates an existing entry.
   * @param key Key identifying the wanted entry.
   * @param value Value to store.
   * @param options Options for overwriting configuration with the following properties:
   * * `prefix`: (Optional) Prefix to overwrite the configured one.
   * * `serializer`: (Optional) Serializer to overwrite the configured one.
   */
  set(key, value, options) {
    const [prefix, storageSerializer] = [options?.prefix, options?.serializer || this.serializer];
    if (this.config.allowNull || !this.config.allowNull && `${value}` !== 'null' && value !== null && value !== undefined) {
      this.storage?.setItem(constructKey(key, prefix, this.config.prefix, this.config.delimiter), storageSerializer.serialize(value));
    } else {
      this.remove(key, prefix);
    }
  }
  /**
   * Gets the entry specified by the given key if existing - otherwise `null`.
   * @param key Key identifying the wanted entry.
   * @param options Options for overwriting configuration with the following properties:
   * * `prefix`: (Optional) Prefix to overwrite the configured one.
   * * `serializer`: (Optional) Serializer to overwrite the configured one.
   */
  get(key, options) {
    const [prefix, storageSerializer] = [options?.prefix, options?.serializer || this.serializer];
    try {
      const constructedKey = constructKey(key, prefix, this.config.prefix, this.config.delimiter);
      const storageItem = this.storage?.getItem(constructedKey);
      return storageItem ? storageSerializer.deserialize(storageItem) : null;
    } catch (error) {
      this.error(error);
      return null;
    }
  }
  /**
   * Removes the entry specified by the given key.
   * @param key Key identifying the entry to remove.
   * @param prefix Optional prefix to overwrite the configured one.
   */
  remove(key, prefix) {
    try {
      this.storage?.removeItem(constructKey(key, prefix, this.config.prefix, this.config.delimiter));
    } catch (error) {
      this.error(error);
    }
  }
  /**
   * Clears all entries of the storage.
   */
  clear() {
    try {
      this.storage?.clear();
    } catch (error) {
      this.error(error);
    }
  }
  /**
   * Triggers the service to emit the given error.
   * @param error Error to emit through the service.
   */
  error(error) {
    this.onError.emit(error);
  }
  static {
    this.ɵfac = function LocalStorageService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LocalStorageService)(i0.ɵɵinject(NGX_LOCAL_STORAGE_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: LocalStorageService,
      factory: LocalStorageService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalStorageService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NGX_LOCAL_STORAGE_CONFIG]
    }]
  }], null);
})();

/**
 * Injects the matching value accessor depending on whether an `NgControl` is bound or not.
 * @returns directive mathing the binding mode
 */
function injectAccessor() {
  const controlAccessor = inject(ControlValueAccessorDirective);
  if (controlAccessor.ngControl) {
    return controlAccessor;
  }
  return inject(NativeValueAccessorDirective);
}

/**
 * Provide a directive to directly interact with stored values.
 */
class LocalStorageDirective {
  constructor() {
    /**
     * The key to use with localstorage.
     */
    this.key = '';
    /**
     * Flag if the bound elements value should be initialized from storage.
     */
    this.initFromStorage = false;
    /**
     * Event which gets fired when a bound value got stored.
     */
    this.storedValue = new EventEmitter();
    this.destroyRef = inject(DestroyRef);
    this.accessor = injectAccessor();
    this.elementRef = inject(ElementRef);
    this.storageService = inject(LocalStorageService);
  }
  /**
   * Sets up:
   * * the key to use
   * * storage observer
   * * initalization from storage
   * * value change observer
   */
  ngAfterViewInit() {
    this.initKey();
    this.storageService.pipe(takeUntilDestroyed(this.destroyRef),
    // TODO: filter should be more accurate
    filter$1(ev => !!ev.key && ev.key.indexOf(this.key) >= 0)).subscribe(ev => {
      this.accessor.writeValue(ev.newValue, this.falsyTransformer);
    });
    this.checkInitFromStorage();
    this.accessor.valueChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.storageService.set(this.key, value, {
        prefix: this.prefix
      });
      this.storedValue.emit(value);
    });
  }
  /**
   * Initalizes the key from either the given value or the elements id or name property.
   */
  initKey() {
    if (!this.key) {
      if (!this.elementRef.nativeElement.id && !this.elementRef.nativeElement.name) {
        this.storageService.error(new Error('No key or element id or name supplied!'));
      }
      this.key = this.elementRef.nativeElement.id || this.elementRef.nativeElement.name;
    }
  }
  /**
   * Initializes the elements value from storage.
   */
  checkInitFromStorage() {
    if (this.initFromStorage) {
      const storedValue = this.storageService.get(this.key, {
        prefix: this.prefix
      });
      try {
        this.accessor.writeValue(storedValue, this.falsyTransformer);
      } catch (error) {
        this.storageService.error(error);
      }
    }
  }
  static {
    this.ɵfac = function LocalStorageDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || LocalStorageDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LocalStorageDirective,
      selectors: [["", "ngxLocalStorage", ""]],
      inputs: {
        key: [0, "ngxLocalStorage", "key"],
        prefix: "prefix",
        initFromStorage: [2, "initFromStorage", "initFromStorage", booleanAttribute],
        falsyTransformer: "falsyTransformer"
      },
      outputs: {
        storedValue: "storedValue"
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵHostDirectivesFeature([ControlValueAccessorDirective, {
        directive: NativeValueAccessorDirective,
        inputs: ["forEvent", "forEvent", "valuePath", "valuePath", "storageDebounce", "storageDebounce"]
      }])]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LocalStorageDirective, [{
    type: Directive,
    args: [{
      selector: '[ngxLocalStorage]',
      standalone: true,
      hostDirectives: [ControlValueAccessorDirective, {
        directive: NativeValueAccessorDirective,
        inputs: ['forEvent', 'valuePath', 'storageDebounce']
      }]
    }]
  }], null, {
    key: [{
      type: Input,
      args: ['ngxLocalStorage']
    }],
    prefix: [{
      type: Input
    }],
    initFromStorage: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    falsyTransformer: [{
      type: Input
    }],
    storedValue: [{
      type: Output
    }]
  });
})();
var FeatureKind;
(function (FeatureKind) {
  FeatureKind[FeatureKind["Serializer"] = 0] = "Serializer";
})(FeatureKind || (FeatureKind = {}));
/**
 * Provides the basic configuration and optional additional features.
 * @param configuration configuration used by the service and directive
 * @param features optional features; e.g. a custom serializer
 * @returns providers used by `ngx-localstorage`
 */
const provideNgxLocalstorage = (configuration, ...features) => {
  if (features?.filter(feature => feature.kind === FeatureKind.Serializer)?.length > 1) {
    throw new Error('Only one serializer feature is allowed!');
  }
  return makeEnvironmentProviders([{
    provide: NGX_LOCAL_STORAGE_CONFIG,
    useValue: configuration
  }, features?.map(feature => feature.providers)]);
};
/**
 * Provides a custom serializer.
 */
const withSerializer = serializer => ({
  kind: FeatureKind.Serializer,
  providers: [{
    provide: NGX_LOCAL_STORAGE_SERIALIZER,
    useClass: serializer
  }]
});
class Version {
  constructor(fullVersion) {
    this.fullVersion = fullVersion;
    const [major, minor, patch] = fullVersion.split('.');
    this.major = major;
    this.minor = minor;
    this.patch = patch;
  }
}
const VERSION = new Version('6.0.0');

/*
 * Public API Surface of ngx-localstorage
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ControlValueAccessorDirective, LocalStorageDirective, LocalStorageService, NGX_LOCAL_STORAGE_CONFIG, NGX_LOCAL_STORAGE_SERIALIZER, NativeValueAccessorDirective, STORAGE_SUPPORT, VERSION, provideNgxLocalstorage, withSerializer };
