import { AsyncPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, map } from 'rxjs';
import { CustodianFormComponent } from './custodian-form.component';

@Component({
  selector: 'of-edit-custodian',
  standalone: true,
  imports: [AsyncPipe, CustodianFormComponent],
  template: ` <of-custodian-form [custodianId]="custodianId$ | async"></of-custodian-form>`
})
export class EditCustodianComponent implements OnInit {
  public custodianId$: Observable<number> | null = null;

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.custodianId$ = this.route.paramMap.pipe(map((params) => Number.parseInt(params.get('id') || '-1', 10)));
  }
}
