import { Component, Input } from '@angular/core';
import { OrdersService } from '../orders.service';
import { CodeNameItem, ORDER_STATUS_VALUES } from '../../shared/code-name-value.model';

@Component({
    selector: 'of-order-processing-error-card',
    imports: [],
    template: `
    @if (processingError) {
      <div class="card text-bg-danger mb-3">
        <div class="card-header d-flex justify-content-between align-items-center">
          <h5 class="card-title m-0">PROCESSING ERROR</h5>
          @if (orderStatus?.code === faUploadFailed) {
            <button class="btn btn-sm btn-light" (click)="retryOrderUpload()">RETRY UPLOAD</button>
          }
        </div>
        <div class="card-body">
          <p class="card-text">{{ processingError }}</p>
        </div>
      </div>
    }
  `
})
export class OrderProcessingErrorCardComponent {
  public faUploadFailed = ORDER_STATUS_VALUES['faUploadFailed'].code;

  @Input()
  public processingError: string | null = null;

  @Input()
  public orderId: number | null = null;

  @Input()
  public orderStatus: CodeNameItem | null = null;

  constructor(private ordersService: OrdersService) {}

  public retryOrderUpload(): void {
    if (this.orderId) {
      this.ordersService.retryUploadOfOrder$(this.orderId).subscribe();
    }
  }
}
