<form [formGroup]="form">
  <of-security-selector (ready)="onSecuritySelectorReady()"></of-security-selector>

  <div class="card">
    <div class="card-header">
      <div class="card-title m-0">TRANSACTION</div>
    </div>
    <div class="card-body">
      <div class="d-flex justify-content-start align-items-end gap-3">
        <!-- Purchase or sell -->
        <div style="width: 210px">
          <ejs-dropdownlist
            placeholder="Purchase or sell *"
            [dataSource]="transactionTypeValues"
            [fields]="{ text: 'name', value: 'code' }"
            floatLabelType="Auto"
            [allowFiltering]="true"
            formControlName="transactionType"
          ></ejs-dropdownlist>
        </div>

        <!-- Quantity Type -->
        <div style="width: 320px" class="d-flex gap-3 mb-2 align-items-center">
          <small>Order quantity type:</small>
          @for (quantityType of quantityTypeValues; track $index) {
            <ejs-radiobutton
              [label]="quantityType.name"
              [value]="quantityType.code"
              name="radQuantityType"
              formControlName="quantityType"
            ></ejs-radiobutton>
          }
        </div>

        <!-- Quantity Value -->
        <div class="d-flex gap-2 align-items-end">
          <div style="width: 300px">
            <ejs-numerictextbox
              formControlName="quantity"
              format="#,###.#####"
              [placeholder]="lblQuantityNominalValue"
              floatLabelType="Auto"
              [showSpinButton]="false"
            ></ejs-numerictextbox>
          </div>
          <small>{{ lblQuantityType }}</small>
        </div>
      </div>

      <div class="d-flex justify-content-start align-items-end gap-3">
        <!-- Instruction date -->
        <div style="width: 210px">
          <ejs-datepicker
            placeholder="Instruction date"
            formControlName="instructionDate"
            format="dd/MM/yyyy"
          ></ejs-datepicker>
        </div>

        <!-- Transaction Date -->
        <div style="width: 210px">
          <ejs-datepicker
            placeholder="Transaction date *"
            formControlName="transactionDate"
            format="dd/MM/yyyy"
          ></ejs-datepicker>
        </div>

        <!-- Execution instruction -->
        <div style="width: 210px">
          <ejs-dropdownlist
            placeholder="Execution instruction *"
            [dataSource]="executionInstructionValues"
            [fields]="{ text: 'name', value: 'code' }"
            floatLabelType="Auto"
            [allowFiltering]="true"
            formControlName="executionInstruction"
          ></ejs-dropdownlist>
        </div>

        <!-- Custodian -->
        <div style="width: 210px">
          <ejs-dropdownlist
            placeholder="Custodian *"
            [dataSource]="custodians$ | async"
            [fields]="{ text: 'code', value: 'code' }"
            floatLabelType="Auto"
            [allowFiltering]="true"
            formControlName="custodian"
            (change)="onCustodianSelected($event)"
          ></ejs-dropdownlist>
        </div>
      </div>

      <div class="d-flex justify-content-start align-items-center gap-3">
        <!-- Execution method -->
        <div style="width: 210px">
          <ejs-dropdownlist
            placeholder="Execution method *"
            [dataSource]="executionMethodValues"
            [fields]="{ text: 'name', value: 'code' }"
            floatLabelType="Auto"
            [allowFiltering]="true"
            formControlName="executionMethod"
          ></ejs-dropdownlist>
        </div>

        <!-- Order type -->
        <div style="width: 210px">
          <ejs-dropdownlist
            placeholder="Order type *"
            [dataSource]="orderTypeValues"
            [fields]="{ text: 'name', value: 'code' }"
            floatLabelType="Auto"
            [allowFiltering]="true"
            formControlName="orderType"
          ></ejs-dropdownlist>
        </div>

        <!-- Limit price -->
        <div style="width: 210px">
          <ejs-numerictextbox
            formControlName="limitPrice"
            [format]="ntbLimitPriceFormat"
            placeholder="Limit price"
            floatLabelType="Auto"
            [showSpinButton]="false"
          ></ejs-numerictextbox>
        </div>

        <!-- TIF -->
        <div style="width: 210px">
          <ejs-dropdownlist
            placeholder="TIF *"
            [dataSource]="tifValues"
            [fields]="{ text: 'name', value: 'code' }"
            floatLabelType="Auto"
            [allowFiltering]="true"
            formControlName="tif"
          ></ejs-dropdownlist>
        </div>
      </div>
    </div>
  </div>

  <of-allocations-form
    [quantityTypeLabel]="form.get('quantityType')?.value"
    [totalQuantityOrNominal]="form.get('quantity')?.value"
    [securityCurrency]="securitySelectorForm.get('currency')?.value"
    [securityPrice]="securitySelectorForm.get('lastClose')?.value"
    [excludedPortfolios]="excludePortfolios"
    [portfoliosHoldingSecurity]="securitySelectorForm.get('portfoliosHoldingSecurity')?.value"
    [transactionType]="form.get('transactionType')?.value"
    (totalQuantityOrNominalChanged)="onTotalQuantityOrNominalUpdateRequested($event)"
  ></of-allocations-form>

  <div class="card mb-3">
    <div class="card-header">
      <div class="card-title m-0">ADDITIONAL INFORMATION (EXTERNAL)</div>
    </div>
    <div class="card-body">
      <ejs-textbox
        placeholder="Additional information (external)"
        formControlName="additionalInformation"
        [multiline]="true"
        floatLabelType="Auto"
        style="height: 100px"
      ></ejs-textbox>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-header">
      <div class="card-title m-0">ADDITIONAL INFORMATION (INTERNAL)</div>
    </div>
    <div class="card-body">
      <ejs-textbox
        placeholder="Additional information (internal)"
        formControlName="additionalInternalInformation"
        [multiline]="true"
        floatLabelType="Auto"
        style="height: 100px"
      ></ejs-textbox>
    </div>
  </div>

  <of-attachment-uploader [form]="form"></of-attachment-uploader>
</form>

@if (environment === 'development') {
  <div class="card my-3 text-warning-emphasis bg-warning-subtle">
    <div class="card-body font-monospace">
      <small>
        {{ form.getRawValue() | json }}
      </small>
    </div>
  </div>
}

<div class="d-flex gap-3">
  <div class="btn-group">
    <a routerLink="/orders" class="btn btn-link">BACK</a>
    <button type="button" class="btn btn-light" (click)="reset()" [disabled]="submitting">RESET</button>
    <button type="button" class="btn btn-primary" [disabled]="form.invalid || submitting" (click)="submit()">
      SUBMIT
    </button>
  </div>
  @if (submitting) {
    <mat-spinner diameter="20"></mat-spinner>
  }
</div>
