import { AsyncPipe, DatePipe, DecimalPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { RouterModule } from '@angular/router';
import {
  Column,
  ExcelExportService,
  FilterService,
  GridComponent,
  GridModule,
  PageService,
  SearchService,
  ToolbarService
} from '@syncfusion/ej2-angular-grids';
import { Observable, tap } from 'rxjs';
import {
  FlattenedPartialExecutionViewItem,
  PartialExecutionViewItemWithPartialExecutions
} from './partial-execution-view-item.model';
import { PartialExecutionsService } from './partial-executions.service';
import { canViewOrders } from '../orders/can-view-orders.component';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import { OrderStatusBadgeComponent } from '../shared/order-status-badge.component';
import { ClickEventArgs } from '@syncfusion/ej2-angular-navigations';
import { flatten } from 'lodash-es';
import moment from 'moment';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';

@Component({
    selector: 'of-partial-executions',
    imports: [
        AsyncPipe,
        DatePipe,
        DateRangePickerModule,
        DecimalPipe,
        GridModule,
        RouterModule,
        OrderStatusBadgeComponent
    ],
    providers: [ExcelExportService, FilterService, PageService, SearchService, ToolbarService],
    templateUrl: './partial-executions.component.html'
})
export class PartialExecutionsComponent implements OnInit {
  public partialExecutions$: Observable<PartialExecutionViewItemWithPartialExecutions[]> | null = null;
  public partialExecutionsForExport: FlattenedPartialExecutionViewItem[] | undefined = undefined;
  public canViewOrderDetails: boolean;
  public today = new Date();
  public searching = false;
  public selectedDateRange: Date[] = [moment().subtract(2, 'days').toDate(), new Date()];

  private columnsForExport: Column[] = [
    { field: 'id', headerText: 'Order ID' } as Column,
    { field: 'status.name', headerText: 'Status', width: '134px' } as Column,
    { field: 'transactionType', headerText: 'Type', width: '72px' } as Column,
    { field: 'securityCurrency', headerText: 'Currency', width: '68px' } as Column,
    {
      field: 'quantity',
      headerText: 'Original Qty / Nominal',
      textAlign: 'Right',
      format: 'N4',
      width: '166px'
    } as Column,
    { field: 'quantityType', headerText: 'Type', width: '70px' } as Column,
    {
      field: 'executedQuantity',
      headerText: 'Executed Qty / Nominal',
      textAlign: 'Right',
      format: 'N4',
      width: '174px'
    } as Column,
    { field: 'executedQuantityType', headerText: 'Type', width: '70px' } as Column,
    { field: 'securityName', headerText: 'Security', width: '220px' } as Column,
    { field: 'securityIsin', headerText: 'Security ISIN', width: '140px' } as Column,
    { field: 'securityType', headerText: 'Security Type', width: '150px' } as Column,
    { field: 'broker', headerText: 'Broker', width: '100px' } as Column,
    { field: 'custodian', headerText: 'Custodian', width: '76px' } as Column,
    { field: 'portfolioShortName', headerText: 'Portfolio', width: '120px' } as Column,
    { field: 'portfolioExternalName', headerText: 'Portfolio Ext ID', width: '150px' } as Column,
    { field: 'executionInstruction', headerText: 'Instruction', width: '150px' } as Column,
    {
      field: 'created',
      headerText: 'Partial Exec Timestamp',
      width: '168px'
    } as Column,
    { field: 'transactionRefNumber', headerText: 'Partial Exec Trn Ref', width: '141px' } as Column,
    {
      field: 'partialExecutionQuantity',
      headerText: 'Partial Exec Qty / Nominal',
      textAlign: 'Right',
      format: 'N4',
      width: '192px'
    } as Column,
    { field: 'partialExecutionQuantityType', headerText: 'Partial Exec Type', width: '125px' } as Column,
    { field: 'comments', headerText: 'Comments', width: '300px' } as Column
  ];

  @ViewChild('grdExecutions')
  public grid?: GridComponent;

  constructor(
    private partialExecutionsService: PartialExecutionsService,
    authService: OrderFlowAuthService
  ) {
    this.canViewOrderDetails = canViewOrders(authService);
  }

  public ngOnInit(): void {
    this.setPartialExecutions();
  }

  public onToolbarClick(args: ClickEventArgs): void {
    if (args.item.id === 'grdExecutions_excelexport') {
      (this.grid as GridComponent).excelExport({
        dataSource: this.partialExecutionsForExport,
        columns: this.columnsForExport
      });
    } else if (args.item.id === 'grdExecutions_csvexport') {
      (this.grid as GridComponent).csvExport({
        dataSource: this.partialExecutionsForExport,
        columns: this.columnsForExport
      });
    }
  }

  public dateRangeSelected(): void {
    this.searching = true;

    this.setPartialExecutions();

    setTimeout(() => {
      this.searching = false;
    }, 500);
  }

  private setPartialExecutions(): void {
    this.partialExecutions$ = this.partialExecutionsService
      .listOrdersWithPartialExecutions$(this.selectedDateRange[0], this.selectedDateRange[1])
      .pipe(
        tap(
          (partialExecutions) =>
            (this.partialExecutionsForExport = partialExecutions
              ? flatten(
                  partialExecutions.map((pe) => [
                    pe as FlattenedPartialExecutionViewItem,
                    ...pe.partialExecutions.map((p) => ({
                      ...p,
                      broker: null,
                      created: moment(p.created).format('DD/MM/YYYY HH:mm'),
                      custodian: null,
                      executedQuantity: null,
                      executedQuantityType: null,
                      executionInstructions: null,
                      faExternalId: null,
                      id: null,
                      limitPrice: null,
                      orderType: null,
                      portfolioExternalName: null,
                      portfolioShortName: null,
                      quantity: null,
                      quantityType: null,
                      securityCurrency: null,
                      securityIsin: null,
                      securityName: null,
                      securityType: null,
                      status: null,
                      transactionDate: null,
                      transactionType: null,
                      partialExecutionQuantity: p.quantity,
                      partialExecutionQuantityType: p.quantityType || pe.quantityType
                    }))
                  ])
                )
              : [])
        )
      );
  }
}
