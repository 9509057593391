import { Component, Input, OnInit } from '@angular/core';
import { DetailedOrder } from '../orders/order.model';
import { CanApproveOrderComponent } from './can-approve-order.component';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { APPROVAL_STATUS_VALUES, CodeNameItem, PRETRADE_CHECK_STATUS_VALUES } from '../shared/code-name-value.model';
import { OrdersService } from '../orders/orders.service';
import { Router } from '@angular/router';

@Component({
    selector: 'of-approve-order-card',
    imports: [CanApproveOrderComponent, ReactiveFormsModule, TextBoxModule],
    template: `
    @if (order) {
      <of-can-approve-order [orderId]="order.id" [orderStatus]="order.status">
        <div class="card mb-3">
          <div class="card-header px-2">
            <h5 class="card-title m-0">ORDER APPROVAL</h5>
          </div>
          <div class="card-body p-0" [formGroup]="form">
            <ejs-textbox
              placeholder="Comments (required when pre-trade checks breached)"
              [multiline]="true"
              style="height: 100px"
              formControlName="comments"
            ></ejs-textbox>
            <div class="card-footer px-2">
              <div class="btn-group btn-group-sm">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  (click)="approve()"
                  [disabled]="!form.valid || submitting"
                >
                  APPROVE
                </button>
                <button
                  type="button"
                  class="btn btn-outline-danger"
                  (click)="reject()"
                  [disabled]="!form.valid || submitting"
                >
                  REJECT
                </button>
              </div>
            </div>
          </div>
        </div>
      </of-can-approve-order>
    }
  `
})
export class ApproveOrderCardComponent implements OnInit {
  public form: FormGroup = new FormGroup({});
  public approvalStatus: CodeNameItem | null = null;
  public submitting = false;

  @Input()
  public order: DetailedOrder | null = null;

  constructor(
    private fb: FormBuilder,
    private ordersService: OrdersService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.form = this.fb.group({
      comments: this.fb.control<string | null>(null, {
        validators: [
          RxwebValidators.required({
            conditionalExpression: () =>
              this.order?.preTradeCheck?.status?.code === PRETRADE_CHECK_STATUS_VALUES['breached'].code
          })
        ]
      })
    });
  }

  public approve(): void {
    this.submit(APPROVAL_STATUS_VALUES['approved']);
  }

  public reject(): void {
    this.submit(APPROVAL_STATUS_VALUES['rejected']);
  }

  private submit(approvalStatus: CodeNameItem): void {
    if (this.form && this.order) {
      this.submitting = true;
      this.form.disable();
      this.ordersService
        .updateOrderApprovalStatus$(this.order.id, {
          ...this.form.value,
          approvalStatus
        })
        .subscribe(() => {
          this.submitting = false;
          this.router.navigate(['/orders']);
        });
    }
  }
}
