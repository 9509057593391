import { Injectable } from '@angular/core';
import { PortfolioWithAccounts } from './portfolio-with-accounts.model';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Observable, catchError, map, switchMap, tap, throwError } from 'rxjs';
import { showErrorDialog } from '../shared/utils';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { LocalStorageService } from 'ngx-localstorage';
import moment from 'moment';
import { STORE_PORTFOLIOS } from '../shared/constants';

@Injectable({
  providedIn: 'root'
})
export class PortfoliosService {
  private apiEndpoint = `${environment.apiEndpoint}/api/portfolios`;
  public portfoliosSet: string | null | undefined = null;
  public portfoliosSet$ = new BehaviorSubject<boolean>(false);

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
    private dbService: NgxIndexedDBService,
    private storage: LocalStorageService
  ) {}

  public listPortfolios$(): Observable<PortfolioWithAccounts[]> {
    return this.dbService.getAll<PortfolioWithAccounts>(STORE_PORTFOLIOS).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public refreshPortfoliosCache$(): Observable<PortfolioWithAccounts[]> {
    return this.loadPortfoliosIntoCache$();
  }

  public checkAndLoadPortfoliosIntoCache(): void {
    const portfoliosSet = this.storage.get<string>('portfolios_set');

    if (!portfoliosSet || moment().diff(moment(portfoliosSet), 'days') > 1) {
      this.loadPortfoliosIntoCache$().subscribe();
    } else {
      this.portfoliosSet = portfoliosSet;
      this.logger.log('Successfully loaded portfolios from cache');
      this.portfoliosSet$.next(true);
    }
  }

  private loadPortfoliosIntoCache$(): Observable<PortfolioWithAccounts[]> {
    return this.http.get<PortfolioWithAccounts[]>(this.apiEndpoint).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      }),
      switchMap((portfolios) => {
        return this.dbService.clear(STORE_PORTFOLIOS).pipe(
          tap(() => this.logger.log('Successfully cleared portfolios DB')),
          switchMap(() => {
            return this.dbService.bulkAdd(STORE_PORTFOLIOS, portfolios).pipe(
              tap(() => {
                this.logger.log(`Successfully loaded ${portfolios.length} portfolios to DB`);
                const portfoliosSet = moment().toISOString();
                this.storage.set('portfolios_set', portfoliosSet);
                this.portfoliosSet = portfoliosSet;
                this.portfoliosSet$.next(true);
              }),
              map(() => portfolios)
            );
          })
        );
      })
    );
  }
}
