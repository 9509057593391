@if (order$ | async; as order) {
  <div class="row mb-3 g-3">
    <div class="col-xl-5 col-lg-12 d-flex flex-column gap-3">
      <of-order-details-card [order]="order"></of-order-details-card>

      <of-order-additional-info-card
        [additionalInformation]="order.additionalInternalInformation"
        suffix=" (INTERNAL)"
      ></of-order-additional-info-card>
      <of-order-additional-info-card
        [additionalInformation]="order.additionalInformation"
        suffix=" (EXTERNAL)"
      ></of-order-additional-info-card>
    </div>

    <div class="col-xl-7 col-lg-12">
      <of-order-processing-error-card
        [processingError]="order.processingError"
        [orderId]="order.id"
        [orderStatus]="order.status"
      ></of-order-processing-error-card>

      <of-partial-executions-card
        [partialExecutions]="order.partialExecutions"
        [currency]="order.account.currency"
        [orderId]="order.id"
      ></of-partial-executions-card>

      <of-ack-order
        [acknowledged]="order.acknowledged"
        [acknowledger]="order.acknowledger"
        [comments]="order.acknowledgedComments"
        [orderStatus]="order.status"
        [orderId]="order.id"
      ></of-ack-order>

      <of-check-order
        [checked]="order.checked"
        [checker]="order.checker"
        [comments]="order.checkerComments"
        [orderStatus]="order.status"
        [orderId]="order.id"
      ></of-check-order>

      <of-approve-order-card [order]="order"></of-approve-order-card>

      <of-order-pretrade-checks-card
        [preTradeCheckLimits]="order.preTradeCheck?.limits"
        [preTradeCheckStatus]="order.preTradeCheck?.status"
        [orderId]="order.id"
        [orderStatus]="order.status"
      ></of-order-pretrade-checks-card>
    </div>
  </div>

  <of-order-approvals-card [approvals]="order.approvals"></of-order-approvals-card>

  <of-order-audit-trail-card [auditTrailPoints]="order.auditTrailPoints"></of-order-audit-trail-card>
} @else {
  <mat-spinner></mat-spinner>
}
