import { Component, ViewChild } from '@angular/core';
import { CanWriteOrdersComponent } from '../orders/can-write-orders.component';
import { PortfoliosService } from '../portfolios/portfolios.service';
import { SecuritiesService } from '../securities/securities.service';
import { ToastComponent } from './toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { formatNumber } from '@angular/common';

@Component({
    selector: 'of-settings-nav-menu',
    imports: [CanWriteOrdersComponent, MatProgressSpinnerModule, ToastComponent],
    template: `
    <of-can-write-orders>
      <li class="nav-item dropdown">
        <a class="nav-link" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="bi bi-gear"></i>
        </a>
        <ul class="dropdown-menu">
          <li>
            <button class="dropdown-item" (click)="clearPortfoliosCache()" [disabled]="refreshingData">
              REFRESH PORTFOLIOS
            </button>
          </li>
          <li>
            <button class="dropdown-item" (click)="clearSecuritiesCache()" [disabled]="refreshingData">
              REFRESH SECURITIES
            </button>
          </li>
        </ul>
      </li>
    </of-can-write-orders>

    <of-toast #toastDataRefreshingProgress [showCloseButton]="false">
      <div id="title" class="d-flex justify-content-between">
        <div>REFRESHING {{ header }}</div>
      </div>
      <div id="content" class="d-flex gap-2">
        @if (refreshingData) {
          <mat-spinner [diameter]="20"></mat-spinner>
        }
        <div>
          <div>{{ toastBody }}</div>
        </div>
      </div>
    </of-toast>
  `
})
export class SettingsNavMenuComponent {
  public refreshingData = false;
  public header = 'DATA';
  public toastBody = 'Processing...';

  @ViewChild('toastDataRefreshingProgress')
  public toastDataRefreshingProgress: ToastComponent | null = null;

  constructor(
    private portfoliosService: PortfoliosService,
    private securitiesService: SecuritiesService
  ) {}

  public clearPortfoliosCache(): void {
    this.refreshingData = true;
    this.header = 'PORTFOLIOS';
    this.toastDataRefreshingProgress?.show();
    this.portfoliosService.refreshPortfoliosCache$().subscribe({
      next: (portfolios) => {
        this.refreshingData = false;
        this.toastBody = `Reloaded ${formatNumber(portfolios.length, 'en-US')} portfolios`;
        setTimeout(() => {
          this.toastDataRefreshingProgress?.hide();
        }, 1500);
      },
      error: () => {
        this.refreshingData = false;
        this.toastDataRefreshingProgress?.hide();
      }
    });
  }

  public clearSecuritiesCache(): void {
    this.refreshingData = true;
    this.header = 'SECURITIES';
    this.toastDataRefreshingProgress?.show();
    this.securitiesService.refreshSecuritiesCache$().subscribe({
      next: (securities) => {
        this.refreshingData = false;
        this.toastBody = `Reloaded ${formatNumber(securities.length, 'en-US')} securities`;
        setTimeout(() => {
          this.toastDataRefreshingProgress?.hide();
        }, 1500);
      },
      error: () => {
        this.refreshingData = false;
        this.toastDataRefreshingProgress?.hide();
      }
    });
  }
}
