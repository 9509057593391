import { Component, Input } from '@angular/core';
import { OrderFlowAuthService } from '../../auth/order-flow-auth.service';
import { ROLE_FX_ORDER_WRITER, ROLE_FX_ORDER_WRITER_RESTRICTED, ROLE_ORDER_WRITER } from '../../shared/constants';

@Component({
  selector: 'of-can-create-order',
  imports: [],
  template: `
    @if (canCreate) {
      <ng-content></ng-content>
    }
  `
})
export class CanCreateOrderComponent {
  public canCreate: boolean | null = null;

  @Input()
  public isCommon: boolean | null = false;

  constructor(private authService: OrderFlowAuthService) {}

  ngOnInit(): void {
    this.canCreate = this.isCommon
      ? this.authService.hasOneOfRoles([ROLE_ORDER_WRITER, ROLE_FX_ORDER_WRITER, ROLE_FX_ORDER_WRITER_RESTRICTED])
      : this.authService.hasRole(ROLE_ORDER_WRITER);
  }
}
