import { DatePipe, DecimalPipe, UpperCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DetailRowService, GridModule, IEditCell, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { QUANTITY_TYPE_VALUES } from '../../shared/code-name-value.model';
import { OrdersService } from '../orders.service';
import { concat, forkJoin, map, tap } from 'rxjs';
import { ToastComponent } from '../../shared/toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NGXLogger } from 'ngx-logger';
import { BulkApproveExecutionsService } from './bulk-approve-executions.service';
import { PartialExecution } from '../../record-partial-execution/partial-execution.model';
import { MatDialog } from '@angular/material/dialog';
import { BulkApprovePartialExecutionDialogComponent } from './bulk-approve-partial-execution-dialog.component';
import { getPartialExecutionStatusShortName, getStatusClass } from '../../shared/utils';
import { AvatarCardComponent } from '../../shared/avatar-card.component';
import { OrderWithDetailedExecutionInfo } from './order-with-execution-info.model';

@Component({
  selector: 'of-bulk-approve-executions',
  providers: [ToolbarService, DetailRowService],
  imports: [
    CheckBoxModule,
    DecimalPipe,
    GridModule,
    DatePipe,
    UpperCasePipe,
    MatProgressSpinnerModule,
    RouterModule,
    ToastComponent,
    AvatarCardComponent
  ],
  template: `
    <div class="card mb-3">
      <div class="card-header d-flex">
        <h5 class="card-title m-0">BULK APPROVE EXECUTIONS</h5>
        <button (click)="openApproveDialog(true)" title="Approve" type="button" class="btn btn-sm ms-2">
          <i class="bi bi-check-circle text-success"></i>
        </button>

        <button class="btn btn-sm ms-2" (click)="openApproveDialog(false)" title="Reject">
          <i class="bi bi-x-circle text-danger"></i>
        </button>
      </div>
      @if (this.loading) {
        <mat-spinner diameter="20"></mat-spinner>
      } @else {
        <div class="card-body p-0">
          <ejs-grid
            [dataSource]="this.ordersToApprove"
            [selectionSettings]="{ checkboxOnly: true, persistSelection: true }"
            [allowTextWrap]="true"
            [editSettings]="{ allowEditing: false }"
            (actionComplete)="onActionComplete($event)"
          >
            <e-columns>
              <e-column field="id" headerText="#" textAlign="Right" width="40" format="N0" [isPrimaryKey]="true">
                <ng-template #template let-data>
                  <a target="_blank" [routerLink]="[data.id]">{{ data.id }}</a>
                </ng-template>
              </e-column>
              <e-column field="transactionType" headerText="Type" width="80" [allowEditing]="false"></e-column>
              <e-column field="security.currency" headerText="Ccy" width="44" [allowEditing]="false"></e-column>
              <e-column
                field="quantity"
                headerText="Qty / Nominal"
                width="140"
                textAlign="Right"
                [allowEditing]="false"
              >
                <ng-template #template let-data>
                  {{ data.quantity | number: '1.0-10' }}
                  <small [title]="data.quantityType.name">({{ data.quantityType.name[0] }})</small>
                </ng-template>
              </e-column>
              <e-column field="security.name" headerText="Security" width="180" [allowEditing]="false">
                <ng-template #template let-data>
                  {{ data.security.name }} <small>({{ data.security.type }})</small>
                </ng-template>
              </e-column>
              <e-column field="broker.code" headerText="Broker" width="80" [allowEditing]="false"></e-column>
              <e-column field="custodian.code" headerText="Custodian" width="80" [allowEditing]="false"></e-column>
              <e-column
                field="portfolioWithAccount"
                headerText="Portfolio / Account"
                width="120"
                [allowEditing]="false"
              ></e-column>
              <e-column field="useOrderInfo" headerText="Use Order Settings" width="80" [displayAsCheckBox]="true">
              </e-column>
              <e-column
                field="executedQuantity"
                headerText="Exec. Qty / Nominal"
                width="120"
                textAlign="Right"
              ></e-column>
              <e-column field="executedQuantityType" headerText="Exec. Type" width="100"></e-column>
              <e-column
                field="transactionRef"
                headerText="Trn. Ref"
                width="100"
                [edit]="{ params: { showClearButton: true } }"
              ></e-column>
              <e-column
                field="comments"
                headerText="Comments"
                width="200"
                [edit]="{ params: { showClearButton: true } }"
              ></e-column>
            </e-columns>
            <ng-template #detailTemplate let-data>
              <table class="table m-0">
                <thead>
                  <tr>
                    <th>Recorded</th>
                    <th>Transaction Ref.</th>
                    <th>Status</th>
                    <th class="text-end">Quantity / Nominal</th>
                    <th class="text-center">Recorded by</th>
                    <th>Comments</th>
                  </tr>
                </thead>
                <tbody>
                  @for (partialExecution of data.partialExecutions; track $index) {
                    <tr>
                      <td class="align-middle">
                        {{ partialExecution.created | date: 'dd/MM/yyyy HH:mm' }}
                        @if (partialExecution.isFinalExecution) {
                          <span class="badge text-success-emphasis bg-success-subtle ms-2">Final</span>
                        }
                      </td>
                      <td class="align-middle">{{ partialExecution.transactionRefNumber }}</td>
                      <td class="align-middle">
                        <span [class]="getStatusClass(partialExecution.status)">
                          {{ getPartialExecutionStatusShortName(partialExecution.status) | uppercase }}
                        </span>
                      </td>
                      <td class="align-middle text-end">
                        {{ partialExecution.quantity | number: '1.0-10' }}
                        @if (partialExecution.quantityType) {
                          <small [title]="partialExecution.quantityType.name">
                            ({{ partialExecution.quantityType.name[0] }})
                          </small>
                        }
                      </td>
                      <td class="d-flex justify-content-center">
                        <of-avatar-card [user]="partialExecution.createdBy"></of-avatar-card>
                      </td>
                      <td class="align-middle">
                        @if (partialExecution.comments) {
                          <div [innerHTML]="partialExecution.comments"></div>
                        }
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </ng-template>
          </ejs-grid>
        </div>
      }
    </div>

    <of-toast #toastOrdersExecutionProgress [showCloseButton]="false">
      <div id="title" class="d-flex justify-content-between">
        <div>EXECUTING ORDERS</div>
        <div>{{ ordersExecutedCount }}/{{ ordersExecutedTotal }}</div>
      </div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          {{ ordersExecutedMessage }}
        </div>
      </div>
    </of-toast>
  `
})
export class BulkApproveExecutionsComponent implements OnInit {
  public ordersExecutedCount: number | null = null;
  public ordersExecutedTotal: number | null = null;
  public ordersExecutedMessage: string | null = null;
  public ordersToApprove: OrderWithDetailedExecutionInfo[] = [];
  public loading: boolean = false;

  @ViewChild('toastOrdersExecutionProgress')
  public toastOrdersExecutionProgress: ToastComponent | null = null;

  constructor(
    public bulkApproveExecutionsService: BulkApproveExecutionsService,
    private router: Router,
    private ordersService: OrdersService,
    private approveRejectPartialExecutionDialog: MatDialog
  ) {}

  public ngOnInit(): void {
    if (!this.bulkApproveExecutionsService.ordersToExecute) {
      this.router.navigate(['/orders']);
    } else {
      this.loading = true;
      var orders = this.bulkApproveExecutionsService.ordersToExecute;
      const orderRequests = orders.map((order) =>
        this.ordersService.getOrder$(order.id).pipe(
          map((detailedOrder) => ({
            ...order,
            ...detailedOrder
          }))
        )
      );

      forkJoin(orderRequests)
        .pipe(
          map((updatedOrders) =>
            updatedOrders
              .map((order) => ({
                ...order,
                partialExecutions: order.partialExecutions?.filter((pe) => pe.status !== 'Approved') || []
              }))
              .filter((order) => order.partialExecutions.length > 0)
          )
        )
        .subscribe((filteredOrders) => {
          this.ordersToApprove = filteredOrders;
          if (filteredOrders.length < 1) {
            this.router.navigate(['/orders']);
          }
          this.loading = false;
        });
    }
  }

  public onActionComplete(args: any): void {
    if (args.name === 'actionComplete' && args.requestType === 'batchsave') {
    }
  }

  public getPartialExecutionStatusShortName(status: string): string | null {
    return getPartialExecutionStatusShortName(status);
  }

  public getStatusClass(status: string): string {
    return getStatusClass(status);
  }

  public openApproveDialog(isApproval: boolean): void {
    this.approveRejectPartialExecutionDialog
      .open(BulkApprovePartialExecutionDialogComponent, {
        minWidth: 400,
        data: {
          ordersWithExecutions: this.ordersToApprove,
          isApproval: isApproval
        }
      })
      .afterClosed()
      .subscribe(() => this.router.navigate(['/orders']));
  }
}
