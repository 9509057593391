import { Component } from '@angular/core';
import { CannotCheckOrderService } from './cannot-check-order.service';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'of-cannot-check-order',
    imports: [MatCardModule, RouterLink],
    template: `
    <mat-card appearance="outlined">
      <mat-card-title>Forbidden</mat-card-title>
      <mat-card-content>
        @if (message) {
          <p>{{ message }}</p>
        }
      </mat-card-content>
      <mat-card-actions>
        <a mat-button [routerLink]="['/']">BACK</a>
      </mat-card-actions>
    </mat-card>
  `
})
export class CannotCheckOrderComponent {
  public message: string;

  constructor(cannotCheckService: CannotCheckOrderService) {
    this.message = cannotCheckService.message;
  }
}
