import { UploadPath } from '../app/shared/attachment-uploader.component';
import { FEEDBACK_FORM, TENANT_PROD, USER_MANUAL } from '../app/shared/constants';

const apiEndpoint = 'https://cigpofapiuat.azurewebsites.net';
const graphEndpoint = 'https://graph.microsoft.com/v1.0';

const tempFileUploadPath: UploadPath = {
  saveUrl: `${apiEndpoint}/api/orders/attachment/upload`,
  removeUrl: `${apiEndpoint}/api/orders/attachment/delete`
};

export const environment = {
  env: 'uat',
  consentScopes: ['api://eb1dfced-2edb-46bc-9125-2d421e6a244f/user_impersonation'],
  apiEndpoint,
  graphEndpoint,
  clientId: 'd3739b54-d2d3-45f7-970d-c1f38a48f07d',
  tenant: TENANT_PROD,
  userManual: USER_MANUAL,
  appInsights: {
    instrumentationKey: '2d60f0fa-4f58-48ea-a49e-eaaa2f0eb6ca'
  },
  feedbackForm: FEEDBACK_FORM,
  tempFileUploadPath,
  staffInitialsApi: {
    endpoint: 'https://cigp-portal-api.azurewebsites.net',
    consentScopes: ['api://1b86d041-9931-422e-893a-2bd531e9c692/user_impersonation']
  }
};
