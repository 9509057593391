import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DetailedOrder } from '../orders/order.model';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { OrdersService } from '../orders/orders.service';
import { combineLatest, switchMap } from 'rxjs';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { APPROVAL_STATUS_VALUES, PRETRADE_CHECK_STATUS_VALUES } from '../shared/code-name-value.model';
import { MatCardModule } from '@angular/material/card';
import { PretradeCheckStatusBadgeComponent } from '../shared/pretrade-check-status-badge.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UpperCasePipe } from '@angular/common';
import { OrderPretradeChecksCardComponent } from '../orders/order-details/order-pretrade-checks-card/order-pretrade-checks-card.component';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ToastComponent } from '../shared/toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
    selector: 'of-approve-order',
    imports: [
        MatCardModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        OrderPretradeChecksCardComponent,
        PretradeCheckStatusBadgeComponent,
        ReactiveFormsModule,
        RouterModule,
        TextBoxModule,
        ToastComponent,
        UpperCasePipe
    ],
    template: `
    @if (order) {
      <div class="card mb-3">
        <div class="card-header">
          <h4 class="card-title">{{ approvalStatus === approved ? 'Approve' : 'Reject' }} order {{ order.id }}</h4>
          <div class="card-subtitle">
            {{ order.transactionType }} {{ order.quantity }} {{ order.security.name }} (account:
            {{ order.portfolio.shortName }})
            <small
              ><i>
                <a [routerLink]="['/orders', order.id]" target="_blank">Details</a>
              </i></small
            >
          </div>
        </div>
        <div class="card-body p-0">
          @if (!submitted) {
            <form [formGroup]="form">
              <ejs-textbox
                placeholder="Comments (required when pre-trade checks breached)"
                formControlName="comments"
                [multiline]="true"
                style="height: 100px"
              ></ejs-textbox>
            </form>
          } @else {
            <div class="m-3">
              <p>Your response {{ form.get('approvalStatus')?.value | uppercase }} was successfully recorded.</p>
              <p>You may go back to the <a [routerLink]="['/']">list of orders</a> or close this window</p>
            </div>
          }
        </div>
        <div class="card-footer px-2">
          <div class="btn-group btn-group-sm">
            <a class="btn btn-outline-secondary" [routerLink]="['/']">BACK</a>
            <button
              type="button"
              class="btn btn-outline-primary"
              (click)="submit(order.id)"
              [disabled]="!form.valid || submitting"
            >
              {{ approvalStatus === approved ? 'APPROVE' : 'REJECT' }}
            </button>
          </div>
        </div>
      </div>

      <of-order-pretrade-checks-card
        [preTradeCheckLimits]="order.preTradeCheck?.limits"
        [preTradeCheckStatus]="order.preTradeCheck?.status"
      ></of-order-pretrade-checks-card>
    }

    <of-toast #toastProcessingProgress [showCloseButton]="false">
      <div id="title" class="d-flex justify-content-between">
        <div>PROCESSING</div>
      </div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          <div>Processing...</div>
        </div>
      </div>
    </of-toast>
  `
})
export class ApproveOrderComponent implements OnInit {
  public form: FormGroup = new FormGroup({});
  public order: DetailedOrder | null = null;
  public comments: string | null = null;
  public submitting = false;
  public submitted = false;
  public approvalStatus: string | null = null;
  public approved = APPROVAL_STATUS_VALUES['approved'].code;

  @ViewChild('toastProcessingProgress')
  public toastProcessingProgress: ToastComponent | null = null;

  constructor(
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    const observable$ = combineLatest([
      this.route.paramMap.pipe(
        switchMap((params) => this.ordersService.getOrder$(Number.parseInt(params.get('id') || '-1', 10)))
      ),
      this.route.queryParamMap
    ]);

    observable$.subscribe(([order, queryParams]) => {
      this.order = order;
      this.approvalStatus = queryParams.get('status');

      this.form = this.fb.group({
        approvalStatus: this.fb.control<string | null>(this.approvalStatus, Validators.required),
        comments: this.fb.control<string | null>(null, {
          validators: [
            RxwebValidators.required({
              conditionalExpression: () =>
                order.preTradeCheck?.status.code === PRETRADE_CHECK_STATUS_VALUES['breached'].code
            })
          ]
        })
      });

      if (
        this.order &&
        !!queryParams.get('noComment') &&
        order.preTradeCheck?.status.code !== PRETRADE_CHECK_STATUS_VALUES['breached'].code &&
        this.form.valid
      ) {
        this.submit(this.order.id);
      }
    });
  }

  public submit(orderId: number): void {
    if (this.form) {
      this.submitting = true;
      this.form.disable();
      this.toastProcessingProgress?.show();
      const approvalStatus = Object.values(APPROVAL_STATUS_VALUES).find(
        (s) => s.code === this.form.get('approvalStatus')?.value
      );
      this.ordersService.updateOrderApprovalStatus$(orderId, { ...this.form.value, approvalStatus }).subscribe({
        next: () => {
          this.submitting = false;
          this.submitted = true;
          this.toastProcessingProgress?.hide();
        },
        error: () => {
          this.submitting = false;
          this.form.enable();
          this.toastProcessingProgress?.hide();
        }
      });
    }
  }
}
