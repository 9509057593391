import { Component, OnInit, ViewChild } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { Broker } from './broker.model';
import { BrokersService } from './brokers.service';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { AsyncPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { BROKER_DESTINATION_EMAIL_CODE } from '../shared/constants';
import { BrokerDestinationBadgeComponent } from './broker-destination-badge.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BulkUpdatePortfolioRestrictionsComponent } from '../portfolios/bulk-update-portfolio-restrictions.component';
import { MatDialog } from '@angular/material/dialog';
import { UpdateMultiplePortfoliosRestrictionsRequest } from '../portfolios/portfolio.model';
import { CanWriteBrokerComponent } from './create-broker/can-write-broker.component';
import { ToastComponent } from '../shared/toast.component';

@Component({
  selector: 'of-brokers',
  imports: [
    AsyncPipe,
    BrokerDestinationBadgeComponent,
    GridModule,
    RouterModule,
    SwitchModule,
    MatProgressSpinnerModule,
    CanWriteBrokerComponent,
    ToastComponent
],
  templateUrl: './brokers.component.html',
  styleUrl: './brokers.component.css'
})
export class BrokersComponent implements OnInit {
  public brokers$: Observable<Broker[]> | null = null;
  public showHidden = false;
  public destinationEmail = BROKER_DESTINATION_EMAIL_CODE;
  public brokerPortfolioRestrictionsMessage: string | null = null;

  @ViewChild('toastBrokerPortfolioRestrictionsProgress')
  public toastBrokerPortfolioRestrictionsProgress: ToastComponent | null = null;

  constructor(
    private brokersService: BrokersService,
    private bulkUpdatePortfolioRestrictionsDialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.setBrokers();
  }

  public onShowHiddenChange(event: any): void {
    this.showHidden = event.checked;
    this.setBrokers();
  }

  private setBrokers() {
    this.brokers$ = this.brokersService.listBrokers$(this.showHidden, true);
  }

  public openBulkExcludeDialog(): void {
    const dialogRef = this.bulkUpdatePortfolioRestrictionsDialog.open(BulkUpdatePortfolioRestrictionsComponent, {
      minWidth: '900px',
      minHeight: '180px'
    });

    dialogRef.afterClosed().subscribe((data: { portfolioInternalNames: string[]; action: string }) => {
      if (!data) return;

      this.brokerPortfolioRestrictionsMessage = 'Updating broker restrictions...';
      this.toastBrokerPortfolioRestrictionsProgress?.show();

      var request: UpdateMultiplePortfoliosRestrictionsRequest = {
        portfolioInternalNames: data.portfolioInternalNames
      };

      const update$ =
        data.action === 'exclude'
          ? this.brokersService.restrictPortfolioForAllBrokers$(request)
          : this.brokersService.whitelistPortfolioForAllBrokers$(request);

      update$.pipe(finalize(() => this.toastBrokerPortfolioRestrictionsProgress?.hide())).subscribe();
    });
  }
}
