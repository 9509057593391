<div class="card mb-3">
  <div class="card-header d-flex justify-content-between">
    <div class="card-title m-0">SECURITY</div>
  </div>
  <div class="card-body">
    @if (securities) {
      <form [formGroup]="securityForm">
        <div class="d-flex justify-content-between align-items-center gap-3">
          <!-- Security -->
          <div class="flex-fill">
            <ejs-autocomplete
              [dataSource]="securities"
              [fields]="fields"
              placeholder="Select a security"
              (filtering)="onFiltering($event)"
              [highlight]="true"
              [autofill]="true"
              (change)="onSecuritySelected($event)"
              formControlName="faSecurityCode"
              [allowCustom]="false"
            >
              <ng-template #itemTemplate="" let-data="">
                <div class="d-flex justify-content-between">
                  <div>{{ data.name }}</div>
                  <div>
                    <small>{{ data.faSecurityCode }} | {{ data.currency }}</small>
                  </div>
                </div>
              </ng-template>
            </ejs-autocomplete>
          </div>
          @if (securityForm.get('hasMonetaryBenefit')?.value) {
            <h4 class="m-0">
              <span class="badge text-warning-emphasis bg-warning-subtle" title="This security has monetary benefits"
                >Monetary Benefits</span
              >
            </h4>
          }
          @if (securityForm.get('complexProduct')?.value) {
            <h4 class="m-0">
              <span class="badge text-warning-emphasis bg-warning-subtle" title="This security is a complex product"
                >Complex Product</span
              >
            </h4>
          }
        </div>

        <div class="d-flex gap-3">
          <!-- Security name -->
          <ejs-textbox
            formControlName="name"
            placeholder="Security name"
            floatLabelType="Auto"
            width="380px"
          ></ejs-textbox>

          <!-- Type of security -->
          <ejs-textbox formControlName="type" placeholder="Type" floatLabelType="Auto"></ejs-textbox>

          <!-- Class 3 -->
          <ejs-textbox formControlName="class3" placeholder="Class 3" floatLabelType="Auto" width="260px"></ejs-textbox>

          <!-- Country -->
          <ejs-textbox formControlName="country" placeholder="Country" floatLabelType="Auto" width="180px"></ejs-textbox>

          <!-- ISIN -->
          <ejs-textbox formControlName="isin" placeholder="ISIN" floatLabelType="Auto" width="120px"></ejs-textbox>

          <!-- Trade code -->
          <ejs-textbox
            formControlName="tradeCode"
            placeholder="Trade code"
            floatLabelType="Auto"
            width="120px"
          ></ejs-textbox>

          <!-- Currency -->
          <ejs-textbox
            formControlName="currency"
            placeholder="Currency"
            floatLabelType="Auto"
            width="80px"
          ></ejs-textbox>

          <!-- Last close -->
          <ejs-numerictextbox
            formControlName="lastClose"
            placeholder="Last close"
            floatLabelType="Auto"
            [showSpinButton]="false"
            [format]="lastCloseNtbFormat"
            width="80px"
          >
          </ejs-numerictextbox>
        </div>

        @if (securityForm.get('type')?.value === typeTd) {
          <div class="card mt-3">
            <div class="card-header">
              <small class="card-title m-0">TIME DEPOSIT</small>
            </div>
            <div class="card-body d-flex gap-3 align-items-end">
              <!-- TD maturity date -->
              <div style="width: 210px">
                <ejs-datepicker
                  placeholder="Maturity date"
                  formControlName="tdMaturityDate"
                  format="dd/MM/yyyy"
                  [min]="today"
                  floatLabelType="Auto"
                ></ejs-datepicker>
              </div>

              <div class="d-flex gap-2">
                <!-- TD tenor qty -->
                <div style="width: 120px">
                  <ejs-numerictextbox
                    formControlName="tdTenorQty"
                    placeholder="Tenor (quantity)"
                    floatLabelType="Auto"
                    [showSpinButton]="false"
                    format="N0"
                    [min]="1"
                  >
                  </ejs-numerictextbox>
                </div>

                <!-- TD tenor suffix -->
                <div style="width: 120px">
                  <ejs-dropdownlist
                    placeholder="Tenor (unit)"
                    [dataSource]="tdTenorSuffixes"
                    floatLabelType="Auto"
                    formControlName="tdTenorSuffix"
                  ></ejs-dropdownlist>
                </div>
              </div>

              <div class="d-flex gap-2 align-items-center">
                <!-- TD rate -->
                <ejs-numerictextbox
                  formControlName="tdRate"
                  placeholder="Rate"
                  floatLabelType="Auto"
                  [showSpinButton]="false"
                  format="p4"
                >
                </ejs-numerictextbox>

                <!-- TD best rate -->
                <div class="mt-4">
                  <ejs-checkbox label="Best rate" formControlName="tdBestRate"></ejs-checkbox>
                </div>

                <!-- TD Start Date-->
                <ejs-datepicker
                  placeholder="Start date"
                  formControlName="tdStartDate"
                  format="dd/MM/yyyy"
                  [min]="today"
                  floatLabelType="Auto"
                ></ejs-datepicker>
              </div>
            </div>
          </div>
        }
      </form>
    } @else {
      <div class="d-flex justify-content-center align-items-center" style="height: 80px">
        <mat-spinner diameter="50"></mat-spinner>
      </div>
    }
  </div>
</div>
