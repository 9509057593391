import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Custodian } from './custodian.model';
import { CustodiansService } from './custodians.service';
import { AsyncPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';

@Component({
    selector: 'of-custodians',
    imports: [AsyncPipe, GridModule, RouterModule, SwitchModule],
    template: `
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <h4 class="card-title m-0">CUSTODIANS</h4>
        <div class="d-flex align-items-center gap-2">
          <ejs-switch (change)="onShowHiddenChange($event)"></ejs-switch>
          <div>Show hidden</div>
        </div>
      </div>
      <div class="card-body p-0">
        <ejs-grid [dataSource]="custodians$ | async">
          <e-columns>
            <e-column field="id" headerText="#" width="20">
              <ng-template #template let-data>
                <span class="me-2">{{ data.id }}</span>
                @if (data.hidden) {
                  <i class="bi bi-ban text-danger" title="Hidden"></i>
                }
              </ng-template>
            </e-column>
            <e-column field="code" headerText="Code" width="50"></e-column>
            <e-column field="name" headerText="Name" width="100"></e-column>
            <e-column width="20">
              <ng-template #template let-data>
                <a class="btn btn-outline-secondary border-0" [routerLink]="['/custodians', data.id, 'edit']"
                  ><i class="bi bi-pencil-square"></i
                ></a>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>
  `
})
export class CustodiansComponent {
  public custodians$: Observable<Custodian[]> | null = null;
  public showHidden = false;

  constructor(private custodiansService: CustodiansService) {}

  public ngOnInit(): void {
    this.setCustodians();
  }

  public onShowHiddenChange(event: any): void {
    this.showHidden = event.checked;
    this.setCustodians();
  }

  private setCustodians() {
    this.custodians$ = this.custodiansService.listCustodians$(this.showHidden, true);
  }
}
