import { EventEmitter, Inject, Injectable } from '@angular/core';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
import { AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { NGXLogger } from 'ngx-logger';
import { environment } from '../../environments/environment';
import { Observable, Subject, map, tap } from 'rxjs';
import { ROLE_ORDER_APPROVER, ROLE_ORDER_CHECKER, ROLE_ORDER_READER, ROLE_ORDER_WRITER } from '../shared/constants';

interface IDToken {
  roles?: string[];
}

@Injectable({
  providedIn: 'root'
})
export class OrderFlowAuthService {
  public loggedIn$ = new Subject<boolean>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private logger: NGXLogger
  ) {}

  public get loggedIn(): boolean {
    return !!this.authService.instance.getActiveAccount();
  }

  public get account(): AccountInfo | null {
    return this.authService.instance.getActiveAccount();
  }

  public checkAndSetActiveAccount(): void {
    /**
     * If no active account set but there are accounts signed in, sets first account to active account
     * To use active account set here, subscribe to inProgress$ first in your component
     */
    const activeAccount = this.authService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().filter((a) => a.tenantId === environment.tenant).length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
      this.logger.log(this.account);
    }
  }

  public setActiveAccount(account: AccountInfo): void {
    this.authService.instance.setActiveAccount(account);
  }

  public login$(): Observable<void> {
    if (this.msalGuardConfig.authRequest) {
      return this.authService
        .loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest)
        .pipe(tap(() => this.loggedIn$.next(true)));
    } else {
      return this.authService.loginRedirect().pipe(tap(() => this.loggedIn$.next(true)));
    }
  }

  public logout$(): Observable<void> {
    return this.authService.logoutRedirect();
  }

  public hasRole(role: string): boolean {
    const typedIDToken = this.account?.idTokenClaims as IDToken;
    const hasRole = typedIDToken && typedIDToken.roles && typedIDToken.roles.filter((r) => r === role).length > 0;
    return hasRole !== undefined && hasRole;
  }

  public hasOneOfRoles(roles: string[]): boolean {
    if (!this.account) {
      return false;
    }

    const typedIDToken = this.account.idTokenClaims as IDToken;

    if (!typedIDToken || !typedIDToken.roles) {
      return false;
    }

    for (const role of roles) {
      if (typedIDToken.roles.filter((r) => r === role).length > 0) {
        return true;
      }
    }

    return false;
  }

  public canViewFundamentalData(): boolean {
    return (
      this.loggedIn &&
      this.hasOneOfRoles([ROLE_ORDER_APPROVER, ROLE_ORDER_CHECKER, ROLE_ORDER_READER, ROLE_ORDER_WRITER])
    );
  }

  public getAccessToken$(): Observable<string> {
    return this.authService
      .acquireTokenSilent({
        scopes: environment.consentScopes
      })
      .pipe(map((authResult) => authResult.accessToken));
  }
}
