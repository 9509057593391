import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { Observable, catchError, throwError } from 'rxjs';
import { showErrorDialog } from '../shared/utils';
import { BasicHoldingWithBasicPortfolio } from './basic-holding-with-basic-portfolio.model';

@Injectable({
  providedIn: 'root'
})
export class HoldingsService {
  private apiEndpoint = `${environment.apiEndpoint}/api/holdings`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog
  ) {}

  public getMarketValueOfPortfolio$(portfolioId: number): Observable<number> {
    return this.http.get<number>(`${this.apiEndpoint}/${portfolioId}/market-value`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public listPortfoliosHoldingASpecificSecurity$(securityCode: string): Observable<BasicHoldingWithBasicPortfolio[]> {
    return this.http
      .get<BasicHoldingWithBasicPortfolio[]>(`${this.apiEndpoint}/portfolios-with-security`, {
        params: {
          securityCode
        }
      })
      .pipe(
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }
}
