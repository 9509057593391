import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SecuritySelectorComponent } from '../../securities/security-selector/security-selector.component';
import { ChangeEventArgs, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { Observable } from 'rxjs';
import { Custodian } from '../../custodians/custodian.model';
import {
  EXECUTION_INSTRUCTION_VALUES,
  EXECUTION_METHOD_VALUES,
  ORDER_TYPE_VALUES,
  QUANTITY_TYPE_VALUES,
  TIF_VALUES,
  TRANSACTION_TYPE_VALUES
} from '../../shared/code-name-value.model';
import { environment } from '../../../environments/environment';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';
import { NumericTextBoxModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { AsyncPipe, JsonPipe } from '@angular/common';
import { AllocationsFormComponent } from '../../allocations-form/allocations-form.component';
import { AttachmentUploaderComponent } from '../../shared/attachment-uploader.component';
import { CustodiansService } from '../../custodians/custodians.service';
import { Router } from '@angular/router';
import {
  AllocationToAddOrUpdate,
  OrderToAddOrUpdate,
  OrderToAddOrUpdateFormConfig,
  OrderWithAllocationsToAddOrUpdate
} from '../order-with-allocations-to-add-or-update.model';
import { SecurityFormConfig } from '../../securities/security.model';
import { OrdersService } from '../orders.service';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import moment, { Moment } from 'moment';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { getSecurityPriceNtbFormat } from '../../shared/utils';
import { OrderCloningParams } from '../create-order/create-order.component';
import { CLONE_TYPE_WITH_ALLOCATION } from '../../shared/constants';

@Component({
  selector: 'of-order-form',
  standalone: true,
  imports: [
    AllocationsFormComponent,
    AsyncPipe,
    AttachmentUploaderComponent,
    DatePickerModule,
    DropDownListModule,
    JsonPipe,
    MatProgressSpinnerModule,
    NumericTextBoxModule,
    RadioButtonModule,
    ReactiveFormsModule,
    SecuritySelectorComponent,
    TextBoxModule
  ],
  templateUrl: './order-form.component.html',
  styleUrl: './order-form.component.css'
})
export class OrderFormComponent implements OnInit {
  public form: FormGroup = new FormGroup({});
  public custodians$: Observable<Custodian[]> | null = null;
  public submitting = false;
  public lblQuantityNominalValue = 'Total (shared by all allocations) *';
  public executionInstructionValues = EXECUTION_INSTRUCTION_VALUES;
  public executionMethodValues = Object.values(EXECUTION_METHOD_VALUES);
  public orderTypeValues = Object.values(ORDER_TYPE_VALUES);
  public quantityTypeValues = Object.values(QUANTITY_TYPE_VALUES);
  public tifValues = Object.values(TIF_VALUES);
  public transactionTypeValues = Object.values(TRANSACTION_TYPE_VALUES);
  public environment = environment.env;
  public excludePortfolios: string[] = [];
  public lblQuantityType: string | null | undefined = null;
  public ntbLimitPriceFormat = '#,###.#####';

  @ViewChild(SecuritySelectorComponent, { static: true })
  public securitySelector: SecuritySelectorComponent | null = null;

  @ViewChild(AllocationsFormComponent, { static: true })
  public allocationsForm: AllocationsFormComponent | null = null;

  @Input()
  public orderToUpdateId: number | null = null;

  @Input()
  public cloningParams: OrderCloningParams | null = null;

  constructor(
    private fb: FormBuilder,
    private custodiansService: CustodiansService,
    private ordersService: OrdersService,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.custodians$ = this.custodiansService.listCustodians$(false);

    if (this.securitySelector) {
      this.form = this.fb.group<OrderToAddOrUpdateFormConfig>({
        additionalInformation: this.fb.control<string | null>(null),
        additionalInternalInformation: this.fb.control<string | null>(null),
        attachmentTempPath: this.fb.control<string | null>(null),
        custodian: this.fb.control<string | null>(null, Validators.required),
        executionInstruction: this.fb.control<string | null>(null, Validators.required),
        executionMethod: this.fb.control<string | null>(null, Validators.required),
        instructionDate: this.fb.control<Date | null>(null),
        limitPrice: this.fb.control<number | null>(null),
        orderType: this.fb.control<string | null>(null, Validators.required),
        quantity: this.fb.control<number | null>(null, Validators.required),
        quantityType: this.fb.control<string | null>(null, Validators.required),
        security: this.securitySelector.createForm(),
        tif: this.fb.control<string | null>(null, Validators.required),
        transactionDate: this.fb.control<Date | null>(null, Validators.required),
        transactionType: this.fb.control<string | null>(null, Validators.required)
      });

      this.form.get('limitPrice')?.disable();
      this.form.get('quantity')?.disable();

      this.form.get('quantityType')?.valueChanges.subscribe((value: string) => {
        this.form.get('quantity')?.enable();
        this.lblQuantityNominalValue = `Total ${value.toLowerCase()} (shared by all allocations) *`;
        this.lblQuantityType =
          value === QUANTITY_TYPE_VALUES['quantity'].code
            ? 'shares / units'
            : this.securitySelectorForm.get('currency')?.value;

        // Adjust the quantity value when changing the quantity type (nominal <-> quantity)
        this.recomputeQuantityValueOnQuantityTypeChange();
      });
    }

    this.securitySelectorForm.get('faSecurityCode')?.valueChanges.subscribe(() => {
      if (!this.submitting) {
        this.form.get('limitPrice')?.reset();
      }
    });

    this.securitySelectorForm
      .get('multiplier')
      ?.valueChanges.subscribe((multiplier) => (this.ntbLimitPriceFormat = getSecurityPriceNtbFormat(multiplier)));

    this.securitySelectorForm.get('lastClose')?.valueChanges.subscribe((lastClose) => {
      if (
        !this.form.get('limitPrice')?.value &&
        this.form.get('orderType')?.value === ORDER_TYPE_VALUES['limit'].code
      ) {
        this.form.get('limitPrice')?.setValue(lastClose);
      }
    });

    this.form.get('orderType')?.valueChanges.subscribe((orderType) => {
      if (orderType === ORDER_TYPE_VALUES['market'].code) {
        this.form.get('limitPrice')?.disable();
        this.form.get('limitPrice')?.reset();
      } else {
        if (!this.form.get('limitPrice')?.value) {
          this.form.get('limitPrice')?.setValue(this.securitySelectorForm.get('lastClose')?.value);
        }

        this.form.get('limitPrice')?.enable();
      }
    });

    this.form.get('quantityType')?.setValue(QUANTITY_TYPE_VALUES['quantity'].code);
    this.form.get('executionQuantityType')?.setValue(QUANTITY_TYPE_VALUES['quantity'].code);
  }

  private recomputeQuantityValueOnQuantityTypeChange() {
    const securityPrice = this.securitySelectorForm.get('lastClose')?.value;
    const curQuantity = this.form.get('quantity')?.value;
    if (curQuantity && securityPrice) {
      const newQuantity = this.isQuantity() ? curQuantity / securityPrice : curQuantity * securityPrice;
      this.form.get('quantity')?.setValue(newQuantity);
    }
  }

  public onSecuritySelectorReady(): void {
    this.setInitialData();
  }

  public onTotalQuantityOrNominalUpdateRequested(totalQuantityOrNominal: number): void {
    this.form?.get('quantity')?.setValue(totalQuantityOrNominal);
  }

  public submit(): void {
    this.submitting = true;
    this.form.disable();
    this.allocationsForm?.form.disable();

    let instructionDate: Moment | null = moment(this.form.get('instructionDate')?.value);
    instructionDate = instructionDate.isValid() ? instructionDate : null;

    let transactionDate: Moment | null = moment(this.form.get('transactionDate')?.value);
    transactionDate = transactionDate.isValid() ? transactionDate : null;

    let tdMaturityDate: Moment | null = moment(this.securitySelectorForm.get('tdMaturityDate')?.value);
    tdMaturityDate = tdMaturityDate.isValid() ? tdMaturityDate : null;

    const tdTenorQty = this.securitySelectorForm.get('tdTenorQty')?.value || null;
    const tdTenorSuffix = this.securitySelectorForm.get('tdTenorSuffix')?.value || null;
    const tdTenor =
      tdTenorQty && tdTenorSuffix ? `${tdTenorQty} ${tdTenorSuffix.toLowerCase()}${tdTenorQty > 1 ? 's' : ''}` : null;

    const order: OrderWithAllocationsToAddOrUpdate = {
      order: {
        ...(this.form.getRawValue() as OrderToAddOrUpdate),
        instructionDate: instructionDate?.format('YYYY-MM-DD') || null,
        tdBestRate: this.securitySelectorForm.get('tdBestRate')?.value || null,
        tdMaturityDate: tdMaturityDate?.format('YYYY-MM-DD') || null,
        tdRate: this.securitySelectorForm.get('tdRate')?.value || null,
        tdTenor,
        transactionDate: transactionDate?.format('YYYY-MM-DD') || null
      },
      allocations: this.allocationsForm?.allocations.getRawValue() as AllocationToAddOrUpdate[]
    };

    const observable$ = this.orderToUpdateId
      ? this.ordersService.updateOrder$(this.orderToUpdateId, order)
      : this.ordersService.insertOrder$(order);

    observable$.subscribe({
      next: () => {
        this.submitting = false;
        this.router.navigate(['/orders']);
      },
      error: () => {
        this.submitting = false;
        this.form.enable();
        this.allocationsForm?.form.enable();
      }
    });
  }

  public reset(): void {
    this.form.reset();
    this.allocationsForm?.form.reset();
    this.form.enable();
  }

  public get securitySelectorForm(): FormGroup<SecurityFormConfig> {
    return this.form.get('security') as FormGroup<SecurityFormConfig>;
  }

  public onCustodianSelected(event: ChangeEventArgs): void {
    this.excludePortfolios = (event.itemData as Custodian)?.excludedPortfolios;
  }

  private setInitialData(): void {
    if (this.orderToUpdateId) {
      this.setOrderToEditInitialData();
    } else if (this.cloningParams) {
      if (this.cloningParams.cloneType === CLONE_TYPE_WITH_ALLOCATION) {
        this.setOrderToCloneWithAllocationInitialData();
      } else {
        this.setOrderToCloneInitialData();
      }
    }
  }

  private setOrderToCloneInitialData() {
    this.ordersService
      .getOrderForCloning$(this.cloningParams!.orderId)
      .subscribe((orderToClone: OrderToAddOrUpdate) => {
        if (orderToClone) {
          this.form.patchValue(orderToClone);
          this.securitySelector?.setTimeDepositInitialValues(
            orderToClone.tdMaturityDate,
            orderToClone.tdTenor,
            orderToClone.tdRate,
            orderToClone.tdBestRate
          );

          this.form.get('instructionDate')?.reset();
          this.form.get('transactionDate')?.reset();

          if (orderToClone.instructionDate) {
            this.form.get('instructionDate')?.setValue(moment(orderToClone.instructionDate).toDate());
          }

          if (orderToClone.transactionDate) {
            this.form.get('transactionDate')?.setValue(moment(orderToClone.transactionDate).toDate());
          }
        }
      });
  }

  private setOrderToCloneWithAllocationInitialData() {
    this.ordersService
      .getOrderForCloningWithAllocation$(this.cloningParams!.orderId)
      .subscribe((orderToClone: OrderWithAllocationsToAddOrUpdate) => {
        this.initializeOrderFormWithOrderWithAllocationData(orderToClone);
      });
  }

  private setOrderToEditInitialData() {
    this.ordersService
      .getOrderForUpdate$(this.orderToUpdateId!)
      .subscribe((orderWithAllocationsToUpdate: OrderWithAllocationsToAddOrUpdate) => {
        this.initializeOrderFormWithOrderWithAllocationData(orderWithAllocationsToUpdate);
      });
  }

  private initializeOrderFormWithOrderWithAllocationData(
    orderWithAllocationsToUpdate: OrderWithAllocationsToAddOrUpdate
  ) {
    if (orderWithAllocationsToUpdate) {
      this.form.patchValue(orderWithAllocationsToUpdate.order);
      this.securitySelector?.setTimeDepositInitialValues(
        orderWithAllocationsToUpdate.order.tdMaturityDate,
        orderWithAllocationsToUpdate.order.tdTenor,
        orderWithAllocationsToUpdate.order.tdRate,
        orderWithAllocationsToUpdate.order.tdBestRate
      );

      this.form.get('instructionDate')?.reset();
      this.form.get('transactionDate')?.reset();

      if (orderWithAllocationsToUpdate.order.instructionDate) {
        this.form.get('instructionDate')?.setValue(moment(orderWithAllocationsToUpdate.order.instructionDate).toDate());
      }

      if (orderWithAllocationsToUpdate.order.transactionDate) {
        this.form.get('transactionDate')?.setValue(moment(orderWithAllocationsToUpdate.order.transactionDate).toDate());
      }

      this.form.get('quantity')?.setValue(orderWithAllocationsToUpdate.allocations[0].quantity);

      this.allocationsForm?.allocations.patchValue(orderWithAllocationsToUpdate.allocations);

      this.allocationsForm?.allocationFormReady$.subscribe((index) => {
        if (orderWithAllocationsToUpdate && index === 0) {
          this.allocationsForm
            ?.getAllocationForm(0)
            .get('portfolio')
            ?.valueChanges.subscribe(() => {
              if (orderWithAllocationsToUpdate) {
                this.allocationsForm
                  ?.getAllocationForm(0)
                  .get('account')
                  ?.setValue(orderWithAllocationsToUpdate.allocations[0].account);
                this.allocationsForm
                  ?.getAllocationForm(0)
                  .get('accountStr')
                  ?.setValue(orderWithAllocationsToUpdate.allocations[0].account.name);

                if (orderWithAllocationsToUpdate.allocations[0].fxRate) {
                  this.allocationsForm?.getAllocationForm(0).get('fxRate')?.enable();
                }
              }
            });

          this.allocationsForm
            ?.getAllocationForm(0)
            .get('portfolioStr')
            ?.setValue(orderWithAllocationsToUpdate?.allocations[0].portfolio.internalName);
        }
      });
    }
  }

  private isQuantity(): boolean {
    return this.form.get('quantityType')?.value === QUANTITY_TYPE_VALUES['quantity'].code;
  }
}
