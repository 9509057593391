import { Component, Input } from '@angular/core';
import { CodeNameItem, ORDER_STATUS_VALUES } from '../shared/code-name-value.model';

@Component({
    selector: 'of-can-ack-order',
    imports: [],
    template: `
    @if (canAck) {
      <ng-content></ng-content>
    }
  `
})
export class CanAckOrderComponent {
  public canAck = false;

  @Input()
  public orderStatus: CodeNameItem | null = null;

  ngOnInit(): void {
    this.canAck = canAckOrder(this.orderStatus);
  }
}

export function canAckOrder(orderStatus: CodeNameItem | null): boolean {
  return orderStatus?.code === ORDER_STATUS_VALUES['sent'].code;
}
