import { Component, ViewChild } from '@angular/core';
import { finalize, Observable } from 'rxjs';
import { Custodian } from './custodian.model';
import { CustodiansService } from './custodians.service';
import { AsyncPipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { GridModule } from '@syncfusion/ej2-angular-grids';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { MatDialog } from '@angular/material/dialog';
import { BulkUpdatePortfolioRestrictionsComponent } from '../portfolios/bulk-update-portfolio-restrictions.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastComponent } from '../shared/toast.component';
import { CanWriteCustodianComponent } from './custodian-form/can-write-custodian.component';
import { UpdateMultiplePortfoliosRestrictionsRequest } from '../portfolios/portfolio.model';

@Component({
  selector: 'of-custodians',
  imports: [
    AsyncPipe,
    GridModule,
    RouterModule,
    SwitchModule,
    ToastComponent,
    MatProgressSpinnerModule,
    CanWriteCustodianComponent
  ],
  template: `
    <div class="card">
      <div class="card-header d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <h4 class="card-title m-0">CUSTODIANS</h4>
          <div class="btn-group btn-group-sm ms-2">
            <of-can-write-custodian>
              <button
                type="button"
                class="btn btn-sm btn-outline-danger border-0"
                title="Portfolio Restrictions"
                (click)="openBulkExcludeDialog()"
              >
                <i class="bi bi-sign-stop"></i>
              </button>
            </of-can-write-custodian>
          </div>
        </div>
        <div class="d-flex align-items-center gap-2">
          <ejs-switch (change)="onShowHiddenChange($event)"></ejs-switch>
          <div>Show hidden</div>
        </div>
      </div>
      <div class="card-body p-0">
        <ejs-grid [dataSource]="custodians$ | async">
          <e-columns>
            <e-column field="id" headerText="#" width="20">
              <ng-template #template let-data>
                <span class="me-2">{{ data.id }}</span>
                @if (data.hidden) {
                  <i class="bi bi-ban text-danger" title="Hidden"></i>
                }
              </ng-template>
            </e-column>
            <e-column field="code" headerText="Code" width="50"></e-column>
            <e-column field="name" headerText="Name" width="100"></e-column>
            <e-column width="20">
              <ng-template #template let-data>
                <a class="btn btn-outline-secondary border-0" [routerLink]="['/custodians', data.id, 'edit']"
                  ><i class="bi bi-pencil-square"></i
                ></a>
              </ng-template>
            </e-column>
          </e-columns>
        </ejs-grid>
      </div>
      <of-toast #toastCustodianPortfolioRestrictionsProgress [showCloseButton]="false">
        <div id="title" class="d-flex justify-content-between">
          <div>UPDATING CUSTODIANS WITH NEW RESTRICTIONS</div>
        </div>
        <div id="content" class="d-flex gap-2">
          <mat-spinner [diameter]="20"></mat-spinner>
          <div>
            {{ custodianPortfolioRestrictionsMessage }}
          </div>
        </div>
      </of-toast>
    </div>
  `
})
export class CustodiansComponent {
  public custodians$: Observable<Custodian[]> | null = null;
  public showHidden = false;
  public custodianPortfolioRestrictionsMessage: string | null = null;

  @ViewChild('toastCustodianPortfolioRestrictionsProgress')
  public toastCustodianPortfolioRestrictionsProgress: ToastComponent | null = null;

  constructor(
    private custodiansService: CustodiansService,
    private bulkUpdatePortfolioRestrictionsDialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.setCustodians();
  }

  public onShowHiddenChange(event: any): void {
    this.showHidden = event.checked;
    this.setCustodians();
  }

  private setCustodians() {
    this.custodians$ = this.custodiansService.listCustodians$(this.showHidden, true);
  }

  public openBulkExcludeDialog(): void {
    const dialogRef = this.bulkUpdatePortfolioRestrictionsDialog.open(BulkUpdatePortfolioRestrictionsComponent, {
      minWidth: '900px',
      minHeight: '180px'
    });

    dialogRef.afterClosed().subscribe((data: { portfolioInternalNames: string[]; action: string }) => {
      if (!data) return;

      this.custodianPortfolioRestrictionsMessage = 'Updating custodian restrictions...';
      this.toastCustodianPortfolioRestrictionsProgress?.show();

      var request: UpdateMultiplePortfoliosRestrictionsRequest = {
        portfolioInternalNames: data.portfolioInternalNames
      };

      const update$ =
        data.action === 'exclude'
          ? this.custodiansService.restrictPortfolioForAllCustodians$(request)
          : this.custodiansService.whitelistPortfolioForAllCustodians$(request);

      update$.pipe(finalize(() => this.toastCustodianPortfolioRestrictionsProgress?.hide())).subscribe();
    });
  }
}
