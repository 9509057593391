import { Component, Input } from '@angular/core';
import { DetailedOrder } from '../../order.model';
import { MatDialog } from '@angular/material/dialog';
import { PreviewPdfComponent } from '../../preview-pdf.component';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe, DecimalPipe, formatDate, PercentPipe } from '@angular/common';
import { OrderStatusBadgeComponent } from '../../../shared/order-status-badge.component';
import { ORDER_STATUS_VALUES } from '../../../shared/code-name-value.model';
import { TimeagoPipe } from '../../../shared/timeago.pipe';
import { CanPreviewOrderComponent } from '../can-preview-order.component';
import { CanRecordPartialExecutionComponent } from '../../../record-partial-execution/can-record-partial-execution.component';
import { isSpotOrForwardFxOrder } from '../../../shared/utils';
import { RecordPartialExecutionDialogComponent } from '../../../record-partial-execution/record-partial-execution-dialog.component';

@Component({
  selector: 'of-order-details-card',
  imports: [
    CanPreviewOrderComponent,
    CanRecordPartialExecutionComponent,
    DatePipe,
    DecimalPipe,
    OrderStatusBadgeComponent,
    PercentPipe,
    RouterModule,
    TimeagoPipe,
    CommonModule
  ],
  templateUrl: './order-details-card.component.html',
  styleUrl: './order-details-card.component.css'
})
export class OrderDetailsCardComponent {
  public executable = ORDER_STATUS_VALUES['executable'].code;
  public sent = ORDER_STATUS_VALUES['sent'].code;
  public formatDate = formatDate;

  @Input()
  public order: DetailedOrder | null = null;

  constructor(
    private pdfViewerDialog: MatDialog,
    private recordPartialExecutionDialog: MatDialog
  ) {}

  public viewPdf(orderId: number): void {
    this.pdfViewerDialog.open(PreviewPdfComponent, {
      minWidth: '860px',
      data: { orderId }
    });
  }

  public isSpotOrForward(type: string): boolean {
    return isSpotOrForwardFxOrder(type);
  }

  public viewAttachment(base64Content: string): void {
    this.pdfViewerDialog.open(PreviewPdfComponent, {
      minWidth: '860px',
      data: { base64Content }
    });
  }

  public recordPartialExecution(): void {
    this.recordPartialExecutionDialog.open(RecordPartialExecutionDialogComponent, {
      minWidth: '400px',
      data: this.order
    });
  }
}
