import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { Observable, catchError, interval, map, of, switchMap } from 'rxjs';
import { HealthCheckResponse } from './health-check-response.model';
import { HealthStatus } from './health-status.enum';

@Injectable({
  providedIn: 'root'
})
export class HealthService {
  private apiEndpoint = `${environment.apiEndpoint}/health`;

  constructor(private http: HttpClient, private logger: NGXLogger) {}

  public isBackendAvailable$(): Observable<boolean> {
    return this.http.get<HealthCheckResponse>(this.apiEndpoint).pipe(
      map((response: HealthCheckResponse) => {
        this.logger.log(response);
        return response.status !== HealthStatus.Unhealthy;
      }),
      catchError((error: any) => {
        if (error.status !== 503) {
          this.logger.error(error);
        }
        return of(false);
      })
    );
  }

  public isFAApiAvailable$(): Observable<boolean> {
    return this.http.get<HealthCheckResponse>(this.apiEndpoint).pipe(
      map((response: HealthCheckResponse) => response.results.faApi.status === HealthStatus.Healthy),
      catchError((error: any) => {
        if (error.status !== 503) {
          this.logger.error(error);
        }
        return of(false);
      })
    );
  }

  public isBackendAvailableInterval$(): Observable<boolean> {
    return interval(60000).pipe(switchMap(() => this.isBackendAvailable$()));
  }
}
