import { Component } from '@angular/core';
import { OrderFlowAuthService } from '../../auth/order-flow-auth.service';
import { ROLE_CUSTODIAN_WRITER } from '../../shared/constants';

@Component({
  selector: 'of-can-write-custodian',
  standalone: true,
  imports: [],
  template: `
    @if (canWrite) {
      <ng-content></ng-content>
    }
  `
})
export class CanWriteCustodianComponent {
  public canWrite: boolean | null = null;

  constructor(private authService: OrderFlowAuthService) {}

  ngOnInit(): void {
    this.canWrite = this.authService.hasRole(ROLE_CUSTODIAN_WRITER);
  }
}
