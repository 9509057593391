import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NumericTextBoxModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { OrdersService } from '../orders/orders.service';
import { PartialExecution } from './partial-execution.model';
import { RadioButtonModule } from '@syncfusion/ej2-angular-buttons';

@Component({
  selector: 'of-delete-partial-execution-dialog',
  imports: [RadioButtonModule, ReactiveFormsModule, NumericTextBoxModule, TextBoxModule],
  template: `
    @if (data) {
      <form [formGroup]="form">
        <div class="d-flex flex-column gap-3 p-3">
          <h5 mat-dialog-title class="m-0">DELETE EXECUTION</h5>
          <div mat-dialog-content>
            <ejs-textbox
              formControlName="comments"
              placeholder="Comments (optional)"
              multiline="true"
              floatLabelType="Auto"
              style="height: 100px"
            ></ejs-textbox>
          </div>
          <div mat-dialog-actions class="d-flex justify-content-end">
            <div class="btn-group">
              <button class="btn btn-secondary" (click)="onCloseClick()">CLOSE</button>
              <button class="btn btn-primary" [disabled]="!form.valid" (click)="onExecuteClick()" cdkFocusInitial>
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </form>
    }
  `
})
export class DeletePartialExecutionDialogComponent {
  public form: FormGroup = new FormGroup({});
  public submitting = false;

  constructor(
    public dialogRef: MatDialogRef<DeletePartialExecutionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { partialExecution: PartialExecution; orderId: number },
    private ordersService: OrdersService,
    private fb: FormBuilder
  ) {
    this.form = this.fb.group({
      comments: this.fb.control<string | null>(null)
    });
  }

  public onExecuteClick(): void {
    this.form.disable();

    this.submitting = true;
    var comments = this.form.get('comments')?.value;

    this.ordersService.deletePartialExecution$(this.data.orderId!, this.data.partialExecution.id, comments).subscribe({
      next: () => {
        this.submitting = false;
        this.dialogRef.close({ refresh: true });
      },
      error: () => (this.submitting = false)
    });

    this.dialogRef.close();
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
