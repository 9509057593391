import { Component, Input, OnInit } from '@angular/core';
import { User } from './user.model';
import { NgStyle } from '@angular/common';
import { randomCigpColor } from './utils';

@Component({
  selector: 'of-avatar-card',
  standalone: true,
  imports: [NgStyle],
  template: `
    @if (user) {
      <div class="d-flex gap-2 align-items-center">
        @if (user.profilePicture) {
          <img [src]="user.profilePicture" class="e-avatar e-avatar-circle" [alt]="user.name" [title]="title" />
        } @else {
          <div
            class="e-avatar e-avatar-circle text-white d-flex justify-content-center align-items-center"
            [ngStyle]="{ 'background-color': backgroundColor }"
            [title]="title"
          >
            {{ user.initials }}
          </div>
        }

        @if (showName) {
          <div>{{ user.name }}</div>
        }
      </div>
    }
  `,
  styles: `
    img {
      height: 36px;
      width: 36px;
    }
    div.e-avatar {
      height: 36px;
      width: 36px;
      cursor: default;
    }
  `
})
export class AvatarCardComponent implements OnInit {
  public backgroundColor = randomCigpColor();
  public title: string | null = null;

  @Input()
  public user: User | null = null;

  @Input()
  public showName = false;

  @Input()
  public titlePrefix: string | null = null;

  public ngOnInit() {
    if (this.user) {
      if (this.titlePrefix) {
        this.title = `${this.titlePrefix} ${this.user.name}`;
      } else {
        this.title = this.user.name;
      }
    } else {
      this.title = '';
    }
  }
}
