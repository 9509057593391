import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CanWriteBrokerComponent } from './create-broker/can-write-broker.component';

@Component({
  selector: 'of-brokers-nav-menu',
  standalone: true,
  imports: [CanWriteBrokerComponent, RouterModule],
  template: `
    <of-can-create-broker>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          BROKERS
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" [routerLink]="['/brokers']"><i class="bi bi-list"></i> LIST</a>
          </li>
          <li>
            <a class="dropdown-item" [routerLink]="['/brokers', 'create']"><i class="bi bi-plus-lg"></i> NEW</a>
          </li>
        </ul>
      </li>
    </of-can-create-broker>
  `
})
export class BrokersNavMenuComponent {}
