import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ORDER_STATUS_VALUES } from '../shared/code-name-value.model';
import { RouterModule } from '@angular/router';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { PortfolioWithGroupKey, UpdateMultiplePortfoliosRestrictionsFormConfig } from './portfolio.model';
import { PortfoliosService } from './portfolios.service';
import { CheckBoxSelectionService, DropDownListModule, MultiSelectModule } from '@syncfusion/ej2-angular-dropdowns';
import { JsonPipe } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { FormControlLabelComponent } from '../shared/form-control-label.component';
import { ValidationErrorComponent } from '../shared/validation-error.component';
import { BrowserModule } from '@angular/platform-browser';

@Component({
  selector: 'of-bulk-update-portfolio-restrictions',
  imports: [
    ReactiveFormsModule,
    RouterModule,
    TextBoxModule,
    MultiSelectModule,
    DropDownListModule,
    FormControlLabelComponent,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    SwitchModule
  ],
  template: `
    <form [formGroup]="form">
      <h5 mat-dialog-title class="p-3">BULK UPDATE PORTFOLIO RESTRICTIONS</h5>
      <div mat-dialog-content class="px-3 pb-3 d-flex flex-column gap-2">
        <div class="d-flex flex-column gap-1">
          <of-form-control-label>Portfolio Restrictions</of-form-control-label>
          <ejs-multiselect
            mode="CheckBox"
            [enableGroupCheckBox]="true"
            [dataSource]="portfolios"
            [fields]="{ text: 'shortName', value: 'internalName', groupBy: 'groupKey' }"
            formControlName="portfolioInternalNames"
          >
          </ejs-multiselect>
        </div>
      </div>
      <div mat-dialog-actions class="px-3 pb-3">
        <div class="d-flex justify-content-end gap-2">
          <button class="btn btn-outline-secondary" (click)="onCloseClick()">CLOSE</button>
          <button class="btn btn-danger" [disabled]="!form.valid" (click)="onExcludeClick()" cdkFocusInitial>
            EXCLUDE
          </button>
          <button class="btn btn-success" [disabled]="!form.valid" (click)="onWhitelistClick()" cdkFocusInitial>
            WHITELIST
          </button>
        </div>
      </div>
    </form>
  `,
  providers: [CheckBoxSelectionService]
})
export class BulkUpdatePortfolioRestrictionsComponent {
  public form: FormGroup = new FormGroup({});
  public portfolios: PortfolioWithGroupKey[] | null = [];

  constructor(
    public dialogRef: MatDialogRef<BulkUpdatePortfolioRestrictionsComponent>,
    private fb: FormBuilder,
    private portfoliosService: PortfoliosService
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group<UpdateMultiplePortfoliosRestrictionsFormConfig>({
      portfolioInternalNames: this.fb.control<string[] | null>(null, Validators.required)
    });

    this.portfoliosService.listPortfolios$().subscribe((portfolios) => {
      this.portfolios = portfolios.map((p) => ({
        ...p.portfolio,
        groupKey: p.portfolio.shortName.split('_')[0] || 'Other'
      }));
    });
  }

  public onExcludeClick(): void {
    this.dialogRef.close({ ...this.form.getRawValue(), action: 'exclude' });
  }

  public onWhitelistClick(): void {
    this.dialogRef.close({ ...this.form.getRawValue(), action: 'whitelist' });
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }
}
