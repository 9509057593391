import { JsonPipe, KeyValuePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'of-api-error-dialog',
    imports: [JsonPipe, KeyValuePipe, MatDialogModule],
    template: `
    <h1 mat-dialog-title>ERROR</h1>
    <div mat-dialog-content>
      @switch (data.status) {
        @case (400) {
          <!-- HTTP 400 -->
          <h4>Please fix the following validation errors:</h4>
          @if (jsonError) {
            @if (jsonError.errors) {
              <ul>
                @for (member of jsonError.errors | keyvalue; track $index) {
                  <li>
                    <strong>{{ member.key }}:</strong> {{ member.value | json }}
                  </li>
                }
              </ul>
            } @else {
              <p>{{ jsonError | json }}</p>
            }
          }
        }
        @case (429) {
          <!-- HTTP 429 -->
          <h4>Limit exceeded:</h4>
          @if (data.message) {
            <p>{{ data.message }}</p>
          } @else {
            <p>{{ data.error || data | json }}</p>
          }
        }
        @default {
          <!-- Other Errors -->
          <strong>HTTP {{ data.status }}:</strong> {{ textError || data.error || data | json }}
        }
      }
    </div>
    <div mat-dialog-actions>
      <button class="btn btn-outline-primary" [mat-dialog-close]="data" cdkFocusInitial>OK</button>
    </div>
  `
})
export class ApiErrorDialogComponent implements OnInit {
  public jsonError: any;
  public textError: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<ApiErrorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.error) {
      if (typeof data.error === 'string') {
        try {
          this.jsonError = JSON.parse(data.error);
        } catch (error) {
          console.error(error);
        }
      } else {
        this.jsonError = data.error;
      }
    }
  }

  async ngOnInit(): Promise<void> {
    if (!this.jsonError) {
      this.textError = await this.data;
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}
