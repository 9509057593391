import { Component, Input } from '@angular/core';
import { Approval } from '../../approve/approval.model';
import { DatePipe } from '@angular/common';
import { AvatarCardComponent } from '../../shared/avatar-card.component';
import { ApprovalStatusBadgeComponent } from '../../shared/approval-status-badge.component';

@Component({
  selector: 'of-order-approvals-card',
  standalone: true,
  imports: [ApprovalStatusBadgeComponent, AvatarCardComponent, DatePipe],
  template: `
    @if (approvals) {
      <div class="card">
        <div class="card-header px-2">
          <h5 class="card-title m-0">APPROVALS</h5>
        </div>
        <div class="card-body p-0">
          <table class="table m-0">
            <thead>
              <tr>
                <th>Approver</th>
                <th>Status</th>
                <th>Received</th>
                <th>Comments</th>
              </tr>
            </thead>
            <tbody>
              @for (approval of approvals; track $index) {
                <tr>
                  <td><of-avatar-card [user]="approval.approver" [showName]="true"></of-avatar-card></td>
                  <td><of-approval-status-badge [status]="approval.approvalStatus"></of-approval-status-badge></td>
                  <td>{{ approval.responseReceived | date: 'dd/MM/yyyy HH:mm' }}</td>
                  <td>
                    @if (approval.comments) {
                      <div [innerHTML]="approval.comments"></div>
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    }
  `
})
export class OrderApprovalsCardComponent {
  @Input()
  public approvals: Approval[] | null = null;
}
