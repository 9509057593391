import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Security, SecurityFormConfig } from '../security.model';
import {
  AutoCompleteComponent,
  AutoCompleteModule,
  ChangeEventArgs,
  DropDownListModule
} from '@syncfusion/ej2-angular-dropdowns';
import { SecuritiesService } from '../securities.service';
import { AsyncPipe } from '@angular/common';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NumericTextBoxModule, TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { MatButtonModule } from '@angular/material/button';
import { BasicHoldingWithBasicPortfolio } from '../../holdings/basic-holding-with-basic-portfolio.model';
import { HoldingsService } from '../../holdings/holdings.service';
import { getSecurityPriceNtbFormat } from '../../shared/utils';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import moment from 'moment';
import { capitalize, trimEnd } from 'lodash-es';
import { TYPE_TD } from '../../shared/constants';

@Component({
    selector: 'of-security-selector',
    imports: [
        AsyncPipe,
        AutoCompleteModule,
        CheckBoxModule,
        DatePickerModule,
        DropDownListModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        NumericTextBoxModule,
        ReactiveFormsModule,
        TextBoxModule
    ],
    templateUrl: './security-selector.component.html',
    styleUrl: './security-selector.component.css'
})
export class SecuritySelectorComponent implements AfterViewInit {
  public securityForm: FormGroup = new FormGroup({});
  public securities: Security[] | null = null;
  public fields = { text: 'name', value: 'faSecurityCode' };
  public lastCloseNtbFormat = '#,###.#####';
  public today = new Date();

  public tdTenorSuffixes = ['Day', 'Week', 'Month', 'Year'];
  public typeTd = TYPE_TD;

  @Output()
  public ready = new EventEmitter<void>();

  @ViewChild(AutoCompleteComponent, { static: true })
  public autoComplete: AutoCompleteComponent | null = null;

  constructor(
    private fb: FormBuilder,
    private securitiesService: SecuritiesService,
    private holdingsService: HoldingsService
  ) {}

  public createForm(): FormGroup {
    this.securityForm = this.fb.group<SecurityFormConfig>({
      class3: this.fb.control<string | null>(null),
      currency: this.fb.control<string | null>(null, Validators.required),
      eodCode: this.fb.control<string | null>(null, Validators.required),
      faSecurityCode: this.fb.control<string | null>(null, Validators.required),
      hasMonetaryBenefit: this.fb.control<boolean | null>(null),
      isin: this.fb.control<string | null>(null, Validators.required),
      lastClose: this.fb.control<number | null>(null),
      multiplier: this.fb.control<number | null>(null),
      name: this.fb.control<string | null>(null, Validators.required),
      portfoliosHoldingSecurity: this.fb.control<BasicHoldingWithBasicPortfolio[] | null>(null),
      tdBestRate: this.fb.control<boolean | null>(null),
      tdMaturityDate: this.fb.control<Date | null>(null),
      tdRate: this.fb.control<number | null>(null),
      tdTenorQty: this.fb.control<null | null>(null),
      tdTenorSuffix: this.fb.control<string | null>(null),
      tradeCode: this.fb.control<string | null>(null),
      type: this.fb.control<string | null>(null, Validators.required)
    });

    this.securityForm.get('type')?.valueChanges.subscribe((type) => {
      if (type === this.typeTd) {
        this.securityForm.get('tdTenorQty')?.enable();
        this.securityForm.get('tdTenorSuffix')?.enable();
        this.securityForm.get('tdMaturityDate')?.enable();
        this.securityForm.get('tdRate')?.enable();
        this.securityForm.get('tdBestRate')?.enable();
      } else {
        this.securityForm.get('tdTenorQty')?.disable();
        this.securityForm.get('tdTenorQty')?.reset();
        this.securityForm.get('tdTenorSuffix')?.disable();
        this.securityForm.get('tdTenorSuffix')?.reset();
        this.securityForm.get('tdMaturityDate')?.disable();
        this.securityForm.get('tdMaturityDate')?.reset();
        this.securityForm.get('tdRate')?.disable();
        this.securityForm.get('tdRate')?.reset();
        this.securityForm.get('tdBestRate')?.disable();
        this.securityForm.get('tdBestRate')?.reset();
      }
    });

    this.securityForm.get('tdBestRate')?.valueChanges.subscribe((checked) => {
      if (checked) {
        this.securityForm.get('tdRate')?.disable();
        this.securityForm.get('tdRate')?.reset();
      } else {
        this.securityForm.get('tdRate')?.enable();
      }
    });

    this.securityForm.disable();
    this.securityForm.get('faSecurityCode')?.enable();

    return this.securityForm;
  }

  public ngAfterViewInit(): void {
    this.securitiesService.listSecurities$().subscribe((securities) => {
      this.securities = securities;
      this.ready.emit();
    });
  }

  public onFiltering(e: any): void {
    e.preventDefaultAction = true;

    const predicate = new Predicate('eodCode', 'contains', e.text, true, true)
      .or('faSecurityCode', 'contains', e.text, true, true)
      .or('isin', 'contains', e.text, true, true)
      .or('name', 'contains', e.text, true, true)
      .or('tradeCode', 'contains', e.text, true, true);

    let query = new Query();
    query = e.text != '' ? query.where(predicate) : query;

    e.updateData(this.securities, query);
  }

  public onSecuritySelected(event: ChangeEventArgs): void {
    const selectedSecurity = event.itemData as Security;
    if (selectedSecurity) {
      this.securityForm.get('class3')?.setValue(selectedSecurity.class3);
      this.securityForm.get('currency')?.setValue(selectedSecurity.currency);
      this.securityForm.get('eodCode')?.setValue(selectedSecurity.eodCode);
      this.securityForm.get('hasMonetaryBenefit')?.setValue(selectedSecurity.hasMonetaryBenefit);
      this.securityForm.get('isin')?.setValue(selectedSecurity.isin);
      this.securityForm.get('lastClose')?.setValue(selectedSecurity.lastClose);
      this.securityForm.get('multiplier')?.setValue(selectedSecurity.multiplier);
      this.securityForm.get('name')?.setValue(selectedSecurity.name);
      this.securityForm.get('tradeCode')?.setValue(selectedSecurity.tradeCode);
      this.securityForm.get('type')?.setValue(selectedSecurity.type);

      this.loadLastClose(event.value as string, selectedSecurity.multiplier);
      this.loadPortfoliosHoldingSecurity(event.value as string);
    }
  }

  public setTimeDepositInitialValues(
    maturityDate: string | null,
    tenor: string | null,
    rate: number | null,
    bestRate: boolean | null
  ): void {
    if (moment(maturityDate).isValid()) {
      this.securityForm.get('tdMaturityDate')?.setValue(moment(maturityDate).toDate());
    }

    if (tenor) {
      const [qty, suffix] = tenor.split(' ');
      this.securityForm.get('tdTenorQty')?.setValue(qty);
      this.securityForm.get('tdTenorSuffix')?.setValue(capitalize(trimEnd(suffix, 's')));
    }

    this.securityForm.get('tdRate')?.setValue(rate);
    this.securityForm.get('tdBestRate')?.setValue(bestRate);
  }

  private loadLastClose(faSecurityCode: string, multiplier: number) {
    this.securitiesService.getLastClose$(faSecurityCode).subscribe((lastClose) => {
      this.lastCloseNtbFormat = getSecurityPriceNtbFormat(multiplier);
      this.securityForm.get('lastClose')?.setValue(lastClose);
    });
  }

  private loadPortfoliosHoldingSecurity(faSecurityCode: string): void {
    if (faSecurityCode) {
      this.holdingsService.listPortfoliosHoldingASpecificSecurity$(faSecurityCode).subscribe((portfolios) => {
        this.securityForm.get('portfoliosHoldingSecurity')?.setValue(portfolios);
      });
    } else {
      this.securityForm.get('portfoliosHoldingSecurity')?.setValue(null);
    }
  }
}
