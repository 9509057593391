import { Component, OnInit } from '@angular/core';
import { OrderFormComponent } from '../order-form/order-form.component';
import { Observable, map } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { FxOrderFormComponent } from '../fx-order-form/fx-order-form.component';
import { FX_FORWARD_SECURITY_TYPE, FX_SPOT_SECURITY_TYPE } from '../../shared/constants';

@Component({
  selector: 'of-edit-order',
  imports: [AsyncPipe, OrderFormComponent, FxOrderFormComponent],
  template: ` @if (!this.isFxOrder) {
      <of-order-form [orderToUpdateId]="orderToUpdateId$ | async"></of-order-form>
    } @else {
      <of-fx-order-form [orderToUpdateId]="orderToUpdateId$ | async"></of-fx-order-form>
    }`
})
export class EditOrderComponent implements OnInit {
  public orderToUpdateId$: Observable<number> | null = null;
  public isFxOrder: boolean = false;

  constructor(private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.orderToUpdateId$ = this.route.paramMap.pipe(map((params) => Number.parseInt(params.get('id') || '-1', 10)));
    this.route.queryParamMap
      .pipe(map((params) => params.get('securityType')))
      .subscribe((value) => (this.isFxOrder = this.isFx(value ?? '')));
  }

  public isFx(type: string): boolean {
    return type == FX_FORWARD_SECURITY_TYPE || type == FX_SPOT_SECURITY_TYPE;
  }
}
