import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import { User } from './user.model';
import { showErrorDialog } from './utils';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private apiEndpoint = `${environment.apiEndpoint}/api/users`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
    private storage: LocalStorageService
  ) {}

  public getUser$(email: string): Observable<User> {
    const user = this.storage.get<User>(`user_${email}`);

    return user
      ? of(user)
      : this.http.get<User>(`${this.apiEndpoint}/${email}`).pipe(
          tap((res) => this.storage.set(`user_${email}`, res)),
          catchError((error: any) => {
            this.logger.error(error);
            showErrorDialog(this.errorDialog, error);
            return throwError(() => new Error(error));
          })
        );
  }
}
