import { Injectable } from '@angular/core';
import { OrderWithDetailedExecutionInfo } from './order-with-execution-info.model';
import { Order } from '../order.model';
import { QUANTITY_TYPE_VALUES } from '../../shared/code-name-value.model';
import { OrdersService } from '../orders.service';
import { forkJoin, map, Observable, of } from 'rxjs';
import { OrderWithExecutionInfo } from '../bulk-execute-orders/order-with-execution-info.model';

@Injectable({
  providedIn: 'root'
})
export class BulkApproveExecutionsService {
  private _ordersToApprove: OrderWithExecutionInfo[] | null = null;

  public get ordersToExecute(): OrderWithExecutionInfo[] | null {
    return this._ordersToApprove;
  }

  public setOrdersToApprove(orders: Order[]) {
    this._ordersToApprove = orders
      ? orders.map((order) => ({
          ...order,
          executedQuantity: null,
          executedQuantityType: QUANTITY_TYPE_VALUES['quantity'].code,
          comments: null,
          transactionRef: null,
          useOrderInfo: false
        }))
      : null;
  }

  public clearOrdersToApprove() {
    this._ordersToApprove = null;
  }
}
