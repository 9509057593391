import { Component, Input } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';

@Component({
    selector: 'of-validation-error',
    imports: [],
    template: `
    @if (form && !form.pristine && form.get(field)?.hasError(validationRule)) {
      <span class="text-danger" style="font-size: 12px;"><ng-content></ng-content></span>
    }
  `
})
export class ValidationErrorComponent {
  @Input()
  public form: UntypedFormGroup | AbstractControl | null = null;

  @Input()
  public field: string = '';

  @Input()
  public validationRule: string = '';
}
