import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { OrderPermissionsService } from '../orders/order-permissions.service';
import { map } from 'rxjs';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import { ROLE_ORDER_APPROVER } from '../shared/constants';

export const canApproveOrderGuard: CanActivateFn = (route, state) => {
  if (!inject(OrderFlowAuthService).hasRole(ROLE_ORDER_APPROVER)) {
    return false;
  }

  return inject(OrderPermissionsService)
    .canApproveOrder$(route.params['id'])
    .pipe(map((canApprove) => canApprove.isAuthorized));
};
