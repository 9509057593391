import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Order } from '../order.model';
import { DecimalPipe, JsonPipe } from '@angular/common';
import { groupBy, orderBy, sumBy } from 'lodash-es';
import { User } from '../../shared/user.model';
import { CodeNameItem } from '../../shared/code-name-value.model';
import { Broker } from '../../brokers/broker.model';
import { Security } from '../../securities/security.model';
import { AvatarCardComponent } from '../../shared/avatar-card.component';
import { RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { PdfOrder, PdfOrdersByBroker } from '../pdf-order.model';
import { PreviewPdfComponent } from '../preview-pdf.component';
import { CIGP_AM_PORTFOLIO_LONG_NAME, TYPE_TD } from '../../shared/constants';

export interface OrdersByBroker {
  broker: Broker;
  byAccountNumbers: {
    accountNumber: string;
    byPreparers: {
      preparer: User;
      byApprovers: {
        approver: User;
        bySecurities: {
          security: Security;
          byTds: {
            td: string;
            byQuantityTypes: {
              quantityType: CodeNameItem;
              byTransactionTypes: {
                transactionType: string;
                byOrderTypes: {
                  orderType: string;
                  byLimitPrices: {
                    limitPrice: number;
                    byTifs: {
                      tif: string;
                      byRandomAccountNumbers: {
                        order: PdfOrder;
                        orders: Order[];
                        randomAccountNumber: string;
                      }[];
                    }[];
                  }[];
                }[];
              }[];
            }[];
          }[];
        }[];
      }[];
    }[];
  }[];
}

export interface ExtendedOrder extends Order {
  accountNumber: string;
  randomAccountNumber: string;
  tdRandomIdentifier: string;
}

@Component({
  selector: 'of-bulk-send-orders-dialog',
  standalone: true,
  imports: [AvatarCardComponent, DecimalPipe, JsonPipe, MatDialogModule, RouterModule],
  templateUrl: './bulk-send-orders-dialog.component.html'
})
export class BulkSendOrdersDialogComponent {
  public aggregatedOrders: OrdersByBroker[];
  public environment = environment.env;

  constructor(
    public dialogRef: MatDialogRef<BulkSendOrdersDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Order[],
    private pdfViewerDialog: MatDialog
  ) {
    const extendedData = data.map(
      (order: Order) =>
        ({
          ...order,
          accountNumber:
            order.isEamAccount && order.portfolio.externalName
              ? order.portfolio.externalName
              : CIGP_AM_PORTFOLIO_LONG_NAME,
          randomAccountNumber:
            order.isEamAccount && order.portfolio.externalName
              ? `${order.portfolio.externalName}^${Math.random().toString(36).substring(7)}` // EAM account orders should not be aggregated
              : CIGP_AM_PORTFOLIO_LONG_NAME,
          tdRandomIdentifier: order.security.type === TYPE_TD ? Math.random().toString(36).substring(7) : ''
        }) as ExtendedOrder
    );

    this.aggregatedOrders = orderBy(
      Object.entries(groupBy(extendedData, 'broker.id')).map(([kBroker, vBroker]) => ({
        broker: vBroker[0].broker,
        byAccountNumbers: orderBy(
          Object.entries(groupBy(vBroker, 'accountNumber')).map(([kAccountNumber, vAccountNumber]) => ({
            accountNumber: kAccountNumber,
            byPreparers: Object.entries(groupBy(vAccountNumber, 'createdBy.email')).map(([kPreparer, vPreparer]) => ({
              preparer: vPreparer[0].createdBy,
              byApprovers: Object.entries(groupBy(vPreparer, 'approver.email')).map(([kApprover, vApprover]) => ({
                approver: vApprover[0].approver,
                bySecurities: orderBy(
                  Object.entries(groupBy(vApprover, 'security.faSecurityCode')).map(([kSecurity, vSecurity]) => ({
                    security: vSecurity[0].security,
                    byTds: orderBy(
                      Object.entries(groupBy(vSecurity, 'tdRandomIdentifier')).map(([kTd, vTd]) => ({
                        td: vTd[0].tdRandomIdentifier || '',
                        byQuantityTypes: orderBy(
                          Object.entries(groupBy(vTd, 'quantityType.code')).map(([kQtyType, vQtyType]) => ({
                            quantityType: vQtyType[0].quantityType,
                            byTransactionTypes: orderBy(
                              Object.entries(groupBy(vQtyType, 'transactionType')).map(
                                ([kTransactionType, vTransactionType]) => ({
                                  transactionType: kTransactionType,
                                  byOrderTypes: orderBy(
                                    Object.entries(groupBy(vTransactionType, 'orderType')).map(
                                      ([kOrderType, vOrderType]) => ({
                                        orderType: kOrderType,
                                        byLimitPrices: Object.entries(groupBy(vOrderType, 'limitPrice')).map(
                                          ([kLimitPrice, vLimitPrice]) => ({
                                            limitPrice: Number(kLimitPrice),
                                            byTifs: orderBy(
                                              Object.entries(groupBy(vLimitPrice, 'tif')).map(([kTif, vTif]) => ({
                                                tif: kTif,
                                                byRandomAccountNumbers: Object.entries(
                                                  groupBy(vTif, 'randomAccountNumber')
                                                ).map(([kRandomAccountNumber, vRandomAccountNumber]) => ({
                                                  randomAccountNumber: kRandomAccountNumber,
                                                  order: {
                                                    ...vRandomAccountNumber[0],
                                                    quantity: sumBy(vRandomAccountNumber, 'quantity')
                                                  },
                                                  orders: orderBy(vRandomAccountNumber, 'id')
                                                }))
                                              })),
                                              'tif'
                                            )
                                          })
                                        )
                                      })
                                    ),
                                    'orderType'
                                  )
                                })
                              ),
                              'transactionType'
                            )
                          })),
                          'quantityType.name'
                        )
                      })),
                      'td'
                    )
                  })),
                  'security.name'
                )
              }))
            }))
          })),
          'accountNumber'
        )
      })),
      'broker.name'
    );
  }

  public onCancelClick(): void {
    this.dialogRef.close();
  }

  public onSendClick(): void {
    const ordersByBrokers: PdfOrdersByBroker[] = this.aggregatedOrders.map((byBroker) => ({
      broker: byBroker.broker,
      orders: byBroker.byAccountNumbers.map((byAccountNumber) => ({
        accountNumber: byAccountNumber.accountNumber,
        orders: byAccountNumber.byPreparers.flatMap((byPreparer) =>
          byPreparer.byApprovers.flatMap((byApprover) =>
            byApprover.bySecurities.flatMap((bySecurity) =>
              bySecurity.byTds.flatMap((byTd) =>
                byTd.byQuantityTypes.flatMap((byQtyType) =>
                  byQtyType.byTransactionTypes.flatMap((byTransactionType) =>
                    byTransactionType.byOrderTypes.flatMap((byOrderType) =>
                      byOrderType.byLimitPrices.flatMap((byLimitPrice) =>
                        byLimitPrice.byTifs.flatMap((byTif) =>
                          byTif.byRandomAccountNumbers.map((byRandomAccountNumber) => ({
                            ...byRandomAccountNumber.order,
                            constituentOrderIds: byRandomAccountNumber.orders.map((o) => o.id)
                          }))
                        )
                      )
                    )
                  )
                )
              )
            )
          )
        )
      }))
    }));

    this.dialogRef.close(ordersByBrokers);
  }

  public viewPdf(aggregatedOrder: PdfOrder): void {
    this.pdfViewerDialog.open(PreviewPdfComponent, {
      minWidth: '860px',
      data: { aggregatedOrder }
    });
  }
}
