import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormArray, FormControl, FormGroup, NG_ASYNC_VALIDATORS, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import * as i0 from '@angular/core';
import { Directive, Input, forwardRef, LOCALE_ID, Injectable, Inject, NgModule } from '@angular/core';
import * as i1 from '@angular/common';
import { getLocaleNumberSymbol, NumberSymbol, CommonModule, DecimalPipe } from '@angular/common';
class TypedForm {}
const ValidationAlphabetLocale = {
  'danish': 'danish',
  'french': 'french',
  'german': 'german',
  'greek': 'greek',
  'spanish': 'spanish',
  'russian': 'russian'
};
const CONTROLS_ERROR = "controlsError";
const VALUE_CHANGED_SYNC = "valueChangedSync";
const FUNCTION_STRING = "function";
const OBJECT_STRING = "object";
const RX_WEB_VALIDATOR = "rxwebValidator";
const NUMBER = "number";
const BOOLEAN$1 = "boolean";
const CUSTOM = "custom";
const TEMPLATE_VALIDATION_CONFIG = "template-validation-config";
const CONDITIONAL_VALIDATOR = "conditionalValidator";
const VALIDATOR_CONFIG$2 = "validatorConfig";
const THIS = "this";
const RXCODE = "-rxw-";
const MODEL = "model";
const MODEL_INSTANCE = "modelInstance";
const PATCH = "patch";
class Linq {
  static functionCreator(expression) {
    var functionSetter = [];
    var match = expression.match(/^\s*\(?\s*([^)]*)\s*\)?\s*=>(.*)/);
    var splitSelect = match[2].split(",");
    for (var i = 0; i < splitSelect.length; i++) {
      var equalToOperator = splitSelect[i].match(/^\s*\(?\s*([^)]*)\s*\)?\s*|===|!==|==|!=|>=|>|<=|<|(.*)/);
      if (equalToOperator !== null) {
        functionSetter = new Function(match[1], "return " + equalToOperator.input);
      } else {
        equalToOperator = splitSelect[i].match(/^\s*\(?\s*([^)]*)\s*\)?\s*=(.*)/);
        if (equalToOperator === null) {
          functionSetter = new Function(match[1], "return " + splitSelect.input);
        } else {
          functionSetter = new Function(match[1], "return " + equalToOperator.input);
        }
      }
    }
    if (splitSelect.length == 0) functionSetter = {
      accessFunction: new Function(match[1], "return " + match[2])
    };
    return functionSetter;
  }
  static execute(jObject, config, parentObject, modelInstance, isDynamicConfig) {
    let expressionFunction = isDynamicConfig ? config.dynamicConfig : config.conditionalExpression;
    let lastParam = isDynamicConfig ? config : modelInstance;
    if (parentObject && typeof expressionFunction == "string") expressionFunction = Linq.functionCreator(expressionFunction);
    if (parentObject && expressionFunction) return modelInstance && modelInstance.constructor !== Object ? expressionFunction.call(modelInstance, parentObject, jObject, lastParam) : expressionFunction(parentObject, jObject, lastParam);
    return true;
  }
  static getConditionPath(texts) {
    let path = "";
    for (var i = 1; i < texts.length; i++) path += texts.length - 1 == i ? texts[i].trim() : `${texts[i].trim()}.`;
    return path;
  }
  static expressionParser(expression, isNonValidationExpression) {
    let splitExpressions = [];
    let columns = [];
    let expressionString = expression.toString();
    let expressionArguments = Linq.extractArguments(expressionString);
    if (expressionArguments.length > 0) {
      let splitTexts = [];
      expressionString.replace(/\s/g, '').replace(new RegExp(/{|}/, "g"), "").split(new RegExp(/return|===|!==|==|!=|>=|>|<=|<|&&/)).forEach(t => {
        let texts = t.replace(/\(|\)/g, "").split("||");
        for (let text of texts) splitTexts.push(text);
      });
      splitTexts.forEach(t => {
        expressionArguments.forEach((x, i) => {
          t = t.trim();
          if (t.startsWith(x + '.')) {
            var splitText = t.split('.');
            if (splitText.length == 2 || splitText.length >= 2 && isNonValidationExpression) {
              if (!isNonValidationExpression) columns.push({
                propName: splitText[1].trim(),
                argumentIndex: i == 3 ? 0 : i == 2 ? 1 : i == 1 ? -1 : i
              });else columns.push({
                propName: this.getConditionPath(splitText),
                argumentIndex: i == 3 ? 0 : i == 2 ? 1 : i == 1 ? -1 : i
              });
            } else {
              var arrayProp = splitText[1].split('[');
              let jObject = {
                propName: splitText[splitText.length - 1].trim(),
                objectPropName: arrayProp[0],
                arrayIndex: arrayProp.length > 1 ? arrayProp[1].replace("]", "") : undefined,
                argumentIndex: i === 3 ? 0 : i === 2 ? 1 : i
              };
              columns.push(jObject);
            }
          }
        });
      });
    }
    return columns;
  }
  static extractArguments(splitText) {
    let expressionArguments = [THIS];
    if (splitText[0].trim() !== "(" && !splitText.trim().startsWith("function")) {
      let text = splitText[0].split("=>")[0];
      expressionArguments.push(text.trim().replace("(", "").replace(")", ""));
    } else {
      let splitTexts = splitText.match(/\(([^)]+)\)/g);
      if (splitTexts && splitTexts[0]) splitTexts[0].split(",").forEach(t => expressionArguments.push(t.trim().replace("(", "").replace(")", "")));
    }
    return expressionArguments;
  }
  static expressionColumns(expression, isNonValidationExpression = false) {
    var columns = [];
    let splitExpressions = [];
    if (typeof expression == "string") {
      expression.split("=>")[1].split(" && ").forEach(t => {
        t.split(" || ").forEach(x => {
          splitExpressions.push(x.trim().split(' ')[0]);
        });
      });
      splitExpressions.forEach(t => {
        var splitText = t.split('.');
        if (splitText.length == 2) columns.push({
          propName: splitText[1].trim()
        });else {
          var arrayProp = splitText[1].split('[');
          let jObject = {
            propName: splitText[splitText.length - 1].trim(),
            objectPropName: arrayProp[0],
            arrayIndex: arrayProp.length > 1 ? arrayProp[1].replace("]", "") : undefined
          };
          columns.push(jObject);
        }
      });
    } else {
      columns = Linq.expressionParser(expression, isNonValidationExpression);
    }
    return columns;
  }
  static dynamicConfigParser(expression, propName) {
    let controlNames = [];
    let expressionString = expression.toString();
    let expressionArguments = Linq.extractArguments(expressionString);
    let splitString = expressionString.replace(new RegExp(/\r?\n|\r|;/g), ' ').replace(/["%()\{}=\\?�`'#<>|,;:+-]+/g, " ").split(/ /g);
    if (expressionArguments.length > 3) expressionArguments.splice(expressionArguments.length - 1, 1);
    expressionArguments.forEach(t => {
      splitString.filter(x => x != `${t}.${propName}` && x.startsWith(`${t}.`)).forEach(x => {
        let split = x.split('.');
        if (split.length == 2) controlNames.push({
          propName: x.replace(`${t}.`, '')
        });else {
          var arrayProp = split[1].split('[');
          let jObject = {
            propName: split[split.length - 1].trim(),
            objectPropName: arrayProp[0],
            arrayIndex: arrayProp.length > 1 ? arrayProp[1].replace("]", "") : undefined
          };
          controlNames.push(jObject);
        }
      });
    });
    return controlNames;
  }
}
const AnnotationTypes = {
  numeric: 'numeric',
  required: 'required',
  minLength: 'minLength',
  maxLength: 'maxLength',
  minNumber: 'minNumber',
  maxNumber: 'maxNumber',
  pattern: 'pattern',
  password: 'password',
  compare: 'compare',
  minDate: 'minDate',
  maxDate: 'maxDate',
  alpha: 'alpha',
  alphaNumeric: 'alphaNumeric',
  email: 'email',
  hexColor: 'hexColor',
  lowerCase: 'lowerCase',
  url: 'url',
  upperCase: 'upperCase',
  nested: 'nested',
  propArray: 'propArray',
  propObject: 'propObject',
  contains: 'contains',
  range: 'range',
  custom: 'custom',
  digit: "digit",
  creditCard: "creditCard",
  time: "time",
  json: "json",
  greaterThan: "greaterThan",
  greaterThanEqualTo: "greaterThanEqualTo",
  lessThan: "lessThan",
  lessThanEqualTo: "lessThanEqualTo",
  choice: "choice",
  different: "different",
  even: "even",
  odd: "odd",
  factor: "factor",
  leapYear: "leapYear",
  allOf: "allOf",
  oneOf: "oneOf",
  noneOf: "noneOf",
  mac: "mac",
  ascii: "ascii",
  dataUri: "dataUri",
  port: "port",
  latLong: "latLong",
  extension: "extension",
  fileSize: "fileSize",
  endsWith: "endsWith",
  startsWith: "startsWith",
  primeNumber: "primeNumber",
  latitude: "latitude",
  longitude: "longitude",
  compose: "compose",
  rule: "rule",
  file: "file",
  image: "image",
  unique: "unique",
  notEmpty: "notEmpty",
  ip: "ip",
  cusip: "cusip",
  grid: "grid",
  date: 'date',
  and: 'and',
  or: 'or',
  not: 'not',
  minTime: 'minTime',
  maxTime: 'maxTime',
  requiredTrue: 'requiredTrue',
  mask: 'mask',
  iban: 'iban',
  updateOn: 'updateOn'
};
const INVALID = "INVALID";
const PROPERTY = "property";
const OBJECT_PROPERTY = "objectProperty";
const ARRAY_PROPERTY = "arrayProperty";
const STRING = "string";
const MESSAGE = "message";
const BLANK = "";
const KEYPRESS = "onkeypress";
const ONCHANGE = "onchange";
const ONCLICK = "onclick";
const ONKEYUP = "onkeyup";
const ONBLUR = "onblur";
const ONFOCUS = "onfocus";
const ELEMENT_VALUE = "value";
const BLUR = "blur";
const FOCUS = "focus";
const CHANGE = "change";
const KEY_DOWN = "keydown";
const KEY_PRESS = "keypress";
const PASTE = "paste";
const INPUT = "INPUT";
const SELECT = "SELECT";
const CHECKBOX = "checkbox";
const RADIO = "radio";
const FILE = "file";
const TEXTAREA = "textarea";
const DECORATORS = {
  disabled: 'disabled',
  error: 'error',
  trim: 'trim',
  ltrim: 'ltrim',
  rtrim: 'rtrim',
  blacklist: 'blacklist',
  stripLow: 'stripLow',
  toBoolean: 'toBoolean',
  toDate: 'toDate',
  toDouble: 'toDouble',
  toFloat: 'toFloat',
  toInt: 'toInt',
  string: 'toString',
  whitelist: 'whitelist',
  escape: 'escape',
  prefix: 'prefix',
  suffix: 'suffix',
  sanitize: 'sanitize',
  elementClass: 'elementClass',
  updateOn: 'updateOn'
};
const defaultContainer = new class {
  constructor() {
    this.instances = [];
    this.modelIncrementCount = 0;
  }
  get(instanceFunc) {
    let instance = this.instances.filter(instance => instance.instance === instanceFunc)[0];
    return instance;
  }
  getInstance(target, parameterIndex, propertyKey, decoratorType) {
    let isPropertyKey = propertyKey != undefined;
    let instanceFunc = !isPropertyKey ? target : target.constructor;
    let instance = this.instances.filter(instance => instance.instance === instanceFunc)[0];
    if (!instance) instance = this.addInstanceContainer(instanceFunc);
    return instance;
  }
  addPropsConfig(target, configs) {
    let instanceContainer = this.instances.filter(instance => instance.instance == target)[0];
    if (instanceContainer) {
      for (let config of configs) {
        for (let prop of config.propNames) {
          let propertyInfo = instanceContainer.properties.filter(t => t.name == prop && t.propertyType !== OBJECT_PROPERTY && t.propertyType !== ARRAY_PROPERTY)[0];
          if (propertyInfo) {
            this.addPropConfig(target, [propertyInfo], config);
          } else if (prop === ":all:") this.addPropConfig(target, instanceContainer.properties.filter(t => t.propertyType !== OBJECT_PROPERTY && t.propertyType !== ARRAY_PROPERTY), config);
        }
      }
    } else if (configs === undefined) this.addInstanceContainer(target);
  }
  addPropConfig(target, properties, config) {
    for (var propertyInfo of properties) {
      let excludeProp = false;
      if (config.excludePropNames) excludeProp = config.excludePropNames.filter(t => t == propertyInfo.name)[0] !== undefined;
      if (!excludeProp) {
        if (config.validationConfig) for (let typeName in config.validationConfig) {
          this.init({
            constructor: target
          }, 0, propertyInfo.name, typeName, config.validationConfig[typeName] === true ? undefined : config.validationConfig[typeName], false);
        }
        if (config.error) this.addDecoratorConfig({
          constructor: target
        }, 0, propertyInfo.name, config.error, DECORATORS.error);
        if (config.disable) this.addDecoratorConfig({
          constructor: target
        }, 0, propertyInfo.name, config.disable, DECORATORS.disabled);
        if (config.elementClass) this.addDecoratorConfig({
          constructor: target
        }, 0, propertyInfo.name, config.elementClass, DECORATORS.elementClass);
        if (config.ignore) propertyInfo.ignore = config.ignore;
      }
    }
  }
  addSanitizer(target, parameterIndex, propertyKey, decoratorType, value) {
    let instance = this.getInstance(target, parameterIndex, propertyKey, decoratorType);
    if (instance) {
      if (!instance.sanitizers[propertyKey]) instance.sanitizers[propertyKey] = [];
      instance.sanitizers[propertyKey].push({
        name: decoratorType,
        config: value
      });
    }
  }
  addDecoratorConfig(target, parameterIndex, propertyKey, config, decoratorType) {
    let isPropertyKey = propertyKey != undefined;
    let instanceFunc = !isPropertyKey ? target : target.constructor;
    let instance = this.instances.filter(instance => instance.instance === instanceFunc)[0];
    if (!instance) instance = this.addInstanceContainer(instanceFunc);
    instance.nonValidationDecorators[decoratorType].conditionalExpressions[propertyKey] = config.conditionalExpression;
    let columns = Linq.expressionColumns(config.conditionalExpression, true);
    columns.forEach(column => {
      if (column.argumentIndex !== -1) {
        let columnName = !column.objectPropName ? `${column.propName}${RXCODE}${column.argumentIndex}` : `${column.objectPropName}.${column.propName}${RXCODE}${column.argumentIndex}`;
        if (!instance.nonValidationDecorators[decoratorType].changeDetection[columnName]) instance.nonValidationDecorators[decoratorType].changeDetection[columnName] = [];
        let disabledColumns = instance.nonValidationDecorators[decoratorType].changeDetection[columnName];
        if (disabledColumns.indexOf(columnName) === -1) disabledColumns.push(propertyKey);
      } else {
        if (!instance.nonValidationDecorators[decoratorType].controlProp[propertyKey]) instance.nonValidationDecorators[decoratorType].controlProp[propertyKey] = {};
        instance.nonValidationDecorators[decoratorType].controlProp[propertyKey][column.propName.replace(";", "")] = true;
      }
    });
  }
  init(target, parameterIndex, propertyKey, annotationType, config, isAsync) {
    var decoratorConfiguration = {
      propertyIndex: parameterIndex,
      propertyName: propertyKey,
      annotationType: annotationType,
      config: config,
      isAsync: isAsync,
      isValidator: annotationType !== "updateOn"
    };
    let isPropertyKey = propertyKey != undefined;
    this.addAnnotation(!isPropertyKey ? target : target.constructor, decoratorConfiguration);
  }
  initPropertyObject(name, propertyType, entity, target, config) {
    var propertyInfo = {
      name: name,
      propertyType: propertyType,
      entity: entity,
      dataPropertyName: config ? config.name : undefined,
      entityProvider: config ? config.entityProvider : undefined,
      defaultValue: config ? config.defaultValue : undefined,
      objectConfig: config && config.autoCreate ? {
        autoCreate: config.autoCreate
      } : undefined
    };
    defaultContainer.addProperty(target.constructor, propertyInfo);
  }
  addInstanceContainer(instanceFunc) {
    let instanceContainer = {
      instance: instanceFunc,
      propertyAnnotations: [],
      properties: [],
      nonValidationDecorators: {
        disabled: {
          conditionalExpressions: {},
          changeDetection: {},
          controlProp: {}
        },
        error: {
          conditionalExpressions: {},
          changeDetection: {},
          controlProp: {}
        },
        elementClass: {
          conditionalExpressions: {},
          changeDetection: {},
          controlProp: {}
        }
      },
      sanitizers: {}
    };
    this.instances.push(instanceContainer);
    return instanceContainer;
  }
  addProperty(instanceFunc, propertyInfo, isFromAnnotation = false) {
    let instance = this.instances.filter(instance => instance.instance === instanceFunc)[0];
    if (instance) {
      this.addPropertyInfo(instance, propertyInfo, !isFromAnnotation);
    } else {
      instance = this.addInstanceContainer(instanceFunc);
      this.addPropertyInfo(instance, propertyInfo);
    }
  }
  addPropertyInfo(instance, propertyInfo, isAddProperty = false) {
    var property = this.getProperty(instance, propertyInfo);
    if (!property) instance.properties.push(propertyInfo);else if (isAddProperty) this.updateProperty(property, propertyInfo);
    if (property && propertyInfo.messageNexus) property.messageNexus = propertyInfo.messageNexus;
  }
  addAnnotation(instanceFunc, decoratorConfiguration) {
    this.addProperty(instanceFunc, {
      propertyType: PROPERTY,
      name: decoratorConfiguration.propertyName
    }, true);
    let instance = this.instances.filter(instance => instance.instance === instanceFunc)[0];
    if (instance) instance.propertyAnnotations.push(decoratorConfiguration);else {
      instance = this.addInstanceContainer(instanceFunc);
      instance.propertyAnnotations.push(decoratorConfiguration);
    }
    if (decoratorConfiguration.config && decoratorConfiguration.config.conditionalExpression) {
      let columns = Linq.expressionColumns(decoratorConfiguration.config.conditionalExpression);
      this.addChangeValidation(instance, decoratorConfiguration.propertyName, columns);
    }
    if (decoratorConfiguration.config && decoratorConfiguration.config.dynamicConfig) {
      let columns = Linq.dynamicConfigParser(decoratorConfiguration.config.dynamicConfig, decoratorConfiguration.propertyName);
      this.addChangeValidation(instance, decoratorConfiguration.propertyName, columns);
    }
    this.setConditionalColumns(instance, decoratorConfiguration);
  }
  setConditionalColumns(instance, decoratorConfiguration) {
    if (instance && decoratorConfiguration.config) {
      if (decoratorConfiguration.annotationType == AnnotationTypes.and || decoratorConfiguration.annotationType == AnnotationTypes.or || decoratorConfiguration.annotationType == AnnotationTypes.not) {
        Object.keys(decoratorConfiguration.config.validation).forEach(t => {
          if (typeof decoratorConfiguration.config.validation[t] !== "boolean") this.setLogicalConditional(instance, t, decoratorConfiguration.config.validation[t].fieldName, decoratorConfiguration.propertyName);
        });
      } else this.setLogicalConditional(instance, decoratorConfiguration.annotationType, decoratorConfiguration.config.fieldName, decoratorConfiguration.propertyName);
    }
  }
  setLogicalConditional(instance, annotationType, fieldName, propertyName) {
    if (instance && (annotationType == AnnotationTypes.compare || annotationType == AnnotationTypes.greaterThan || annotationType == AnnotationTypes.greaterThanEqualTo || annotationType == AnnotationTypes.lessThan || annotationType == AnnotationTypes.lessThanEqualTo || annotationType == AnnotationTypes.different || annotationType == AnnotationTypes.factor || annotationType == AnnotationTypes.minTime || annotationType == AnnotationTypes.maxTime || annotationType == AnnotationTypes.creditCard && fieldName || (annotationType == AnnotationTypes.minDate || annotationType == AnnotationTypes.maxDate) && fieldName)) {
      this.setConditionalValueProp(instance, fieldName, propertyName);
    }
  }
  setConditionalValueProp(instance, propName, refPropName) {
    if (propName) {
      let splitProps = propName.split ? propName.split('.') : '';
      if (splitProps.length < 2) {
        if (!instance.conditionalValidationProps) instance.conditionalValidationProps = {};
        if (!instance.conditionalValidationProps[propName]) instance.conditionalValidationProps[propName] = [];
        if (instance.conditionalValidationProps[propName].indexOf(refPropName) == -1) instance.conditionalValidationProps[propName].push(refPropName);
      } else this.addChangeValidation(instance, refPropName, [{
        argumentIndex: 1,
        objectPropName: splitProps[0],
        propName: splitProps[1],
        referencePropName: refPropName
      }]);
    }
  }
  addChangeValidation(instance, propertyName, columns) {
    if (instance) {
      if (!instance.conditionalValidationProps) instance.conditionalValidationProps = {};
      columns.forEach(t => {
        if (t.propName && !t.objectPropName) {
          if (!instance.conditionalValidationProps[t.propName]) instance.conditionalValidationProps[t.propName] = [];
          if (instance.conditionalValidationProps[t.propName].indexOf(propertyName) == -1) instance.conditionalValidationProps[t.propName].push(propertyName);
        } else {
          if (t.propName && t.objectPropName) {
            if (!instance.conditionalObjectProps) instance.conditionalObjectProps = [];
            t.referencePropName = propertyName;
            instance.conditionalObjectProps.push(t);
          }
        }
      });
    }
  }
  clearInstance(instanceFunc) {
    let instance = this.instances.filter(instance => instance.instance === instanceFunc)[0];
    if (instance) {
      let indexOf = this.instances.indexOf(instance);
      this.instances.splice(indexOf, 1);
    }
  }
  getProperty(instance, propertyInfo) {
    return instance.properties.filter(t => t.name == propertyInfo.name)[0];
  }
  updateProperty(property, currentProperty) {
    property.dataPropertyName = currentProperty.dataPropertyName;
    property.defaultValue = currentProperty.defaultValue;
  }
}();
function baseDecoratorFunction(annotationType, config, isAsync = false) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.init(target, parameterIndex, propertyKey, annotationType, config, isAsync);
  };
}
const RegExRule = {
  alpha: /^[a-zA-Z]+$/,
  alphaExits: /[a-zA-Z]/,
  alphaWithSpace: /^[a-zA-Z\s]+$/,
  macId: /^([0-9a-fA-F][0-9a-fA-F]:){5}([0-9a-fA-F][0-9a-fA-F])$/,
  onlyDigit: /^[0-9]+$/,
  isDigitExits: /[0-9]/,
  lowerCase: /[a-z]/,
  upperCase: /[A-Z]/,
  specialCharacter: /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
  advancedEmail: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  basicEmail: /^(([^<>()\[\]\\.,,:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  alphaNumeric: /^[0-9a-zA-Z]+$/,
  alphaNumericWithSpace: /^[0-9a-zA-Z\s]+$/,
  hexColor: /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i,
  strictHexColor: /^#?([0-9A-F]{3}|[0-9A-F]{6})$/i,
  float: /^(?:[-+]?(?:[0-9]+))?(?:\.[0-9]*)?(?:[eE][\+\-]?(?:[0-9]+))?$/,
  decimal: /^[-+]?([0-9]+|\.[0-9]+|[0-9]+\.[0-9]+)$/,
  hexaDecimal: /^[0-9A-F]+$/i,
  date: /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/,
  time: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
  timeWithSeconds: /^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
  url: /^(https?:\/\/(?:www\.|(?!www)|(?!a-zA-Z))[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www)|(?!a-zA-Z))[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]\.[^\s]{2,})$/,
  localhostUrl: /^(https?:\/\/localhost\:([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])|localhost\::([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])|https?:\/\/localhost\::([1-9][0-9]{0,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5]))$/,
  interanetUrl: /^(https?:\/\/[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9])$/,
  ascii: /^[\x00-\x7F]+$/,
  dataUri: /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z0-9-.!#$%*+.{}|~`]+=[a-z0-9-.!#$%*+.{}|~`]+)*)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*?)$/i,
  lat: /^\(?[+-]?(90(\.0+)?|[1-8]?\d(\.\d+)?)$/,
  long: /^\s?[+-]?(180(\.0+)?|1[0-7]\d(\.\d+)?|\d{1,2}(\.\d+)?)\)?$/,
  ipV4: /^(\d{1,3})\.(\d{1,3})\.(\d{1,3})\.(\d{1,3})$/,
  ipV6: /^((?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(:[a-fA-F\d]{1,4}){0,1}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(:[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(:[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:){1}(?:(:[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(:[a-fA-F\d]{1,4}){1,6}|:)|(?::((?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(%[0-9a-zA-Z]{1,})?$/,
  cidrV4: /^(3[0-2]|[12]?[0-9])$/,
  cidrV6: /^(12[0-8]|1[01][0-9]|[1-9]?[0-9])$/,
  cusip: /^[0-9A-Z]{9}$/,
  grid: /^[GRID:]*([0-9A-Z]{2})[-\s]*([0-9A-Z]{5})[-\s]*([0-9A-Z]{10})[-\s]*([0-9A-Z]{1})$/g
};
const ALPHABET = "alphabet";
const DIGIT = "digit";
const CONTAINS = "contains";
const LOWERCASE = "lowerCase";
const UPPERCASE = "upperCase";
const SPECIAL_CHARACTER = "specialCharacter";
const MIN_LENGTH = "minLength";
const MAX_LENGTH = "maxLength";
class RegexValidator {
  static isExits(value, regex) {
    return value.match(regex) != null;
  }
  static isValid(value, regex) {
    return regex.test(value);
  }
  static isNotBlank(value, isRemoveSpace = false) {
    return !isRemoveSpace ? value === 0 || value !== undefined && value !== null && value !== "" : value === 0 || value !== undefined && value !== null && String(value).trim() !== "";
  }
  static isValidPassword(passwordValidation, value) {
    let isValid = false;
    let jObject = {};
    let keyName = "status";
    let objectProperties = Object.getOwnPropertyNames(passwordValidation);
    for (let propertyName of objectProperties) {
      switch (propertyName) {
        case ALPHABET:
          isValid = RegexValidator.isExits(value, RegExRule.alphaExits);
          keyName = ALPHABET;
          break;
        case DIGIT:
          isValid = RegexValidator.isValid(value, RegExRule.isDigitExits);
          keyName = DIGIT;
          break;
        case CONTAINS:
          isValid = value.indexOf(passwordValidation[CONTAINS]) != -1;
          keyName = CONTAINS;
          break;
        case LOWERCASE:
          isValid = RegexValidator.isValid(value, RegExRule.lowerCase);
          keyName = LOWERCASE;
          break;
        case UPPERCASE:
          isValid = RegexValidator.isValid(value, RegExRule.upperCase);
          keyName = UPPERCASE;
          break;
        case SPECIAL_CHARACTER:
          isValid = RegexValidator.isExits(value, RegExRule.specialCharacter);
          keyName = SPECIAL_CHARACTER;
          break;
        case MIN_LENGTH:
          isValid = value.length >= passwordValidation[propertyName];
          keyName = MIN_LENGTH;
          break;
        case MAX_LENGTH:
          isValid = value.length <= passwordValidation[propertyName];
          keyName = MAX_LENGTH;
          break;
      }
      if (!isValid) break;
    }
    return {
      isValid: isValid,
      keyName: keyName
    };
  }
  static isZero(value) {
    return value == 0;
  }
  static commaRegex() {
    return new RegExp(",", "g");
  }
}
class ReactiveFormConfig {
  static set(jObject) {
    if (jObject) ReactiveFormConfig.json = jObject;
  }
  static get(path) {
    let jObject;
    if (ReactiveFormConfig.json) {
      let splitPath = path.split('.');
      for (let columnName of splitPath) {
        jObject = !jObject ? ReactiveFormConfig.json[columnName] : jObject[columnName];
        if (!jObject) break;
      }
    }
    return jObject;
  }
}
ReactiveFormConfig.i18n = {};
ReactiveFormConfig.number = {};
ReactiveFormConfig.json = {};
ReactiveFormConfig.autoInstancePush = false;
class ObjectMaker {
  static toJson(key, config, values, additional = {}) {
    ObjectMaker.setMessage();
    let message = config ? config.message : null;
    let messageKey = undefined;
    if (!message && config && config.messageKey) messageKey = config.messageKey;
    let messageText = message ? message : ReactiveFormConfig && ReactiveFormConfig.json && ReactiveFormConfig.json.validationMessage && ReactiveFormConfig.json.validationMessage[messageKey || key] ? ReactiveFormConfig.json.validationMessage[messageKey || key] : '';
    values.forEach((t, index) => {
      if (t instanceof Date) t = this.getDateString(t);
      messageText = messageText.replace(`{{${index}}}`, t);
    });
    if (config && config.messageNexus) Object.keys(config.messageNexus).forEach(propName => {
      messageText = messageText.replace(`{{${propName}}}`, config.messageNexus[propName]);
    });
    let jObject = {};
    jObject[key] = {
      message: messageText,
      refValues: values
    };
    if (config && config.isAddMessageKey) jObject["messageKey"] = messageKey;
    if (additional) {
      if (additional.min) jObject[key].min = additional.min;
      if (additional.max) jObject[key].max = additional.max;
    }
    return jObject;
  }
  static null() {
    return null;
  }
  static getPasswordMessage() {
    let messageKey = "password";
    return ReactiveFormConfig && ReactiveFormConfig.json && ReactiveFormConfig.json.validationMessage && ReactiveFormConfig.json.validationMessage[messageKey] ? ReactiveFormConfig.json.validationMessage[messageKey] : '';
  }
  static setMessage() {
    if (ReactiveFormConfig.i18n && ReactiveFormConfig.i18n.validationMessage && ObjectMaker.language !== ReactiveFormConfig.i18n.language) {
      if (!ReactiveFormConfig.json) ReactiveFormConfig.json = {};
      ReactiveFormConfig.json.validationMessage = ReactiveFormConfig.i18n.validationMessage();
      ObjectMaker.language = ReactiveFormConfig.i18n.language;
    }
  }
  static getDateString(value) {
    let seperator = ReactiveFormConfig && ReactiveFormConfig.json && ReactiveFormConfig.json.baseConfig && ReactiveFormConfig.json.baseConfig.seperator ? ReactiveFormConfig.json.baseConfig.seperator : "/";
    let dateFormat = ReactiveFormConfig && ReactiveFormConfig.json && ReactiveFormConfig.json.baseConfig && ReactiveFormConfig.json.baseConfig.dateFormat ? ReactiveFormConfig.json.baseConfig.dateFormat : "mdy";
    if (ReactiveFormConfig && ReactiveFormConfig.json && ReactiveFormConfig.json.internationalization && ReactiveFormConfig.json.internationalization.dateFormat && ReactiveFormConfig.json.internationalization.seperator) {
      seperator = ReactiveFormConfig.json.internationalization.seperator;
      dateFormat = ReactiveFormConfig.json.internationalization.dateFormat;
    }
    let result = '';
    let year = value.getFullYear().toString();
    let month = String(value.getMonth() + 1);
    let day = String(value.getDay());
    switch (dateFormat) {
      case 'ymd':
        result = "".concat(year, seperator, month, seperator, day);
        break;
      case 'dmy':
        result = "".concat(day, seperator, month, seperator, year);
        break;
      case 'mdy':
        result = "".concat(month, seperator, day, seperator, year);
        break;
    }
    return result;
  }
}
ObjectMaker.language = "";
function isObjectType(value) {
  return !(typeof value == "string" || typeof value === "number" || typeof value === "boolean" || value instanceof Date);
}
function isObject(value) {
  return Object.prototype.toString.call(value) === '[object Object]';
}
function clone(jsonObject) {
  let jObject = {};
  if (isObjectType(jsonObject)) {
    for (var columnName in jsonObject) {
      if (columnName != "formGroup") {
        if (Array.isArray(jsonObject[columnName])) {
          jObject[columnName] = [];
          for (let row of jsonObject[columnName]) {
            if (isObject(row)) jObject[columnName].push(clone(row));else jObject[columnName].push(row);
          }
        } else if (typeof jsonObject[columnName] == "object" && !(jsonObject[columnName] instanceof RegExp)) jObject[columnName] = clone(jsonObject[columnName]);else jObject[columnName] = jsonObject[columnName];
      }
    }
    return jObject;
  } else return jsonObject;
}
function merge(firstObject, secondObject) {
  for (var columnName in secondObject) {
    if (Array.isArray(secondObject[columnName])) {
      if (!firstObject[columnName]) firstObject[columnName] = [];
      for (let row of secondObject[columnName]) firstObject[columnName].push(clone(row));
    } else if (typeof firstObject[columnName] == "object" && !(firstObject[columnName] instanceof RegExp)) firstObject[columnName] = merge(firstObject[columnName], secondObject[columnName]);else firstObject[columnName] = secondObject[columnName];
  }
  return firstObject;
}
function isMatched(jsonObject, compareObject) {
  let isModified = false;
  for (var columnName in compareObject) {
    if (Array.isArray(jsonObject[columnName])) {
      for (var i = 0; i < jsonObject[columnName].length; i++) {
        isModified = isMatched(jsonObject[columnName][i], compareObject[columnName][i]);
      }
    } else if (typeof jsonObject[columnName] == "object" && !(jsonObject[columnName] instanceof RegExp)) isModified = isMatched(jsonObject[columnName], compareObject[columnName]);else isModified = !(jsonObject[columnName] == compareObject[columnName]);
    if (isModified) break;
  }
  return isModified;
}
const PROP_ARRAY = "propArray";
class RxFormArray extends FormArray {
  constructor(arrayObject, controls, validatorOrOpts, asyncValidator, arrayConfig) {
    super(controls, validatorOrOpts, asyncValidator);
    this.arrayObject = arrayObject;
    this.arrayConfig = arrayConfig;
    this._isModified = false;
    this._modified = [];
    this.cloneObject(arrayObject);
  }
  get isModified() {
    return this._isModified;
  }
  push(control, options = {
    isAddedInstance: false
  }) {
    let formGroup = this.root;
    if (this.arrayObject) if (control.modelInstance) {
      if (!options.isAddedInstance) this.arrayObject.push(control.modelInstance);else this.arrayObject[this.arrayObject.length] = control.modelInstance;
    }
    super.push(control);
    if (formGroup[VALUE_CHANGED_SYNC]) formGroup.valueChangedSync();
    this.patch();
    this.checkValidation();
  }
  patch() {
    this.checkModification();
    if (this.parent) this.parent[PATCH]();
  }
  resetForm(options) {
    if (options && options.index >= 0 && options.groupOption) {
      this.controls[options.index].resetForm(options.groupOption);
    } else {
      for (var i = 0; i < this._baseValue.length; i++) {
        if (this.controls[i] !== undefined) this.controls[i].resetForm({
          value: this._baseValue[i]
        });else if (options && options.pushFunction) {
          let formGroup = options.pushFunction(this._baseValue[i]);
          this.push(formGroup);
        }
      }
    }
  }
  commit() {
    this._baseValue = [];
    for (let formGroup of this.controls) {
      formGroup.commit();
      this._baseValue.push(clone(formGroup.value));
    }
    this.patch();
  }
  removeAt(index, options = {
    isRemovedInstance: false
  }) {
    let formGroup = this.root;
    if (!options.isRemovedInstance) this.arrayObject.splice(index, 1);else {
      for (var i = index; i < this.arrayObject.length - 1; i++) this.arrayObject[i] = this.arrayObject[i + 1];
      this.arrayObject.pop();
    }
    super.removeAt(index, options);
    if (formGroup[VALUE_CHANGED_SYNC]) formGroup.valueChangedSync();
    this.patch();
    this.checkValidation();
  }
  checkValidation() {
    setTimeout(() => {
      if (this.arrayConfig != undefined && this.arrayConfig.allowMaxIndex && this.length > this.arrayConfig.allowMaxIndex) this.setErrors(ObjectMaker.toJson(PROP_ARRAY, this.arrayConfig, [this.length, this.arrayConfig.allowMaxIndex]));else if (this.errors && this.errors[PROP_ARRAY]) delete this.errors[PROP_ARRAY];
    });
  }
  checkModification() {
    this._isModified = !(this._baseValue.length == this.controls.length);
    if (!this._isModified) for (var i = 0; i < this.controls.length; i++) {
      this._isModified = isMatched(this._baseValue[i], this.controls[i].value);
      if (this._isModified) break;
    }
  }
  cloneObject(value) {
    this._baseValue = [];
    for (let row of value) {
      this._baseValue.push(clone(row));
    }
  }
}
var NumericValueType;
(function (NumericValueType) {
  NumericValueType[NumericValueType["PositiveNumber"] = 1] = "PositiveNumber";
  NumericValueType[NumericValueType["NegativeNumber"] = 2] = "NegativeNumber";
  NumericValueType[NumericValueType["Both"] = 3] = "Both";
})(NumericValueType || (NumericValueType = {}));
var IpVersion;
(function (IpVersion) {
  IpVersion[IpVersion["V4"] = 1] = "V4";
  IpVersion[IpVersion["V6"] = 2] = "V6";
  IpVersion[IpVersion["AnyOne"] = 3] = "AnyOne";
})(IpVersion || (IpVersion = {}));
var ErrorMessageBindingStrategy;
(function (ErrorMessageBindingStrategy) {
  ErrorMessageBindingStrategy[ErrorMessageBindingStrategy["None"] = 0] = "None";
  ErrorMessageBindingStrategy[ErrorMessageBindingStrategy["OnSubmit"] = 1] = "OnSubmit";
  ErrorMessageBindingStrategy[ErrorMessageBindingStrategy["OnDirty"] = 2] = "OnDirty";
  ErrorMessageBindingStrategy[ErrorMessageBindingStrategy["OnTouched"] = 3] = "OnTouched";
  ErrorMessageBindingStrategy[ErrorMessageBindingStrategy["OnDirtyOrTouched"] = 4] = "OnDirtyOrTouched";
  ErrorMessageBindingStrategy[ErrorMessageBindingStrategy["OnDirtyOrSubmit"] = 5] = "OnDirtyOrSubmit";
  ErrorMessageBindingStrategy[ErrorMessageBindingStrategy["OnTouchedOrSubmit"] = 6] = "OnTouchedOrSubmit";
})(ErrorMessageBindingStrategy || (ErrorMessageBindingStrategy = {}));
var ResetFormType;
(function (ResetFormType) {
  ResetFormType[ResetFormType["ControlsOnly"] = 1] = "ControlsOnly";
  ResetFormType[ResetFormType["FormGroupsOnly"] = 2] = "FormGroupsOnly";
  ResetFormType[ResetFormType["FormArraysOnly"] = 3] = "FormArraysOnly";
  ResetFormType[ResetFormType["ControlsAndFormGroupsOnly"] = 4] = "ControlsAndFormGroupsOnly";
  ResetFormType[ResetFormType["DefinedPropsOnly"] = 5] = "DefinedPropsOnly";
  ResetFormType[ResetFormType["All"] = 6] = "All";
})(ResetFormType || (ResetFormType = {}));
const MODEL_INSTANCE_VALUE = "modelInstanceValue";
class ApplicationUtil {
  static getParentObjectValue(control) {
    if (control.parent) {
      let parent = this.parentObjectValue(control.parent);
      return parent.value;
    }
    return {};
  }
  static getParentModelInstanceValue(control) {
    if (control.parent) {
      let parent = this.parentObjectValue(control.parent);
      return parent[MODEL_INSTANCE_VALUE];
    }
    return {};
  }
  static getRootFormGroup(control) {
    if (control.parent) {
      return this.getRootFormGroup(control.parent);
    }
    return control;
  }
  static getParentControl(control) {
    if (control.parent) {
      let parent = this.parentObjectValue(control.parent);
      return parent;
    }
    return control;
  }
  static getFormControlName(control) {
    let controlName = '';
    if (control.parent) {
      for (var formControlName in control.parent.controls) {
        if (control.parent.controls[formControlName] == control) {
          controlName = formControlName;
          break;
        }
      }
    }
    return controlName;
  }
  static getParentFormArray(control) {
    if (control.parent && !(control.parent instanceof FormArray || control.parent instanceof RxFormArray)) {
      let parent = this.getParentFormArray(control.parent);
      return parent;
    }
    return control.parent;
  }
  static toLower(value) {
    if (value) return String(value).toLowerCase().trim();
    return value;
  }
  static getControl(fieldName, formGroup) {
    let splitText = fieldName.split('.');
    if (splitText.length > 1) {
      var formControl = formGroup;
      splitText.forEach((name, index) => {
        formControl = formControl.controls[name];
      });
      return formControl;
    } else return formGroup.controls[fieldName];
  }
  static getFormControl(fieldName, control) {
    let splitText = fieldName.split('.');
    if (splitText.length > 1 && control.parent) {
      var formControl = this.getParentControl(control);
      splitText.forEach((name, index) => {
        formControl = formControl.controls[name];
      });
      return formControl;
    }
    return control.parent ? control.parent.get([fieldName]) : undefined;
  }
  static parentObjectValue(control) {
    if (!control.parent) return control;else control = this.parentObjectValue(control.parent);
    return control;
  }
  static isNumeric(value) {
    return value - parseFloat(value) + 1 >= 0;
  }
  static notEqualTo(primaryValue, secondaryValue) {
    let firstValue = primaryValue === undefined || primaryValue === null ? "" : primaryValue;
    let secondValue = secondaryValue === undefined || secondaryValue === null ? "" : secondaryValue;
    if (firstValue instanceof Date && secondValue instanceof Date) return +firstValue != +secondValue;
    return firstValue != secondValue;
  }
  static numericValidation(allowDecimal, acceptValue) {
    let decimalSymbol;
    if (ReactiveFormConfig && ReactiveFormConfig.number) {
      decimalSymbol = ReactiveFormConfig.json && ReactiveFormConfig.json.allowDecimalSymbol ? ReactiveFormConfig.json.allowDecimalSymbol : ReactiveFormConfig.number.decimalSymbol;
    } else {
      decimalSymbol = ".";
    }
    acceptValue = acceptValue == undefined ? NumericValueType.PositiveNumber : acceptValue;
    let regex = /^[0-9]+$/;
    switch (acceptValue) {
      case NumericValueType.PositiveNumber:
        regex = !allowDecimal ? /^[0-9]+$/ : decimalSymbol == "." || decimalSymbol == undefined ? /^[0-9\.]+$/ : /^[0-9\,]+$/;
        break;
      case NumericValueType.NegativeNumber:
        regex = !allowDecimal ? /^[-][0-9]+$/ : decimalSymbol == "." || decimalSymbol == undefined ? /^[-][0-9\.]+$/ : /^[-][0-9\,]+$/;
        break;
      case NumericValueType.Both:
        regex = !allowDecimal ? /^[-|+]?[0-9]+$/ : decimalSymbol == "." || decimalSymbol == undefined ? /^[-|+]?[0-9\.]+$/ : /^[-|+]?[0-9\,]+$/;
        break;
    }
    return regex;
  }
  static configureControl(control, config, type) {
    if (!control.validatorConfig) {
      let jObject = {};
      jObject[type] = config;
      Object.assign(control, {
        validatorConfig: jObject
      });
    } else control.validatorConfig[type] = config;
  }
  static lowerCaseWithTrim(value) {
    return typeof value === "string" ? value.toLowerCase().trim() : String(value).toLowerCase().trim();
  }
  /** Check if a value is an object */
  static isObject(value) {
    return Object.prototype.toString.call(value) === '[object Object]';
  }
  /** Check if a value is an object */
  static isArray(value) {
    return Array.isArray(value);
  }
  static cloneValue(value) {
    return ApplicationUtil.isObject(value) ? ApplicationUtil.isArray(value) ? [...value] : {
      ...value
    } : value;
  }
}
function instanceProvider(instanceFunc, entityObject) {
  let instance = defaultContainer.get(instanceFunc);
  let prototype = entityObject ? entityObject.__proto__ : getInstance(instanceFunc, []).__proto__;
  if (prototype.__proto__) {
    let isLoop = false;
    do {
      isLoop = prototype.__proto__.constructor != Object;
      if (isLoop) {
        let extendClassInstance = defaultContainer.get(prototype.__proto__.constructor);
        instance = merge(clone(instance), clone(extendClassInstance));
        prototype = prototype.__proto__;
      }
    } while (isLoop);
  }
  return instance;
}
function getInstance(model, objectArguments) {
  let classInstance = Object.create(model.prototype);
  try {
    model.apply(classInstance, objectArguments);
  } catch (ex) {
    ///resolution of issue https://github.com/rxweb/rxweb/issues/188
    classInstance = Reflect.construct(model, objectArguments);
  }
  return classInstance;
}
class DisableProvider {
  constructor(decoratorType, entityObject) {
    this.decoratorType = decoratorType;
    this.entityObject = entityObject;
  }
  getFormGroupName(currentFormGroup) {
    let keyName = '';
    if (currentFormGroup.parent) for (var controlName of Object.keys(currentFormGroup.parent.controls)) if (currentFormGroup.parent.controls[controlName] == currentFormGroup) {
      keyName = controlName;
      break;
    }
    return keyName;
  }
  zeroArgumentProcess(control, columnName) {
    let disabledColumns = [];
    this.getDisabledColumns(control.parent, `${columnName}${RXCODE}0`, false).forEach(t => disabledColumns.push(t));
    let path = this.topControlPath(control, columnName);
    let splitPath = path.split(".");
    if (splitPath.length > 1) {
      let rootFormGroup = ApplicationUtil.getRootFormGroup(control);
      this.getDisabledColumns(rootFormGroup, `${path}${RXCODE}0`, true).forEach(t => disabledColumns.push(t));
      let controlPath = '';
      for (var i = 0; i < splitPath.length - 2; i++) {
        let controlName = splitPath[i];
        controlPath = `${path.replace(`${controlName}.`, '')}${RXCODE}-0`;
        if (rootFormGroup.controls[controlName]) {
          this.getDisabledColumns(rootFormGroup.controls[controlName], controlPath, true, controlName).forEach(t => disabledColumns.push(t));
          rootFormGroup = rootFormGroup.controls[controlName];
        }
      }
    }
    return disabledColumns;
  }
  getDisabledColumns(formGroup, columnName, isRoot, pathName = "") {
    if (formGroup[MODEL_INSTANCE]) {
      let instanceContainer = instanceProvider(formGroup[MODEL_INSTANCE].constructor, this.entityObject);
      return this.getChangeDetectionColumns(instanceContainer, columnName, isRoot, pathName);
    }
    return [];
  }
  getChangeDetectionColumns(instanceContainer, columnName, isRoot, pathName = "") {
    let conditionalDisableControls = [];
    let columns = instanceContainer.nonValidationDecorators[this.decoratorType].changeDetection[columnName];
    if (columns) {
      columns.forEach(t => {
        conditionalDisableControls.push({
          controlPath: pathName ? `${pathName}.${t}` : t,
          conditionalExpression: instanceContainer.nonValidationDecorators[this.decoratorType].conditionalExpressions[t],
          isRoot: isRoot
        });
      });
    }
    return conditionalDisableControls;
  }
  topControlPath(control, columnName) {
    if (control.parent) {
      let name = this.getFormGroupName(control.parent);
      if (name) {
        columnName = `${name}.${columnName}`;
        return this.topControlPath(control.parent, columnName);
      }
    }
    return columnName;
  }
  childControlDisabledExpression(formGroup, columnName, path = "") {
    let disabledColumns = [];
    if (formGroup[MODEL_INSTANCE]) {
      let instanceContainer = defaultContainer.get(formGroup[MODEL_INSTANCE].constructor);
      if (instanceContainer) {
        this.getChangeDetectionColumns(instanceContainer, columnName, true, path).forEach(t => disabledColumns.push(t));
        var props = instanceContainer.properties.filter(t => t.propertyType == OBJECT_PROPERTY);
        props.forEach(t => {
          if (formGroup.controls[t.name]) {
            let columns = this.getDisabledColumns(formGroup.controls[t.name], columnName, true, path ? `${path}.${t.name}` : `${t.name}`);
            columns.forEach(x => disabledColumns.push(x));
            this.childControlDisabledExpression(formGroup.controls[t.name], columnName, path ? `${path}.${t.name}` : `${t.name}`).forEach(y => disabledColumns.push(y));
          }
        });
      }
    }
    return disabledColumns;
  }
  oneArgumentProcess(control, columnName) {
    let path = this.topControlPath(control, columnName);
    let rootFormGroup = ApplicationUtil.getRootFormGroup(control);
    let childColumns = this.childControlDisabledExpression(rootFormGroup, path);
    return childColumns;
  }
}
const ISO_DATE_REGEX = /^(?:[\+-]?\d{4}(?!\d{2}\b))(?:(-?)(?:(?:0[1-9]|1[0-2])(?:\1(?:[12]\d|0[1-9]|3[01]))?|W(?:[0-4]\d|5[0-2])(?:-?[1-7])?|(?:00[1-9]|0[1-9]\d|[12]\d{2}|3(?:[0-5]\d|6[1-6])))(?:[T\s](?:(?:(?:[01]\d|2[0-3])(?:(:?)[0-5]\d)?|24\:?00)(?:[\.,]\d+(?!:))?)?(?:\2[0-5]\d(?:[\.,]\d+)?)?(?:[zZ]|(?:[\+-])(?:[01]\d|2[0-3]):?(?:[0-5]\d)?)?)?)?$/;
class DateProvider {
  isDate(value) {
    return value instanceof Date && !isNaN(value.valueOf());
  }
  getRegex(dateFormat) {
    var regExp;
    switch (dateFormat) {
      case 'ymd':
        regExp = "^(?:[0-9]{4})-(1[0-2]|0?[1-9])-(3[01]|[12][0-9]|0?[1-9])$";
        break;
      case 'dmy':
        regExp = "^(3[01]|[12][0-9]|0?[1-9])-(1[0-2]|0?[1-9])-(?:[0-9]{2})?[0-9]{2}$";
        break;
      case 'mdy':
        regExp = "^(1[0-2]|0?[1-9])-(3[01]|[12][0-9]|0?[1-9])-(?:[0-9]{2})?[0-9]{2}$";
        break;
    }
    return new RegExp(regExp);
  }
  regex(config) {
    var regExp;
    if (ReactiveFormConfig && ReactiveFormConfig.json && ReactiveFormConfig.json.internationalization && ReactiveFormConfig.json.internationalization.dateFormat && ReactiveFormConfig.json.internationalization.seperator) regExp = this.getRegex(config.dateFormat || ReactiveFormConfig.json.internationalization.dateFormat);else regExp = ReactiveFormConfig && ReactiveFormConfig.json && ReactiveFormConfig.json.baseConfig && ReactiveFormConfig.json.baseConfig.dateFormat ? this.getRegex(config.dateFormat || ReactiveFormConfig.json.baseConfig.dateFormat) : this.getRegex(config.dateFormat || "mdy");
    return regExp;
  }
  getDate(value, configDateFormat = undefined, isBaseFormat = false) {
    let year, month, day;
    if (!this.isDate(value)) {
      let seperator;
      let dateFormat;
      if (ISO_DATE_REGEX.test(value)) {
        return new Date(value);
      } else {
        seperator = ReactiveFormConfig && ReactiveFormConfig.json && ReactiveFormConfig.json.baseConfig && ReactiveFormConfig.json.baseConfig.seperator ? ReactiveFormConfig.json.baseConfig.seperator : "/";
        dateFormat = configDateFormat || ReactiveFormConfig && ReactiveFormConfig.json && ReactiveFormConfig.json.baseConfig && ReactiveFormConfig.json.baseConfig.dateFormat ? ReactiveFormConfig.json.baseConfig.dateFormat : "mdy";
      }
      if (!isBaseFormat && ReactiveFormConfig && ReactiveFormConfig.json && ReactiveFormConfig.json.internationalization && ReactiveFormConfig.json.internationalization.dateFormat && ReactiveFormConfig.json.internationalization.seperator) {
        seperator = ReactiveFormConfig.json.internationalization.seperator;
        dateFormat = configDateFormat || ReactiveFormConfig.json.internationalization.dateFormat;
      }
      switch (dateFormat) {
        case 'ymd':
          [year, month, day] = value.split(seperator).map(val => +val);
          break;
        case 'dmy':
          [day, month, year] = value.split(seperator).map(val => +val);
          break;
        case 'mdy':
          [month, day, year] = value.split(seperator).map(val => +val);
          break;
      }
      return new Date(year, month - 1, day);
    } else return value;
  }
  isValid(value, config) {
    if (config && config.isValid) return config.isValid(value);
    if (typeof value == "string") {
      // Fixed issue : https://github.com/rxweb/rxweb/issues/280 & feature request : https://github.com/rxweb/rxweb/issues/295
      if (config && config.allowISODate && ISO_DATE_REGEX.test(value)) return true;
      let seperator = '/';
      if (ReactiveFormConfig && ReactiveFormConfig.json && ReactiveFormConfig.json.baseConfig && ReactiveFormConfig.json.baseConfig.seperator) seperator = ReactiveFormConfig.json.baseConfig.seperator;
      if (ReactiveFormConfig.json && ReactiveFormConfig.json.internationalization && ReactiveFormConfig.json.internationalization.seperator) seperator = ReactiveFormConfig.json.internationalization.seperator;
      if (value.split(seperator).length !== 3) return false;
      value = value.replace(seperator, '-').replace(seperator, '-');
      return this.regex(config).test(value);
    } else return this.isDate(value);
  }
  getConfigDateValue(config) {
    let date = config.value;
    if (config.value && typeof config.value == "string") {
      date = this.getDate(config.value, config.dateFormat, true);
    }
    return date;
  }
  getCompareDate(config, control) {
    let date = this.getConfigDateValue(config);
    if (config.fieldName) {
      let checkControl = ApplicationUtil.getFormControl(config.fieldName, control);
      if (checkControl && checkControl.value) {
        date = this.getDate(checkControl.value, config.dateFormat);
      }
    }
    return date;
  }
}
function isNotBlank(value) {
  return value !== undefined && value !== null && value !== "";
}
function trim$1(value) {
  if (isNotBlank(value)) if (typeof value === "string") return value.trim();
  return value;
}
;
function ltrim$1(value) {
  if (isNotBlank(value)) if (typeof value === "string") return value.replace(/^\s+/g, '');
  return value;
}
function rtrim$1(value) {
  if (isNotBlank(value)) if (typeof value === "string") return value.replace(/\s+$/g, '');
  return value;
}
function blacklist$1(value, chars) {
  if (isNotBlank(value)) if (typeof value === "string") return value.replace(new RegExp('[$' + chars + ']+', 'g'), '');
  return value;
}
;
function stripLow$1(value, keepNewLines) {
  let chars = keepNewLines === true ? '\x00-\x09\x0B\x0C\x0E-\x1F\x7F' : '\x00-\x1F\x7F';
  return blacklist$1(value, chars);
}
function toBoolean$1(value, strict) {
  if (isNotBlank(value)) {
    if (strict) {
      return value === '1' || value === 'true';
    }
    return value !== '0' && value !== 'false' && value !== '';
  }
  return value;
}
function toFloat$1(value) {
  if (isNotBlank(value)) {
    var decimalSymbol = '.';
    if (ReactiveFormConfig && ReactiveFormConfig.number) {
      decimalSymbol = ReactiveFormConfig.json && ReactiveFormConfig.json.allowDecimalSymbol ? ReactiveFormConfig.json.allowDecimalSymbol : ReactiveFormConfig.number.decimalSymbol;
    }
    if (decimalSymbol == ',' && typeof value == "string") value = value.replace(',', '.');
    if (ApplicationUtil.isNumeric(value)) return parseFloat(value);
  }
  return null;
}
function toDouble$1(value) {
  return toFloat$1(value);
}
function toInt$1(value, radix) {
  if (isNotBlank(value)) if (ApplicationUtil.isNumeric(value)) return parseInt(value, radix || 10);
  return null;
}
function toString$1(value, radix) {
  if (isNotBlank(value)) return String(value);
  return value;
}
function whitelist$1(value, chars) {
  if (isNotBlank(value)) if (typeof value === "string") return value.replace(new RegExp(`[^${chars}]+`, 'g'), '');
  return value;
}
function toDate$1(value, config) {
  var dateProvider = new DateProvider();
  if (isNotBlank(value)) if (typeof value === "string" && dateProvider.isValid(value, config)) {
    value = dateProvider.getDate(value);
    return value;
  }
  return null;
}
function escape$1(value) {
  if (isNotBlank(value)) return value.replace(/&/g, '&amp;').replace(/"/g, '&quot;').replace(/'/g, '&#x27;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\//g, '&#x2F;').replace(/\\/g, '&#x5C;').replace(/`/g, '&#96;');
  return value;
}
function prefix$1(value, text) {
  if (isNotBlank(value)) return `${text}${value}`;
  return value;
}
function suffix$1(value, text) {
  if (isNotBlank(value)) return `${value}${text}`;
  return value;
}
function sanitize$1(value, config) {
  return config.custom(value);
}
const SANITIZERS = {
  trim: trim$1,
  ltrim: ltrim$1,
  rtrim: rtrim$1,
  blacklist: blacklist$1,
  stripLow: stripLow$1,
  toBoolean: toBoolean$1,
  toDouble: toDouble$1,
  toFloat: toFloat$1,
  toInt: toInt$1,
  'toString': toString$1,
  whitelist: whitelist$1,
  toDate: toDate$1,
  escape: escape$1,
  prefix: prefix$1,
  suffix: suffix$1,
  sanitize: sanitize$1
};
const DIRTY = "dirty";
const TOUCHED = "touched";
const UNTOUCHED = "untouched";
const PRISTINE = "pristine";
const PENDING = "pending";
class RxFormControl extends FormControl {
  constructor(formState, validator, asyncValidator, entityObject, baseObject, controlName, _sanitizers) {
    super(formState, validator, asyncValidator);
    this.entityObject = entityObject;
    this.baseObject = baseObject;
    this._sanitizers = _sanitizers;
    this._errorMessages = [];
    this._childColumns = [];
    this._refDisableControls = [];
    this._refMessageControls = [];
    this._refClassNameControls = [];
    this._isPassedExpression = false;
    this._dirty = false;
    this.backEndErrors = {};
    this.defineErrorsProperty();
    this._baseValue = formState === undefined ? null : this.getFormState(formState);
    this._isModified = false;
    this.keyName = controlName;
    this._validators = validator.validators;
    this._asyncValidators = validator.asyncValidators;
    this._errorMessageBindingStrategy = ReactiveFormConfig.get("reactiveForm.errorMessageBindingStrategy");
    if (this._sanitizers) {
      var floatSanitizer = this._sanitizers.filter(t => t.name == "toFloat")[0];
      if (floatSanitizer && this._baseValue && ReactiveFormConfig.number && ReactiveFormConfig.number.decimalSymbol == ",") {
        let baseValue = String(this._baseValue);
        if (baseValue.indexOf('.') != -1) {
          this._baseValue = baseValue.replace(".", ReactiveFormConfig.number.decimalSymbol);
          super.setValue(this._baseValue);
        }
      }
    }
  }
  get errorMessages() {
    if (!this._messageExpression) {
      if (this._errorMessages.length == 0 && this.errors) this.setControlErrorMessages();
    } else if (this._messageExpression && !this._isPassedExpression) return [];
    if (!this.errors && this._errorMessages.length > 0) this.setControlErrorMessages();
    if (this._language != this.getLanguage()) this.setControlErrorMessages();
    return this._errorMessages;
  }
  get errorMessage() {
    if (!this._messageExpression) {
      if (this._errorMessage == undefined && this.errors) this.setControlErrorMessages();
    } else if (this._messageExpression && !this._isPassedExpression) return undefined;
    if (!this.errors && this._errorMessage) this.setControlErrorMessages();
    if (this._language != this.getLanguage()) this.setControlErrorMessages();
    return this._errorMessage;
  }
  defineErrorsProperty() {
    Object.defineProperty(this, "errors", {
      configurable: true,
      get() {
        if (this._language && this._language != this.getLanguage() && this.validator) {
          this["errors"] = this.validator(this);
        }
        return this._errors;
      },
      set(value) {
        this._errors = value;
      }
    });
  }
  getFormState(value) {
    let baseValue = value;
    if (Array.isArray(value)) {
      baseValue = [];
      value.forEach(t => baseValue.push(t));
    }
    return baseValue;
  }
  get isModified() {
    return this._isModified;
  }
  getValidators() {
    return this.getValidatorSource(this._validators);
  }
  getAsyncValidators() {
    return this.getValidatorSource(this._asyncValidators);
  }
  getValidatorSource(validators) {
    if (validators) return Array.isArray(validators) ? [...validators] : [validators];
    return [];
  }
  setValidators(newValidator) {
    this._validators = newValidator;
    super.setValidators(newValidator);
  }
  setAsyncValidators(newValidator) {
    this._asyncValidators = newValidator;
    super.setAsyncValidators(newValidator);
  }
  setValue(value, options) {
    this.parent.changing = true;
    let parsedValue = this.getSanitizedValue(value);
    if (options && options.dirty) this.baseObject[this.keyName] = value;
    this.entityObject[this.keyName] = parsedValue;
    super.setValue(value, options);
    this.bindError();
    this.bindClassName();
    this.executeExpressions();
    this.callPatch();
    if (options && !options.updateChanged && this.root[VALUE_CHANGED_SYNC]) {
      this.root[VALUE_CHANGED_SYNC]();
    }
    this.parent.changing = false;
  }
  getControlValue() {
    return this.getSanitizedValue(this.value);
  }
  bindError() {
    if (this._messageExpression) this._isPassedExpression = this.executeExpression(this._messageExpression, this);
    this.setControlErrorMessages();
    var t = this;
    t["errors"] = this.errors;
  }
  bindClassName() {
    if (this.updateOnElementClass && typeof this.updateOnElementClass === "function") {
      let className = this.executeExpression(this._classNameExpression, this);
      let updateElement = this.updateOnElementClass;
      updateElement(className);
    }
  }
  setBackEndErrors(error) {
    Object.keys(error).forEach(key => this.backEndErrors[key] = error[key]);
    this.setControlErrorMessages();
  }
  clearBackEndErrors(errors) {
    if (!errors) this.backEndErrors = {};else Object.keys(errors).forEach(t => delete this.backEndErrors[t]);
    this.setControlErrorMessages();
  }
  markAsTouched(opts) {
    let currentState = this.touched;
    super.markAsTouched(opts);
    if (currentState != this.touched) this.runControlPropChangeExpression([TOUCHED, UNTOUCHED]);
  }
  markAsUntouched(opts) {
    let currentState = this.untouched;
    super.markAsUntouched(opts);
    if (currentState != this.untouched) this.runControlPropChangeExpression([UNTOUCHED, TOUCHED]);
  }
  markAsDirty(opts) {
    let currentState = this._dirty;
    super.markAsDirty(opts);
    this._dirty = true;
    if (currentState != this._dirty) this.runControlPropChangeExpression([DIRTY]);
  }
  markAsPristine(opts) {
    let currentState = this.pristine;
    super.markAsPristine(opts);
    if (currentState != this.pristine) this.runControlPropChangeExpression([PRISTINE]);
  }
  markAsPending(opts) {
    let currentState = this.pending;
    super.markAsDirty(opts);
    if (currentState != this.pending) this.runControlPropChangeExpression([PENDING]);
  }
  runControlPropChangeExpression(propNames) {
    propNames.forEach(name => {
      if (this._controlProp && this._messageExpression && this._controlProp[name] || !this._messageExpression && this.checkErrorMessageStrategy()) this.bindError();
      if (this._classNameControlProp && this._classNameControlProp[name]) this.bindClassName();
    });
  }
  refresh() {
    this.getMessageExpression(this.parent, this.keyName);
    this.bindConditionalControls(DECORATORS.disabled, "_refDisableControls");
    this.bindConditionalControls(DECORATORS.error, "_refMessageControls");
    this.bindConditionalControls(DECORATORS.elementClass, "_refClassNameControls");
    this.executeExpressions();
    this.bindError();
  }
  reset(value, options = {}) {
    if (value !== undefined) this.setValue(value, options);else this.setValue(this.getFormState(this._baseValue), options);
    this._dirty = false;
  }
  commit() {
    this._baseValue = this.value;
    this.callPatch();
  }
  callPatch() {
    this._isModified = this.getValue(this._baseValue) != this.getValue(this.value);
    if (this.parent && this.parent[PATCH]) this.parent[PATCH](this.keyName);
  }
  checkErrorMessageStrategy() {
    let isBind = true;
    switch (this._errorMessageBindingStrategy) {
      case ErrorMessageBindingStrategy.OnSubmit:
        isBind = this.parent.submitted;
        break;
      case ErrorMessageBindingStrategy.OnDirty:
        isBind = this._dirty;
        break;
      case ErrorMessageBindingStrategy.OnTouched:
        isBind = this.touched;
        break;
      case ErrorMessageBindingStrategy.OnDirtyOrTouched:
        isBind = this._dirty || this.touched;
        break;
      case ErrorMessageBindingStrategy.OnDirtyOrSubmit:
        isBind = this._dirty || this.parent.submitted;
        break;
      case ErrorMessageBindingStrategy.OnTouchedOrSubmit:
        isBind = this.touched || this.parent.submitted;
        break;
      default:
        isBind = true;
    }
    return isBind;
  }
  executeExpressions() {
    this.processExpression("_refDisableControls", "disabled");
    this.processExpression("_refMessageControls", "bindError");
    this.processExpression("_refClassNameControls", "bindClassName");
  }
  getMessageExpression(formGroup, keyName) {
    if (formGroup[MODEL_INSTANCE]) {
      let instanceContainer = defaultContainer.get(formGroup[MODEL_INSTANCE].constructor);
      if (instanceContainer) {
        this._messageExpression = instanceContainer.nonValidationDecorators.error.conditionalExpressions[keyName];
        this._controlProp = instanceContainer.nonValidationDecorators.error.controlProp[this.keyName];
        this._classNameExpression = instanceContainer.nonValidationDecorators.elementClass.conditionalExpressions[keyName];
        this._classNameControlProp = instanceContainer.nonValidationDecorators.elementClass.controlProp[keyName];
        if (this._classNameExpression) this.updateOnElementClass = true;
      }
    }
  }
  getSanitizedValue(value) {
    if (this._sanitizers) {
      for (let sanitizer of this._sanitizers) {
        value = SANITIZERS[sanitizer.name](value, sanitizer.config);
      }
    }
    return value;
  }
  bindConditionalControls(decoratorType, refName) {
    this._disableProvider = new DisableProvider(decoratorType, this.entityObject);
    this[refName] = this._disableProvider.zeroArgumentProcess(this, this.keyName);
    this._disableProvider.oneArgumentProcess(this, `${this.keyName}${RXCODE}1`).forEach(t => this[refName].push(t));
  }
  setControlErrorMessages() {
    if (!this._messageExpression && this.checkErrorMessageStrategy() || this._isPassedExpression) {
      this._errorMessages = [];
      if (this.errors) {
        Object.keys(this.errors).forEach(t => {
          if (this.parent) {
            this.parent[CONTROLS_ERROR][this.keyName] = this._errorMessage = this.getErrorMessage(this.errors, t);
            if (!this._errorMessage) {
              let errorObject = ObjectMaker.toJson(t, undefined, [this.errors[t][t]]);
              this.parent[CONTROLS_ERROR][this.keyName] = this._errorMessage = this.getErrorMessage(errorObject, t);
            }
          } else this._errorMessage = this.getErrorMessage(this.errors, t);
          this._errorMessages.push(this._errorMessage);
        });
      } else {
        this._errorMessage = undefined;
        if (this.parent) {
          this.parent[CONTROLS_ERROR][this.keyName] = undefined;
          delete this.parent[CONTROLS_ERROR][this.keyName];
        }
      }
      let backEndErrors = Object.keys(this.backEndErrors);
      if (backEndErrors.length > 0) backEndErrors.forEach(t => {
        this._errorMessages.push(this._errorMessage = this.backEndErrors[t]);
      });
    } else {
      this._errorMessages = [];
      this._errorMessage = undefined;
    }
    this._language = this.getLanguage();
  }
  getLanguage() {
    return ReactiveFormConfig.i18n && ReactiveFormConfig.i18n.language ? ReactiveFormConfig.i18n.language : undefined;
  }
  getErrorMessage(errorObject, keyName) {
    if (errorObject[keyName][MESSAGE]) return errorObject[keyName][MESSAGE];
    return;
  }
  processExpression(propName, operationType) {
    if (this[propName]) for (var controlInfo of this[propName]) {
      let control = controlInfo.isRoot ? ApplicationUtil.getControl(controlInfo.controlPath, ApplicationUtil.getRootFormGroup(this)) : ApplicationUtil.getFormControl(controlInfo.controlPath, this);
      if (control) {
        if (operationType == "disabled") {
          let result = this.executeExpression(controlInfo.conditionalExpression, control);
          if (result) control.disable();else control.enable();
        } else if (operationType == "bindError") control.bindError();else if (operationType == "bindClassName") control.bindClassName();
      }
    }
  }
  executeExpression(expression, control) {
    return expression.call(control.parent[MODEL_INSTANCE], control, ApplicationUtil.getParentModelInstanceValue(this), control.parent[MODEL_INSTANCE]);
  }
  getValue(value) {
    return value !== undefined && value !== null && value !== "" ? value : "";
  }
}
const OBJECT = "object";
const BOOLEAN = "boolean";
class FormDataProvider {
  convertToFormData(jObject, options) {
    return this.convertFormData(jObject, undefined, undefined, options);
  }
  convertFormData(jObject, currentFormData, parentKey, options) {
    let formData = currentFormData || new FormData();
    let propName = '';
    for (var columnName in jObject) {
      propName = !parentKey ? columnName : `${parentKey}[${columnName}]`;
      if (Array.isArray(jObject[columnName])) {
        jObject[columnName].forEach((row, index) => {
          propName = `${columnName}[${index}]`;
          if (typeof row === OBJECT) this.convertFormData(row, formData, propName, options);else this.nonObjectValueBind(row, formData, propName, options);
        });
      } else if (jObject[columnName] !== null && !(jObject[columnName] instanceof Date) && typeof jObject[columnName] === OBJECT && !(jObject[columnName] instanceof File || jObject[columnName] instanceof FileList)) {
        this.convertFormData(jObject[columnName], formData, propName, options);
      } else {
        this.nonObjectValueBind(jObject[columnName], formData, propName, options);
      }
    }
    return formData;
  }
  nonObjectValueBind(value, formData, propName, options) {
    if (typeof value === BOOLEAN) {
      let formValue = value ? true : false;
      formData.append(propName, formValue);
    } else if (value instanceof FileList) {
      for (var i = 0; i < value.length; i++) {
        formData.append(options && options.excludeImageIndex && value.length === 1 ? propName : `${propName}[${i}]`, value.item(i));
      }
    } else {
      if (RegexValidator.isNotBlank(value)) formData.append(propName, value);
    }
  }
}
function isResetControl(controlName, control, options) {
  let isReset = true;
  if (options) {
    isReset = false;
    if (options.resetType) switch (options.resetType) {
      case ResetFormType.ControlsOnly:
        isReset = control instanceof FormControl;
        break;
      case ResetFormType.ControlsAndFormGroupsOnly:
        isReset = control instanceof FormControl || control instanceof FormGroup;
        break;
      case ResetFormType.FormGroupsOnly:
        isReset = control instanceof FormGroup;
        break;
      case ResetFormType.FormArraysOnly:
        isReset = control instanceof FormArray;
        break;
      case ResetFormType.DefinedPropsOnly:
        isReset = options.value ? Object.keys(options.value).indexOf(controlName) != -1 : false;
        break;
      default:
        isReset = true;
        break;
    }
    if (!isReset && options.with) isReset = options.with.filter(x => x.split('.')[0] == controlName.split('.')[0])[0] !== undefined;
    if (!isReset && options.value && (options.resetType === undefined || options.resetType !== ResetFormType.DefinedPropsOnly)) isReset = true;
  }
  return isReset;
}
function getNestedOptions(controlName, options) {
  if (options) {
    let jObjectOptions = {};
    if (options.resetType) jObjectOptions.resetType = options.resetType == ResetFormType.FormGroupsOnly || options.resetType == ResetFormType.FormArraysOnly ? ResetFormType.ControlsOnly : options.resetType;
    if (options.with) {
      let nestedControls = options.with.filter(t => t.split('.')[0] == controlName);
      let controlNames = nestedControls.map(x => {
        let splitControls = x.split('.');
        splitControls.splice(0, 1);
        return splitControls.join('.');
      });
      jObjectOptions.with = controlNames;
    }
    if (options.value && options.value[controlName]) jObjectOptions.value = options.value[controlName];
    jObjectOptions = Object.keys(jObjectOptions).length > 0 ? jObjectOptions : undefined;
    return jObjectOptions;
  }
  return undefined;
}
class RxFormGroup extends FormGroup {
  constructor(model, entityObject, controls, validatorOrOpts, asyncValidator) {
    super(controls, validatorOrOpts, asyncValidator);
    this.model = model;
    this.entityObject = entityObject;
    this._modified = {};
    this._isModified = false;
    this.changing = false;
    this.baseObject = {};
    for (var column in this.entityObject) this.baseObject[column] = this.entityObject[column];
    this.formDataProvider = new FormDataProvider();
  }
  bindPrimaryKey(modelInstance, jObject) {
    let instanceContainer = defaultContainer.get(modelInstance.constructor);
    if (instanceContainer) {
      let primaryKeyProp = instanceContainer.properties.filter(x => x.isPrimaryKey)[0];
      if (primaryKeyProp && this.modelInstance[primaryKeyProp.name]) jObject[primaryKeyProp.name] = this.modelInstance[primaryKeyProp.name];
    }
  }
  get modifiedValue() {
    let jObject = {};
    if (Object.keys(this._modified).length > 0) {
      this.bindPrimaryKey(this.modelInstance, jObject);
      for (var columnName in this._modified) {
        if (this.controls[columnName] instanceof RxFormGroup) jObject[columnName] = this.controls[columnName].modifiedValue;else if (this.controls[columnName] instanceof FormArray) {
          let formArray = this.controls[columnName];
          jObject[columnName] = [];
          for (var i = 0; i < this._modified[columnName].length; i++) {
            let modifiedValue = formArray.controls[i].modifiedValue;
            if (Object.keys(modifiedValue).length > 0) jObject[columnName].push(modifiedValue);
          }
          if (jObject[columnName].length == 0) delete jObject[columnName];
        } else jObject[columnName] = this._modified[columnName];
      }
      return jObject;
    }
    return this._modified;
  }
  get isModified() {
    return this._isModified;
  }
  patch(controlName) {
    if (controlName) {
      let control = this.controls[controlName];
      this.processModified(controlName, control);
    } else {
      this.nestedFormsModification();
    }
    this._isModified = Object.keys(this._modified).length > 0;
    if (!this._isModified) this.nestedArrayIsModified();
    if (this.parent && this.parent.patch) this.parent.patch();
  }
  isDirty() {
    let isDirty = false;
    for (let name in this.value) {
      let currentValue = this.modelInstance[name];
      if (!(this.controls[name] instanceof FormGroup || this.controls[name] instanceof FormArray)) {
        isDirty = ApplicationUtil.notEqualTo(this.baseObject[name], currentValue);
      } else if (this.controls[name] instanceof RxFormGroup) isDirty = this.controls[name].isDirty();else if (this.controls[name] instanceof FormArray) {
        for (let formGroup of this.controls[name].controls) {
          isDirty = formGroup.isDirty();
        }
      }
      if (isDirty) break;
    }
    return isDirty;
  }
  resetForm(options) {
    for (let name in this.controls) {
      if (isResetControl(name, this.controls[name], options)) {
        if (this.controls[name] instanceof FormGroup) this.controls[name].resetForm(getNestedOptions(name, options));else if (this.controls[name] instanceof FormArray) {
          this.controls[name].resetForm(options && options.value ? options.value[name] : undefined);
        } else {
          if (options && options.value && RegexValidator.isNotBlank(options.value[name])) this.controls[name].reset(options.value[name]);else this.controls[name].reset();
        }
      }
    }
  }
  commit() {
    for (let name in this.controls) {
      if (this.controls[name] instanceof FormGroup) this.controls[name].commit();else if (this.controls[name] instanceof FormArray) {
        this.controls[name].commit();
      } else {
        this.controls[name].commit();
      }
    }
  }
  patchModelValue(value, options) {
    if (value) {
      for (let name in this.controls) {
        if (this.controls[name] instanceof RxFormGroup && value[name]) this.controls[name].patchModelValue(value[name], options);else if (this.controls[name] instanceof FormArray && Array.isArray(value[name])) {
          let index = 0;
          for (let formGroup of this.controls[name].controls) {
            if (value[name][index]) formGroup.patchModelValue(value[name][index], options);
            index = index + 1;
          }
        } else if (value[name] !== undefined) this.controls[name].patchValue(value[name], options);
      }
    }
  }
  getErrorSummary(onlyMessage) {
    let jObject = {};
    Object.keys(this.controls).forEach(columnName => {
      if (this.controls[columnName] instanceof FormGroup) {
        let error = this.controls[columnName].getErrorSummary(false);
        if (Object.keys(error).length > 0) jObject[columnName] = error;
      } else if (this.controls[columnName] instanceof FormArray) {
        let index = 0;
        for (let formGroup of this.controls[columnName].controls) {
          let error = formGroup.getErrorSummary(false);
          if (Object.keys(error).length > 0) {
            error.index = index;
            if (!jObject[columnName]) jObject[columnName] = [];
            jObject[columnName].push(error);
          }
          index++;
        }
      } else {
        if (this.controls[columnName].errors) {
          let error = this.controls[columnName].errors;
          if (onlyMessage) for (let validationName in error) jObject[columnName] = error[validationName].message;else jObject[columnName] = error;
        }
      }
    });
    return jObject;
  }
  valueChangedSync() {
    Object.keys(this.controls).forEach(columnName => {
      if (!(this.controls[columnName] instanceof FormArray || this.controls[columnName] instanceof RxFormArray) && !(this.controls[columnName] instanceof FormGroup || this.controls[columnName] instanceof RxFormGroup) && !(this.entityObject[columnName] instanceof FormControl || this.entityObject[columnName] instanceof RxFormControl) && this.controls[columnName].getControlValue && ApplicationUtil.notEqualTo(this.controls[columnName].getControlValue(), this.entityObject[columnName])) {
        this.controls[columnName].setValue(this.entityObject[columnName], {
          updateChanged: true
        });
      } else if (this.controls[columnName] instanceof FormArray || this.controls[columnName] instanceof RxFormArray) {
        for (let formGroup of this.controls[columnName].controls) {
          formGroup.valueChangedSync();
        }
      } else if (this.controls[columnName] instanceof RxFormGroup) {
        this.controls[columnName].valueChangedSync();
      }
    });
  }
  refreshDisable() {
    Object.keys(this.controls).forEach(columnName => {
      if (!(this.controls[columnName] instanceof FormArray || this.controls[columnName] instanceof RxFormArray) && !(this.controls[columnName] instanceof FormGroup || this.controls[columnName] instanceof RxFormGroup)) {
        this.controls[columnName].refresh();
      } else if (this.controls[columnName] instanceof RxFormGroup) {
        this.controls[columnName].refreshDisable();
      }
    });
  }
  bindErrorMessages() {
    Object.keys(this.controls).forEach(columnName => {
      if (!(this.controls[columnName] instanceof FormArray || this.controls[columnName] instanceof RxFormArray) && !(this.controls[columnName] instanceof FormGroup || this.controls[columnName] instanceof RxFormGroup)) {
        this.controls[columnName].bindError();
      } else if (this.controls[columnName] instanceof RxFormGroup) {
        this.controls[columnName].bindErrorMessages();
      }
    });
  }
  get submitted() {
    return this._submitted;
  }
  set submitted(value) {
    this._submitted = value;
    Object.keys(this.controls).forEach(columnName => {
      if (this.controls[columnName] instanceof FormArray) {
        let formArray = this.controls[columnName];
        for (let formGroup of formArray.controls) formGroup.submitted = value;
      } else if (this.controls[columnName] instanceof FormGroup) {
        this.controls[columnName].submitted = value;
      } else this.controls[columnName].bindError();
    });
  }
  get modelInstanceValue() {
    return clone(this.entityObject);
  }
  get modelInstance() {
    return this.entityObject;
  }
  get controlsError() {
    return this.getErrorSummary(true);
  }
  toFormData(options) {
    return this.formDataProvider.convertToFormData(this.value, options);
  }
  processModified(controlName, control) {
    if (control.isModified) this._modified[controlName] = control.value;else delete this._modified[controlName];
    this._isModified = Object.keys(this._modified).length > 0;
  }
  nestedArrayIsModified() {
    for (var controlName in this.controls) {
      if (this.controls[controlName] instanceof RxFormArray) this._isModified = this.controls[controlName].isModified;
      if (this._isModified) break;
    }
  }
  setBackEndErrors(errors) {
    Object.keys(errors).forEach(controlName => {
      if (this.controls[controlName]) {
        if (this.controls[controlName] instanceof FormGroup) this.controls[controlName].setBackEndErrors(errors[controlName]);else this.controls[controlName].setBackEndErrors(errors[controlName]);
      }
    });
  }
  clearBackEndErrors(errors) {
    let clearErrors = errors ? Object.keys(errors) : Object.keys(this.controls);
    clearErrors.forEach(controlName => {
      if (this.controls[controlName]) {
        if (this.controls[controlName] instanceof FormGroup) errors ? this.controls[controlName].clearBackEndErrors(errors[controlName]) : this.controls[controlName].clearBackEndErrors();else errors ? this.controls[controlName].clearBackEndErrors(errors[controlName]) : this.controls[controlName].clearBackEndErrors();
      }
    });
  }
  nestedFormsModification() {
    for (var controlName in this.controls) {
      if (this.controls[controlName] instanceof RxFormGroup) this.processModified(controlName, this.controls[controlName]);else if (this.controls[controlName] instanceof RxFormArray) {
        if (this.controls[controlName].isModified) {
          let formGroups = this.controls[controlName].controls;
          this._modified[controlName] = [];
          for (var formGroup of formGroups) {
            if (formGroup.isModified) {
              if (!this._modified[controlName]) this._modified[controlName] = [];
              this._modified[controlName].push(formGroup.modifiedValue);
            }
          }
          if (this._modified[controlName].length == 0) delete this._modified[controlName];
        } else if (this._modified[controlName]) delete this._modified[controlName];
      }
    }
  }
}
class FormProvider {
  static ProcessRule(control, config, isDynamicConfig = false) {
    if (config && config.expressionProcessed) return true;
    const formGroupValue = ApplicationUtil.getParentObjectValue(control);
    const parentObject = control.parent ? ApplicationUtil.cloneValue(control.parent.value) : undefined;
    let modelInstance = undefined;
    if (control.parent && control.parent instanceof RxFormGroup) modelInstance = control.parent.modelInstance;
    if (parentObject) {
      this.updateFormControlValue(parentObject, control.parent.controls, control, config);
      this.forDisableUpdate(parentObject, config);
    } else if (config.conditionalExpression) return false;
    return Linq.execute(formGroupValue, config, parentObject, modelInstance, isDynamicConfig);
  }
  static updateFormControlValue(parentObject, controls, control, config) {
    for (var controlName in parentObject) {
      if (!(parentObject[controlName] instanceof Object)) if (controls[controlName] === control) {
        parentObject[controlName] = control.value;
        break;
      }
    }
  }
  static forDisableUpdate(parentObject, config) {
    if (config.disableConfig) Object.keys(config.disableConfig).forEach(column => {
      parentObject[column] = config.disableConfig[column];
    });
  }
}
class ValidatorValueChecker {
  static pass(control, config) {
    if (FormProvider.ProcessRule(control, config)) return RegexValidator.isNotBlank(control.value);else return false;
  }
  static passArrayValue(control, config) {
    if (FormProvider.ProcessRule(control, config)) return typeof control.value === "string" ? RegexValidator.isNotBlank(control.value) : control.value instanceof Array;else return false;
  }
}
const ARRAY_CONFIG = "ArrayConfig";
const FIELD_CONFIG = "FieldConfig";
const IP_CONFIG = "IpConfig";
const NUMBER_CONFIG = "NumberConfig";
const PASSWORD_CONFIG = "PasswordConfig";
const PATTERN_CONFIG = "PatternConfig";
const RANGE_CONFIG = "RangeConfig";
const RELATIONAL_OPERATOR_CONFIG = "RelationalOperatorConfig";
const CONFIG_REQUIRED_FIELDS = {
  [ARRAY_CONFIG]: ["matchValues"],
  [FIELD_CONFIG]: ["fieldName"],
  [IP_CONFIG]: ["version"],
  [PASSWORD_CONFIG]: ["validation"],
  [NUMBER_CONFIG]: ["value"],
  [PATTERN_CONFIG]: ["expression"],
  [RANGE_CONFIG]: ["minimumNumber", "maximumNumber"]
};
function getConfigObject(config, control, configName = '') {
  return config != undefined && config != true ? configProvider(control, config, configName) : {};
}
function configProvider(control, config, configName) {
  if (config.dynamicConfig) {
    let currentConfig = FormProvider.ProcessRule(control, clone(config), true);
    if (typeof currentConfig != "boolean") {
      currentConfig.conditionalExpression = config.conditionalExpression;
      currentConfig.dynamicConfig = config.dynamicConfig;
      Object.keys(config).forEach(t => {
        if (t != "conditionalExpression" && t != "dynamicConfig" || currentConfig[t] === undefined) {
          currentConfig[t] = config[t];
        }
      });
      return currentConfig;
    } else return config;
  }
  return checkRequiredProps(config, configName);
}
function checkRequiredProps(config, configName) {
  let props = CONFIG_REQUIRED_FIELDS[configName];
  if (configName) {
    props.forEach(prop => {
      if (config[prop] === undefined) throw new Error(`Pass the property of '${prop}' with value in the ${configName}, otherwise it won't work.`);
    });
  }
  return config;
}
const alphabet = {
  'danish': /^[A-ZÆØÅ]+$/i,
  'french': /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ]+$/i,
  'german': /^[A-ZÄÖÜß]+$/i,
  'spanish': /^[a-zñáéíóúü]+$/i,
  'russian': /^[А-ЯЁ]+$/i
};
const alphaWithWhitespace = {
  'danish': /^[A-ZÆØÅ\s]+$/i,
  'french': /^[A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ\s]+$/i,
  'german': /^[A-ZÄÖÜß\s]+$/i,
  'spanish': /^[a-zñáéíóúü\s]+$/i,
  'russian': /^[А-ЯЁ\s]+$/i
};
const alphanumeric = {
  'danish': /^[0-9A-ZÆØÅ]+$/i,
  'french': /^[0-9A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ]+$/i,
  'german': /^[0-9A-ZÄÖÜß]+$/i,
  'spanish': /^[0-9a-zñáéíóúü]+$/i,
  'russian': /^[0-9А-ЯЁ]+$/i
};
const alphanumericWithWitespace = {
  'danish': /^[0-9A-ZÆØÅ\s]+$/i,
  'french': /^[0-9A-ZÀÂÆÇÉÈÊËÏÎÔŒÙÛÜŸ\s]+$/i,
  'german': /^[0-9A-ZÄÖÜß\s]+$/i,
  'spanish': /^[0-9a-zñáéíóúü\s]+$/i,
  'russian': /^[0-9А-ЯЁ\s]+$/i
};
function alphaValidation(configModel, control, regExps, key) {
  let config = getConfigObject(configModel, control);
  if (ValidatorValueChecker.pass(control, config)) {
    regExps = getRegex(key, regExps, config);
    var isValid = !config || !config.allowWhiteSpace ? RegexValidator.isValid(control.value, regExps[0]) : RegexValidator.isValid(control.value, regExps[1]);
    if (!isValid) return ObjectMaker.toJson(key, config, [control.value]);
  }
  return ObjectMaker.null();
}
function getRegex(key, regExps, config) {
  if (config.allowCharacters) if (config.allowWhiteSpace) regExps[1] = new RegExp(`^[0-9a-zA-Z @${config.allowCharacters}]+$`, ``);else regExps[0] = new RegExp(`^[0-9a-zA-Z @${config.allowCharacters}]+$`, ``);
  switch (key) {
    case "alpha":
      var alphaLocale = config.locale ? config.locale : ReactiveFormConfig.json && ReactiveFormConfig.json.defaultValidationLocale && ReactiveFormConfig.json.defaultValidationLocale.alpha ? ReactiveFormConfig.json.defaultValidationLocale.alpha : "";
      return [alphaLocale && alphaLocale in alphabet ? alphabet[alphaLocale] : regExps[0], alphaLocale && alphaLocale in alphaWithWhitespace ? alphaWithWhitespace[alphaLocale] : regExps[1]];
      break;
    case "alphaNumeric":
      var alphaNumericLocale = config.locale ? config.locale : ReactiveFormConfig.json && ReactiveFormConfig.json.defaultValidationLocale && ReactiveFormConfig.json.defaultValidationLocale.alphaNumeric ? ReactiveFormConfig.json.defaultValidationLocale.alphaNumeric : "";
      return [alphaNumericLocale && alphaNumericLocale in alphanumeric ? alphanumeric[alphaNumericLocale] : regExps[0], alphaNumericLocale && alphaNumericLocale in alphanumericWithWitespace ? alphanumericWithWitespace[alphaNumericLocale] : regExps[1]];
      break;
  }
}
function alphaValidator(configModel) {
  return control => {
    return alphaValidation(configModel, control, [RegExRule.alpha, RegExRule.alphaWithSpace], AnnotationTypes.alpha);
  };
}
function alphaNumericValidator(configModel) {
  return control => {
    return alphaValidation(configModel, control, [RegExRule.alphaNumeric, RegExRule.alphaNumericWithSpace], AnnotationTypes.alphaNumeric);
  };
}
function compareValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control, FIELD_CONFIG);
    const compareControl = ApplicationUtil.getFormControl(config.fieldName, control);
    const controlValue = control.value;
    const compareControlValue = compareControl ? compareControl.value : '';
    if (RegexValidator.isNotBlank(controlValue) || RegexValidator.isNotBlank(compareControlValue)) {
      if (!(compareControl && compareControl.value === controlValue)) return ObjectMaker.toJson(AnnotationTypes.compare, config, [controlValue, compareControlValue]);
    }
    return ObjectMaker.null();
  };
}
function containsValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      let failed = false;
      const values = config.values ? config.values : [config.value];
      for (let value of values) {
        failed = control.value.indexOf(value) == -1;
        if (!failed) break;
      }
      if (failed) return ObjectMaker.toJson(AnnotationTypes.contains, config, [control.value, config.value]);
    }
    return ObjectMaker.null();
  };
}
function checkLength(length, checks) {
  let isPassed = false;
  for (let check of checks) {
    isPassed = check == length;
    if (isPassed) break;
  }
  return isPassed;
}
function calculate(numbers) {
  let numberSum = 0;
  for (var i = 0; i < numbers.length; i++) numberSum += parseInt(numbers.substring(i, i + 1));
  let deltas = new Array(0, 1, 2, 3, 4, -4, -3, -2, -1, 0);
  for (var i = numbers.length - 1; i >= 0; i -= 2) {
    numberSum += deltas[parseInt(numbers.substring(i, i + 1))];
  }
  let mod = numberSum % 10;
  mod = 10 - mod;
  if (mod == 10) mod = 0;
  return mod;
}
function creditCardValidator(configModel) {
  let cardDigits = {
    AmericanExpress: [15],
    DinersClub: [14, 16, 19],
    Discover: [16, 19],
    JCB: [16, 19],
    Maestro: [12, 16, 19],
    MasterCard: [16],
    Visa: [13, 16, 19]
  };
  function validate(creditCardNumber) {
    var digit = parseInt(creditCardNumber.substring(creditCardNumber.length - 1, creditCardNumber.length));
    return calculate(creditCardNumber.substring(0, creditCardNumber.length - 1)) == parseInt(String(digit)) ? !0 : !1;
  }
  function getCardProviderName(cardNumber) {
    var cardProviderName = "";
    return /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/.test(cardNumber) ? cardProviderName = "Maestro" : /^5[1-5]/.test(cardNumber) ? cardProviderName = "MasterCard" : /^4/.test(cardNumber) ? cardProviderName = "Visa" : /^3[47]/.test(cardNumber) ? cardProviderName = "AmericanExpress" : /^(?:2131|1800|35)/.test(cardNumber) ? cardProviderName = "JCB" : /^3(?:0[0-5]|[68])/.test(cardNumber) ? cardProviderName = "DinersClub" : /^6(?:011|5)/.test(cardNumber) && (cardProviderName = "Discover"), cardProviderName;
  }
  return control => {
    const controlValue = control.value;
    let config = getConfigObject(configModel, control);
    const parentObject = control.parent ? control.parent.value : undefined;
    if (FormProvider.ProcessRule(control, config)) {
      if (RegexValidator.isNotBlank(controlValue)) {
        let isValid = false;
        let cardTypes = config.fieldName && parentObject[config.fieldName] ? [parentObject[config.fieldName]] : config.creditCardTypes;
        let cardType = '';
        for (let creditCardType of cardTypes) {
          isValid = checkLength(controlValue.length, cardDigits[creditCardType]) && getCardProviderName(controlValue) == creditCardType && validate(controlValue);
          cardType = creditCardType;
          if (isValid) break;
        }
        if (!isValid) return ObjectMaker.toJson(AnnotationTypes.creditCard, config, [controlValue, cardType]);
      }
    }
    return ObjectMaker.null();
  };
}
function regexValidation(configModel, control, regExp, key) {
  let config = getConfigObject(configModel, control);
  return validate(config, control, regExp, key);
}
function validate(config, control, regExp, key) {
  if (ValidatorValueChecker.pass(control, config)) {
    if (!RegexValidator.isValid(control.value, regExp)) return ObjectMaker.toJson(key, config, [control.value]);
  }
  return ObjectMaker.null();
}
function digitValidator(configModel) {
  return control => {
    return regexValidation(configModel, control, RegExRule.onlyDigit, AnnotationTypes.digit);
  };
}
function emailValidator(configModel) {
  return control => {
    return regexValidation(configModel, control, RegExRule.basicEmail, AnnotationTypes.email);
  };
}
function hexColorValidator(configModel) {
  return control => {
    return regexValidation(configModel, control, RegExRule.strictHexColor, AnnotationTypes.hexColor);
  };
}
function lowercaseValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!(control.value === control.value.toLowerCase())) return ObjectMaker.toJson(AnnotationTypes.lowerCase, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
const OPERATORS = {
  lessThan: "<",
  greaterThan: ">",
  lessThanEqualTo: "<=",
  greaterThanEqualTo: ">="
};
function runCondition$1(leftValue, rightValue, operator) {
  let result = false;
  switch (operator) {
    case OPERATORS.lessThan:
    case OPERATORS.greaterThan:
      result = leftValue > rightValue;
      break;
    case OPERATORS.lessThanEqualTo:
    case OPERATORS.greaterThanEqualTo:
      result = leftValue >= rightValue;
      break;
  }
  return result;
}
function dateChecker(control, config, operationType) {
  config = getConfigObject(config, control);
  var dateProvider = new DateProvider();
  if (FormProvider.ProcessRule(control, config)) {
    if (RegexValidator.isNotBlank(control.value)) {
      let checkDate = dateProvider.getCompareDate(config, control);
      if (dateProvider.isDate(control.value) || dateProvider.isValid(control.value, config)) {
        let currentControlValue = dateProvider.getDate(control.value);
        let isValid = operationType == AnnotationTypes.minDate ? runCondition$1(currentControlValue, checkDate, config.operator || OPERATORS.greaterThanEqualTo) : runCondition$1(checkDate, currentControlValue, config.operator || OPERATORS.lessThanEqualTo);
        if (!isValid) return ObjectMaker.toJson(operationType, config, [control.value, checkDate]);
      } else return ObjectMaker.toJson(operationType, config, [control.value, checkDate]);
    }
  }
  return ObjectMaker.null();
}
function validateDate(control, config, operationType) {
  config = getConfigObject(config, control);
  var dateProvider = new DateProvider();
  if (FormProvider.ProcessRule(control, config)) {
    if (RegexValidator.isNotBlank(control.value)) {
      if (!dateProvider.isDate(control.value) && !dateProvider.isValid(control.value, config)) {
        return ObjectMaker.toJson(operationType, config, [control.value]);
      }
    }
  }
  return ObjectMaker.null();
}
function maxDateValidator(configModel) {
  return control => {
    return dateChecker(control, configModel, AnnotationTypes.maxDate);
  };
}
function maxLengthValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control, NUMBER_CONFIG);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!(control.value.length <= config.value)) return ObjectMaker.toJson(AnnotationTypes.maxLength, config, [control.value, config.value]);
    }
    return ObjectMaker.null();
  };
}
function maxNumberValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control, NUMBER_CONFIG);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!(parseFloat(control.value) <= config.value)) return ObjectMaker.toJson(AnnotationTypes.maxNumber, config, [control.value, config.value]);
    }
    return ObjectMaker.null();
  };
}
function minDateValidator(configModel) {
  return control => {
    return dateChecker(control, configModel, AnnotationTypes.minDate);
  };
}
function minLengthValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control, NUMBER_CONFIG);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!(String(control.value).length >= config.value)) return ObjectMaker.toJson(AnnotationTypes.minLength, config, [control.value, config.value]);
    }
    return ObjectMaker.null();
  };
}
function minNumberValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control, NUMBER_CONFIG);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!(parseFloat(control.value) >= config.value)) return ObjectMaker.toJson(AnnotationTypes.minNumber, config, [control.value, config.value]);
    }
    return ObjectMaker.null();
  };
}
function passwordValidator(configModel) {
  function getMessageObject(jObject, keyName) {
    if (!jObject.message && !jObject.messageKey) {
      let message = ObjectMaker.getPasswordMessage();
      jObject.message = message && typeof message == "string" ? message : ApplicationUtil.isObject(message) ? message[keyName] : "";
      if (!jObject.message) jObject.message = message["password"];
      jObject.messageKey = "";
    }
    return jObject;
  }
  return control => {
    let config = getConfigObject(configModel, control, PASSWORD_CONFIG);
    let controlValue = control.value;
    if (RegexValidator.isNotBlank(controlValue)) {
      let validation = RegexValidator.isValidPassword(config.validation, controlValue);
      let jObject = {};
      jObject.message = config.message && config.message[validation.keyName] ? config.message[validation.keyName] : typeof config.message == "string" ? config.message : '';
      jObject.messageKey = config.messageKey && config.messageKey[validation.keyName] ? config.messageKey[validation.keyName] : typeof config.messageKey == "string" ? config.messageKey : "";
      jObject = getMessageObject(jObject, validation.keyName);
      if (!validation.isValid) return ObjectMaker.toJson(AnnotationTypes.password, jObject, [controlValue]);
    }
    return ObjectMaker.null();
  };
}
function rangeValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control, RANGE_CONFIG);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!((control.value || control.value === 0) && parseFloat(control.value) >= config.minimumNumber && parseFloat(control.value) <= config.maximumNumber)) return ObjectMaker.toJson(AnnotationTypes.range, config, [control.value, config.minimumNumber, config.maximumNumber]);
    }
    return ObjectMaker.null();
  };
}
function uppercaseValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!(control.value === control.value.toUpperCase())) return ObjectMaker.toJson(AnnotationTypes.upperCase, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function requiredValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (FormProvider.ProcessRule(control, config)) {
      if (!RegexValidator.isNotBlank(control.value)) {
        return ObjectMaker.toJson(AnnotationTypes.required, config, []);
      }
    }
    return ObjectMaker.null();
  };
}
function patternValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control, PATTERN_CONFIG);
    if (ValidatorValueChecker.pass(control, config)) {
      for (var pattern in config.expression) if (!RegexValidator.isValid(control.value, config.expression[pattern])) return ObjectMaker.toJson(pattern, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function timeValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      let isValid = config.allowSeconds ? RegexValidator.isValid(control.value, RegExRule.timeWithSeconds) : RegexValidator.isValid(control.value, RegExRule.time);
      if (!isValid) return ObjectMaker.toJson(AnnotationTypes.time, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function urlValidation(configModel, control) {
  var regex = RegExRule.url;
  let config = getConfigObject(configModel, control);
  if (config && config.urlValidationType) {
    switch (config.urlValidationType) {
      case 1:
        regex = RegExRule.url;
        break;
      case 2:
        regex = RegExRule.localhostUrl;
        break;
      case 3:
        regex = RegExRule.interanetUrl;
        break;
    }
  }
  return validate(config, control, regex, AnnotationTypes.url);
}
function urlValidator(configModel) {
  return control => {
    return urlValidation(configModel, control);
  };
}
function jsonValidator(configModel) {
  function process(value) {
    var result = false;
    try {
      var json = JSON.parse(value);
      result = !!json && typeof json === 'object';
    } catch (ex) {
      result = false;
    }
    return result;
  }
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!process(control.value)) return ObjectMaker.toJson(AnnotationTypes.json, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
const operatorOpposite = {
  [AnnotationTypes.greaterThan]: AnnotationTypes.lessThan,
  [AnnotationTypes.lessThan]: AnnotationTypes.greaterThan,
  [AnnotationTypes.greaterThanEqualTo]: AnnotationTypes.lessThanEqualTo,
  [AnnotationTypes.lessThanEqualTo]: AnnotationTypes.greaterThanEqualTo
};
function relationalCheck(control, config, relationalOperatorName) {
  config = getConfigObject(config, control);
  const matchControl = config.fieldName ? ApplicationUtil.getFormControl(config.fieldName, control) : undefined;
  const matchControlValue = matchControl ? matchControl.value : config.value !== undefined ? config.value : '';
  if (FormProvider.ProcessRule(control, config)) {
    if (config.isArrayControl) return arrayControlValidation(control, config, relationalOperatorName);
    if (isValid$1(control, matchControlValue, relationalOperatorName) === false) return ObjectMaker.toJson(relationalOperatorName, config, [control.value, matchControlValue]);
  }
  return ObjectMaker.null();
}
function isValid$1(control, matchControlValue, relationalOperatorName) {
  if (RegexValidator.isNotBlank(control.value) && RegexValidator.isNotBlank(matchControlValue)) {
    let isValid = false;
    switch (relationalOperatorName) {
      case AnnotationTypes.greaterThan:
        isValid = parseFloat(control.value) > parseFloat(matchControlValue);
        break;
      case AnnotationTypes.lessThan:
        isValid = parseFloat(control.value) < parseFloat(matchControlValue);
        break;
      case AnnotationTypes.greaterThanEqualTo:
        isValid = parseFloat(control.value) >= parseFloat(matchControlValue);
        break;
      case AnnotationTypes.lessThanEqualTo:
        isValid = parseFloat(control.value) <= parseFloat(matchControlValue);
        break;
    }
    return isValid;
  }
  return null;
}
function setTimeFunc(invalidateControls) {
  let timeOut = setTimeout(() => {
    invalidateControls.forEach(t => {
      t.updateValueAndValidity();
    });
    clearTimeout(timeOut);
  }, 200);
}
function arrayControlValidation(control, config, relationalOperatorName) {
  let formArray = ApplicationUtil.getParentFormArray(control);
  let parentFormGroup = control.parent ? control.parent : undefined;
  let oppositeOperator = operatorOpposite[relationalOperatorName];
  let updateValidityControls = [];
  if (formArray && parentFormGroup && formArray.controls.length > 1) {
    let indexOf = formArray.controls.indexOf(parentFormGroup);
    let fieldName = ApplicationUtil.getFormControlName(control);
    let valid = true;
    if (indexOf > 0) valid = validateControl(formArray, control, indexOf - 1, fieldName, oppositeOperator, relationalOperatorName, updateValidityControls);
    if (valid && formArray.controls.length > indexOf + 1) valid = validateControl(formArray, control, indexOf + 1, fieldName, relationalOperatorName, relationalOperatorName, updateValidityControls);
    if (updateValidityControls.length > 0) setTimeFunc(updateValidityControls);
    if (valid === false) return ObjectMaker.toJson(relationalOperatorName, config, [control.value]);
  }
  return ObjectMaker.null();
}
function validateControl(formArray, control, indexOf, fieldName, oppositeOperator, relationalOperatorName, updateValidityControls) {
  let valid = false;
  let formGroup = formArray.controls[indexOf];
  if (formGroup && formGroup.controls) {
    let formControl = formGroup.controls[fieldName];
    valid = isValid$1(control, formControl.value, oppositeOperator);
    if (valid && formControl.errors && formControl.errors[relationalOperatorName]) updateValidityControls.push(formControl);
  }
  return valid;
}
function greaterThanValidator(configModel) {
  return control => {
    return relationalCheck(control, configModel, AnnotationTypes.greaterThan);
  };
}
function greaterThanEqualToValidator(configModel) {
  return control => {
    return relationalCheck(control, configModel, AnnotationTypes.greaterThanEqualTo);
  };
}
function lessThanEqualToValidator(configModel) {
  return control => {
    return relationalCheck(control, configModel, AnnotationTypes.lessThanEqualTo);
  };
}
function lessThanValidator(configModel) {
  return control => {
    return relationalCheck(control, configModel, AnnotationTypes.lessThan);
  };
}
function choiceValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (FormProvider.ProcessRule(control, config)) {
      if (control.value instanceof Array) {
        config.minLength = config.minLength == undefined ? 0 : config.minLength;
        config.maxLength = config.maxLength == undefined ? 0 : config.maxLength;
        if (control.value.length < config.minLength || config.maxLength !== 0 && control.value.length > config.maxLength) return ObjectMaker.toJson(AnnotationTypes.choice, config, [control.value]);
      }
    }
    return ObjectMaker.null();
  };
}
function differentValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control, FIELD_CONFIG);
    if (ValidatorValueChecker.pass(control, config)) {
      const differentControl = ApplicationUtil.getFormControl(config.fieldName, control);
      const differentControlValue = differentControl ? differentControl.value : '';
      if (!(differentControl && differentControl.value != control.value)) return ObjectMaker.toJson(AnnotationTypes.different, config, [control.value, differentControlValue]);
    }
    return ObjectMaker.null();
  };
}
function numericValidator(configModel) {
  return control => {
    if (configModel && (!control[VALIDATOR_CONFIG$2] || !control[VALIDATOR_CONFIG$2][AnnotationTypes.numeric])) ApplicationUtil.configureControl(control, configModel, AnnotationTypes.numeric);
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!RegexValidator.isValid(control.value, ApplicationUtil.numericValidation(config.allowDecimal, config.acceptValue))) return ObjectMaker.toJson(AnnotationTypes.numeric, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function evenValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!(control.value % 2 == 0)) return ObjectMaker.toJson(AnnotationTypes.even, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function oddValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!!(control.value % 2 == 0) || !ApplicationUtil.isNumeric(control.value)) return ObjectMaker.toJson(AnnotationTypes.odd, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function factorValidator(configModel) {
  function positiveFactors(dividend, value) {
    let isPositive = false;
    for (var index = 1; index <= Math.floor(Math.sqrt(dividend)); index += 1) {
      if (dividend % index === 0) {
        if (index == value) isPositive = true;
        if (dividend / index !== index) if (dividend / index == value) isPositive = true;
        if (isPositive) break;
      }
    }
    return isPositive;
  }
  return control => {
    let config = getConfigObject(configModel, control);
    const dividendField = control.parent && config.fieldName ? ApplicationUtil.getFormControl(config.fieldName, control) : undefined;
    const dividend = config.fieldName && dividendField ? dividendField.value : config.dividend;
    if (FormProvider.ProcessRule(control, config)) {
      if (RegexValidator.isNotBlank(control.value) && dividend > 0) {
        if (!RegexValidator.isValid(control.value, RegExRule.onlyDigit) || !positiveFactors(dividend, parseInt(control.value))) return ObjectMaker.toJson(AnnotationTypes.factor, config, [control.value]);
      }
    }
    return ObjectMaker.null();
  };
}
function leapYearValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      var isValid = control.value % 100 === 0 ? control.value % 400 === 0 : control.value % 4 === 0;
      if (!isValid) return ObjectMaker.toJson(AnnotationTypes.leapYear, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function allOfValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control, ARRAY_CONFIG);
    if (ValidatorValueChecker.passArrayValue(control, config)) {
      var testResult = false;
      for (let value of config.matchValues) {
        testResult = control.value.some(y => y == value);
        if (!testResult) break;
      }
      if (!testResult) return ObjectMaker.toJson(AnnotationTypes.allOf, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function oneOfValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control, ARRAY_CONFIG);
    if (ValidatorValueChecker.passArrayValue(control, config)) {
      var testResult = false;
      for (let value of config.matchValues) {
        let matchValue = ApplicationUtil.lowerCaseWithTrim(value);
        testResult = Array.isArray(control.value) ? control.value.some(y => ApplicationUtil.lowerCaseWithTrim(y) === matchValue) : ApplicationUtil.lowerCaseWithTrim(control.value) === matchValue;
        if (testResult) break;
      }
      if (!testResult) return ObjectMaker.toJson(AnnotationTypes.oneOf, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function noneOfValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control, ARRAY_CONFIG);
    if (FormProvider.ProcessRule(control, config)) {
      var testResult = false;
      for (let value of config.matchValues) {
        let matchValue = ApplicationUtil.lowerCaseWithTrim(value);
        testResult = Array.isArray(control.value) ? control.value.some(y => ApplicationUtil.lowerCaseWithTrim(y) === matchValue) : ApplicationUtil.lowerCaseWithTrim(control.value) === matchValue;
        if (testResult) break;
      }
      if (testResult) return ObjectMaker.toJson(AnnotationTypes.noneOf, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function macValidator(configModel) {
  return control => {
    return regexValidation(configModel, control, RegExRule.macId, AnnotationTypes.mac);
  };
}
function asciiValidator(configModel) {
  return control => {
    return regexValidation(configModel, control, RegExRule.ascii, AnnotationTypes.ascii);
  };
}
function dataUriValidator(configModel) {
  return control => {
    return regexValidation(configModel, control, RegExRule.dataUri, AnnotationTypes.dataUri);
  };
}
function portValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      let isValid = RegexValidator.isValid(control.value, RegExRule.onlyDigit) && control.value >= 0 && control.value <= 65535;
      if (!isValid) return ObjectMaker.toJson(AnnotationTypes.port, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function latLongValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      let splitText = control.value.split(',');
      if (!(splitText.length > 1 && RegexValidator.isValid(splitText[0], RegExRule.lat) && RegexValidator.isValid(splitText[1], RegExRule.long))) return ObjectMaker.toJson(AnnotationTypes.latLong, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function extensionValidator(configModel) {
  return (control, files) => {
    let config = getConfigObject(configModel, control);
    if (!control[VALIDATOR_CONFIG$2] || !control[VALIDATOR_CONFIG$2][AnnotationTypes.extension]) ApplicationUtil.configureControl(control, config, AnnotationTypes.extension);
    if (files && FormProvider.ProcessRule(control, config)) {
      if (RegexValidator.isNotBlank(control.value)) {
        let testResult = true;
        let extension = '';
        for (var i = 0; i < files.length; i++) {
          let file = files.item(i);
          let splitText = file.name.split(".");
          extension = splitText[splitText.length - 1];
          let result = config.extensions.filter(t => {
            return extension.toLowerCase() == t.toLowerCase();
          })[0];
          if (!result && !configModel.isExcludeExtensions) {
            testResult = false;
            break;
          } else {
            if (result && configModel.isExcludeExtensions) {
              testResult = false;
              break;
            }
          }
        }
        if (!testResult) return ObjectMaker.toJson(AnnotationTypes.extension, config, [extension, config.extensions.join(",")]);
      }
    }
    return ObjectMaker.null();
  };
}
function fileSizeValidator(configModel) {
  return (control, files) => {
    let config = getConfigObject(configModel, control);
    if (!control[VALIDATOR_CONFIG$2] || !control[VALIDATOR_CONFIG$2][AnnotationTypes.fileSize]) ApplicationUtil.configureControl(control, config, AnnotationTypes.fileSize);
    if (files && FormProvider.ProcessRule(control, config)) {
      if (RegexValidator.isNotBlank(control.value)) {
        let minFileSize = config.minSize ? config.minSize : 0;
        let testResult = false;
        let fileSize = 0;
        for (var i = 0; i < files.length; i++) {
          let file = files.item(i);
          fileSize = file.size;
          testResult = !(fileSize >= minFileSize && fileSize <= config.maxSize);
          if (testResult) break;
        }
        if (testResult) return ObjectMaker.toJson(AnnotationTypes.fileSize, config, [fileSize, minFileSize, config.maxSize]);
      }
    }
    return ObjectMaker.null();
  };
}
function endsWithValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      let failed = false;
      let values = config.values ? config.values : [config.value];
      for (let value of values) {
        var endString = String(control.value).substr(control.value.length - value.length, value.length);
        failed = endString != value;
        if (!failed) break;
      }
      if (failed) return ObjectMaker.toJson(AnnotationTypes.endsWith, config, [control.value, config.value]);
    }
    return ObjectMaker.null();
  };
}
function startsWithValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      let failed = false;
      let values = config.values ? config.values : [config.value];
      for (let value of values) {
        let startString = String(control.value).substr(0, value.length);
        failed = config.isRestrict && String(startString).toLowerCase() == String(value).toLowerCase() || !config.isRestrict && startString != value;
        if (!failed) break;
      }
      if (failed) return ObjectMaker.toJson(AnnotationTypes.startsWith, config, [control.value, config.value]);
    }
    return ObjectMaker.null();
  };
}
function primeNumberValidator(configModel) {
  function isPrime(value) {
    let isPrimeNumber = value != 1;
    for (var i = 2; i < value; i++) {
      if (value % i == 0) {
        isPrimeNumber = false;
        break;
      }
    }
    return isPrimeNumber;
  }
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!ApplicationUtil.isNumeric(control.value) || !isPrime(control.value)) return ObjectMaker.toJson(AnnotationTypes.primeNumber, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function latitudeValidator(configModel) {
  return control => {
    return regexValidation(configModel, control, RegExRule.lat, AnnotationTypes.latitude);
  };
}
function longitudeValidator(configModel) {
  return control => {
    return regexValidation(configModel, control, RegExRule.long, AnnotationTypes.longitude);
  };
}
function composeValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (FormProvider.ProcessRule(control, config)) {
      if (config.validators) {
        let result = undefined;
        for (let validator of config.validators) {
          result = validator(control);
          if (result) break;
        }
        if (result) return config.messageKey || config.message ? ObjectMaker.toJson(config.messageKey || AnnotationTypes.compose, config, [control.value]) : result;
      }
    }
    return ObjectMaker.null();
  };
}
function ruleValidator(configModel, entity) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (FormProvider.ProcessRule(control, config)) {
      let result = null;
      for (let rule of config.customRules) {
        result = rule(entity);
        if (result) break;
      }
      if (result) return result;
    }
    return ObjectMaker.null();
  };
}
function fileValidator(configModel) {
  return (control, files) => {
    let config = getConfigObject(configModel, control);
    if (!control[VALIDATOR_CONFIG$2] || !control[VALIDATOR_CONFIG$2][AnnotationTypes.file]) ApplicationUtil.configureControl(control, config, AnnotationTypes.file);
    if (files) {
      if (FormProvider.ProcessRule(control, config)) {
        if (RegexValidator.isNotBlank(control.value)) {
          let minFiles = config.minFiles ? config.minFiles : 0;
          let maxFiles = config.maxFiles ? config.maxFiles : files.length;
          if (!(files.length > 0 && files[0] instanceof File && files.length >= minFiles && files.length <= maxFiles)) return ObjectMaker.toJson(AnnotationTypes.file, config, [files.length, minFiles, maxFiles]);
        }
      }
    }
    return ObjectMaker.null();
  };
}
function customValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (FormProvider.ProcessRule(control, config)) {
      const formGroupValue = ApplicationUtil.getParentObjectValue(control);
      const parentObject = control.parent ? control.parent.value : undefined;
      let result = null;
      for (let rule of config.customRules) {
        result = rule(formGroupValue, parentObject, config.additionalValue);
        if (result) break;
      }
      if (result) return result;
    }
    return ObjectMaker.null();
  };
}
function uniqueValidator(configModel) {
  var setTimeoutFunc = (invalidateControls, controlValues) => {
    let timeOut = setTimeout(() => {
      invalidateControls.forEach(t => {
        let isMatched = controlValues.filter(x => x == t.value)[0];
        if (!isMatched) t.updateValueAndValidity();
      });
      clearTimeout(timeOut);
    }, 200);
  };
  var additionalValidation = (config, fieldName, formGroup, formArray, currentValue) => {
    let indexOf = formArray.controls.indexOf(formGroup);
    let formArrayValue = [];
    if (indexOf != -1) {
      formArray.value.forEach((t, i) => {
        if (indexOf != i) formArrayValue.push(t);
      });
      return config.additionalValidation(currentValue, indexOf, fieldName, formGroup.value, formArrayValue);
    }
    return false;
  };
  return control => {
    let config = getConfigObject(configModel, control);
    if (FormProvider.ProcessRule(control, config)) {
      if (RegexValidator.isNotBlank(control.value)) {
        let formArray = ApplicationUtil.getParentFormArray(control);
        let parentFormGroup = control.parent ? control.parent : undefined;
        let invalidateControls = [];
        let controlValues = [];
        if (formArray && parentFormGroup) {
          let currentValue = control.value;
          let fieldName = ApplicationUtil.getFormControlName(control);
          let isMatched = false;
          for (let formGroup of formArray.controls) {
            if (formGroup != parentFormGroup) {
              isMatched = ApplicationUtil.toLower(formGroup.controls[fieldName].value) == ApplicationUtil.toLower(currentValue) && !(formGroup.controls[fieldName].errors && formGroup.controls[fieldName].errors[AnnotationTypes.unique]);
              if (formGroup.controls[fieldName].errors && formGroup.controls[fieldName].errors[AnnotationTypes.unique]) {
                var matchedControl = formArray.controls.filter(t => t.controls[fieldName] != formGroup.controls[fieldName] && ApplicationUtil.toLower(t.controls[fieldName].value) == ApplicationUtil.toLower(formGroup.controls[fieldName].value))[0];
                if (!matchedControl) invalidateControls.push(formGroup.controls[fieldName]);
              } else controlValues.push(formGroup.controls[fieldName].value);
            }
            if (isMatched) break;
          }
          if (invalidateControls.length > 0) setTimeoutFunc(invalidateControls, controlValues);
          let validation = false;
          if (config.additionalValidation) {
            validation = additionalValidation(config, fieldName, parentFormGroup, formArray, currentValue);
          }
          if (isMatched && !validation) return ObjectMaker.toJson(AnnotationTypes.unique, config, [control.value]);
        }
      }
    }
    return ObjectMaker.null();
  };
}
function imageValidator(configModel) {
  return (control, files) => {
    let config = getConfigObject(configModel, control);
    if (!control[VALIDATOR_CONFIG$2] || !control[VALIDATOR_CONFIG$2][AnnotationTypes.image]) ApplicationUtil.configureControl(control, config, AnnotationTypes.image);
    if (!files) return ObjectMaker.null();
    return new Promise((resolve, reject) => {
      if (FormProvider.ProcessRule(control, config)) {
        if (RegexValidator.isNotBlank(control.value)) {
          let testResult = false;
          for (var i = 0; i < files.length; i++) {
            let file = files.item(i);
            let type = file.type ? file.type.split('/') : [];
            testResult = type.length > 1 && type[0] == "image";
            if (!testResult) break;
            let image = new Image();
            config.minWidth = config.minWidth ? config.minWidth : 0;
            config.minHeight = config.minHeight ? config.minHeight : 0;
            image.onload = () => {
              testResult = image.width >= config.minWidth && image.height >= config.minHeight && image.width <= config.maxWidth && image.height <= config.maxHeight;
              if (!testResult) resolve(ObjectMaker.toJson(AnnotationTypes.image, config, [image.width, image.height]));else resolve(ObjectMaker.null());
            };
            image.onerror = () => {
              resolve(ObjectMaker.toJson(AnnotationTypes.image, config, []));
            };
            image.src = URL.createObjectURL(file);
          }
          if (!testResult) resolve(ObjectMaker.toJson(AnnotationTypes.image, config, []));
        }
      }
      return ObjectMaker.null();
    });
  };
}
function notEmptyValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (FormProvider.ProcessRule(control, config)) {
      if (!RegexValidator.isNotBlank(control.value, true)) {
        return ObjectMaker.toJson(AnnotationTypes.notEmpty, config, []);
      }
    }
    return ObjectMaker.null();
  };
}
function checkIpV4(value) {
  let isValid = RegexValidator.isValid(value, RegExRule.ipV4);
  if (isValid) {
    const splitDots = value.split('.');
    for (let ipNum of splitDots) {
      isValid = ipNum <= 255;
      if (!isValid) break;
    }
  }
  return isValid;
}
function checkIpV6(value) {
  return RegexValidator.isValid(value, RegExRule.ipV6);
}
function ipValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control, IP_CONFIG);
    if (ValidatorValueChecker.pass(control, config)) {
      let values = config.isCidr ? control.value.split('/') : [control.value];
      var isValid = config.version == IpVersion.V4 ? checkIpV4(values[0]) : config.version == IpVersion.V6 ? checkIpV6(values[0]) : checkIpV4(values[0]) || checkIpV6(values[0]);
      if (config.isCidr && isValid) {
        isValid = values.length > 1 ? config.version == IpVersion.V4 ? RegexValidator.isValid(values[1], RegExRule.cidrV4) : config.version == IpVersion.V6 ? RegexValidator.isValid(values[1], RegExRule.cidrV6) : RegexValidator.isValid(values[1], RegExRule.cidrV4) || RegexValidator.isValid(values[1], RegExRule.cidrV6) : false;
      }
      if (!isValid) return ObjectMaker.toJson(AnnotationTypes.ip, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function cusipValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      var controlValue = control.value.toUpperCase();
      let isValid = RegexValidator.isValid(controlValue, RegExRule.cusip);
      if (isValid) {
        let numericValues = controlValue.split("").map(value => {
          var charCode = value.charCodeAt(0);
          return charCode >= "A".charCodeAt(0) && charCode <= "Z".charCodeAt(0) ? charCode - "A".charCodeAt(0) + 10 : value;
        });
        let totalCount = 0;
        for (var i = 0; i < numericValues.length - 1; i++) {
          var numericValue = parseInt(numericValues[i], 10);
          if (i % 2 !== 0) {
            numericValue *= 2;
          }
          if (numericValue > 9) {
            numericValue -= 9;
          }
          totalCount += numericValue;
        }
        totalCount = (10 - totalCount % 10) % 10;
        isValid = totalCount == numericValues[numericValues.length - 1];
      }
      if (!isValid) return ObjectMaker.toJson(AnnotationTypes.cusip, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function gridValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      let controlValue = control.value.toUpperCase();
      var isValid = RegexValidator.isValid(controlValue, RegExRule.grid);
      if (isValid) {
        controlValue = controlValue.replace(/\s/g, '').replace(/-/g, '');
        if ('GRID:' === controlValue.substr(0, 5)) {
          controlValue = controlValue.substr(5);
        }
        let alphaNums = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var alphaNumLength = alphaNums.length,
          length = controlValue.length,
          check = Math.floor(alphaNumLength / 2);
        for (var i = 0; i < length; i++) {
          check = ((check || alphaNumLength) * 2 % (alphaNumLength + 1) + alphaNums.indexOf(controlValue.charAt(i))) % alphaNumLength;
        }
        isValid = check === 1;
      }
      if (!isValid) return ObjectMaker.toJson(AnnotationTypes.grid, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function dateValidator(configModel) {
  return control => {
    return validateDate(control, configModel, AnnotationTypes.date);
  };
}
function runCondition(leftValue, rightValue, operator) {
  let result = false;
  switch (operator) {
    case OPERATORS.lessThan:
    case OPERATORS.greaterThan:
      result = leftValue > rightValue;
      break;
    case OPERATORS.lessThanEqualTo:
    case OPERATORS.greaterThanEqualTo:
      result = leftValue >= rightValue;
      break;
  }
  return result;
}
function isValid(control, config) {
  return config.allowSeconds ? RegexValidator.isValid(control.value, RegExRule.timeWithSeconds) : RegexValidator.isValid(control.value, RegExRule.time);
}
function getTime(value) {
  let splitTime = value ? value.split(':') : [];
  return new Date(1970, 0, 1, splitTime[0] ? splitTime[0] : 0, splitTime[1] ? splitTime[1] : 0, splitTime[2] ? splitTime[2] : 0).getTime();
}
function timeChecker(control, config, operationType) {
  config = getConfigObject(config, control);
  if (FormProvider.ProcessRule(control, config)) {
    if (RegexValidator.isNotBlank(control.value)) {
      if (isValid(control, config)) {
        let crossFormControl = config.fieldName ? ApplicationUtil.getFormControl(config.fieldName, control) : undefined;
        let crossControlValue = crossFormControl ? getTime(crossFormControl.value) : getTime(config.value);
        let currentControlValue = getTime(control.value);
        let isValid = operationType == AnnotationTypes.minTime ? runCondition(currentControlValue, crossControlValue, config.operator || OPERATORS.greaterThanEqualTo) : runCondition(crossControlValue, currentControlValue, config.operator || OPERATORS.lessThanEqualTo);
        let additionalValue = {
          [operationType == AnnotationTypes.minTime ? "min" : "max"]: crossControlValue
        };
        if (!isValid) return ObjectMaker.toJson(operationType, config, [control.value], additionalValue);
      } else return ObjectMaker.toJson(operationType, config, [control.value]);
    }
  }
  return ObjectMaker.null();
}
function minTimeValidator(configModel) {
  return control => {
    return timeChecker(control, configModel, AnnotationTypes.minTime);
  };
}
function maxTimeValidator(configModel) {
  return control => {
    return timeChecker(control, configModel, AnnotationTypes.maxTime);
  };
}
function requiredTrueValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (FormProvider.ProcessRule(control, config)) {
      if (control.value !== true) {
        return ObjectMaker.toJson(AnnotationTypes.requiredTrue, config, []);
      }
    }
    return ObjectMaker.null();
  };
}
function maskValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (!control[VALIDATOR_CONFIG$2] || !control[VALIDATOR_CONFIG$2][AnnotationTypes.mask]) ApplicationUtil.configureControl(control, config, AnnotationTypes.mask);
    return null;
  };
}
const IBAN_COUNTRY_CODE_REGEX = {
  AD: /^(AD[0-9]{2})\d{8}[A-Z0-9]{12}$/,
  AE: /^(AE[0-9]{2})\d{3}\d{16}$/,
  AL: /^(AL[0-9]{2})\d{8}[A-Z0-9]{16}$/,
  AT: /^(AT[0-9]{2})\d{16}$/,
  AZ: /^(AZ[0-9]{2})[A-Z0-9]{4}\d{20}$/,
  BA: /^(BA[0-9]{2})\d{16}$/,
  BE: /^(BE[0-9]{2})\d{12}$/,
  BG: /^(BG[0-9]{2})[A-Z]{4}\d{6}[A-Z0-9]{8}$/,
  BH: /^(BH[0-9]{2})[A-Z]{4}[A-Z0-9]{14}$/,
  BR: /^(BR[0-9]{2})\d{23}[A-Z]{1}[A-Z0-9]{1}$/,
  BY: /^(BY[0-9]{2})[A-Z0-9]{4}\d{20}$/,
  CH: /^(CH[0-9]{2})\d{5}[A-Z0-9]{12}$/,
  CR: /^(CR[0-9]{2})\d{18}$/,
  CY: /^(CY[0-9]{2})\d{8}[A-Z0-9]{16}$/,
  CZ: /^(CZ[0-9]{2})\d{20}$/,
  DE: /^(DE[0-9]{2})\d{18}$/,
  DK: /^(DK[0-9]{2})\d{14}$/,
  DO: /^(DO[0-9]{2})[A-Z]{4}\d{20}$/,
  EE: /^(EE[0-9]{2})\d{16}$/,
  EG: /^(EG[0-9]{2})\d{25}$/,
  ES: /^(ES[0-9]{2})\d{20}$/,
  FI: /^(FI[0-9]{2})\d{14}$/,
  FO: /^(FO[0-9]{2})\d{14}$/,
  FR: /^(FR[0-9]{2})\d{10}[A-Z0-9]{11}\d{2}$/,
  GB: /^(GB[0-9]{2})[A-Z]{4}\d{14}$/,
  GE: /^(GE[0-9]{2})[A-Z0-9]{2}\d{16}$/,
  GI: /^(GI[0-9]{2})[A-Z]{4}[A-Z0-9]{15}$/,
  GL: /^(GL[0-9]{2})\d{14}$/,
  GR: /^(GR[0-9]{2})\d{7}[A-Z0-9]{16}$/,
  GT: /^(GT[0-9]{2})[A-Z0-9]{4}[A-Z0-9]{20}$/,
  HR: /^(HR[0-9]{2})\d{17}$/,
  HU: /^(HU[0-9]{2})\d{24}$/,
  IE: /^(IE[0-9]{2})[A-Z0-9]{4}\d{14}$/,
  IL: /^(IL[0-9]{2})\d{19}$/,
  IQ: /^(IQ[0-9]{2})[A-Z]{4}\d{15}$/,
  IR: /^(IR[0-9]{2})0\d{2}0\d{18}$/,
  IS: /^(IS[0-9]{2})\d{22}$/,
  IT: /^(IT[0-9]{2})[A-Z]{1}\d{10}[A-Z0-9]{12}$/,
  JO: /^(JO[0-9]{2})[A-Z]{4}\d{22}$/,
  KW: /^(KW[0-9]{2})[A-Z]{4}[A-Z0-9]{22}$/,
  KZ: /^(KZ[0-9]{2})\d{3}[A-Z0-9]{13}$/,
  LB: /^(LB[0-9]{2})\d{4}[A-Z0-9]{20}$/,
  LC: /^(LC[0-9]{2})[A-Z]{4}[A-Z0-9]{24}$/,
  LI: /^(LI[0-9]{2})\d{5}[A-Z0-9]{12}$/,
  LT: /^(LT[0-9]{2})\d{16}$/,
  LU: /^(LU[0-9]{2})\d{3}[A-Z0-9]{13}$/,
  LV: /^(LV[0-9]{2})[A-Z]{4}[A-Z0-9]{13}$/,
  MC: /^(MC[0-9]{2})\d{10}[A-Z0-9]{11}\d{2}$/,
  MD: /^(MD[0-9]{2})[A-Z0-9]{20}$/,
  ME: /^(ME[0-9]{2})\d{18}$/,
  MK: /^(MK[0-9]{2})\d{3}[A-Z0-9]{10}\d{2}$/,
  MR: /^(MR[0-9]{2})\d{23}$/,
  MT: /^(MT[0-9]{2})[A-Z]{4}\d{5}[A-Z0-9]{18}$/,
  MU: /^(MU[0-9]{2})[A-Z]{4}\d{19}[A-Z]{3}$/,
  NL: /^(NL[0-9]{2})[A-Z]{4}\d{10}$/,
  NO: /^(NO[0-9]{2})\d{11}$/,
  PK: /^(PK[0-9]{2})[A-Z0-9]{4}\d{16}$/,
  PL: /^(PL[0-9]{2})\d{24}$/,
  PS: /^(PS[0-9]{2})[A-Z0-9]{4}\d{21}$/,
  PT: /^(PT[0-9]{2})\d{21}$/,
  QA: /^(QA[0-9]{2})[A-Z]{4}[A-Z0-9]{21}$/,
  RO: /^(RO[0-9]{2})[A-Z]{4}[A-Z0-9]{16}$/,
  RS: /^(RS[0-9]{2})\d{18}$/,
  SA: /^(SA[0-9]{2})\d{2}[A-Z0-9]{18}$/,
  SC: /^(SC[0-9]{2})[A-Z]{4}\d{20}[A-Z]{3}$/,
  SE: /^(SE[0-9]{2})\d{20}$/,
  SI: /^(SI[0-9]{2})\d{15}$/,
  SK: /^(SK[0-9]{2})\d{20}$/,
  SM: /^(SM[0-9]{2})[A-Z]{1}\d{10}[A-Z0-9]{12}$/,
  SV: /^(SV[0-9]{2})[A-Z0-9]{4}\d{20}$/,
  TL: /^(TL[0-9]{2})\d{19}$/,
  TN: /^(TN[0-9]{2})\d{20}$/,
  TR: /^(TR[0-9]{2})\d{5}[A-Z0-9]{17}$/,
  UA: /^(UA[0-9]{2})\d{6}[A-Z0-9]{19}$/,
  VA: /^(VA[0-9]{2})\d{18}$/,
  VG: /^(VG[0-9]{2})[A-Z0-9]{4}\d{16}$/,
  XK: /^(XK[0-9]{2})\d{16}$/
};
function hasValidIbanFormat(value, countryCode) {
  const strippedStr = value.replace(/[\s\-]+/gi, '').toUpperCase();
  const isoCountryCode = countryCode || strippedStr.slice(0, 2).toUpperCase();
  return isoCountryCode in IBAN_COUNTRY_CODE_REGEX && IBAN_COUNTRY_CODE_REGEX[isoCountryCode].test(strippedStr);
}
function hasValidIbanChecksum(str) {
  const strippedStr = str.replace(/[^A-Z0-9]+/gi, '').toUpperCase(); // Keep only digits and A-Z latin alphabetic
  const rearranged = strippedStr.slice(4) + strippedStr.slice(0, 4);
  const alphaCapsReplacedWithDigits = rearranged.replace(/[A-Z]/g, char => char.charCodeAt(0) - 55);
  const remainder = alphaCapsReplacedWithDigits.match(/\d{1,7}/g).reduce((acc, value) => Number(acc + value) % 97, '');
  return remainder === 1;
}
function ibanValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      if (!(hasValidIbanFormat(control.value, config.countryCode) && hasValidIbanChecksum(control.value))) return ObjectMaker.toJson(AnnotationTypes.iban, config, [control.value, config.countryCode]);
    }
    return ObjectMaker.null();
  };
}
const APP_VALIDATORS = {
  "alphaNumeric": alphaNumericValidator,
  "alpha": alphaValidator,
  "compare": compareValidator,
  "email": emailValidator,
  "hexColor": hexColorValidator,
  "lowerCase": lowercaseValidator,
  "maxDate": maxDateValidator,
  "maxNumber": maxNumberValidator,
  "minDate": minDateValidator,
  "minNumber": minNumberValidator,
  "contains": containsValidator,
  "upperCase": uppercaseValidator,
  "maxLength": maxLengthValidator,
  "minLength": minLengthValidator,
  "password": passwordValidator,
  "range": rangeValidator,
  "required": requiredValidator,
  "creditCard": creditCardValidator,
  "digit": digitValidator,
  "pattern": patternValidator,
  "time": timeValidator,
  "url": urlValidator,
  "json": jsonValidator,
  "greaterThan": greaterThanValidator,
  "greaterThanEqualTo": greaterThanEqualToValidator,
  "lessThan": lessThanValidator,
  "lessThanEqualTo": lessThanEqualToValidator,
  "choice": choiceValidator,
  "different": differentValidator,
  "numeric": numericValidator,
  "even": evenValidator,
  "odd": oddValidator,
  "factor": factorValidator,
  "leapYear": leapYearValidator,
  "allOf": allOfValidator,
  "oneOf": oneOfValidator,
  "noneOf": noneOfValidator,
  "mac": macValidator,
  "ascii": asciiValidator,
  "dataUri": dataUriValidator,
  "port": portValidator,
  "latLong": latLongValidator,
  "extension": extensionValidator,
  "fileSize": fileSizeValidator,
  "endsWith": endsWithValidator,
  "startsWith": startsWithValidator,
  "primeNumber": primeNumberValidator,
  "latitude": latitudeValidator,
  "longitude": longitudeValidator,
  "compose": composeValidator,
  "rule": ruleValidator,
  "file": fileValidator,
  "unique": uniqueValidator,
  "image": imageValidator,
  "notEmpty": notEmptyValidator,
  "ip": ipValidator,
  "cusip": cusipValidator,
  "grid": gridValidator,
  "date": dateValidator,
  "minTime": minTimeValidator,
  "maxTime": maxTimeValidator,
  "requiredTrue": requiredTrueValidator,
  "mask": maskValidator,
  "iban": ibanValidator
};
function baseAsyncValidator(configModel, validatorName) {
  return control => {
    configModel = configModel || {};
    if (configModel.validatorConfig) {
      if (FormProvider.ProcessRule(control, configModel)) {
        return configModel.validatorConfig.pipe(map(resolveConfig(configModel, validatorName, control)));
      }
      return of(null);
    } else return of(resolveConfig(configModel, validatorName, control)(configModel));
  };
}
function resolveConfig(configModel, validatorName, control) {
  return config => {
    let configClone = {
      ...configModel,
      ...config,
      ...{
        expressionProcessed: true
      }
    };
    return APP_VALIDATORS[validatorName](configClone)(control);
  };
}
function alpha(config) {
  return baseDecoratorFunction(AnnotationTypes.alpha, config);
}
function alphaAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.alpha, [baseAsyncValidator(config, AnnotationTypes.alpha)], true);
}
function alphaNumeric(config) {
  return baseDecoratorFunction(AnnotationTypes.alphaNumeric, config);
}
function alphaNumericAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.alphaNumeric, [baseAsyncValidator(config, AnnotationTypes.alphaNumeric)], true);
}
function compare(config) {
  return baseDecoratorFunction(AnnotationTypes.compare, config);
}
function contains(config) {
  return baseDecoratorFunction(AnnotationTypes.contains, config);
}
function containsAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.contains, [baseAsyncValidator(config, AnnotationTypes.contains)], true);
}
function creditCard(config) {
  return baseDecoratorFunction(AnnotationTypes.creditCard, config);
}
function creditCardAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.creditCard, [baseAsyncValidator(config, AnnotationTypes.creditCard)], true);
}
function digit(config) {
  return baseDecoratorFunction(AnnotationTypes.digit, config);
}
function email(config) {
  return baseDecoratorFunction(AnnotationTypes.email, config);
}
function hexColor(config) {
  return baseDecoratorFunction(AnnotationTypes.hexColor, config);
}
function lowerCase(config) {
  return baseDecoratorFunction(AnnotationTypes.lowerCase, config);
}
function maxDate(config) {
  return baseDecoratorFunction(AnnotationTypes.maxDate, config);
}
function maxDateAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.maxDate, [baseAsyncValidator(config, AnnotationTypes.maxDate)], true);
}
function maxLength(config) {
  return baseDecoratorFunction(AnnotationTypes.maxLength, config);
}
function maxLengthAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.maxLength, [baseAsyncValidator(config, AnnotationTypes.maxLength)], true);
}
function minDate(config) {
  return baseDecoratorFunction(AnnotationTypes.minDate, config);
}
function minDateAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.minDate, [baseAsyncValidator(config, AnnotationTypes.minDate)], true);
}
function maxNumber(config) {
  return baseDecoratorFunction(AnnotationTypes.maxNumber, config);
}
function maxNumberAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.maxNumber, [baseAsyncValidator(config, AnnotationTypes.maxNumber)], true);
}
function minLength(config) {
  return baseDecoratorFunction(AnnotationTypes.minLength, config);
}
function minLengthAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.minLength, [baseAsyncValidator(config, AnnotationTypes.minLength)], true);
}
function minNumber(config) {
  return baseDecoratorFunction(AnnotationTypes.minNumber, config);
}
function minNumberAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.minNumber, [baseAsyncValidator(config, AnnotationTypes.minNumber)], true);
}
function password(config) {
  return baseDecoratorFunction(AnnotationTypes.password, config);
}
function passwordAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.password, [baseAsyncValidator(config, AnnotationTypes.password)], true);
}
function pattern(config) {
  return baseDecoratorFunction(AnnotationTypes.pattern, config);
}
function patternAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.pattern, [baseAsyncValidator(config, AnnotationTypes.pattern)], true);
}
function propArray(entity, config) {
  return function (target, propertyKey, parameterIndex) {
    var propertyInfo = {
      name: propertyKey,
      propertyType: ARRAY_PROPERTY,
      entity: entity,
      dataPropertyName: config ? config.name : undefined,
      entityProvider: config ? config.entityProvider : undefined,
      arrayConfig: config ? {
        allowMaxIndex: config.allowMaxIndex,
        messageKey: config.messageKey,
        createBlank: config.createBlank
      } : undefined
    };
    defaultContainer.addProperty(target.constructor, propertyInfo);
  };
}
function propObject(entity, config) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.initPropertyObject(propertyKey, OBJECT_PROPERTY, entity, target, config);
  };
}
function prop(config) {
  return function (target, propertyKey, parameterIndex) {
    var propertyInfo = {
      name: propertyKey,
      propertyType: PROPERTY,
      dataPropertyName: config ? config.name : undefined,
      defaultValue: config ? config.defaultValue : undefined,
      ignore: config ? config.ignore : undefined,
      isPrimaryKey: config ? config.isPrimaryKey : undefined,
      messageNexus: config ? config.messageNexus : undefined
    };
    defaultContainer.addProperty(target.constructor, propertyInfo);
  };
}
function range(config) {
  return baseDecoratorFunction(AnnotationTypes.range, config);
}
function rangeAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.range, [baseAsyncValidator(config, AnnotationTypes.range)], true);
}
function required(config) {
  return baseDecoratorFunction(AnnotationTypes.required, config);
}
function upperCase(config) {
  return baseDecoratorFunction(AnnotationTypes.upperCase, config);
}
function time(config) {
  return baseDecoratorFunction(AnnotationTypes.time, config);
}
function timeAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.time, [baseAsyncValidator(config, AnnotationTypes.time)], true);
}
function url(config) {
  return baseDecoratorFunction(AnnotationTypes.url, config);
}
function urlAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.url, [baseAsyncValidator(config, AnnotationTypes.url)], true);
}
function json(config) {
  return baseDecoratorFunction(AnnotationTypes.json, config);
}
function greaterThan(config) {
  return baseDecoratorFunction(AnnotationTypes.greaterThan, config);
}
function greaterThanAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.greaterThan, [baseAsyncValidator(config, AnnotationTypes.greaterThan)], true);
}
function greaterThanEqualTo(config) {
  return baseDecoratorFunction(AnnotationTypes.greaterThanEqualTo, config);
}
function greaterThanEqualToAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.greaterThanEqualTo, [baseAsyncValidator(config, AnnotationTypes.greaterThanEqualTo)], true);
}
function lessThanEqualTo(config) {
  return baseDecoratorFunction(AnnotationTypes.lessThanEqualTo, config);
}
function lessThanEqualToAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.lessThanEqualTo, [baseAsyncValidator(config, AnnotationTypes.lessThanEqualTo)], true);
}
function lessThan(config) {
  return baseDecoratorFunction(AnnotationTypes.lessThan, config);
}
function lessThanAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.lessThan, [baseAsyncValidator(config, AnnotationTypes.lessThan)], true);
}
function choice(config) {
  return baseDecoratorFunction(AnnotationTypes.choice, config);
}
function choiceAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.choice, [baseAsyncValidator(config, AnnotationTypes.choice)], true);
}
function different(config) {
  return baseDecoratorFunction(AnnotationTypes.different, config);
}
function numeric(config) {
  return baseDecoratorFunction(AnnotationTypes.numeric, config);
}
function numericAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.numeric, [baseAsyncValidator(config, AnnotationTypes.numeric)], true);
}
function even(config) {
  return baseDecoratorFunction(AnnotationTypes.even, config);
}
function odd(config) {
  return baseDecoratorFunction(AnnotationTypes.odd, config);
}
function factor(config) {
  return baseDecoratorFunction(AnnotationTypes.factor, config);
}
function factorAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.factor, [baseAsyncValidator(config, AnnotationTypes.factor)], true);
}
function leapYear(config) {
  return baseDecoratorFunction(AnnotationTypes.leapYear, config);
}
function allOf(config) {
  return baseDecoratorFunction(AnnotationTypes.allOf, config);
}
function allOfAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.allOf, [baseAsyncValidator(config, AnnotationTypes.allOf)], true);
}
function oneOf(config) {
  return baseDecoratorFunction(AnnotationTypes.oneOf, config);
}
function oneOfAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.oneOf, [baseAsyncValidator(config, AnnotationTypes.oneOf)], true);
}
function noneOf(config) {
  return baseDecoratorFunction(AnnotationTypes.noneOf, config);
}
function noneOfAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.noneOf, [baseAsyncValidator(config, AnnotationTypes.noneOf)], true);
}
function mac(config) {
  return baseDecoratorFunction(AnnotationTypes.mac, config);
}
function ascii(config) {
  return baseDecoratorFunction(AnnotationTypes.ascii, config);
}
function dataUri(config) {
  return baseDecoratorFunction(AnnotationTypes.dataUri, config);
}
function port(config) {
  return baseDecoratorFunction(AnnotationTypes.port, config);
}
function latLong(config) {
  return baseDecoratorFunction(AnnotationTypes.latLong, config);
}
function extension(config) {
  return baseDecoratorFunction(AnnotationTypes.extension, config);
}
function extensionAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.extension, [baseAsyncValidator(config, AnnotationTypes.extension)], true);
}
function fileSize(config) {
  return baseDecoratorFunction(AnnotationTypes.fileSize, config);
}
function fileSizeAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.fileSize, [baseAsyncValidator(config, AnnotationTypes.fileSize)], true);
}
function endsWith(config) {
  return baseDecoratorFunction(AnnotationTypes.endsWith, config);
}
function endsWithAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.endsWith, [baseAsyncValidator(config, AnnotationTypes.endsWith)], true);
}
function startsWith(config) {
  return baseDecoratorFunction(AnnotationTypes.startsWith, config);
}
function startsWithAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.startsWith, [baseAsyncValidator(config, AnnotationTypes.startsWith)], true);
}
function primeNumber(config) {
  return baseDecoratorFunction(AnnotationTypes.primeNumber, config);
}
function latitude(config) {
  return baseDecoratorFunction(AnnotationTypes.latitude, config);
}
function longitude(config) {
  return baseDecoratorFunction(AnnotationTypes.longitude, config);
}
function rule(config) {
  return baseDecoratorFunction(AnnotationTypes.rule, config);
}
function file(config) {
  return baseDecoratorFunction(AnnotationTypes.file, config);
}
function fileAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.file, [baseAsyncValidator(config, AnnotationTypes.file)], true);
}
function custom(config) {
  return baseDecoratorFunction(AnnotationTypes.custom, config);
}
function customAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.custom, [baseAsyncValidator(config, AnnotationTypes.custom)], true);
}
function unique(config) {
  return baseDecoratorFunction(AnnotationTypes.unique, config);
}
function image(config) {
  return baseDecoratorFunction(AnnotationTypes.image, config);
}
function imageAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.image, [baseAsyncValidator(config, AnnotationTypes.image)], true);
}
function notEmpty(config) {
  return baseDecoratorFunction(AnnotationTypes.notEmpty, config);
}
function async(validators) {
  return baseDecoratorFunction(AnnotationTypes.async, validators, true);
}
function cusip(config) {
  return baseDecoratorFunction(AnnotationTypes.cusip, config);
}
function grid(config) {
  return baseDecoratorFunction(AnnotationTypes.grid, config);
}
function date(config) {
  return baseDecoratorFunction(AnnotationTypes.date, config);
}
function dateAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.date, [baseAsyncValidator(config, AnnotationTypes.date)], true);
}
function disable(config) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addDecoratorConfig(target, parameterIndex, propertyKey, config, DECORATORS.disabled);
  };
}
function error(config) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addDecoratorConfig(target, parameterIndex, propertyKey, config, DECORATORS.error);
  };
}
function and(config) {
  return baseDecoratorFunction(AnnotationTypes.and, config);
}
function or(config) {
  return baseDecoratorFunction(AnnotationTypes.or, config);
}
function not(config) {
  return baseDecoratorFunction(AnnotationTypes.not, config);
}
function trim() {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.trim);
  };
}
function ltrim() {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.ltrim);
  };
}
function rtrim() {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.rtrim);
  };
}
function blacklist(chars) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.blacklist, chars);
  };
}
function stripLow(keepNewLines) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.stripLow, keepNewLines);
  };
}
function toBoolean(strict) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.toBoolean, strict);
  };
}
function toDouble() {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.toDouble);
  };
}
function toFloat() {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.toFloat);
  };
}
function toInt(radix) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.toInt, radix);
  };
}
function toString() {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.string);
  };
}
function whitelist(chars) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.whitelist, chars);
  };
}
function toDate(config) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.toDate, config);
  };
}
function escape() {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.escape);
  };
}
function prefix(text) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.prefix, text);
  };
}
function suffix(text) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.suffix, text);
  };
}
function model(config) {
  return function (target) {
    defaultContainer.addPropsConfig(target, config);
  };
}
function sanitize(config) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addSanitizer(target, parameterIndex, propertyKey, DECORATORS.sanitize, config);
  };
}
function elementClass(config) {
  return function (target, propertyKey, parameterIndex) {
    defaultContainer.addDecoratorConfig(target, parameterIndex, propertyKey, config, DECORATORS.elementClass);
  };
}
function minTime(config) {
  return baseDecoratorFunction(AnnotationTypes.minTime, config);
}
function minTimeAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.minTime, [baseAsyncValidator(config, AnnotationTypes.minTime)], true);
}
function maxTime(config) {
  return baseDecoratorFunction(AnnotationTypes.maxTime, config);
}
function maxTimeAsync(config) {
  return baseDecoratorFunction(AnnotationTypes.maxTime, [baseAsyncValidator(config, AnnotationTypes.maxTime)], true);
}
function compose(config) {
  return baseDecoratorFunction(AnnotationTypes.compose, config);
}
function requiredTrue(config) {
  return baseDecoratorFunction(AnnotationTypes.requiredTrue, config);
}
function mask(config) {
  return baseDecoratorFunction(AnnotationTypes.mask, config);
}
function updateOn(config) {
  return baseDecoratorFunction(AnnotationTypes.updateOn, config);
}
class HtmlControlTemplateDirective {
  constructor(templateRef) {
    this.templateRef = templateRef;
  }
}
HtmlControlTemplateDirective.ɵfac = function HtmlControlTemplateDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || HtmlControlTemplateDirective)(i0.ɵɵdirectiveInject(i0.TemplateRef));
};
HtmlControlTemplateDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: HtmlControlTemplateDirective,
  selectors: [["", "htmlControlTemplate", ""]],
  inputs: {
    type: [0, "htmlControlTemplate", "type"]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HtmlControlTemplateDirective, [{
    type: Directive,
    args: [{
      selector: '[htmlControlTemplate]'
    }]
  }], function () {
    return [{
      type: i0.TemplateRef
    }];
  }, {
    type: [{
      type: Input,
      args: ['htmlControlTemplate']
    }]
  });
})();
class BaseDirective {
  applyValidations(controls, model = null) {
    if (this.model) {
      let modelConfig = defaultContainer.get(model || this.model.constructor);
      if (modelConfig) {
        modelConfig.properties.forEach(property => {
          if (controls[property.name]) {
            switch (property.propertyType) {
              case PROPERTY:
                this.setValidatorConfig(controls[property.name], modelConfig, property);
                break;
              case OBJECT_PROPERTY:
                this.applyValidations(controls[property.name].controls, property.entity);
                break;
            }
          }
        });
      }
    }
  }
  setValidatorConfig(control, modelConfig, property) {
    let annotations = modelConfig.propertyAnnotations.filter(t => t.propertyName == property.name);
    annotations.forEach(annotation => {
      if (!control[TEMPLATE_VALIDATION_CONFIG]) control[TEMPLATE_VALIDATION_CONFIG] = {};
      ApplicationUtil.configureControl(control, annotation.config ? annotation.config : "", annotation.annotationType);
    });
  }
}
BaseDirective.ɵfac = function BaseDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || BaseDirective)();
};
BaseDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BaseDirective,
  inputs: {
    model: "model"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseDirective, [{
    type: Directive
  }], null, {
    model: [{
      type: Input
    }]
  });
})();
const DISABLED_EXPRESSION = "disableExpression";
function conditionalChangeValidator(conditionalValidationProps) {
  var timeOuts = [];
  var oldValue = undefined;
  var setTimeOut = (control, config) => {
    if (control[DISABLED_EXPRESSION]) runDisabledExpression(control, config);
    var timeOut = setTimeout(t => {
      clearTimeout(timeOut);
      control.updateValueAndValidity({
        emitEvent: false
      });
    }, 100);
  };
  return control => {
    let value = control.value;
    if (control.parent && oldValue != value) {
      const rootFormGroup = ApplicationUtil.getRootFormGroup(control);
      const parentFormGroup = control.parent;
      oldValue = value;
      timeOuts = [];
      let controlName = ApplicationUtil.getFormControlName(control);
      let disabledConfig = {
        [controlName]: value
      };
      conditionalValidationProps.forEach(t => {
        let a = control;
        if (t.indexOf("[]") != -1) {
          var splitText = t.split("[]");
          var formArray = rootFormGroup.get([splitText[0]]);
          if (formArray) formArray.controls.forEach(formGroup => {
            var abstractControl = formGroup.get(splitText[1]);
            if (abstractControl) {
              setTimeOut(abstractControl, disabledConfig);
            }
          });
        } else {
          let splitText = t.split('.');
          if (splitText.length > 1) {
            var control = null;
            t.split('.').forEach((name, index) => {
              control = index == 0 ? rootFormGroup.controls[name] : control.controls[name];
            });
          } else {
            control = parentFormGroup.controls[t];
          }
          if (control) {
            setTimeOut(control, disabledConfig);
          }
        }
      });
    }
    return ObjectMaker.null();
  };
}
function runDisabledExpression(control, config) {
  let isDisabled = FormProvider.ProcessRule(control, {
    conditionalExpression: control[DISABLED_EXPRESSION],
    disableConfig: config
  });
  if (isDisabled && !control.disabled) control.disable();else if (control.disabled) control.enable();
}
class RxwebFormDirective extends BaseDirective {
  constructor() {
    super(...arguments);
    this.clearTimeoutNumber = 0;
    this.validationRule = {};
  }
  ngAfterContentInit() {
    if (this.formGroup && !this.formGroup[MODEL] && this.formGroup.parent == null) {
      this.expressionProcessor(this.formGroup.controls);
      this.setConditionalValidator(this.formGroup.controls);
    } else if (this.formGroup && !this.formGroup[MODEL] && this.formGroup.parent instanceof FormArray) {
      this.expressionProcessor(this.formGroup.controls);
      this.setConditionalValidator(this.formGroup.controls);
    } else if (this.ngForm) {
      this.configureModelValidations();
    }
  }
  configureModelValidations() {
    this.clearTimeoutNumber = setTimeout(() => {
      clearTimeout(this.clearTimeoutNumber);
      this.applyValidations(this.ngForm.form.controls);
      this.expressionProcessor(this.ngForm.form.controls);
      this.setConditionalValidator(this.ngForm.form.controls);
      this.updateValueAndValidity(this.ngForm.form.controls);
    }, 500);
  }
  updateValueAndValidity(controls) {
    Object.keys(controls).forEach(key => {
      if (controls[key] instanceof FormGroup) this.updateValueAndValidity(controls[key].controls);else if (controls[key] instanceof FormArray) this.updateValueAndValidity(controls[key].controls);else controls[key].updateValueAndValidity();
    });
  }
  expressionProcessor(controls, rootFieldName = "") {
    Object.keys(controls).forEach(fieldName => {
      let formControl = controls[fieldName];
      if (formControl.validatorConfig) {
        Object.keys(AnnotationTypes).forEach(validatorName => {
          if (formControl.validatorConfig[validatorName] && formControl.validatorConfig[validatorName].disableExpression) {
            formControl["disableExpression"] = formControl.validatorConfig[validatorName].disableExpression;
            let columns = Linq.expressionColumns(formControl.validatorConfig[validatorName].disableExpression);
            columns.forEach(t => {
              defaultContainer.setConditionalValueProp(this.validationRule, rootFieldName + t.propName, fieldName);
            });
          }
          if (formControl.validatorConfig[validatorName] && formControl.validatorConfig[validatorName].conditionalExpression) {
            let columns = Linq.expressionColumns(formControl.validatorConfig[validatorName].conditionalExpression);
            columns.forEach(t => {
              defaultContainer.setConditionalValueProp(this.validationRule, rootFieldName + t.propName, fieldName);
            });
          }
          if (formControl.validatorConfig[validatorName] && formControl.validatorConfig[validatorName].dynamicConfig) {
            let columns = Linq.dynamicConfigParser(formControl.validatorConfig[validatorName].dynamicConfig, fieldName);
            columns.forEach(t => {
              defaultContainer.setConditionalValueProp(this.validationRule, rootFieldName + t.propName, fieldName);
            });
          }
          if (formControl.validatorConfig[validatorName] && (validatorName == AnnotationTypes.and || validatorName == AnnotationTypes.or || validatorName == AnnotationTypes.not)) {
            Object.keys(formControl.validatorConfig[validatorName].validation).forEach(t => {
              if (typeof formControl.validatorConfig[validatorName].validation[t] !== "boolean") defaultContainer.setLogicalConditional(this.validationRule, t, formControl.validatorConfig[validatorName].validation[t].fieldName, fieldName);
            });
          } else if (formControl.validatorConfig[validatorName] && (validatorName == AnnotationTypes.compare || validatorName == AnnotationTypes.greaterThan || validatorName == AnnotationTypes.greaterThanEqualTo || validatorName == AnnotationTypes.lessThan || validatorName == AnnotationTypes.lessThanEqualTo || validatorName == AnnotationTypes.different || validatorName == AnnotationTypes.factor || validatorName == AnnotationTypes.minTime || validatorName == AnnotationTypes.maxTime || validatorName == AnnotationTypes.creditCard && formControl.validatorConfig[validatorName].fieldName || (validatorName == AnnotationTypes.minDate || validatorName == AnnotationTypes.maxDate) && formControl.validatorConfig[validatorName].fieldName)) {
            defaultContainer.setConditionalValueProp(this.validationRule, formControl.validatorConfig[validatorName].fieldName, fieldName);
          }
        });
      } else if (formControl instanceof FormGroup) {
        this.expressionProcessor(formControl.controls, `${fieldName}.`);
      } else if (formControl instanceof FormArray) {
        if (formControl.controls) formControl.controls.forEach((t, i) => {
          if (t.controls) this.expressionProcessor(t.controls, `${fieldName}[]`);
        });
      }
    });
  }
  setConditionalValidator(controls) {
    Object.keys(controls).forEach(fieldName => {
      if (this.validationRule.conditionalValidationProps && this.validationRule.conditionalValidationProps[fieldName]) {
        controls[fieldName][CONDITIONAL_VALIDATOR] = conditionalChangeValidator(this.validationRule.conditionalValidationProps[fieldName]);
      } else if (controls[fieldName] instanceof FormGroup && this.validationRule.conditionalObjectProps) {
        var fields = this.validationRule.conditionalObjectProps.filter(t => t.objectPropName == fieldName);
        let nestedFormGroup = controls[fieldName];
        let propWiseConditionalControls = {};
        fields.forEach(x => {
          if (!propWiseConditionalControls[x.propName]) propWiseConditionalControls[x.propName] = [];
          propWiseConditionalControls[x.propName].push(x.referencePropName);
        });
        Object.keys(propWiseConditionalControls).forEach(key => {
          nestedFormGroup.controls[key][CONDITIONAL_VALIDATOR] = conditionalChangeValidator(propWiseConditionalControls[key]);
        });
      } else if (controls[fieldName] instanceof FormArray) {
        //fix https://github.com/rxweb/rxweb/issues/274
        controls[fieldName].controls.forEach((t, i) => {
          if (t.controls == undefined) this.setConditionalValidator({
            [i]: t
          });else this.setConditionalValidator(t.controls);
        });
      }
    });
  }
  ngOnDestroy() {}
}
RxwebFormDirective.ɵfac = /* @__PURE__ */(() => {
  let ɵRxwebFormDirective_BaseFactory;
  return function RxwebFormDirective_Factory(__ngFactoryType__) {
    return (ɵRxwebFormDirective_BaseFactory || (ɵRxwebFormDirective_BaseFactory = i0.ɵɵgetInheritedFactory(RxwebFormDirective)))(__ngFactoryType__ || RxwebFormDirective);
  };
})();
RxwebFormDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RxwebFormDirective,
  selectors: [["", "formGroup", ""], ["", "rxwebForm", ""]],
  inputs: {
    formGroup: "formGroup",
    ngForm: [0, "rxwebForm", "ngForm"]
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RxwebFormDirective, [{
    type: Directive,
    args: [{
      selector: '[formGroup],[rxwebForm]'
    }]
  }], null, {
    formGroup: [{
      type: Input
    }],
    ngForm: [{
      type: Input,
      args: ['rxwebForm']
    }]
  });
})();
class AsyncValidationDirective {
  validate(control) {
    if (this.async) return this.async(control);
    return of(null);
  }
}
AsyncValidationDirective.ɵfac = function AsyncValidationDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AsyncValidationDirective)();
};
AsyncValidationDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: AsyncValidationDirective,
  selectors: [["", "ngModel", ""], ["", "formControlName", ""], ["", "formControl", ""]],
  inputs: {
    async: "async"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_ASYNC_VALIDATORS,
    useExisting: forwardRef(() => AsyncValidationDirective),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AsyncValidationDirective, [{
    type: Directive,
    args: [{
      selector: '[ngModel],[formControlName],[formControl]',
      providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: forwardRef(() => AsyncValidationDirective),
        multi: true
      }]
    }]
  }], null, {
    async: [{
      type: Input
    }]
  });
})();
const VALIDATOR_CONFIG$1 = "validatorConfig";
const FILE_VALIDATOR_NAMES = ["extension", "fileSize", "file"];
class FileControlDirective {
  constructor(elementRef) {
    this.elementRef = elementRef;
    this.isProcessed = false;
    this.validators = [];
    this.onChange = _ => {};
    this.onTouched = () => {};
    this.element = elementRef.nativeElement;
  }
  onChangeCall(element) {
    let files = element.files;
    if (this.writeFile) this.onChange(files);else {
      if (files.length > 0) this.onChange(element.value);else this.onChange(undefined);
    }
  }
  writeValue(value) {}
  registerOnChange(invocation) {
    this.onChange = invocation;
  }
  registerOnTouched(invocation) {
    this.onTouched = invocation;
  }
  set extension(config) {
    this.pushValidator(FILE_VALIDATOR_NAMES[0], config);
  }
  set fileSize(config) {
    this.pushValidator(FILE_VALIDATOR_NAMES[1], config);
  }
  set file(config) {
    this.pushValidator(FILE_VALIDATOR_NAMES[2], config);
  }
  setConfig(control) {
    FILE_VALIDATOR_NAMES.forEach(t => {
      if (!this[t] && control[VALIDATOR_CONFIG$1] && control[VALIDATOR_CONFIG$1][t]) this[t] = control[VALIDATOR_CONFIG$1][t];
    });
    this.isProcessed = true;
  }
  pushValidator(validatorName, config) {
    if (config) this.validators.push(APP_VALIDATORS[validatorName](config));
  }
  validate(control) {
    if (!this.isProcessed) this.setConfig(control);
    var result = null;
    for (var validator of this.validators) {
      result = validator(control, this.element.files);
      if (result) break;
    }
    return result;
  }
}
FileControlDirective.ɵfac = function FileControlDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || FileControlDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
FileControlDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FileControlDirective,
  selectors: [["input", "type", "file"]],
  hostBindings: function FileControlDirective_HostBindings(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵlistener("change", function FileControlDirective_change_HostBindingHandler($event) {
        return ctx.onChangeCall($event.target);
      })("blur", function FileControlDirective_blur_HostBindingHandler() {
        return ctx.onTouched();
      });
    }
  },
  inputs: {
    writeFile: "writeFile",
    extension: "extension",
    fileSize: "fileSize",
    file: "file"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: FileControlDirective,
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => FileControlDirective),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FileControlDirective, [{
    type: Directive,
    args: [{
      selector: "input[type=file]",
      host: {
        "(change)": "onChangeCall($event.target)",
        "(blur)": "onTouched()"
      },
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: FileControlDirective,
        multi: true
      }, {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => FileControlDirective),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    writeFile: [{
      type: Input
    }],
    extension: [{
      type: Input
    }],
    fileSize: [{
      type: Input
    }],
    file: [{
      type: Input
    }]
  });
})();
const VALIDATOR_CONFIG = "validatorConfig";
class ImageFileControlDirective {
  constructor(elementRef) {
    this.elementRef = elementRef;
    this.isProcessed = false;
    this.element = elementRef.nativeElement;
  }
  set image(config) {
    this.imageValidation = APP_VALIDATORS.image(config);
  }
  setConfig(control) {
    let image = "image";
    if (!this[image] && control[VALIDATOR_CONFIG] && control[VALIDATOR_CONFIG][image]) this[image] = control[VALIDATOR_CONFIG][image];
    this.isProcessed = true;
  }
  validate(control) {
    if (!this.isProcessed) this.setConfig(control);
    if (this.imageValidation) {
      return this.imageValidation(control, this.element.files);
    }
    return of(null);
  }
}
ImageFileControlDirective.ɵfac = function ImageFileControlDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ImageFileControlDirective)(i0.ɵɵdirectiveInject(i0.ElementRef));
};
ImageFileControlDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ImageFileControlDirective,
  selectors: [["input", "type", "file"]],
  inputs: {
    image: "image"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_ASYNC_VALIDATORS,
    useExisting: forwardRef(() => ImageFileControlDirective),
    multi: true
  }])]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ImageFileControlDirective, [{
    type: Directive,
    args: [{
      selector: "input[type=file]",
      providers: [{
        provide: NG_ASYNC_VALIDATORS,
        useExisting: forwardRef(() => ImageFileControlDirective),
        multi: true
      }]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }];
  }, {
    image: [{
      type: Input
    }]
  });
})();
class ControlExpressionProcess {
  constructor() {
    this.controlConfig = {};
    this.isProcessed = false;
  }
  setModelConfig(control) {
    this.isProcessed = true;
    if (this.controlConfig && this.controlConfig.validatorConfig) {
      control[VALIDATOR_CONFIG$2] = this.controlConfig.validatorConfig;
      this.controlConfig = undefined;
    }
  }
}
ControlExpressionProcess.ɵfac = function ControlExpressionProcess_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ControlExpressionProcess)();
};
ControlExpressionProcess.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ControlExpressionProcess,
  inputs: {
    name: "name",
    formControlName: "formControlName"
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ControlExpressionProcess, [{
    type: Directive
  }], null, {
    name: [{
      type: Input
    }],
    formControlName: [{
      type: Input
    }]
  });
})();
class BaseValidator extends ControlExpressionProcess {
  constructor() {
    super(...arguments);
    this.validators = [];
  }
  validation(control) {
    let result = null;
    for (let validator of this.validators) {
      result = validator(control);
      if (result) break;
    }
    if (!result && this.maskProvider) result = this.maskProvider.validate();
    return result;
  }
  setEventName() {
    var eventName = '';
    switch (this.element.tagName) {
      case INPUT:
      case TEXTAREA:
        eventName = this.element.type == CHECKBOX || this.element.type == RADIO || this.element.type == FILE ? CHANGE : INPUT;
        break;
      case SELECT:
        eventName = CHANGE;
        break;
    }
    this.eventName = eventName.toLowerCase();
  }
}
BaseValidator.ɵfac = /* @__PURE__ */(() => {
  let ɵBaseValidator_BaseFactory;
  return function BaseValidator_Factory(__ngFactoryType__) {
    return (ɵBaseValidator_BaseFactory || (ɵBaseValidator_BaseFactory = i0.ɵɵgetInheritedFactory(BaseValidator)))(__ngFactoryType__ || BaseValidator);
  };
})();
BaseValidator.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: BaseValidator,
  inputs: {
    formControl: "formControl"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseValidator, [{
    type: Directive
  }], null, {
    formControl: [{
      type: Input
    }]
  });
})();
const SIMPLE_EMAIL_VALIDATION = "simple-email-validation";
const ADVANCED_EMAIL_VALIDATION = "advanced-email-validation";
class MaskProvider {
  constructor(input, mask, renderer, formControl, config) {
    this.input = input;
    this.mask = mask;
    this.renderer = renderer;
    this.formControl = formControl;
    this.config = config;
    this.eventListeners = [];
    this.oldValue = '';
    this.type = 'text';
    this.slotChar = '_';
    this.autoClear = false;
    this.isInvalid = false;
    this.internalProcess = false;
    this.bind();
  }
  bind() {
    if (RegexValidator.isNotBlank(this.formControl.value)) this.input.value = this.formControl.value;
    this.tests = [];
    this.partialPosition = this.mask.length;
    this.len = this.mask.length;
    this.firstNonMaskPos = null;
    this.defs = {
      '9': '[0-9]',
      'a': '[A-Za-z]',
      '*': '[A-Za-z0-9]'
    };
    this.androidChrome = false;
    let maskTokens = this.mask.split('');
    for (let i = 0; i < maskTokens.length; i++) {
      let c = maskTokens[i];
      if (c == '?') {
        this.len--;
        this.partialPosition = i;
      } else if (this.defs[c]) {
        this.tests.push(new RegExp(this.defs[c]));
        if (this.firstNonMaskPos === null) {
          this.firstNonMaskPos = this.tests.length - 1;
        }
        if (i < this.partialPosition) {
          this.lastRequiredNonMaskPos = this.tests.length - 1;
        }
      } else {
        this.tests.push(null);
      }
    }
    this.minLength = this.config.minLength ? this.lastRequiredNonMaskPos - (this.lastRequiredNonMaskPos - this.config.minLength) : this.lastRequiredNonMaskPos;
    this.buffer = [];
    for (let i = 0; i < maskTokens.length; i++) {
      let c = maskTokens[i];
      if (c != '?') {
        if (this.defs[c]) this.buffer.push(this.getPlaceholder(i));else this.buffer.push(c);
      }
    }
    this.defaultBuffer = this.buffer.join('');
    this.focusText = this.input.value;
    this.bindEvents();
    this.checkVal();
  }
  bindEvents() {
    this.eventListeners.push(this.renderer.listen(this.input, FOCUS, this.onFocus.bind(this)));
    this.eventListeners.push(this.renderer.listen(this.input, BLUR, this.onBlur.bind(this)));
    this.eventListeners.push(this.renderer.listen(this.input, KEY_DOWN, this.onKeyDown.bind(this)));
    this.eventListeners.push(this.renderer.listen(this.input, KEY_PRESS, this.onKeyPress.bind(this)));
    this.eventListeners.push(this.renderer.listen(this.input, "input", this.onInput.bind(this)));
    this.eventListeners.push(this.renderer.listen(this.input, PASTE, this.handleInputChange.bind(this)));
  }
  validate() {
    if (this.input.value && this.oldValue != this.input.value) {
      this.checkVal(true);
      this.isCompleted(null, true);
      this.oldValue = this.input.value;
    }
    let config = getConfigObject(this.config, this.formControl);
    if (RegexValidator.isNotBlank(this.getUnmaskedValue()) && FormProvider.ProcessRule(this.formControl, config)) {
      if (this.isInvalid) {
        return ObjectMaker.toJson(AnnotationTypes.mask, config, [this.formControl.value]);
      }
    }
    return ObjectMaker.null();
  }
  writeValue(value) {
    this.value = value;
    if (this.input) {
      if (this.value == undefined || this.value == null) {
        this.input.value = '';
      }
      this.checkVal();
    }
    this.updateFilledState();
  }
  caret(first, last) {
    let range, begin, end;
    if (!this.input.offsetParent || this.input !== document.activeElement) {
      return;
    }
    if (typeof first == 'number') {
      begin = first;
      end = typeof last === 'number' ? last : begin;
      if (this.input.setSelectionRange) {
        this.input.setSelectionRange(begin, end);
      } else if (this.input['createTextRange']) {
        range = this.input['createTextRange']();
        range.collapse(true);
        range.moveEnd('character', end);
        range.moveStart('character', begin);
        range.select();
      }
    } else {
      if (this.input.setSelectionRange) {
        begin = this.input.selectionStart;
        end = this.input.selectionEnd;
      } else if (document['selection'] && document['selection'].createRange) {
        range = document['selection'].createRange();
        begin = 0 - range.duplicate().moveStart('character', -100000);
        end = begin + range.text.length;
      }
      return {
        begin: begin,
        end: end
      };
    }
  }
  isCompleted(lastRequiredNonMaskPos, isNotRunValidator) {
    let completed;
    lastRequiredNonMaskPos = lastRequiredNonMaskPos || this.lastRequiredNonMaskPos;
    for (let i = this.firstNonMaskPos; i <= lastRequiredNonMaskPos; i++) {
      if (this.tests[i] && this.buffer[i] === this.getPlaceholder(i)) {
        return false;
      }
    }
    this.isInvalid = false;
    if (!isNotRunValidator) this.formControl.updateValueAndValidity();
    return true;
  }
  getPlaceholder(i) {
    if (i < this.slotChar.length) {
      return this.slotChar.charAt(i);
    }
    return this.slotChar.charAt(0);
  }
  seekNext(pos) {
    while (++pos < this.len && !this.tests[pos]);
    return pos;
  }
  seekPrev(pos) {
    while (--pos >= 0 && !this.tests[pos]);
    return pos;
  }
  shiftL(begin, end) {
    let i, j;
    if (begin < 0) {
      return;
    }
    for (i = begin, j = this.seekNext(end); i < this.len; i++) {
      if (this.tests[i]) {
        if (j < this.len && this.tests[i].test(this.buffer[j])) {
          this.buffer[i] = this.buffer[j];
          this.buffer[j] = this.getPlaceholder(j);
        } else {
          break;
        }
        j = this.seekNext(j);
      }
    }
    this.writeBuffer();
    this.caret(Math.max(this.firstNonMaskPos, begin));
  }
  shiftR(pos) {
    let i, c, j, t;
    for (i = pos, c = this.getPlaceholder(pos); i < this.len; i++) {
      if (this.tests[i]) {
        j = this.seekNext(i);
        t = this.buffer[i];
        this.buffer[i] = c;
        if (j < this.len && this.tests[j].test(t)) {
          c = t;
        } else {
          break;
        }
      }
    }
  }
  handleAndroidInput(e) {
    var curVal = this.input.value;
    var pos = this.caret();
    if (this.oldVal && this.oldVal.length && this.oldVal.length > curVal.length) {
      this.checkVal(true);
      while (pos.begin > 0 && !this.tests[pos.begin - 1]) pos.begin--;
      if (pos.begin === 0) {
        while (pos.begin < this.firstNonMaskPos && !this.tests[pos.begin]) pos.begin++;
      }
      this.caret(pos.begin, pos.begin);
    } else {
      var pos2 = this.checkVal(true);
      while (pos.begin < this.len && !this.tests[pos.begin]) pos.begin++;
      this.caret(pos.begin, pos.begin);
    }
    if (this.isCompleted()) {
      this.isInvalid = false;
    } else {
      this.isInvalid = true;
      this.formControl.updateValueAndValidity();
    }
  }
  onBlur(e) {
    this.focus = false;
    this.checkVal();
    this.updateModel(e);
    this.updateFilledState();
    if (this.input.value != this.focusText) {
      let event = document.createEvent('HTMLEvents');
      event.initEvent('change', true, false);
      this.input.dispatchEvent(event);
      let maskedValue = this.input.value;
      this.formControl.setValue(this.config.valueWithMask ? maskedValue : this.getUnmaskedValue());
      this.input.value = maskedValue;
    }
  }
  onKeyDown(e) {
    let k = e.which || e.keyCode,
      pos,
      begin,
      end;
    let iPhone = false;
    this.oldVal = this.input.value;
    let controlValid = this.config.minLength ? this.isCompleted(this.minLength + 1) : false;
    if (k === 8 || k === 46 || iPhone && k === 127) {
      pos = this.caret();
      begin = pos.begin;
      end = pos.end;
      if (end - begin === 0) {
        begin = k !== 46 ? this.seekPrev(begin) : end = this.seekNext(begin - 1);
        end = k === 46 ? this.seekNext(end) : end;
      }
      this.clearBuffer(begin, end);
      this.shiftL(begin, end - 1);
      this.setControlValue(e, false, controlValid);
      this.updateModel(e);
      e.preventDefault();
    } else if (k === 13) {
      this.onBlur(e);
      this.setControlValue(e, false, controlValid);
      this.updateModel(e);
    } else if (k === 27) {
      this.input.value = this.focusText;
      this.caret(0, this.checkVal());
      this.updateModel(e);
      this.setControlValue(e, false, controlValid);
      e.preventDefault();
    }
  }
  onKeyPress(e) {
    var k = e.which || e.keyCode,
      pos = this.caret(),
      p,
      c,
      next,
      completed;
    if (e.ctrlKey || e.altKey || e.metaKey || k < 32) {
      return;
    } else if (k && k !== 13) {
      if (pos.end - pos.begin !== 0) {
        this.clearBuffer(pos.begin, pos.end);
        this.shiftL(pos.begin, pos.end - 1);
      }
      p = this.seekNext(pos.begin - 1);
      if (p < this.len) {
        c = String.fromCharCode(k);
        if (this.tests[p].test(c)) {
          this.shiftR(p);
          this.buffer[p] = c;
          this.writeBuffer();
          next = this.seekNext(p);
          this.caret(next);
          if (pos.begin <= this.lastRequiredNonMaskPos) {
            completed = this.isCompleted();
          }
        }
      }
      e.preventDefault();
    }
    this.updateModel(e);
    if (completed === undefined) completed = this.isCompleted();
    this.setControlValue(e, completed, this.config.minLength ? this.isCompleted(this.minLength) : false);
  }
  clearBuffer(start, end) {
    let i;
    for (i = start; i < end && i < this.len; i++) {
      if (this.tests[i]) {
        this.buffer[i] = this.getPlaceholder(i);
      }
    }
  }
  writeBuffer() {
    this.input.value = this.buffer.join('');
  }
  checkVal(allow) {
    let test = this.input.value,
      lastMatch = -1,
      i,
      c,
      pos;
    for (i = 0, pos = 0; i < this.len; i++) {
      if (this.tests[i]) {
        this.buffer[i] = this.getPlaceholder(i);
        while (pos++ < test.length) {
          c = test.charAt(pos - 1);
          if (this.tests[i].test(c)) {
            this.buffer[i] = c;
            lastMatch = i;
            break;
          }
        }
        if (pos > test.length) {
          this.clearBuffer(i + 1, this.len);
          break;
        }
      } else {
        if (this.buffer[i] === test.charAt(pos)) {
          pos++;
        }
        if (i < this.partialPosition) {
          lastMatch = i;
        }
      }
    }
    if (allow) {
      this.writeBuffer();
    } else if (lastMatch + 1 < this.partialPosition && (!this.config.minLength || !(lastMatch >= this.minLength))) {
      if (this.autoClear || this.buffer.join('') === this.defaultBuffer) {
        this.isInvalid = true;
      } else {
        this.isInvalid = true;
        this.writeBuffer();
      }
    } else {
      this.writeBuffer();
      this.input.value = this.input.value.substring(0, lastMatch + 1);
    }
    return this.partialPosition ? i : this.firstNonMaskPos;
  }
  onFocus(event) {
    this.focus = true;
    clearTimeout(this.caretTimeoutId);
    let pos;
    this.focusText = this.input.value;
    pos = this.checkVal();
    this.caretTimeoutId = setTimeout(() => {
      if (this.input !== document.activeElement) {
        return;
      }
      this.writeBuffer();
      if (pos == this.mask.replace("?", "").length) {
        this.caret(0, pos);
      } else {
        this.caret(pos);
      }
      this.updateFilledState();
    }, 10);
  }
  onInput(event) {
    if (this.androidChrome) this.handleAndroidInput(event);else this.handleInputChange(event);
  }
  setControlValue(e, isValid, isValidControl) {
    this.isInvalid = isValidControl ? !isValidControl : !isValid;
    let value = this.input.value;
    let controlValue = '';
    if (!this.isInvalid) controlValue = this.config.valueWithMask ? value : this.getUnmaskedValue();
    this.formControl.setValue(controlValue);
    this.oldValue = this.input.value = value;
    if (!isValid) this.onFocus(e);
  }
  handleInputChange(event) {
    setTimeout(() => {
      var pos = this.checkVal(true);
      this.caret(pos);
      this.updateModel(event);
      this.setControlValue(event, this.isCompleted());
    }, 0);
  }
  getUnmaskedValue() {
    let unmaskedBuffer = [];
    for (let i = 0; i < this.buffer.length; i++) {
      let c = this.buffer[i];
      if (this.tests[i] && c != this.getPlaceholder(i)) {
        unmaskedBuffer.push(c);
      }
    }
    return unmaskedBuffer.join('');
  }
  updateModel(e) {}
  updateFilledState() {
    this.filled = this.input && this.input.value != '';
  }
  onDestroy() {
    let eventCount = this.eventListeners.length;
    for (var i = 0; i < eventCount; i++) {
      this.eventListeners[0]();
      this.eventListeners.splice(0, 1);
    }
    this.eventListeners = [];
  }
}
class DecimalProvider {
  constructor(decimalPipe, localeId) {
    this.decimalPipe = decimalPipe;
    this.localeId = localeId;
    this.decimalSeperator = ".";
    this.groupSeperator = ",";
    this.isSetConfig = false;
    this.decimalSeperator = getLocaleNumberSymbol(localeId, NumberSymbol.Decimal);
    ;
    this.groupSeperator = getLocaleNumberSymbol(localeId, NumberSymbol.Group);
    this.setSymbolInConfig();
  }
  replacer(value) {
    value = String(value);
    if (!this.isSetConfig) this.bindConfig();
    value = value.split(this.groupSeperator).join(BLANK);
    if (this.allowDecimalSymbol) value = value.replace(this.decimalSeperator, this.allowDecimalSymbol);
    var splitValue = value.split(this.decimalSeperator);
    value = splitValue.length > 1 && splitValue[1] && RegexValidator.isZero(splitValue[1]) ? splitValue[0] : value;
    return value;
  }
  transFormDecimal(value, digitsInfo, persistZero) {
    value = String(value);
    if (!value) {
      return value;
    }
    let transformedValue = this.decimalPipe.transform(value.replace(ReactiveFormConfig.number.groupSymbol, "").replace(this.decimalSeperator, "."), digitsInfo, this.localeId);
    if (persistZero && value.indexOf(this.decimalSeperator)) {
      let splitTransformed = transformedValue.split(".");
      let splitDigitsInfo = digitsInfo ? digitsInfo.split("-") : [];
      let digits = splitDigitsInfo.length > 1 ? parseInt(splitDigitsInfo[splitDigitsInfo.length - 1]) : 0;
      if (splitTransformed.length > 1 && splitDigitsInfo.length > 0 && digits !== 0 && splitTransformed[1].length !== digits) {
        let diff = digits - splitTransformed[1].length;
        for (let i = 0; i < diff; i++) {
          transformedValue += "0";
        }
      }
    }
    return transformedValue;
  }
  setSymbolInConfig() {
    ReactiveFormConfig.number = {
      decimalSymbol: this.decimalSeperator,
      groupSymbol: this.groupSeperator
    };
  }
  bindConfig() {
    if (ReactiveFormConfig.json) {
      if (ReactiveFormConfig.json.localeId) this.localeId = ReactiveFormConfig.json.localeId;
      if (ReactiveFormConfig.json.allowDecimalSymbol) this.allowDecimalSymbol = ReactiveFormConfig.json.allowDecimalSymbol;
    }
    this.isSetConfig = true;
  }
}
DecimalProvider.ɵfac = function DecimalProvider_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DecimalProvider)(i0.ɵɵinject(i1.DecimalPipe), i0.ɵɵinject(LOCALE_ID));
};
DecimalProvider.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: DecimalProvider,
  factory: DecimalProvider.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DecimalProvider, [{
    type: Injectable
  }], function () {
    return [{
      type: i1.DecimalPipe
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [LOCALE_ID]
      }]
    }];
  }, null);
})();
const NGMODEL_BINDING = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => RxFormControlDirective),
  multi: true
};
const ALLOW_VALIDATOR_WITHOUT_CONFIG = ['required', 'notEmpty', 'alpha', 'alphaNumeric', 'ascii', 'dataUri', 'digit', 'email', 'even', 'hexColor', 'json', 'latitude', 'latLong', 'leapYear', 'longitude', 'lowerCase', 'mac', 'odd', 'port', 'primeNumber', 'time', 'upperCase', 'url', 'unique', 'cusip', 'gird'];
const NUMERIC = "numeric";
const IS_FORMAT = "isFormat";
const DIGITS_INFO = "digitsInfo";
class RxFormControlDirective extends BaseValidator {
  constructor(elementRef, renderer, decimalProvider) {
    super();
    this.elementRef = elementRef;
    this.renderer = renderer;
    this.decimalProvider = decimalProvider;
    this.eventListeners = [];
    this.isNumericSubscribed = false;
    this.isFocusCalled = false;
    this.isMasked = false;
    this.element = elementRef.nativeElement;
    this.setEventName();
  }
  set validationControls(value) {
    this.controls = value;
  }
  get validationControls() {
    return this.controls;
  }
  ngOnInit() {
    let validators = [];
    Object.keys(APP_VALIDATORS).forEach(validatorName => {
      if (this[`rx${validatorName}`] || ALLOW_VALIDATOR_WITHOUT_CONFIG.indexOf(validatorName) != -1 && this[`rx${validatorName}`] == BLANK) {
        validators.push(APP_VALIDATORS[validatorName](this[`rx${validatorName}`]));
        if (this.name && !(this.formControlName && this.formControl)) {
          ApplicationUtil.configureControl(this.controlConfig, this[`rx${validatorName}`], validatorName);
        }
      }
    });
    if (validators.length > 0) this.validators = validators;
    if (this.rxnumeric && (this.rxnumeric.isFormat || this.rxnumeric.digitsInfo)) {
      this.bindNumericElementEvent();
    }
  }
  blurEvent() {
    if (!(this.formControl && this.formControl.errors && this.formControl.errors.numeric)) {
      if (this.formControl.value !== null && this.formControl.value !== undefined) {
        let value = this.decimalProvider.transFormDecimal(this.formControl.value, this.rxnumeric.digitsInfo, this.rxnumeric.persistZero);
        value = !this.rxnumeric.isFormat ? this.decimalProvider.replacer(value) : value;
        this.setValueOnElement(value);
      }
      this.isFocusCalled = false;
    }
  }
  bindNumericElementEvent(config) {
    if (config) this.rxnumeric = config;
    let listener = this.renderer.listen(this.element, BLUR, this.blurEvent.bind(this));
    this.eventListeners.push(listener);
    listener = this.renderer.listen(this.element, FOCUS, event => {
      this.isFocusCalled = true;
      if (!(this.formControl && this.formControl.errors && this.formControl.errors.numeric) && this.formControl.value != null) {
        let value = this.decimalProvider.replacer(this.element.value);
        this.setValueOnElement(value);
      }
    });
    this.eventListeners.push(listener);
  }
  bindValueChangeEvent() {
    if (this.eventName != BLANK) {
      let listener = this.renderer.listen(this.element, this.eventName, () => {
        Object.keys(this.validationControls).forEach(fieldName => {
          this.validationControls[fieldName].updateValueAndValidity();
        });
      });
      this.eventListeners.push(listener);
    }
  }
  subscribeNumericFormatter() {
    if (this.formControl[VALIDATOR_CONFIG$2] && this.formControl[VALIDATOR_CONFIG$2][NUMERIC] && (this.formControl[VALIDATOR_CONFIG$2][NUMERIC][IS_FORMAT] || this.formControl[VALIDATOR_CONFIG$2][NUMERIC][DIGITS_INFO])) {
      if (!this.isNumericSubscribed) {
        this.bindNumericElementEvent(this.formControl[VALIDATOR_CONFIG$2][NUMERIC]);
        this.isNumericSubscribed = true;
      }
      if (!this.isFocusCalled && RegexValidator.isNotBlank(this.formControl.value)) {
        this.blurEvent();
      }
    }
  }
  subscribeMaskValidator() {
    if (this.formControl[VALIDATOR_CONFIG$2] && this.formControl[VALIDATOR_CONFIG$2]["mask"] && !this.isMasked) {
      let config = this.formControl[VALIDATOR_CONFIG$2]["mask"];
      this.maskProvider = new MaskProvider(this.element, config.mask, this.renderer, this.formControl, config);
      this.isMasked = true;
    }
  }
  setValueOnElement(value) {
    this.renderer.setProperty(this.element, ELEMENT_VALUE, value);
  }
  setTemplateValidators(control) {
    for (let validatorName in control[VALIDATOR_CONFIG$2]) {
      this[validatorName] = control[VALIDATOR_CONFIG$2][validatorName];
    }
    delete control[TEMPLATE_VALIDATION_CONFIG];
    delete control[VALIDATOR_CONFIG$2];
    this.ngOnInit();
  }
  updateOnElementClass(element) {
    var previousClassName = '';
    return function (className) {
      if (previousClassName) element.classList.remove(previousClassName);
      if (className) element.classList.add(className);
      previousClassName = className;
    };
  }
  setValidatorConfig(control) {
    if (!this.formControl) {
      this.formControl = control;
      let rxFormControl = this.formControl;
      if (rxFormControl.updateOnElementClass) rxFormControl.updateOnElementClass = this.updateOnElementClass(this.element);
    }
    this.subscribeMaskValidator();
    this.subscribeNumericFormatter();
    if (control[TEMPLATE_VALIDATION_CONFIG]) this.setTemplateValidators(control);
    if (control[CONDITIONAL_VALIDATOR]) {
      this.conditionalValidator = control[CONDITIONAL_VALIDATOR];
      delete control[CONDITIONAL_VALIDATOR];
    }
  }
  validate(control) {
    this.setValidatorConfig(control);
    if (this.conditionalValidator) this.conditionalValidator(control);
    if (!this.isProcessed) this.setModelConfig(control);
    return this.validators && this.validators.length > 0 || this.maskProvider ? this.validation(control) : null;
  }
  ngOnDestroy() {
    this.controls = undefined;
    let eventCount = this.eventListeners.length;
    for (var i = 0; i < eventCount; i++) {
      this.eventListeners[0]();
      this.eventListeners.splice(0, 1);
    }
    this.eventListeners = [];
    if (this.maskProvider) this.maskProvider.onDestroy();
  }
}
RxFormControlDirective.ɵfac = function RxFormControlDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RxFormControlDirective)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(DecimalProvider));
};
RxFormControlDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: RxFormControlDirective,
  selectors: [["", "ngModel", ""], ["", "formControlName", ""], ["", "formControl", ""]],
  inputs: {
    rxalpha: "rxalpha",
    rxalphaNumeric: "rxalphaNumeric",
    rxascii: "rxascii",
    rxcompare: "rxcompare",
    rxcompose: "rxcompose",
    rxcontains: "rxcontains",
    rxcreditCard: "rxcreditCard",
    rxdataUri: "rxdataUri",
    rxdifferent: "rxdifferent",
    rxdigit: "rxdigit",
    rxemail: "rxemail",
    rxendsWith: "rxendsWith",
    rxeven: "rxeven",
    rxextension: "rxextension",
    rxfactor: "rxfactor",
    rxfileSize: "rxfileSize",
    rxgreaterThanEqualTo: "rxgreaterThanEqualTo",
    rxgreaterThan: "rxgreaterThan",
    rxhexColor: "rxhexColor",
    rxjson: "rxjson",
    rxlatitude: "rxlatitude",
    rxlatLong: "rxlatLong",
    rxleapYear: "rxleapYear",
    rxlessThan: "rxlessThan",
    rxlessThanEqualTo: "rxlessThanEqualTo",
    rxlongitude: "rxlongitude",
    rxlowerCase: "rxlowerCase",
    rxmac: "rxmac",
    rxmaxDate: "rxmaxDate",
    rxmaxLength: "rxmaxLength",
    rxmaxNumber: "rxmaxNumber",
    rxminDate: "rxminDate",
    rxminLength: "rxminLength",
    rxminNumber: "rxminNumber",
    rxnumeric: "rxnumeric",
    rxodd: "rxodd",
    rxpassword: "rxpassword",
    rxport: "rxport",
    rxprimeNumber: "rxprimeNumber",
    rxrequired: "rxrequired",
    rxrange: "rxrange",
    rxrule: "rxrule",
    rxstartsWith: "rxstartsWith",
    rxtime: "rxtime",
    rxupperCase: "rxupperCase",
    rxurl: "rxurl",
    rxunique: "rxunique",
    rxnotEmpty: "rxnotEmpty",
    rxcusip: "rxcusip",
    rxgrid: "rxgrid",
    rxdate: "rxdate"
  },
  features: [i0.ɵɵProvidersFeature([NGMODEL_BINDING]), i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RxFormControlDirective, [{
    type: Directive,
    args: [{
      selector: '[ngModel],[formControlName],[formControl]',
      providers: [NGMODEL_BINDING]
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: DecimalProvider
    }];
  }, {
    rxalpha: [{
      type: Input
    }],
    rxalphaNumeric: [{
      type: Input
    }],
    rxascii: [{
      type: Input
    }],
    rxcompare: [{
      type: Input
    }],
    rxcompose: [{
      type: Input
    }],
    rxcontains: [{
      type: Input
    }],
    rxcreditCard: [{
      type: Input
    }],
    rxdataUri: [{
      type: Input
    }],
    rxdifferent: [{
      type: Input
    }],
    rxdigit: [{
      type: Input
    }],
    rxemail: [{
      type: Input
    }],
    rxendsWith: [{
      type: Input
    }],
    rxeven: [{
      type: Input
    }],
    rxextension: [{
      type: Input
    }],
    rxfactor: [{
      type: Input
    }],
    rxfileSize: [{
      type: Input
    }],
    rxgreaterThanEqualTo: [{
      type: Input
    }],
    rxgreaterThan: [{
      type: Input
    }],
    rxhexColor: [{
      type: Input
    }],
    rxjson: [{
      type: Input
    }],
    rxlatitude: [{
      type: Input
    }],
    rxlatLong: [{
      type: Input
    }],
    rxleapYear: [{
      type: Input
    }],
    rxlessThan: [{
      type: Input
    }],
    rxlessThanEqualTo: [{
      type: Input
    }],
    rxlongitude: [{
      type: Input
    }],
    rxlowerCase: [{
      type: Input
    }],
    rxmac: [{
      type: Input
    }],
    rxmaxDate: [{
      type: Input
    }],
    rxmaxLength: [{
      type: Input
    }],
    rxmaxNumber: [{
      type: Input
    }],
    rxminDate: [{
      type: Input
    }],
    rxminLength: [{
      type: Input
    }],
    rxminNumber: [{
      type: Input
    }],
    rxnumeric: [{
      type: Input
    }],
    rxodd: [{
      type: Input
    }],
    rxpassword: [{
      type: Input
    }],
    rxport: [{
      type: Input
    }],
    rxprimeNumber: [{
      type: Input
    }],
    rxrequired: [{
      type: Input
    }],
    rxrange: [{
      type: Input
    }],
    rxrule: [{
      type: Input
    }],
    rxstartsWith: [{
      type: Input
    }],
    rxtime: [{
      type: Input
    }],
    rxupperCase: [{
      type: Input
    }],
    rxurl: [{
      type: Input
    }],
    rxunique: [{
      type: Input
    }],
    rxnotEmpty: [{
      type: Input
    }],
    rxcusip: [{
      type: Input
    }],
    rxgrid: [{
      type: Input
    }],
    rxdate: [{
      type: Input
    }]
  });
})();
var UrlValidationType;
(function (UrlValidationType) {
  UrlValidationType[UrlValidationType["FQDN"] = 1] = "FQDN";
  UrlValidationType[UrlValidationType["LocalHost"] = 2] = "LocalHost";
  UrlValidationType[UrlValidationType["IntranetServer"] = 3] = "IntranetServer";
})(UrlValidationType || (UrlValidationType = {}));
class FormBuilderConfiguration {
  constructor(formBuilderConfiguration) {
    if (formBuilderConfiguration) for (var column in formBuilderConfiguration) this[column] = formBuilderConfiguration[column];
  }
}
class IAbstractControl extends AbstractControl {}
class ControlHostDirective {
  constructor(viewContainerRef) {
    this.viewContainerRef = viewContainerRef;
  }
  set portal(context) {
    if (context.templateRef) {
      if (this.view) {
        this.view.destroy();
        this.view = undefined;
      }
      this.view = this.viewContainerRef.createEmbeddedView(context.templateRef, context);
    }
  }
  ngOnDestroy() {
    if (this.view) this.view.destroy();
    if (this.viewContainerRef) this.viewContainerRef.clear();
  }
}
ControlHostDirective.ɵfac = function ControlHostDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ControlHostDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ControlHostDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ControlHostDirective,
  selectors: [["", "controlHost", ""]],
  inputs: {
    portal: [0, "controlHost", "portal"]
  }
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ControlHostDirective, [{
    type: Directive,
    args: [{
      selector: '[controlHost]'
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, {
    portal: [{
      type: Input,
      args: ['controlHost']
    }]
  });
})();
class BaseFormBuilder {
  constructor() {}
  createInstance() {
    let instance = {};
    defaultContainer.modelIncrementCount = defaultContainer.modelIncrementCount + 1;
    let modelName = `RxWebModel${defaultContainer.modelIncrementCount}`;
    instance.constructor = Function(`"use strict";return(function ${modelName}(){ })`)();
    return instance;
  }
  createClassObject(model, formBuilderConfiguration, classInstance) {
    let instanceContainer = defaultContainer.get(model);
    let autoInstanceConfig = formBuilderConfiguration ? formBuilderConfiguration.autoInstanceConfig : undefined;
    if (!autoInstanceConfig) {
      return classInstance && typeof classInstance != "function" ? classInstance : getInstance(model, []);
    } else {
      classInstance = classInstance && typeof classInstance != "function" ? classInstance : getInstance(model, autoInstanceConfig.arguments || []);
      if (autoInstanceConfig.objectPropInstanceConfig && autoInstanceConfig.objectPropInstanceConfig.length > 0) {
        autoInstanceConfig.objectPropInstanceConfig.forEach(t => {
          let objectProperty = instanceContainer.properties.filter(property => property.name == t.propertyName && property.propertyType == OBJECT_PROPERTY)[0];
          if (objectProperty) {
            let data = classInstance[t.propertyName];
            classInstance[t.propertyName] = getInstance(objectProperty.entity, t.arguments || []);
            if (data) this.setObjectValue(data, classInstance[t.propertyName]);
          }
        });
      }
      if (autoInstanceConfig.arrayPropInstanceConfig && autoInstanceConfig.arrayPropInstanceConfig.length > 0) {
        autoInstanceConfig.arrayPropInstanceConfig.forEach(t => {
          let property = instanceContainer.properties.filter(property => property.name == t.propertyName && property.propertyType == ARRAY_PROPERTY)[0];
          if (property) {
            let data = classInstance[t.propertyName];
            classInstance[t.propertyName] = [];
            for (var i = 0; i < t.rowItems; i++) {
              let instance = getInstance(property.entity, t.arguments || []);
              if (data && data[i]) this.setObjectValue(data[i], instance);
              classInstance[t.propertyName].push(instance);
            }
          }
        });
      }
      return classInstance;
    }
  }
  updateObject(model, entityObject, formBuilderConfiguration) {
    let instanceContainer = instanceProvider(model);
    let classInstance = getInstance(model, []);
    if (instanceContainer) {
      instanceContainer.properties.forEach(t => {
        let entity = (t.propertyType == OBJECT_PROPERTY || t.propertyType == ARRAY_PROPERTY) && t.entity ? t.entity : formBuilderConfiguration && formBuilderConfiguration.genericEntities ? formBuilderConfiguration.genericEntities[t.name] : undefined;
        if (!entity && t.entityProvider) entity = t.entityProvider.call(entityObject);
        switch (t.propertyType) {
          case PROPERTY:
            classInstance[t.name] = this.getValue(entityObject, t, formBuilderConfiguration);
            break;
          case OBJECT_PROPERTY:
            let objectValue = this.getValue(entityObject, t, formBuilderConfiguration);
            if (objectValue) classInstance[t.name] = this.updateObject(entity, objectValue, formBuilderConfiguration);
            break;
          case ARRAY_PROPERTY:
            let arrayObjectValue = this.getValue(entityObject, t, formBuilderConfiguration);
            if (arrayObjectValue && Array.isArray(arrayObjectValue)) {
              classInstance[t.name] = [];
              for (let row of arrayObjectValue) {
                let instanceObject = this.updateObject(entity, row, formBuilderConfiguration);
                classInstance[t.name].push(instanceObject);
              }
            }
            break;
        }
      });
    }
    return classInstance;
  }
  instaceProvider(instanceFunc, entityObject) {
    return instanceProvider(instanceFunc, entityObject);
  }
  getDefaultValue(propertyInfo, value, formBuilderConfiguration) {
    let defaultValue = formBuilderConfiguration && formBuilderConfiguration.propsConfig && formBuilderConfiguration.propsConfig[propertyInfo.name] && formBuilderConfiguration.propsConfig[propertyInfo.name].defaultValue && !RegexValidator.isNotBlank(value) ? formBuilderConfiguration.propsConfig[propertyInfo.name].defaultValue : propertyInfo.defaultValue != undefined && !RegexValidator.isNotBlank(value) ? propertyInfo.defaultValue : value;
    return defaultValue;
  }
  sanitizeValue(instanceContainer, propertyName, value, entityObject, baseObject) {
    if (instanceContainer.sanitizers && instanceContainer.sanitizers[propertyName]) {
      for (let sanitizer of instanceContainer.sanitizers[propertyName]) value = SANITIZERS[sanitizer.name](value, sanitizer.config);
    }
    if (entityObject[propertyName] !== undefined && entityObject[propertyName] !== value) entityObject[propertyName] = value;
    if (baseObject[propertyName] !== undefined && baseObject[propertyName] !== value) baseObject[propertyName] = value;
    return value;
  }
  getValue(entityObject, propertyInfo, formBuilderConfiguration) {
    let propValue = propertyInfo.dataPropertyName ? entityObject[propertyInfo.dataPropertyName] : entityObject[propertyInfo.name];
    return this.getDefaultValue(propertyInfo, propValue, formBuilderConfiguration);
  }
  setObjectValue(entityObject, classInstance) {
    for (var column in entityObject) {
      classInstance[column] = entityObject[column];
    }
  }
}
function andValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      let validatorNames = Object.keys(config.validation);
      let failed = false;
      for (var validatorName of validatorNames) {
        failed = typeof config.validation[validatorName] == "boolean" ? APP_VALIDATORS[validatorName]()(control) : APP_VALIDATORS[validatorName](config.validation[validatorName])(control);
        if (failed) break;
      }
      if (failed) return ObjectMaker.toJson(AnnotationTypes.and, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function orValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      let validatorNames = Object.keys(config.validation);
      let failed = false;
      for (var validatorName of validatorNames) {
        failed = typeof config.validation[validatorName] == "boolean" ? APP_VALIDATORS[validatorName]()(control) : APP_VALIDATORS[validatorName](config.validation[validatorName])(control);
        if (!failed) break;
      }
      if (failed) return ObjectMaker.toJson(AnnotationTypes.or, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
function notValidator(configModel) {
  return control => {
    let config = getConfigObject(configModel, control);
    if (ValidatorValueChecker.pass(control, config)) {
      let validatorNames = Object.keys(config.validation);
      let failed = false;
      for (var validatorName of validatorNames) {
        failed = typeof config.validation[validatorName] == "boolean" ? APP_VALIDATORS[validatorName]()(control) : APP_VALIDATORS[validatorName](config.validation[validatorName])(control);
        if (!failed) break;
      }
      if (!failed) return ObjectMaker.toJson(AnnotationTypes.not, config, [control.value]);
    }
    return ObjectMaker.null();
  };
}
const LOGICAL_VALIDATORS = {
  and: andValidator,
  or: orValidator,
  not: notValidator
};
const ASYNC = "async";
const ENTITY_OBJECT = "entityObject";
class RxFormBuilder extends BaseFormBuilder {
  constructor() {
    super();
    this.conditionalObjectProps = [];
    this.conditionalValidationInstance = {};
    this.builderConfigurationConditionalObjectProps = [];
    this.formGroupPropOtherValidator = {};
    this.currentFormGroupPropOtherValidator = {};
    this.isNested = false;
    this.isGroupCalled = false;
    this.isNestedBinding = false;
  }
  getInstanceContainer(instanceFunc, entityObject) {
    return this.instaceProvider(instanceFunc, entityObject);
  }
  setValue(formGroup, object) {
    for (var col in object) {
      var control = formGroup.get([col]);
      control.setValue(object[col]);
      control.updateValueAndValidity();
    }
  }
  extractExpressions(fomrBuilderConfiguration) {
    if (fomrBuilderConfiguration && fomrBuilderConfiguration.dynamicValidation) {
      for (var property in fomrBuilderConfiguration.dynamicValidation) {
        for (var decorator in fomrBuilderConfiguration.dynamicValidation[property]) {
          if (fomrBuilderConfiguration.dynamicValidation[property][decorator].conditionalExpression) {
            let columns = Linq.expressionColumns(fomrBuilderConfiguration.dynamicValidation[property][decorator].conditionalExpression);
            defaultContainer.addChangeValidation(this.conditionalValidationInstance, property, columns);
          }
        }
      }
    }
    return null;
  }
  addAsyncValidation(property, propertyValidators, propValidationConfig) {
    let asyncValidators = [];
    if (propertyValidators) {
      for (let propertyValidator of propertyValidators) {
        if (propertyValidator.isAsync) propertyValidator.config.forEach(t => {
          asyncValidators.push(t);
        });
      }
    }
    if (propValidationConfig && propValidationConfig[ASYNC]) {
      propValidationConfig[ASYNC].forEach(t => {
        asyncValidators.push(t);
      });
    }
    return asyncValidators;
  }
  addFormControl(property, propertyValidators, propValidationConfig, instance, entity) {
    let validators = [];
    let columns = [];
    if (instance.conditionalValidationProps && instance.conditionalValidationProps[property.name] || this.conditionalValidationInstance.conditionalValidationProps && this.conditionalValidationInstance.conditionalValidationProps[property.name]) {
      let props = [];
      if (instance.conditionalValidationProps && instance.conditionalValidationProps[property.name]) instance.conditionalValidationProps[property.name].forEach(t => props.push(t));
      if (this.conditionalValidationInstance.conditionalValidationProps && this.conditionalValidationInstance.conditionalValidationProps[property.name]) this.conditionalValidationInstance.conditionalValidationProps[property.name].forEach(t => props.push(t));
      validators.push(conditionalChangeValidator(props));
    }
    if (this.conditionalObjectProps.length > 0 || this.builderConfigurationConditionalObjectProps.length > 0) {
      let propConditions = [];
      if (this.conditionalObjectProps) propConditions = this.conditionalObjectProps.filter(t => t.propName == property.name);
      if (this.builderConfigurationConditionalObjectProps) this.builderConfigurationConditionalObjectProps.filter(t => t.propName == property.name).forEach(t => propConditions.push(t));
      propConditions.forEach(t => {
        if (t.referencePropName && columns.indexOf(t.referencePropName) == -1) columns.push(t.referencePropName);
      });
      if (columns.length > 0) validators.push(conditionalChangeValidator(columns));
    }
    for (let propertyValidator of propertyValidators) {
      if (!propertyValidator.isAsync) {
        let config = propertyValidator.config;
        if (property.messageNexus) config = config ? {
          ...{
            messageNexus: property.messageNexus
          },
          ...config
        } : {
          messageNexus: property.messageNexus
        };
        switch (propertyValidator.annotationType) {
          case AnnotationTypes.rule:
            validators.push(APP_VALIDATORS[propertyValidator.annotationType](config, entity));
            break;
          case AnnotationTypes.and:
          case AnnotationTypes.or:
          case AnnotationTypes.not:
            validators.push(LOGICAL_VALIDATORS[propertyValidator.annotationType](config));
            break;
          default:
            validators.push(APP_VALIDATORS[propertyValidator.annotationType](config));
            break;
        }
      }
    }
    if (propValidationConfig) this.additionalValidation(validators, propValidationConfig);
    if (this.currentFormGroupPropOtherValidator[property.name]) this.currentFormGroupPropOtherValidator[property.name].forEach(t => {
      validators.push(t);
    });
    return validators;
  }
  additionalValidation(validations, propValidationConfig) {
    for (var col in AnnotationTypes) {
      if (propValidationConfig[AnnotationTypes[col]] && col != "custom") {
        validations.push(APP_VALIDATORS[AnnotationTypes[col]](propValidationConfig[AnnotationTypes[col]]));
      } else if (col == AnnotationTypes.custom && propValidationConfig[AnnotationTypes[col]]) validations.push(propValidationConfig[col]);
    }
  }
  getEntity(object, formBuilderConfiguration, propertyName, isSameObjectConstructor = false) {
    if (formBuilderConfiguration && formBuilderConfiguration.genericEntities && formBuilderConfiguration.genericEntities[propertyName]) return formBuilderConfiguration.genericEntities[propertyName];
    return isSameObjectConstructor ? object.constructor : undefined;
  }
  getObjectPropertyInstance(object, propertyInfo, formBuilderConfiguration) {
    if (propertyInfo.propertyType == OBJECT_PROPERTY && object[propertyInfo.name]) return object[propertyInfo.name].constructor;else if (propertyInfo.propertyType == ARRAY_PROPERTY && object[propertyInfo.name] && object[propertyInfo.name].length > 0) return object[propertyInfo.name][0].constructor;
    return this.getEntity(object, formBuilderConfiguration, propertyInfo.name);
  }
  checkObjectPropAdditionalValidation(instanceContainer, object, formBuilderConfiguration) {
    var props = instanceContainer.properties.filter(t => t.propertyType == OBJECT_PROPERTY || t.propertyType == ARRAY_PROPERTY);
    props.forEach(t => {
      let entity = t.entity;
      if (!t.entity) entity = this.getObjectPropertyInstance(object, t, formBuilderConfiguration);
      if (entity) {
        let instance = this.getInstanceContainer(entity, null);
        if (instance && instance.conditionalValidationProps) {
          for (var key in instance.conditionalValidationProps) {
            var prop = instanceContainer.properties.filter(t => t.name == key)[0];
            if (prop) {
              if (!instanceContainer.conditionalValidationProps) instanceContainer.conditionalValidationProps = {};
              if (!instanceContainer.conditionalValidationProps[key]) instanceContainer.conditionalValidationProps[key] = [];
              instance.conditionalValidationProps[key].forEach(x => {
                if (t.propertyType != ARRAY_PROPERTY) instanceContainer.conditionalValidationProps[key].push([t.name, x].join('.'));else instanceContainer.conditionalValidationProps[key].push([t.name, x].join('[]'));
              });
            }
          }
        }
      }
    });
  }
  getObject(model, entityObject, formBuilderConfiguration) {
    let json = {};
    if (typeof model == FUNCTION_STRING) json.model = model;
    if (typeof model == FUNCTION_STRING && entityObject instanceof FormBuilderConfiguration) {
      json.entityObject = this.createClassObject(json.model, entityObject);
    }
    if (entityObject && !(entityObject instanceof FormBuilderConfiguration)) json.entityObject = entityObject;
    if (entityObject instanceof FormBuilderConfiguration && !formBuilderConfiguration) json.formBuilderConfiguration = entityObject;else if (!(entityObject instanceof FormBuilderConfiguration) && formBuilderConfiguration) {
      json.formBuilderConfiguration = formBuilderConfiguration;
      json.entityObject = this.createClassObject(json.model, json.formBuilderConfiguration, json.entityObject);
    }
    if (!entityObject) {
      if (typeof model == OBJECT_STRING) json.model = model.constructor;
      json.entityObject = this.createClassObject(json.model, json.formBuilderConfiguration, model);
    } else if (model && entityObject instanceof FormBuilderConfiguration && typeof model == OBJECT_STRING) {
      json[MODEL] = model.constructor;
      json[ENTITY_OBJECT] = this.createClassObject(json.model, json.formBuilderConfiguration, model);
    }
    return json;
  }
  control(value, validators, asyncValidators) {
    return new RxFormControl(value, validators, asyncValidators, {}, {}, '', []);
  }
  array(values, validatorConfig) {
    let formArray = this.group({
      temp: values
    }, validatorConfig).get("temp");
    var formBuilder = new FormBuilder();
    return formBuilder.array(formArray.controls);
  }
  group(groupObject, validatorConfig) {
    let modelInstance = super.createInstance();
    let entityObject = {};
    this.formGroupPropOtherValidator = {};
    this.currentFormGroupPropOtherValidator = this.formGroupPropOtherValidator;
    this.createValidatorFormGroup(groupObject, entityObject, modelInstance, validatorConfig);
    this.currentFormGroupPropOtherValidator = this.formGroupPropOtherValidator;
    this.isGroupCalled = true;
    let formGroup = this.formGroup(modelInstance.constructor, entityObject, validatorConfig);
    this.isGroupCalled = false;
    this.formGroupPropOtherValidator = {};
    this.currentFormGroupPropOtherValidator = this.formGroupPropOtherValidator;
    this.formGroupPropOtherValidator = {};
    return formGroup;
  }
  applyAllPropValidator(propName, validatorConfig, modelInstance) {
    if (validatorConfig && validatorConfig.applyAllProps) {
      if (!(validatorConfig.excludeProps && validatorConfig.excludeProps.length > 0 && validatorConfig.excludeProps.indexOf(propName) == -1)) {
        validatorConfig.applyAllProps.forEach(t => {
          if (t.name == RX_WEB_VALIDATOR) {
            t(propName, modelInstance);
          } else {
            if (!this.currentFormGroupPropOtherValidator[propName]) this.currentFormGroupPropOtherValidator[propName] = [];
            this.currentFormGroupPropOtherValidator[propName].push(t);
          }
        });
      }
    }
  }
  dynamicValidationPropCheck(propName, validatorConfig) {
    return validatorConfig == undefined ? true : !validatorConfig.dynamicValidationConfigurationPropertyName ? true : validatorConfig.dynamicValidationConfigurationPropertyName == propName ? false : true;
  }
  isNotObject(value) {
    return value instanceof Date || value === null || typeof value != OBJECT_STRING;
  }
  createValidatorFormGroup(groupObject, entityObject, modelInstance, validatorConfig) {
    for (var propName in groupObject) {
      var prop = groupObject[propName];
      if (prop instanceof Array && prop.length > 0 && this.isNotObject(prop[0])) {
        let propValidators = prop.length > 1 && prop[1] instanceof Array ? prop[1] : prop.length == 2 ? [prop[1]] : [];
        let propertyAdded = false;
        for (var i = 0; i < propValidators.length; i++) {
          if (propValidators[i].name == RX_WEB_VALIDATOR) {
            propValidators[i](propName, modelInstance);
            propertyAdded = true;
          } else {
            if (!this.currentFormGroupPropOtherValidator[propName]) this.currentFormGroupPropOtherValidator[propName] = [];
            this.currentFormGroupPropOtherValidator[propName].push(propValidators[i]);
          }
        }
        if (!propertyAdded) defaultContainer.initPropertyObject(propName, PROPERTY, undefined, typeof modelInstance == OBJECT_STRING ? modelInstance : {
          constructor: modelInstance
        });
        this.applyAllPropValidator(propName, validatorConfig, modelInstance);
      } else if (prop === null || prop === undefined || typeof prop == STRING || typeof prop == NUMBER || typeof prop == BOOLEAN$1 || prop instanceof Date) {
        defaultContainer.initPropertyObject(propName, PROPERTY, undefined, typeof modelInstance == OBJECT_STRING ? modelInstance : {
          constructor: modelInstance
        });
        this.applyAllPropValidator(propName, validatorConfig, modelInstance);
      } else if (prop instanceof Array) {
        if (prop instanceof FormArray) {
          entityObject[propName] = prop;
        } else {
          let propModelInstance = super.createInstance();
          if (typeof modelInstance == "function") modelInstance.constructor = modelInstance;
          defaultContainer.initPropertyObject(propName, ARRAY_PROPERTY, propModelInstance.constructor, modelInstance);
          entityObject[propName] = [];
          for (let row of prop) {
            let jObject = {};
            entityObject[propName].push(jObject);
            this.createValidatorFormGroup(row, jObject, propModelInstance.constructor, validatorConfig);
          }
        }
      } else if (typeof prop == OBJECT_STRING && !(prop instanceof FormControl || prop instanceof RxFormControl)) {
        let formGroup = prop instanceof FormArray ? prop.controls[0] : prop;
        if (!formGroup.model && (prop instanceof FormGroup || prop instanceof RxFormGroup)) {
          formGroup = this.group(formGroup.controls);
        }
        if (prop instanceof FormGroup || prop instanceof RxFormGroup) {
          entityObject[propName] = prop;
          defaultContainer.initPropertyObject(propName, OBJECT_PROPERTY, formGroup.model, modelInstance);
        } else if (prop instanceof FormArray) {
          entityObject[propName] = prop;
          defaultContainer.initPropertyObject(propName, ARRAY_PROPERTY, formGroup.model, modelInstance);
        } else {
          if (this.dynamicValidationPropCheck(propName, validatorConfig)) {
            this.formGroupPropOtherValidator[propName] = {};
            this.currentFormGroupPropOtherValidator = this.formGroupPropOtherValidator[propName];
            let propModelInstance = super.createInstance();
            entityObject[propName] = {};
            entityObject[propName].constructor = propModelInstance.constructor;
            defaultContainer.initPropertyObject(propName, OBJECT_PROPERTY, entityObject[propName].constructor, modelInstance.constructor == Function ? {
              constructor: modelInstance
            } : modelInstance);
            let objectValidationConfig = this.getValidatorConfig(validatorConfig, groupObject, propName + ".");
            this.createValidatorFormGroup(groupObject[propName], entityObject[propName], entityObject[propName].constructor, objectValidationConfig);
          } else entityObject[propName] = groupObject[propName];
        }
      }
      if (typeof prop == STRING || typeof prop == NUMBER || typeof prop == BOOLEAN$1 || prop instanceof Date) {
        entityObject[propName] = prop;
      } else if (prop && prop.length > 0 && this.isNotObject(prop[0]) && !(prop instanceof FormControl || prop instanceof RxFormControl) && !(prop instanceof FormArray)) {
        entityObject[propName] = prop[0];
      } else if (prop instanceof FormArray) {
        entityObject[propName] = prop;
      } else if (prop instanceof FormControl || prop instanceof RxFormControl) {
        entityObject[propName] = prop;
        defaultContainer.initPropertyObject(propName, PROPERTY, undefined, modelInstance.constructor ? modelInstance : {
          constructor: modelInstance
        });
      }
    }
  }
  getValidatorConfig(validatorConfig, entityObject, rootPropertyName, arrayPropertyName) {
    let excludeProps = [];
    let includeProps = [];
    let ignoreUndefinedProps = [];
    if (!validatorConfig) return {};
    const validationProps = this.getObjectForProperty(validatorConfig.dynamicValidation, rootPropertyName, arrayPropertyName);
    const abstractControlOptions = this.getObjectForProperty(validatorConfig.abstractControlOptions, rootPropertyName, arrayPropertyName);
    if (validatorConfig.excludeProps) excludeProps = this.getProps(validatorConfig.excludeProps, rootPropertyName);
    if (validatorConfig.includeProps) includeProps = this.getProps(validatorConfig.includeProps, rootPropertyName);
    if (validatorConfig.ignoreUndefinedProps) ignoreUndefinedProps = this.getProps(validatorConfig.ignoreUndefinedProps, rootPropertyName, true);
    if (!Object.keys(abstractControlOptions).length && rootPropertyName.endsWith('.') && validatorConfig.abstractControlOptions && validatorConfig.abstractControlOptions[rootPropertyName.substring(0, rootPropertyName.length - 1)]) abstractControlOptions['global'] = validatorConfig.abstractControlOptions[rootPropertyName.substring(0, rootPropertyName.length - 1)];
    const dynamicValidation = validatorConfig.dynamicValidationConfigurationPropertyName && entityObject[validatorConfig.dynamicValidationConfigurationPropertyName] ? entityObject[validatorConfig.dynamicValidationConfigurationPropertyName] : validationProps;
    return {
      ignoreUndefinedProps: ignoreUndefinedProps,
      includeProps: includeProps,
      dynamicValidation: dynamicValidation,
      excludeProps: excludeProps,
      abstractControlOptions: abstractControlOptions
    };
  }
  getObjectForProperty(rootObject, rootPropertyName, arrayPropertyName) {
    const result = {};
    for (let propName in rootObject) {
      if (!propName.startsWith(rootPropertyName) && (!arrayPropertyName || !propName.startsWith(arrayPropertyName))) continue;
      let splitProp = propName.split(".", 2)[1];
      if (!splitProp) continue;
      result[splitProp] = rootObject[propName];
    }
    return result;
  }
  getProps(properties, rootPropertyName, isIgnoreProp = false) {
    let props = [];
    for (let prop of properties) {
      if (prop.indexOf(rootPropertyName) != -1) {
        let splitProps = prop.split(".");
        if (splitProps.length == 2) {
          props.push(splitProps[1]);
        } else if (splitProps.length > 2) {
          splitProps.splice(0, 1);
          props.push(splitProps.join("."));
        }
      }
    }
    if (isIgnoreProp && properties.filter(x => x == rootPropertyName.replace('.', '')).length == 1) props.push(':self:');
    return props;
  }
  formGroup(model, entityObject, formBuilderConfiguration) {
    let json = this.getObject(model, entityObject, formBuilderConfiguration);
    model = json.model;
    entityObject = json.entityObject;
    if (entityObject.constructor != model && !this.isGroupCalled) {
      entityObject = json.entityObject = this.updateObject(model, json.entityObject, formBuilderConfiguration);
    }
    formBuilderConfiguration = json.formBuilderConfiguration;
    if (formBuilderConfiguration) this.extractExpressions(formBuilderConfiguration);
    let instanceContainer = this.getInstanceContainer(model, entityObject);
    this.checkObjectPropAdditionalValidation(instanceContainer, entityObject, formBuilderConfiguration);
    let formGroupObject = {};
    let extendedProperties = {};
    let formChildGroup = undefined;
    let formArrayGroup = undefined;
    var additionalValidations = {};
    instanceContainer.properties.forEach(property => {
      let isIncludeProp = true;
      if (formBuilderConfiguration) {
        if (formBuilderConfiguration.excludeProps && formBuilderConfiguration.excludeProps.length > 0) isIncludeProp = formBuilderConfiguration.excludeProps.indexOf(property.name) == -1;
        if (formBuilderConfiguration.dynamicValidation) additionalValidations = formBuilderConfiguration.dynamicValidation;
        if (formBuilderConfiguration.includeProps && formBuilderConfiguration.includeProps.length > 0) isIncludeProp = formBuilderConfiguration.includeProps.indexOf(property.name) != -1;
        if (formBuilderConfiguration.ignoreUndefinedProps && formBuilderConfiguration.ignoreUndefinedProps.length > 0) {
          isIncludeProp = !(property.propertyType == PROPERTY && !RegexValidator.isNotBlank(json.entityObject[property.name]) && (formBuilderConfiguration.ignoreUndefinedProps.indexOf(property.name) !== -1 || formBuilderConfiguration.ignoreUndefinedProps.indexOf(":self:") !== -1));
        }
      }
      if (property.ignore) isIncludeProp = !property.ignore.call(json.entityObject, json.entityObject);
      if (isIncludeProp) {
        switch (property.propertyType) {
          case PROPERTY:
            if (!(entityObject[property.name] instanceof FormControl || entityObject[property.name] instanceof RxFormControl)) {
              let propertyValidators = instanceContainer.propertyAnnotations.filter(t => t.propertyName == property.name && t.isValidator);
              let updateOn = instanceContainer.propertyAnnotations.filter(t => t.propertyName == property.name && !t.isValidator && t.annotationType === "updateOn")[0];
              let sanitizeValue = super.sanitizeValue(instanceContainer, property.name, super.getDefaultValue(property, entityObject[property.name], formBuilderConfiguration), json.entityObject, Object.assign({}, json.entityObject));
              if (entityObject[property.name] === undefined && sanitizeValue) entityObject[property.name] = sanitizeValue;
              let validators = this.addFormControl(property, propertyValidators, additionalValidations[property.name], instanceContainer, entityObject);
              let abstractControlOptions = {
                validators: validators,
                asyncValidators: this.addAsyncValidation(property, propertyValidators, additionalValidations[property.name])
              };
              abstractControlOptions = this.getAbstractControlOptions(property.name, formBuilderConfiguration, abstractControlOptions);
              if (updateOn && !abstractControlOptions.updateOn) abstractControlOptions.updateOn = updateOn.config.runOn;
              formGroupObject[property.name] = new RxFormControl(sanitizeValue, abstractControlOptions, undefined, json.entityObject, Object.assign({}, json.entityObject), property.name, instanceContainer.sanitizers[property.name]);
              this.isNested = false;
            } else formGroupObject[property.name] = super.getDefaultValue(property, entityObject[property.name], formBuilderConfiguration);
            extendedProperties[property.name] = true;
            break;
          case OBJECT_PROPERTY:
            let objectValue = entityObject[property.name];
            objectValue = !objectValue && property.defaultValue ? property.defaultValue : objectValue;
            if (!objectValue && property.objectConfig && property.objectConfig.autoCreate) objectValue = this.createClassObject(property.entity, {});
            if (objectValue && objectValue instanceof Object && !(objectValue instanceof FormGroup || objectValue instanceof RxFormGroup)) {
              this.isNestedBinding = this.isNested = true;
              if (instanceContainer && instanceContainer.conditionalObjectProps) this.conditionalObjectProps = instanceContainer.conditionalObjectProps.filter(t => t.objectPropName == property.name);
              if (this.conditionalValidationInstance && this.conditionalValidationInstance.conditionalObjectProps) this.builderConfigurationConditionalObjectProps = this.conditionalValidationInstance.conditionalObjectProps.filter(t => t.objectPropName == property.name);
              if (this.formGroupPropOtherValidator[property.name]) this.currentFormGroupPropOtherValidator = this.formGroupPropOtherValidator[property.name];
              let objectValidationConfig = this.getValidatorConfig(formBuilderConfiguration, objectValue, `${property.name}.`);
              let entity = property.entityProvider ? property.entityProvider.call(entityObject) : undefined;
              formGroupObject[property.name] = this.formGroup(entity || property.entity || this.getEntity(objectValue, formBuilderConfiguration, property.name, true), objectValue, objectValidationConfig);
              this.conditionalObjectProps = [];
              this.builderConfigurationConditionalObjectProps = [];
              this.isNestedBinding = this.isNested = false;
            } else if (objectValue instanceof FormGroup || objectValue instanceof RxFormGroup) formGroupObject[property.name] = objectValue;
            break;
          case ARRAY_PROPERTY:
            let arrayObjectValue = entityObject[property.name];
            if (arrayObjectValue && arrayObjectValue instanceof Array && !(arrayObjectValue instanceof FormArray)) {
              this.isNestedBinding = this.isNested = true;
              var formArrayGroup = [];
              let index = 0;
              let entity = property.entityProvider ? property.entityProvider.call(entityObject) : undefined;
              let objectValidationConfig = null;
              for (let subObject of arrayObjectValue) {
                if (instanceContainer && instanceContainer.conditionalObjectProps) this.conditionalObjectProps = instanceContainer.conditionalObjectProps.filter(t => t.objectPropName == property.name && t.arrayIndex == index);
                if (this.conditionalValidationInstance && this.conditionalValidationInstance.conditionalObjectProps) this.builderConfigurationConditionalObjectProps = this.conditionalValidationInstance.conditionalObjectProps.filter(t => t.objectPropName == property.name && t.arrayIndex == index);
                if (this.formGroupPropOtherValidator[property.name]) this.currentFormGroupPropOtherValidator = this.formGroupPropOtherValidator[property.name];
                objectValidationConfig = this.getValidatorConfig(formBuilderConfiguration, subObject, `${property.name}.`, `${property.name}[${index}].`);
                formArrayGroup.push(this.formGroup(entity || property.entity || this.getEntity(subObject, formBuilderConfiguration, property.name, true), subObject, objectValidationConfig));
                index++;
                this.conditionalObjectProps = [];
                this.builderConfigurationConditionalObjectProps = [];
              }
              let abstractControlOptions = this.getAbstractControlOptions(property.name, formBuilderConfiguration, {});
              formGroupObject[property.name] = new RxFormArray(arrayObjectValue, formArrayGroup, abstractControlOptions, null, property.arrayConfig);
              if (ReactiveFormConfig.autoInstancePush) {
                arrayObjectValue.push = instance => {
                  let formGroup = this.formGroup(instance.constructor, instance, objectValidationConfig);
                  formGroupObject[property.name].push(formGroup, {
                    isAddedInstance: true
                  });
                  return 0;
                };
                arrayObjectValue.splice = (start, deleteCount) => {
                  let end = start + deleteCount - 1;
                  for (var i = start; i <= end; i++) {
                    formGroupObject[property.name].removeAt(i, {
                      isRemovedInstance: true
                    });
                  }
                  return [];
                };
              }
              this.isNestedBinding = this.isNested = false;
            } else if (arrayObjectValue instanceof FormArray) formGroupObject[property.name] = arrayObjectValue;else if (property.arrayConfig && property.arrayConfig.createBlank) formGroupObject[property.name] = new RxFormArray([], [], null, null, property.arrayConfig);
            break;
        }
      }
    });
    if (!this.isNested) {
      this.conditionalValidationInstance = {};
      this.builderConfigurationConditionalObjectProps = [];
    }
    let abstractControlOptions = {
      validators: [],
      asyncValidators: [],
      updateOn: formBuilderConfiguration && formBuilderConfiguration.abstractControlOptions && formBuilderConfiguration.abstractControlOptions['global'] ? formBuilderConfiguration.abstractControlOptions['global'] : undefined
    };
    abstractControlOptions = this.getAbstractControlOptions("global", formBuilderConfiguration, abstractControlOptions);
    let formGroup = new RxFormGroup(json.model, json.entityObject, formGroupObject, abstractControlOptions);
    if (defaultContainer.isExperimental) {
      json.entityObject["formGroup"] = formGroup;
      this.overrideProperties(formGroup, json.entityObject, extendedProperties);
    }
    if (!this.isNestedBinding && !this.isGroupCalled) formGroup.refreshDisable();
    return formGroup;
  }
  overrideProperties(formGroup, entityObject, properties) {
    Object.keys(properties).forEach(t => {
      this.overrideProp(entityObject, t, formGroup);
    });
  }
  getAbstractControlOptions(name, formBuilderConfiguration, abstractControlOptions) {
    if (formBuilderConfiguration && formBuilderConfiguration.abstractControlOptions && formBuilderConfiguration.abstractControlOptions[name]) abstractControlOptions.updateOn = formBuilderConfiguration.abstractControlOptions[name];
    const controlOptions = formBuilderConfiguration ? formBuilderConfiguration.baseAbstractControlOptions : null;
    if (controlOptions && controlOptions[name]) {
      if (controlOptions[name].updateOn) abstractControlOptions.updateOn = controlOptions[name].updateOn;
      if (controlOptions[name].validators) {
        if (Array.isArray(controlOptions[name].validators)) controlOptions[name].validators.forEach(validator => abstractControlOptions.validators.push(validator));else abstractControlOptions.validators.push(controlOptions[name].validators);
      }
      if (controlOptions[name].asyncValidators) {
        if (Array.isArray(controlOptions[name].asyncValidators)) controlOptions[name].asyncValidators.forEach(validator => abstractControlOptions.asyncValidators.push(validator));else abstractControlOptions.asyncValidators.push(controlOptions[name].validators);
      }
    }
    return abstractControlOptions;
  }
  overrideProp(entityObject, propName, formGroup) {
    let descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(entityObject), propName);
    let value = entityObject[propName];
    let oldValue = null;
    Object.defineProperty(entityObject, propName, {
      get: () => {
        return descriptor ? descriptor.get.call(entityObject) : value;
      },
      set: v => {
        value = v;
        if (oldValue != v) {
          if (descriptor) descriptor.set.call(entityObject, v);
          if (!formGroup.changing && formGroup.controls[propName]) {
            formGroup.controls[propName].setValue(v);
          }
        }
        oldValue = v;
      }
    });
  }
}
RxFormBuilder.ɵfac = function RxFormBuilder_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RxFormBuilder)();
};
RxFormBuilder.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: RxFormBuilder,
  factory: RxFormBuilder.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RxFormBuilder, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
class TypedFormBuilder {
  constructor() {
    this.formBuilder = new FormBuilder();
    this.rxFormBuilder = new RxFormBuilder();
  }
  group(controlsConfig, options) {
    let paramOptions = options || {};
    if (typeof controlsConfig == FUNCTION_STRING) return !(paramOptions && paramOptions.isInstance) ? this.rxFormBuilder.formGroup(controlsConfig, paramOptions.data, paramOptions.config) : this.rxFormBuilder.formGroup(controlsConfig, paramOptions.data, paramOptions.config).modelInstance;
    return this.formBuilder.group(controlsConfig, options);
  }
}
TypedFormBuilder.ɵfac = function TypedFormBuilder_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TypedFormBuilder)();
};
TypedFormBuilder.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: TypedFormBuilder,
  factory: TypedFormBuilder.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TypedFormBuilder, [{
    type: Injectable
  }], function () {
    return [];
  }, null);
})();
class RxReactiveFormsModule {
  static forRoot() {
    return {
      ngModule: RxReactiveFormsModule,
      providers: []
    };
  }
}
RxReactiveFormsModule.ɵfac = function RxReactiveFormsModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || RxReactiveFormsModule)();
};
RxReactiveFormsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RxReactiveFormsModule
});
RxReactiveFormsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [RxFormBuilder, DecimalProvider, DecimalPipe],
  imports: [CommonModule, FormsModule, ReactiveFormsModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RxReactiveFormsModule, [{
    type: NgModule,
    args: [{
      declarations: [RxwebFormDirective, HtmlControlTemplateDirective, ControlHostDirective, RxFormControlDirective, FileControlDirective, ImageFileControlDirective, AsyncValidationDirective],
      imports: [CommonModule, FormsModule, ReactiveFormsModule],
      providers: [RxFormBuilder, DecimalProvider, DecimalPipe],
      exports: [AsyncValidationDirective, RxwebFormDirective, HtmlControlTemplateDirective, RxFormControlDirective, FileControlDirective, ImageFileControlDirective]
    }]
  }], null, null);
})();
// Experimental
class ReactiveTypedFormsModule {
  constructor() {
    defaultContainer.isExperimental = true;
    ReactiveFormConfig.autoInstancePush = true;
  }
  static forRoot() {
    return {
      ngModule: ReactiveTypedFormsModule,
      providers: []
    };
  }
}
ReactiveTypedFormsModule.ɵfac = function ReactiveTypedFormsModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ReactiveTypedFormsModule)();
};
ReactiveTypedFormsModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ReactiveTypedFormsModule
});
ReactiveTypedFormsModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [{
    provide: FormBuilder,
    useClass: TypedFormBuilder
  }, TypedFormBuilder],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule.forRoot(), ReactiveFormsModule, FormsModule, ReactiveFormsModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ReactiveTypedFormsModule, [{
    type: NgModule,
    args: [{
      declarations: [],
      imports: [CommonModule, FormsModule, ReactiveFormsModule, RxReactiveFormsModule.forRoot()],
      providers: [{
        provide: FormBuilder,
        useClass: TypedFormBuilder
      }, TypedFormBuilder],
      exports: [ReactiveFormsModule, FormsModule, ReactiveFormsModule]
    }]
  }], function () {
    return [];
  }, null);
})();
function baseValidator(config, type, validator) {
  var rxwebValidator = (control, target) => {
    if (typeof control == STRING) defaultContainer.init(target, 0, control, type, config, false);else {
      if (config && (!control.validatorConfig || !control.validatorConfig[type])) ApplicationUtil.configureControl(control, config, type);
      return validator(control);
    }
    return null;
  };
  Object.defineProperty(rxwebValidator, "name", {
    value: RX_WEB_VALIDATOR
  });
  return rxwebValidator;
}
function baseAsyncValidatorExtension(config, type, validator) {
  var rxwebValidator = (control, target) => {
    if (typeof control == STRING) defaultContainer.init(target, 0, control, type, config, true);else {
      if (config && (!control.validatorConfig || !control.validatorConfig[type])) ApplicationUtil.configureControl(control, config, type);
      return validator(control);
    }
    return null;
  };
  Object.defineProperty(rxwebValidator, "name", {
    value: RX_WEB_VALIDATOR
  });
  return rxwebValidator;
}
function alphaValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.alpha, alphaValidator(config));
}
function alphaAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.alpha, baseAsyncValidator(config, AnnotationTypes.alpha));
}
function allOfValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.allOf, allOfValidator(config));
}
function allOfAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.allOf, baseAsyncValidator(config, AnnotationTypes.allOf));
}
function alphaNumericValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.alphaNumeric, alphaNumericValidator(config));
}
function alphaNumericAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.alphaNumeric, baseAsyncValidator(config, AnnotationTypes.alphaNumeric));
}
function choiceValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.choice, choiceValidator(config));
}
function choiceAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.choice, baseAsyncValidator(config, AnnotationTypes.choice));
}
function compareValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.compare, compareValidator(config));
}
function containsValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.contains, containsValidator(config));
}
function containsAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.contains, baseAsyncValidator(config, AnnotationTypes.contains));
}
function creditCardValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.creditCard, creditCardValidator(config));
}
function creditCardAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.creditCard, baseAsyncValidator(config, AnnotationTypes.creditCard));
}
function differentValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.different, differentValidator(config));
}
function digitValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.digit, digitValidator(config));
}
function emailValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.email, emailValidator(config));
}
function evenValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.even, evenValidator(config));
}
function factorValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.factor, factorValidator(config));
}
function factorAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.factor, baseAsyncValidator(config, AnnotationTypes.factor));
}
function greaterThanEqualToValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.greaterThanEqualTo, greaterThanEqualToValidator(config));
}
function greaterThanEqualToAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.greaterThanEqualTo, baseAsyncValidator(config, AnnotationTypes.greaterThanEqualTo));
}
function greaterThanValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.greaterThan, greaterThanValidator(config));
}
function greaterThanAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.greaterThan, baseAsyncValidator(config, AnnotationTypes.greaterThan));
}
function hexColorValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.hexColor, hexColorValidator(config));
}
function jsonValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.json, jsonValidator(config));
}
function leapYearValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.leapYear, leapYearValidator(config));
}
function lessThanEqualToValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.lessThanEqualTo, lessThanEqualToValidator(config));
}
function lessThanEqualToAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.lessThanEqualTo, baseAsyncValidator(config, AnnotationTypes.lessThanEqualTo));
}
function lessThanValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.lessThan, lessThanValidator(config));
}
function lessThanAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.lessThan, baseAsyncValidator(config, AnnotationTypes.lessThan));
}
function lowerCaseValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.lowerCase, lowercaseValidator(config));
}
function macValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.mac, macValidator(config));
}
function maxDateValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.maxDate, maxDateValidator(config));
}
function maxDateAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.maxDate, baseAsyncValidator(config, AnnotationTypes.maxDate));
}
function maxLengthValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.maxLength, maxLengthValidator(config));
}
function maxLengthAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.maxLength, baseAsyncValidator(config, AnnotationTypes.maxLength));
}
function maxNumberValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.maxNumber, maxNumberValidator(config));
}
function maxNumberAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.maxNumber, baseAsyncValidator(config, AnnotationTypes.maxNumber));
}
function minDateValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.minDate, minDateValidator(config));
}
function minDateAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.minDate, baseAsyncValidator(config, AnnotationTypes.minDate));
}
function minLengthValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.minLength, minLengthValidator(config));
}
function minLengthAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.minLength, baseAsyncValidator(config, AnnotationTypes.minLength));
}
function minNumberValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.minNumber, minNumberValidator(config));
}
function minNumberAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.minNumber, baseAsyncValidator(config, AnnotationTypes.minNumber));
}
function noneOfValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.noneOf, noneOfValidator(config));
}
function noneOfAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.noneOf, baseAsyncValidator(config, AnnotationTypes.noneOf));
}
function numericValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.numeric, numericValidator(config));
}
function numericAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.numeric, baseAsyncValidator(config, AnnotationTypes.numeric));
}
function oddValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.odd, oddValidator(config));
}
function oneOfValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.oneOf, oneOfValidator(config));
}
function oneOfAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.oneOf, baseAsyncValidator(config, AnnotationTypes.oneOf));
}
function passwordcValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.password, passwordValidator(config));
}
function passwordAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.password, baseAsyncValidator(config, AnnotationTypes.password));
}
function patternValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.pattern, patternValidator(config));
}
function patternAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.pattern, baseAsyncValidator(config, AnnotationTypes.pattern));
}
function rangeValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.range, rangeValidator(config));
}
function rangeAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.range, baseAsyncValidator(config, AnnotationTypes.range));
}
function requiredValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.required, requiredValidator(config));
}
function timeValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.time, timeValidator(config));
}
function timeAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.time, baseAsyncValidator(config, AnnotationTypes.time));
}
function upperCaseValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.upperCase, uppercaseValidator(config));
}
function urlValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.url, urlValidator(config));
}
function urlAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.url, baseAsyncValidator(config, AnnotationTypes.url));
}
function asciiValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.ascii, asciiValidator(config));
}
function dataUriValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.dataUri, dataUriValidator(config));
}
function portValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.port, portValidator(config));
}
function latLongValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.latLong, latLongValidator(config));
}
function extensionValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.extension, control => {
    return null;
  });
}
function extensionAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.extension, baseAsyncValidator(config, AnnotationTypes.extension));
}
function fileSizeValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.fileSize, control => {
    return null;
  });
}
function fileSizeAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.fileSize, baseAsyncValidator(config, AnnotationTypes.fileSize));
}
function endsWithValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.endsWith, endsWithValidator(config));
}
function endsWithAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.endsWith, baseAsyncValidator(config, AnnotationTypes.endsWith));
}
function startsWithValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.startsWithWith, startsWithValidator(config));
}
function startsWithAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.startsWithWith, baseAsyncValidator(config, AnnotationTypes.startsWith));
}
function primeNumberValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.primeNumber, primeNumberValidator(config));
}
function latitudeValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.latitude, latitudeValidator(config));
}
function longitudeValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.longitude, longitudeValidator(config));
}
function composeValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.compose, composeValidator(config));
}
function fileValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.file, control => {
    return null;
  });
}
function fileAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.file, baseAsyncValidator(config, AnnotationTypes.file));
}
function customValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.custom, customValidator(config));
}
function customAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.custom, baseAsyncValidator(config, AnnotationTypes.custom));
}
function uniqueValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.unique, uniqueValidator(config));
}
function imageValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.image, control => {
    return null;
  });
}
function imageAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.image, baseAsyncValidator(config, AnnotationTypes.image));
}
function notEmptyValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.notEmpty, notEmptyValidator(config));
}
function ipValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.ip, ipValidator(config));
}
function ipAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.ip, baseAsyncValidator(config, AnnotationTypes.ip));
}
function cusipValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.cusip, cusipValidator(config));
}
function gridValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.grid, gridValidator(config));
}
function dateValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.date, dateValidator(config));
}
function dateAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.date, baseAsyncValidator(config, AnnotationTypes.date));
}
function andValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.and, andValidator(config));
}
function orValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.or, orValidator(config));
}
function notValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.not, notValidator(config));
}
function minTimeValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.minTime, minTimeValidator(config));
}
function minTimeAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.minTime, baseAsyncValidator(config, AnnotationTypes.minTime));
}
function maxTimeValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.maxTime, maxTimeValidator(config));
}
function maxTimeAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.maxTime, baseAsyncValidator(config, AnnotationTypes.maxTime));
}
function requiredTrueValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.requiredTrue, requiredTrueValidator(config));
}
function maskValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.mask, maskValidator(config));
}
function ibanValidatorExtension(config) {
  return baseValidator(config, AnnotationTypes.iban, ibanValidator(config));
}
function ibanAsyncValidatorExtension(config) {
  return baseAsyncValidatorExtension(config, AnnotationTypes.iban, baseAsyncValidator(config, AnnotationTypes.iban));
}
class RxwebValidators {}
RxwebValidators.alpha = alphaValidatorExtension;
RxwebValidators.allOf = allOfValidatorExtension;
RxwebValidators.alphaNumeric = alphaNumericValidatorExtension;
RxwebValidators.choice = choiceValidatorExtension;
RxwebValidators.compare = compareValidatorExtension;
RxwebValidators.contains = containsValidatorExtension;
RxwebValidators.creditCard = creditCardValidatorExtension;
RxwebValidators.different = differentValidatorExtension;
RxwebValidators.digit = digitValidatorExtension;
RxwebValidators.email = emailValidatorExtension;
RxwebValidators.even = evenValidatorExtension;
RxwebValidators.factor = factorValidatorExtension;
RxwebValidators.greaterThanEqualTo = greaterThanEqualToValidatorExtension;
RxwebValidators.greaterThan = greaterThanValidatorExtension;
RxwebValidators.hexColor = hexColorValidatorExtension;
RxwebValidators.json = jsonValidatorExtension;
RxwebValidators.leapYear = leapYearValidatorExtension;
RxwebValidators.lessThanEqualTo = lessThanEqualToValidatorExtension;
RxwebValidators.lessThan = lessThanValidatorExtension;
RxwebValidators.lowerCase = lowerCaseValidatorExtension;
RxwebValidators.mac = macValidatorExtension;
RxwebValidators.maxDate = maxDateValidatorExtension;
RxwebValidators.maxLength = maxLengthValidatorExtension;
RxwebValidators.maxNumber = maxNumberValidatorExtension;
RxwebValidators.minDate = minDateValidatorExtension;
RxwebValidators.minLength = minLengthValidatorExtension;
RxwebValidators.minNumber = minNumberValidatorExtension;
RxwebValidators.noneOf = noneOfValidatorExtension;
RxwebValidators.numeric = numericValidatorExtension;
RxwebValidators.odd = oddValidatorExtension;
RxwebValidators.oneOf = oneOfValidatorExtension;
RxwebValidators.password = passwordcValidatorExtension;
RxwebValidators.pattern = patternValidatorExtension;
RxwebValidators.range = rangeValidatorExtension;
RxwebValidators.required = requiredValidatorExtension;
RxwebValidators.time = timeValidatorExtension;
RxwebValidators.upperCase = upperCaseValidatorExtension;
RxwebValidators.url = urlValidatorExtension;
RxwebValidators.ascii = asciiValidatorExtension;
RxwebValidators.dataUri = dataUriValidatorExtension;
RxwebValidators.port = portValidatorExtension;
RxwebValidators.latLong = latLongValidatorExtension;
RxwebValidators.extension = extensionValidatorExtension;
RxwebValidators.fileSize = fileSizeValidatorExtension;
RxwebValidators.endsWith = endsWithValidatorExtension;
RxwebValidators.startsWith = startsWithValidatorExtension;
RxwebValidators.primeNumber = primeNumberValidatorExtension;
RxwebValidators.latitude = latitudeValidatorExtension;
RxwebValidators.longitude = longitudeValidatorExtension;
RxwebValidators.compose = composeValidatorExtension;
RxwebValidators.file = fileValidatorExtension;
RxwebValidators.custom = customValidatorExtension;
RxwebValidators.unique = uniqueValidatorExtension;
RxwebValidators.image = imageValidatorExtension;
RxwebValidators.notEmpty = notEmptyValidatorExtension;
RxwebValidators.ip = ipValidatorExtension;
RxwebValidators.cusip = cusipValidatorExtension;
RxwebValidators.grid = gridValidatorExtension;
RxwebValidators.date = dateValidatorExtension;
RxwebValidators.and = andValidatorExtension;
RxwebValidators.or = orValidatorExtension;
RxwebValidators.not = notValidatorExtension;
RxwebValidators.minTime = minTimeValidatorExtension;
RxwebValidators.maxTime = maxTimeValidatorExtension;
RxwebValidators.requiredTrue = requiredTrueValidatorExtension;
RxwebValidators.mask = maskValidatorExtension;
RxwebValidators.iban = ibanValidatorExtension;
RxwebValidators.alphaAsync = alphaAsyncValidatorExtension;
RxwebValidators.alphaNumericAsync = alphaNumericAsyncValidatorExtension;
RxwebValidators.allOfAsync = allOfAsyncValidatorExtension;
RxwebValidators.choiceAsync = choiceAsyncValidatorExtension;
RxwebValidators.containsAsync = containsAsyncValidatorExtension;
RxwebValidators.creditCardAsync = creditCardAsyncValidatorExtension;
RxwebValidators.customAsync = customAsyncValidatorExtension;
RxwebValidators.dateAsync = dateAsyncValidatorExtension;
RxwebValidators.endsWithAsync = endsWithAsyncValidatorExtension;
RxwebValidators.extensionAsync = extensionAsyncValidatorExtension;
RxwebValidators.factorAsync = factorAsyncValidatorExtension;
RxwebValidators.fileSizeAsync = fileSizeAsyncValidatorExtension;
RxwebValidators.fileAsync = fileAsyncValidatorExtension;
RxwebValidators.greaterThanEqualToAsync = greaterThanEqualToAsyncValidatorExtension;
RxwebValidators.greaterThanAsync = greaterThanAsyncValidatorExtension;
RxwebValidators.imageAsync = imageAsyncValidatorExtension;
RxwebValidators.ipAsync = ipAsyncValidatorExtension;
RxwebValidators.lessThanEqualToAsync = lessThanEqualToAsyncValidatorExtension;
RxwebValidators.lessThanAsync = lessThanAsyncValidatorExtension;
RxwebValidators.maxDateAsync = maxDateAsyncValidatorExtension;
RxwebValidators.maxLengthAsync = maxLengthAsyncValidatorExtension;
RxwebValidators.maxNumberAsync = maxNumberAsyncValidatorExtension;
RxwebValidators.maxTimeAsync = maxTimeAsyncValidatorExtension;
RxwebValidators.minDateAsync = minDateAsyncValidatorExtension;
RxwebValidators.minLengthAsync = minLengthAsyncValidatorExtension;
RxwebValidators.minNumberAsync = minNumberAsyncValidatorExtension;
RxwebValidators.minTimeAsync = minTimeAsyncValidatorExtension;
RxwebValidators.noneOfAsync = noneOfAsyncValidatorExtension;
RxwebValidators.numericAsync = numericAsyncValidatorExtension;
RxwebValidators.oneOfAsync = oneOfAsyncValidatorExtension;
RxwebValidators.passwordAsync = passwordAsyncValidatorExtension;
RxwebValidators.patternAsync = patternAsyncValidatorExtension;
RxwebValidators.rangeAsync = rangeAsyncValidatorExtension;
RxwebValidators.startsWithAsync = startsWithAsyncValidatorExtension;
RxwebValidators.timeAsync = timeAsyncValidatorExtension;
RxwebValidators.urlAsync = urlAsyncValidatorExtension;
RxwebValidators.ibanAsync = ibanAsyncValidatorExtension;

/**
 * Generated bundle index. Do not edit.
 */

export { AsyncValidationDirective, ErrorMessageBindingStrategy, FileControlDirective, FormBuilderConfiguration, HtmlControlTemplateDirective, IAbstractControl, ImageFileControlDirective, IpVersion, NumericValueType, ReactiveFormConfig, ReactiveTypedFormsModule, ResetFormType, RxFormArray, RxFormBuilder, RxFormControl, RxFormControlDirective, RxFormGroup, RxReactiveFormsModule, RxwebFormDirective, RxwebValidators, TypedForm, TypedFormBuilder, UrlValidationType, ValidationAlphabetLocale, allOf, allOfAsync, alpha, alphaAsync, alphaNumeric, alphaNumericAsync, and, ascii, async, blacklist, choice, choiceAsync, compare, compose, contains, containsAsync, creditCard, creditCardAsync, cusip, custom, customAsync, dataUri, date, dateAsync, different, digit, disable, elementClass, email, endsWith, endsWithAsync, error, escape, even, extension, extensionAsync, factor, factorAsync, file, fileAsync, fileSize, fileSizeAsync, greaterThan, greaterThanAsync, greaterThanEqualTo, greaterThanEqualToAsync, grid, hexColor, image, imageAsync, json, latLong, latitude, leapYear, lessThan, lessThanAsync, lessThanEqualTo, lessThanEqualToAsync, longitude, lowerCase, ltrim, mac, mask, maxDate, maxDateAsync, maxLength, maxLengthAsync, maxNumber, maxNumberAsync, maxTime, maxTimeAsync, minDate, minDateAsync, minLength, minLengthAsync, minNumber, minNumberAsync, minTime, minTimeAsync, model, noneOf, noneOfAsync, not, notEmpty, numeric, numericAsync, odd, oneOf, oneOfAsync, or, password, passwordAsync, pattern, patternAsync, port, prefix, primeNumber, prop, propArray, propObject, range, rangeAsync, required, requiredTrue, rtrim, rule, sanitize, startsWith, startsWithAsync, stripLow, suffix, time, timeAsync, toBoolean, toDate, toDouble, toFloat, toInt, toString, trim, unique, updateOn, upperCase, url, urlAsync, whitelist };
