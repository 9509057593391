import { Component } from '@angular/core';
import { BrokerFormComponent } from './broker-form.component';

@Component({
  selector: 'of-create-broker',
  standalone: true,
  imports: [BrokerFormComponent],
  template: ` <of-broker-form></of-broker-form> `
})
export class CreateBrokerComponent {}
