import { Component } from '@angular/core';
import { CanCreateOrderComponent } from './create-order/can-create-order.component';
import { RouterModule } from '@angular/router';
import { CanViewOrdersComponent } from './can-view-orders.component';
import { CanViewPartialExecutionsComponent } from '../partial-executions/can-view-partial-executions.component';
import { CanViewOrdersMenuComponent } from './can-view-orders-menu.component';
import { CanViewOnlyPartialExecutionsComponent } from '../partial-executions/can-view-only-partial-executions.component';

@Component({
    selector: 'of-orders-nav-menu',
    imports: [
        CanCreateOrderComponent,
        CanViewOnlyPartialExecutionsComponent,
        CanViewOrdersComponent,
        CanViewOrdersMenuComponent,
        CanViewPartialExecutionsComponent,
        RouterModule
    ],
    template: `
    <of-can-view-orders-menu>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          ORDERS
        </a>
        <ul class="dropdown-menu">
          <of-can-view-orders>
            <li>
              <a class="dropdown-item" [routerLink]="['/orders']"><i class="bi bi-list"></i> LIST</a>
            </li>
          </of-can-view-orders>
          <of-can-create-order>
            <li>
              <a class="dropdown-item" [routerLink]="['/orders', 'create']"><i class="bi bi-plus-lg"></i> NEW</a>
            </li>
            <li>
              <a class="dropdown-item" [routerLink]="['/orders', 'bulk-upload']"
                ><i class="bi bi-upload"></i> BULK UPLOAD</a
              >
            </li>
          </of-can-create-order>
          <of-can-view-partial-executions>
            <li>
              <a class="dropdown-item" [routerLink]="['/partial-executions']"
                ><i class="bi bi-bar-chart-steps"></i> ORDER EXECUTIONS</a
              >
            </li>
          </of-can-view-partial-executions>
        </ul>
      </li>
    </of-can-view-orders-menu>

    <of-can-view-only-partial-executions>
      <li class="nav-item">
        <a class="nav-link" [routerLink]="['/partial-executions']">ORDER EXECUTIONS</a>
      </li>
    </of-can-view-only-partial-executions>
  `
})
export class OrdersNavMenuComponent {}
