import { Component, Input } from '@angular/core';

@Component({
  selector: 'of-order-additional-info-card',
  standalone: true,
  imports: [],
  template: `
    <div class="card">
      <div class="card-header">
        <div class="card-title m-0">ADDITIONAL INFORMATION {{ suffix }}</div>
      </div>
      <div class="card-body">
        <p>{{ additionalInformation }}</p>
      </div>
    </div>
  `
})
export class OrderAdditionalInfoCardComponent {
  @Input()
  public additionalInformation: string | null = null;

  @Input()
  public suffix: string = '';
}
