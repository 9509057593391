import { Component, OnInit } from '@angular/core';
import { OrderFormComponent } from '../order-form/order-form.component';
import { Observable, map } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'of-edit-order',
  standalone: true,
  imports: [AsyncPipe, OrderFormComponent],
  template: `<of-order-form [orderToUpdateId]="orderToUpdateId$ | async"></of-order-form>`
})
export class EditOrderComponent implements OnInit {
  public orderToUpdateId$: Observable<number> | null = null;

  constructor(private route: ActivatedRoute) {}

  public ngOnInit(): void {
    this.orderToUpdateId$ = this.route.paramMap.pipe(map((params) => Number.parseInt(params.get('id') || '-1', 10)));
  }
}
