import { Component, Input } from '@angular/core';
import { CodeNameItem } from '../../../shared/code-name-value.model';
import { OrderFlowAuthService } from '../../../auth/order-flow-auth.service';
import { isPretradeCheckableStatus } from '../../../shared/utils';
import { ROLE_ORDER_WRITER } from '../../../shared/constants';

@Component({
  selector: 'of-can-execute-order-pretrade-checks',
  standalone: true,
  template: `
    @if (canExecutePreTradeChecks) {
      <ng-content></ng-content>
    }
  `
})
export class CanExecuteOrderPretradeChecksComponent {
  public canExecutePreTradeChecks: boolean | null = null;

  @Input()
  public orderStatus: CodeNameItem | null | undefined = null;

  constructor(private authService: OrderFlowAuthService) {}

  ngOnInit(): void {
    this.canExecutePreTradeChecks =
      isPretradeCheckableStatus(this.orderStatus) && this.authService.hasRole(ROLE_ORDER_WRITER);
  }
}
