export const TENANT_DEV = '86df0574-f6b7-4b61-9727-31891fa0d5c1';
export const TENANT_PROD = '6f41eebe-f4f6-41f6-9d01-03d70104f18c';

export const FEEDBACK_FORM = 'https://forms.office.com/e/Xadt2ervr7';
export const USER_MANUAL = 'https://documentation.cigp.app/books/order-flow';

export const CIGP_COLORS = [
  '#56725A',
  '#5A7865',
  '#5F7E71',
  '#63837D',
  '#678789',
  '#6C858E',
  '#708294',
  '#757F99',
  '#797B9F',
  '#847EA5',
  '#9282AA',
  '#A086B0',
  '#AE8BB5',
  '#BB8FB8',
  '#C094B3',
  '#C698AE',
  '#CB9DA9',
  '#D1A1A3',
  '#D6AFA6',
  '#DCBFAB',
  '#E1CFAF'
];

export const ROLE_BROKER_READER = 'Broker.Read';
export const ROLE_BROKER_WRITER = 'Broker.Write';
export const ROLE_CUSTODIAN_READER = 'Custodian.Read';
export const ROLE_CUSTODIAN_WRITER = 'Custodian.Write';
export const ROLE_ORDER_APPROVER = 'Order.Approve';
export const ROLE_ORDER_CHECKER = 'Order.Check';
export const ROLE_ORDER_READER = 'Order.Read';
export const ROLE_ORDER_WRITER = 'Order.Write';
export const ROLE_PARTIAL_EXECUTIONS_READER = 'PartialExecutions.Read';

export const TYPE_TD = 'Time Deposit';

export const BROKER_DESTINATION_EMAIL_CODE = 'Email';

export const CLONE_TYPE_WITH_ALLOCATION = 'withAllocation';

export const CIGP_AM_PORTFOLIO_LONG_NAME = 'CIGP Asset Management Limited';

export const STORE_PORTFOLIOS = 'portfolios';
export const STORE_SECURITIES = 'securities';
