import { Component } from '@angular/core';
import { CustodianFormComponent } from './custodian-form.component';

@Component({
  selector: 'of-create-custodian',
  standalone: true,
  imports: [CustodianFormComponent],
  template: ` <of-custodian-form></of-custodian-form> `
})
export class CreateCustodianComponent {}
