import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';
import { showErrorDialog, showSuccessSnackbar } from '../shared/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Custodian,
  CustodianToAddOrUpdate,
  DetailedCustodian,
} from './custodian.model';
import { UpdateMultiplePortfoliosRestrictionsRequest } from '../portfolios/portfolio.model';

@Injectable({
  providedIn: 'root'
})
export class CustodiansService {
  private custodians: Custodian[] | null = null;
  private apiEndpoint = `${environment.apiEndpoint}/api/custodians`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  public deleteCustodian$(id: number): Observable<void> {
    return this.http.delete(`${this.apiEndpoint}/${id}`).pipe(
      map(() => showSuccessSnackbar(this.snackBar, 'Custodian successfully deleted')),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public listCustodians$(includeHidden: boolean, forceRefresh = false): Observable<Custodian[]> {
    return this.custodians && !forceRefresh
      ? of(this.custodians.filter((c) => includeHidden || !c.hidden))
      : this.http.get<Custodian[]>(this.apiEndpoint, { params: { includeHidden } }).pipe(
          tap((custodians) => (this.custodians = custodians)),
          catchError((error: any) => {
            this.logger.error(error);
            showErrorDialog(this.errorDialog, error);
            return throwError(() => new Error(error));
          })
        );
  }

  public getCustodian$(id: number): Observable<DetailedCustodian> {
    return this.http.get<DetailedCustodian>(`${this.apiEndpoint}/${id}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public whitelistPortfolioForAllCustodians$(request: UpdateMultiplePortfoliosRestrictionsRequest): Observable<void> {
    return this.http.patch<void>(`${this.apiEndpoint}/whitelist`, request).pipe(
      tap(() => showSuccessSnackbar(this.snackBar, 'Custodian successfully updated')),
      tap(() => this.listCustodians$(true).subscribe()),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public restrictPortfolioForAllCustodians$(request: UpdateMultiplePortfoliosRestrictionsRequest): Observable<void> {
    return this.http.patch<void>(`${this.apiEndpoint}/exclude`, request).pipe(
      tap(() => showSuccessSnackbar(this.snackBar, 'Custodian successfully updated')),
      tap(() => this.listCustodians$(true).subscribe()),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getCustodianForUpdate$(id: number): Observable<CustodianToAddOrUpdate> {
    return this.http.get<CustodianToAddOrUpdate>(`${this.apiEndpoint}/${id}/for-update`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public insertCustodian$(custodian: CustodianToAddOrUpdate): Observable<DetailedCustodian> {
    return this.http.post<DetailedCustodian>(this.apiEndpoint, custodian).pipe(
      tap(() => showSuccessSnackbar(this.snackBar, 'Custodian successfully created')),
      tap(() => this.listCustodians$(true).subscribe()),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public updateCustodian$(id: number, custodian: CustodianToAddOrUpdate): Observable<DetailedCustodian> {
    return this.http.put<DetailedCustodian>(`${this.apiEndpoint}/${id}`, custodian).pipe(
      tap(() => showSuccessSnackbar(this.snackBar, 'Custodian successfully updated')),
      tap(() => this.listCustodians$(true).subscribe()),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }
}
