import { DecimalPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { EditService, GridModule, IEditCell, ToolbarService } from '@syncfusion/ej2-angular-grids';
import { Query, DataManager } from '@syncfusion/ej2-data';
import { QUANTITY_TYPE_VALUES } from '../../shared/code-name-value.model';
import { BulkExecuteOrdersService } from './bulk-execute-orders.service';
import { OrdersService } from '../orders.service';
import { concat, tap } from 'rxjs';
import { ToastComponent } from '../../shared/toast.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NGXLogger } from 'ngx-logger';

@Component({
    selector: 'of-bulk-execute-orders',
    providers: [EditService, ToolbarService],
    imports: [CheckBoxModule, DecimalPipe, GridModule, MatProgressSpinnerModule, RouterModule, ToastComponent],
    template: `
    <div class="card mb-3">
      <div class="card-header">
        <h5 class="card-title m-0">BULK EXECUTE ORDERS</h5>
      </div>
      <div class="card-body p-0">
        <ejs-grid
          #grdOrdersToExecute
          [dataSource]="bulkExecuteOrdersService.ordersToExecute"
          [selectionSettings]="{ checkboxOnly: true, persistSelection: true }"
          [allowTextWrap]="true"
          [editSettings]="{ allowEditing: true, mode: 'Batch' }"
          [toolbar]="['Update', 'Cancel']"
          (actionComplete)="onActionComplete($event)"
        >
          <e-columns>
            <e-column field="id" headerText="#" textAlign="Right" width="36" format="N0" [isPrimaryKey]="true">
              <ng-template #template let-data>
                <a target="_blank" [routerLink]="[data.id]">{{ data.id }}</a>
              </ng-template>
            </e-column>
            <e-column field="transactionType" headerText="Type" width="80" [allowEditing]="false"></e-column>
            <e-column field="security.currency" headerText="Ccy" width="44" [allowEditing]="false"></e-column>
            <e-column field="quantity" headerText="Qty / Nominal" width="140" textAlign="Right" [allowEditing]="false">
              <ng-template #template let-data>
                {{ data.quantity | number: '1.0-10' }}
                <small [title]="data.quantityType.name">({{ data.quantityType.name[0] }})</small>
              </ng-template>
            </e-column>
            <e-column field="security.name" headerText="Security" width="180" [allowEditing]="false">
              <ng-template #template let-data>
                {{ data.security.name }} <small>({{ data.security.type }})</small>
              </ng-template>
            </e-column>
            <e-column field="broker.code" headerText="Broker" width="80" [allowEditing]="false"></e-column>
            <e-column field="custodian.code" headerText="Custodian" width="80" [allowEditing]="false"></e-column>
            <e-column
              field="portfolioWithAccount"
              headerText="Portfolio / Account"
              width="120"
              [allowEditing]="false"
            ></e-column>
            <e-column
              field="useOrderInfo"
              headerText="Use Order Settings"
              width="80"
              editType="booleanedit"
              [displayAsCheckBox]="true"
            >
            </e-column>
            <e-column
              field="executedQuantity"
              headerText="Exec. Qty / Nominal"
              width="120"
              textAlign="Right"
              editType="numericedit"
              [edit]="{ params: { showSpinButton: false } }"
            ></e-column>
            <e-column
              field="executedQuantityType"
              headerText="Exec. Type"
              editType="dropdownedit"
              [edit]="executedQuantityTypeParams"
              width="100"
            ></e-column>
            <e-column
              field="transactionRef"
              headerText="Trn. Ref"
              width="100"
              editType="stringedit"
              [edit]="{ params: { showClearButton: true } }"
            ></e-column>
            <e-column
              field="comments"
              headerText="Comments"
              width="200"
              editType="stringedit"
              [edit]="{ params: { showClearButton: true } }"
            ></e-column>
          </e-columns>
        </ejs-grid>
      </div>
    </div>

    <of-toast #toastOrdersExecutionProgress [showCloseButton]="false">
      <div id="title" class="d-flex justify-content-between">
        <div>EXECUTING ORDERS</div>
        <div>{{ ordersExecutedCount }}/{{ ordersExecutedTotal }}</div>
      </div>
      <div id="content" class="d-flex gap-2">
        <mat-spinner [diameter]="20"></mat-spinner>
        <div>
          {{ ordersExecutedMessage }}
        </div>
      </div>
    </of-toast>
  `
})
export class BulkExecuteOrdersComponent implements OnInit {
  public executedQuantityTypeParams: IEditCell = {
    params: {
      dataSource: new DataManager(Object.values(QUANTITY_TYPE_VALUES)),
      fields: { text: 'name', value: 'code' },
      query: new Query(),
      actionComplete: () => false
    }
  };

  public ordersExecutedCount: number | null = null;
  public ordersExecutedTotal: number | null = null;
  public ordersExecutedMessage: string | null = null;

  @ViewChild('toastOrdersExecutionProgress')
  public toastOrdersExecutionProgress: ToastComponent | null = null;

  constructor(
    public bulkExecuteOrdersService: BulkExecuteOrdersService,
    private router: Router,
    private ordersService: OrdersService,
    private logger: NGXLogger
  ) {}

  public ngOnInit(): void {
    if (!this.bulkExecuteOrdersService.ordersToExecute) {
      this.router.navigate(['/orders']);
    }
  }

  public onActionComplete(args: any): void {
    if (args.name === 'actionComplete' && args.requestType === 'batchsave') {
      this.executeOrders();
    }
  }

  private executeOrders(): void {
    if (!this.bulkExecuteOrdersService.ordersToExecute) {
      return;
    }

    this.ordersExecutedCount = 0;
    this.ordersExecutedTotal = this.bulkExecuteOrdersService.ordersToExecute.length;
    this.ordersExecutedMessage = 'Executing orders...';

    const obs$ = this.bulkExecuteOrdersService.ordersToExecute.map((o) =>
      this.ordersService.executeOrder$(o).pipe(
        tap((id) => {
          this.logger.log(`Order ${id} executed`);
          this.ordersExecutedCount!++;
        })
      )
    );

    this.toastOrdersExecutionProgress?.show();

    concat(...obs$).subscribe({
      next: () => this.handleAfterExecute(true),
      error: () => this.handleAfterExecute(false)
    });
  }

  private handleAfterExecute(success: boolean): void {
    if (success) {
      this.ordersExecutedMessage = `Executed ${this.ordersExecutedMessage} orders.`;
      this.bulkExecuteOrdersService.clearOrdersToExecute();
      setTimeout(() => {
        this.resetToast();
        this.router.navigate(['/orders']);
      }, 2000);
    } else {
      this.resetToast();
    }
  }

  private resetToast() {
    this.toastOrdersExecutionProgress?.hide();
    this.ordersExecutedCount = null;
    this.ordersExecutedTotal = null;
    this.ordersExecutedMessage = null;
  }
}
