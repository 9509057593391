import { Component, OnInit } from '@angular/core';
import { OrderFlowAuthService } from './order-flow-auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'of-logout',
    imports: [],
    template: ``
})
export class LogoutComponent implements OnInit {
  constructor(private authService: OrderFlowAuthService, private router: Router) {}

  public ngOnInit(): void {
    this.authService.logout$().subscribe(() => this.router.navigate(['/']));
  }
}
