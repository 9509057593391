import { Component, OnInit } from '@angular/core';
import { OrderFlowAuthService } from './order-flow-auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'of-login',
    imports: [],
    template: `
    <p>Please complete the interactive login in the popup window</p>
    @if (error !== null) {
    <div>
      <p>
        <strong>{{ error }}</strong>
      </p>
      <button mat-raised-button color="warn" (click)="login()">LOGIN AGAIN</button>
    </div>
    }
  `,
    styles: [':host { margin: 1rem; height: 100%; }', 'p, button { margin: 1rem; }']
})
export class LoginComponent implements OnInit {
  public error: string | null = null;

  constructor(private authService: OrderFlowAuthService, private router: Router) {}

  public ngOnInit(): void {
    setTimeout(() => {
      if (this.authService.loggedIn) {
        this.router.navigate(['/']);
      }
    }, 500);
  }

  public login(): void {
    if (this.authService.loggedIn) {
      this.router.navigate(['/']);
    } else {
      this.error = null;
      this.authService.login$().subscribe(
        () => this.router.navigate(['/']),
        (error) => (this.error = error)
      );
    }
  }
}
