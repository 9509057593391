<div class="card mb-3">
  <div class="card-header d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center gap-3">
      <h5 class="card-title m-0">EXECUTED / PARTIALLY EXECUTED ORDERS</h5>
    </div>
    <div class="d-flex gap-3">
      <ejs-daterangepicker
        placeholder="Enter a date range"
        format="dd/MM/yyyy"
        (change)="dateRangeSelected()"
        [showClearButton]="false"
        [enabled]="!searching"
        [max]="today"
        [(value)]="selectedDateRange"
      ></ejs-daterangepicker>
    </div>
  </div>
  <div class="card-body p-0">
    <ejs-grid
      #grdExecutions
      id="grdExecutions"
      [dataSource]="partialExecutions$ | async"
      [selectionSettings]="{ checkboxOnly: true, persistSelection: true }"
      [allowFiltering]="true"
      [filterSettings]="{ ignoreAccent: true, type: 'Excel' }"
      [allowPaging]="true"
      [pageSettings]="{ pageSize: 50, pageSizes: ['10', '20', '50', '100', '250', 'All'] }"
      [allowExcelExport]="true"
      [allowTextWrap]="true"
      [toolbar]="['ExcelExport', 'CsvExport', 'Search']"
      (toolbarClick)="onToolbarClick($event)"
      [searchSettings]="{ ignoreAccent: true }"
    >
      <e-columns>
        <e-column field="id" headerText="#" textAlign="Right" width="24" [isPrimaryKey]="true">
          <ng-template #template let-data>
            @if (canViewOrderDetails) {
              <a [routerLink]="['/orders', data.id]">{{ data.id }}</a>
            } @else {
              {{ data.id }}
            }
          </ng-template>
        </e-column>
        <e-column field="status.name" headerText="Status" width="40">
          <ng-template #template let-data>
            <of-order-status-badge [status]="data.status" [useShortFormat]="true"></of-order-status-badge>
          </ng-template>
        </e-column>
        <e-column field="transactionType" headerText="Type" width="50"></e-column>
        <e-column field="securityCurrency" headerText="Ccy" width="30"></e-column>
        <e-column field="quantity" headerText="Orig. Qty / Nominal" width="60" textAlign="Right">
          <ng-template #template let-data>
            {{ data.quantity | number: '1.0-10' }}
            <small [title]="data.quantityType">({{ data.quantityType[0] }})</small>
          </ng-template>
        </e-column>
        <e-column field="executedQuantity" headerText="Executed Qty / Nominal" width="60" textAlign="Right">
          <ng-template #template let-data>
            {{ data.executedQuantity | number: '1.0-10' }}
            <small [title]="data.executedQuantityType">({{ data.executedQuantityType[0] }})</small>
          </ng-template>
        </e-column>
        <e-column field="securityName" headerText="Security" width="90">
          <ng-template #template let-data>
            {{ data.securityName }}<br />
            <small>{{ data.securityIsin }} | {{ data.securityType }}</small>
          </ng-template>
        </e-column>
        <e-column field="broker" headerText="Broker" width="50"></e-column>
        <e-column field="custodian" headerText="Custodian" width="50"></e-column>
        <e-column field="portfolioShortName" headerText="Portfolio" width="80">
          <ng-template #template let-data>
            {{ data.portfolioShortName }}
            @if (data.portfolioExternalName) {
              <small> ({{ data.portfolioExternalName }})</small>
            }
          </ng-template>
        </e-column>
        <e-column field="executionInstruction" headerText="Instruction" width="44"></e-column>
        <e-column field="partialExecutions" headerTextAlign="Center" width="220">
          <ng-template #headerTemplate let-data>
            <table class="table table-sm table-borderless m-0">
              <thead>
                <tr>
                  <th colspan="4">Partial Executions</th>
                </tr>
                <tr>
                  <th class="text-start" style="width: 140px">Timestamp</th>
                  <th>Transaction Ref.</th>
                  <th class="text-end">Qty / Nominal</th>
                  <th style="width: 180px">Comments</th>
                </tr>
              </thead>
            </table>
          </ng-template>
          <ng-template #template let-data>
            <table class="table table-sm table-striped m-0 p-0">
              <tbody>
                @for (partialExecution of data.partialExecutions; track $index) {
                  <tr>
                    <td class="align-middle" style="width: 140px">
                      {{ partialExecution.created | date: 'dd/MM/yyyy HH:mm' }}
                    </td>
                    <td class="align-middle">{{ partialExecution.transactionRefNumber }}</td>
                    <td class="align-middle text-end">
                      {{ partialExecution.quantity | number: '1.0-10' }}
                      @if (partialExecution.quantityType) {
                        <small [title]="partialExecution.quantityType">({{ partialExecution.quantityType[0] }})</small>
                      }
                    </td>
                    <td class="align-middle text-wrap" style="width: 180px">{{ partialExecution.comments }}</td>
                  </tr>
                }
              </tbody>
            </table>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>
</div>
