import { EventEmitter, Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppInsightsService } from '../shared/app-insights.service';
import { Observable, catchError, concat, lastValueFrom, map, of, tap, throwError } from 'rxjs';
import { DetailedOrder, Order } from './order.model';
import { showErrorDialog, showSuccessSnackbar } from '../shared/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Comments,
  UpdateApprovalStatusRequest,
  UpdateCheckStatusRequest,
  UpdateMultipleOrdersStatusRequest,
  UpdateOrderStatusRequest
} from '../shared/comments.model';
import { OrderToAddOrUpdate, OrderWithAllocationsToAddOrUpdate } from './order-with-allocations-to-add-or-update.model';
import {
  FlattenedOrderToAddOrUpdate,
  FlattenedOrderToAddOrUpdateWithRowNumber,
  InsertFlatOrderResponse
} from './flattened-order-to-add-or-update.model';
import { PdfOrder, PdfOrdersByBroker } from './pdf-order.model';
import { OrderFlowAuthService } from '../auth/order-flow-auth.service';
import { CodeNameItem } from '../shared/code-name-value.model';
import { OrderSearchRequest } from './order-search-request.model';
import {
  PartialExecutionQuantityUpdateRequest,
  PartialExecutionToAdd
} from '../record-partial-execution/partial-execution.model';
import { PreTradeCheck } from '../shared/pretrade-check.model';
import { OrderWithExecutionInfo } from './bulk-execute-orders/order-with-execution-info.model';
import { or } from '@rxweb/reactive-form-validators';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  private apiEndpoint = `${environment.apiEndpoint}/api/orders`;
  private hubEndpoint = `${environment.apiEndpoint}/hubs/orders`;
  private hubConnection: HubConnection | null = null;

  public orderUpdated = new EventEmitter<number>();
  public emailSent = new EventEmitter<EmailSentEventDetails>();
  public orderStatusUpdated = new EventEmitter<OrderStatusUpdatedEventDetails>();

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
    private snackBar: MatSnackBar,
    private appInsightsService: AppInsightsService,
    private orderFlowAuthService: OrderFlowAuthService
  ) {
    this.startConnection();
    this.addOrdersHubListeners();
  }

  listOrders$(showInactive: boolean | null): Observable<Order[]> {
    const params: any = {};

    if (showInactive) {
      params.showInactive = showInactive;
    }

    return this.http
      .get<Order[]>(this.apiEndpoint, {
        params
      })
      .pipe(
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  searchOrders$(request: OrderSearchRequest): Observable<Order[]> {
    return this.http.post<Order[]>(`${this.apiEndpoint}/search`, request).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getOrder$(id: number): Observable<DetailedOrder> {
    return this.http.get<DetailedOrder>(`${this.apiEndpoint}/${id}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getOrderForCloning$(id: number): Observable<OrderToAddOrUpdate> {
    return this.http.get<OrderToAddOrUpdate>(`${this.apiEndpoint}/${id}/for-cloning`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getOrderForCloningWithAllocation$(id: number): Observable<OrderWithAllocationsToAddOrUpdate> {
    return this.http
      .get<OrderWithAllocationsToAddOrUpdate>(`${this.apiEndpoint}/${id}/for-cloning-with-allocation`)
      .pipe(
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  public getOrderForUpdate$(id: number): Observable<OrderWithAllocationsToAddOrUpdate> {
    return this.http.get<OrderWithAllocationsToAddOrUpdate>(`${this.apiEndpoint}/${id}/for-update`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public previewOrder$(id: number): Observable<string> {
    return this.http
      .get(`${this.apiEndpoint}/${id}/preview`, {
        responseType: 'text'
      })
      .pipe(
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  public previewAggregatedOrder$(order: PdfOrder): Observable<string> {
    return this.http
      .post(`${this.apiEndpoint}/preview-aggregated`, order, {
        responseType: 'text'
      })
      .pipe(
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  public updateOrderApprovalStatus$(id: number, request: UpdateApprovalStatusRequest): Observable<DetailedOrder> {
    return this.http.patch<DetailedOrder>(`${this.apiEndpoint}/${id}/approve`, request).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public updateOrderCheckStatus$(id: number, request: UpdateCheckStatusRequest): Observable<void> {
    return this.http.patch<void>(`${this.apiEndpoint}/${id}/check`, request).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public updateOrderStatus$(id: number, request: UpdateOrderStatusRequest): Observable<void> {
    return this.http.patch<void>(`${this.apiEndpoint}/${id}/update-status`, request).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public executeOrder$(order: OrderWithExecutionInfo): Observable<number> {
    return this.recordPartialExecution$(order.id, {
      comments: order.comments,
      isFinalExecution: true,
      quantity: order.useOrderInfo ? order.quantity : order.executedQuantity!,
      quantityType: order.useOrderInfo ? order.quantityType.code : order.executedQuantityType!,
      transactionRefNumber: order.transactionRef
    }).pipe(map(() => order.id));
  }

  public recordPartialExecution$(id: number, partialExecution: PartialExecutionToAdd): Observable<void> {
    return this.http.patch<void>(`${this.apiEndpoint}/${id}/partial-execution`, partialExecution).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public updatePartialExecutionQuantity$(
    orderId: number,
    executionId: string,
    request: PartialExecutionQuantityUpdateRequest
  ): Observable<void> {
    return this.http.patch<void>(`${this.apiEndpoint}/${orderId}/partial-execution/${executionId}`, request).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public updateMultipleOrdersStatus$(request: UpdateMultipleOrdersStatusRequest): Observable<void> {
    const observables$ = request.orderIds.map((orderId) =>
      this.updateOrderStatus$(orderId, { orderId, newStatus: request.newStatus, comments: request.comments }).pipe(
        tap(() => this.orderStatusUpdated.emit({ orderId, status: request.newStatus }))
      )
    );

    return concat(...observables$);
  }

  public retryUploadOfOrder$(id: number): Observable<void> {
    return this.http.patch<void>(`${this.apiEndpoint}/${id}/retry-upload`, null).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public insertOrder$(order: OrderWithAllocationsToAddOrUpdate): Observable<string> {
    this.appInsightsService.logEvent('insertOrder', order);

    return this.http
      .post(this.apiEndpoint, order, {
        responseType: 'text'
      })
      .pipe(
        tap((res) => showSuccessSnackbar(this.snackBar, res)),
        catchError((error) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  public insertFlatOrder$(order: FlattenedOrderToAddOrUpdate, rowNumber: number): Observable<InsertFlatOrderResponse> {
    this.appInsightsService.logEvent('insertFlatOrder', order);

    return this.http
      .post(`${this.apiEndpoint}/flat`, order, {
        responseType: 'text'
      })
      .pipe(
        map((res) => ({ success: true, orderId: Number.parseInt(res, 10), rowNumber })),
        catchError((error) => {
          this.logger.error(error);
          return of({ success: false, error, rowNumber });
        })
      );
  }

  public updateOrder$(id: number, order: OrderWithAllocationsToAddOrUpdate): Observable<string> {
    this.appInsightsService.logEvent('updateOrder', order);

    return this.http.put<DetailedOrder>(`${this.apiEndpoint}/${id}`, order).pipe(
      tap((res) => showSuccessSnackbar(this.snackBar, `Successfully updated order ${id}`)),
      map((res) => `Successfully updated order ${id}`),
      catchError((error) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public executeOrderPreTradeCheck$(id: number): Observable<PreTradeCheck> {
    this.appInsightsService.logEvent('executeOrderPreTradeCheck', { orderId: id });

    return this.http.post<PreTradeCheck>(`${this.apiEndpoint}/${id}/execute-pre-trade-check`, {}).pipe(
      catchError((error) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public parseBulkOrdersFile$(bulkOrdersFileTempPath: string): Observable<FlattenedOrderToAddOrUpdateWithRowNumber[]> {
    this.appInsightsService.logEvent('parseBulkOrdersFile');

    return this.http
      .post<FlattenedOrderToAddOrUpdate[]>(`${this.apiEndpoint}/bulk/parse`, { bulkOrdersFileTempPath })
      .pipe(
        map((res) =>
          res.map((order, idx) => ({
            ...order,
            accountName: order.accountName.trim(),
            portfolioInternalName: order.portfolioInternalName.trim(),
            rowNumber: idx + 1
          }))
        ),
        catchError((error) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  public getBulkOrdersFileTemplate$(): Observable<Blob> {
    return this.http.get(`${this.apiEndpoint}/bulk/template`, { responseType: 'blob' }).pipe(
      catchError((error) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public sendEmailToBroker$(id: number): Observable<any> {
    return this.http.post(`${this.apiEndpoint}/${id}/send`, null).pipe(
      catchError((error) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public sendMultipleOrdersEmailToBroker$(ordersByBrokers: PdfOrdersByBroker[]): Observable<Object> {
    return this.http.post(`${this.apiEndpoint}/send`, ordersByBrokers).pipe(
      catchError((error) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public deleteOrder$(id: number, comments: Comments): Observable<void | object> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: comments
    };

    return this.http.delete(`${this.apiEndpoint}/${id}`, options).pipe(
      tap(() => showSuccessSnackbar(this.snackBar, `Order ${id} was deleted successfully.`)),
      catchError((error) => {
        this.logger.error(error);
        return throwError(() => new Error(error));
      })
    );
  }

  private startConnection(): void {
    this.hubConnection = new HubConnectionBuilder()
      .withUrl(this.hubEndpoint, {
        accessTokenFactory: () => lastValueFrom(this.orderFlowAuthService.getAccessToken$())
      })
      .build();
    this.hubConnection
      .start()
      .then(() => this.logger.info('Connection started'))
      .catch((err) => this.logger.error(`Error while starting connection: ${err}`));
  }

  private addOrdersHubListeners(): void {
    if (this.hubConnection) {
      this.hubConnection.on('orderUpdated', (orderID: string) => this.orderUpdated.emit(Number.parseInt(orderID, 10)));

      this.hubConnection.on('emailSent', (emailDetails: EmailSentEventDetails) => this.emailSent.emit(emailDetails));
    }
  }
}

export interface EmailSentEventDetails {
  broker: string;
  orderIds: number[];
}

export interface OrderStatusUpdatedEventDetails {
  orderId: number;
  status: CodeNameItem;
}
