import { Component } from '@angular/core';
import { OrderFlowAuthService } from '../../auth/order-flow-auth.service';
import { ROLE_BROKER_WRITER } from '../../shared/constants';

@Component({
    selector: 'of-can-create-broker',
    imports: [],
    template: `
    @if (canWrite) {
      <ng-content></ng-content>
    }
  `
})
export class CanWriteBrokerComponent {
  public canWrite: boolean | null = null;

  constructor(private authService: OrderFlowAuthService) {}

  ngOnInit(): void {
    this.canWrite = this.authService.hasRole(ROLE_BROKER_WRITER);
  }
}
