import * as i0 from '@angular/core';
import { Directive, ContentChildren, Component, ChangeDetectionStrategy, ContentChild, NgModule } from '@angular/core';
import { ComplexBase, setValue, ArrayBase, ComponentBase, Template, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { __decorate } from 'tslib';
import { Dialog, Tooltip } from '@syncfusion/ej2-popups';
const _c0 = ["footerTemplate"];
const _c1 = ["header"];
const _c2 = ["content"];
const _c3 = ["*"];
export * from '@syncfusion/ej2-popups';
import { CommonModule } from '@angular/common';
let input = ['buttonModel', 'isFlat', 'type'];
let outputs$2 = ['click'];
/**
 * 'e-button' directive represent a button of angular dialog
 * It must be contained in a Dialog component(`ej-dialog`).
 * ```html
 * <ejs-dialog id='dialog' showCloseIcon=true>
 *   <e-buttons>
 *    <e-dialogbutton [buttonModal]='okButton'></e-button>
 *    <e-dialogbutton [buttonModal]='cancelButton'></e-button>
 *   </e-buttons>
 * </ejs-dialog>
 * ```
 */
class DialogButtonDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$2);
    this.directivePropList = input;
  }
}
DialogButtonDirective.ɵfac = function DialogButtonDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DialogButtonDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
DialogButtonDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: DialogButtonDirective,
  selectors: [["e-dialogbutton"]],
  inputs: {
    buttonModel: "buttonModel",
    isFlat: "isFlat",
    type: "type"
  },
  outputs: {
    click: "click"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DialogButtonDirective, [{
    type: Directive,
    args: [{
      selector: 'e-buttons>e-dialogbutton',
      inputs: input,
      outputs: outputs$2,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * DialogButton Array Directive
 * @private
 */
class ButtonsDirective extends ArrayBase {
  constructor() {
    super('buttons');
  }
}
ButtonsDirective.ɵfac = function ButtonsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ButtonsDirective)();
};
ButtonsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ButtonsDirective,
  selectors: [["e-buttons"]],
  contentQueries: function ButtonsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, DialogButtonDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ButtonsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-dialog>e-buttons',
      queries: {
        children: new ContentChildren(DialogButtonDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$1 = ['allowDragging', 'animationSettings', 'buttons', 'closeOnEscape', 'content', 'cssClass', 'enableHtmlSanitizer', 'enablePersistence', 'enableResize', 'enableRtl', 'footerTemplate', 'header', 'height', 'isModal', 'locale', 'minHeight', 'position', 'resizeHandles', 'showCloseIcon', 'target', 'visible', 'width', 'zIndex'];
const outputs$1 = ['beforeClose', 'beforeOpen', 'beforeSanitizeHtml', 'close', 'created', 'destroyed', 'drag', 'dragStart', 'dragStop', 'open', 'overlayClick', 'resizeStart', 'resizeStop', 'resizing', 'visibleChange'];
const twoWays$1 = ['visible'];
/**
 * Represents the Angular Dialog Component
 * ```html
 * <ejs-dialog></ejs-dialog>
 * ```
 */
let DialogComponent = class DialogComponent extends Dialog {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['buttons'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$1);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childButtons;
    this.containerContext.ngAfterContentChecked(this);
  }
};
DialogComponent.ɵfac = function DialogComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DialogComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
DialogComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DialogComponent,
  selectors: [["ejs-dialog"]],
  contentQueries: function DialogComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, ButtonsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.header = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childButtons = _t.first);
    }
  },
  inputs: {
    allowDragging: "allowDragging",
    animationSettings: "animationSettings",
    buttons: "buttons",
    closeOnEscape: "closeOnEscape",
    content: "content",
    cssClass: "cssClass",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableResize: "enableResize",
    enableRtl: "enableRtl",
    footerTemplate: "footerTemplate",
    header: "header",
    height: "height",
    isModal: "isModal",
    locale: "locale",
    minHeight: "minHeight",
    position: "position",
    resizeHandles: "resizeHandles",
    showCloseIcon: "showCloseIcon",
    target: "target",
    visible: "visible",
    width: "width",
    zIndex: "zIndex"
  },
  outputs: {
    beforeClose: "beforeClose",
    beforeOpen: "beforeOpen",
    beforeSanitizeHtml: "beforeSanitizeHtml",
    close: "close",
    created: "created",
    destroyed: "destroyed",
    drag: "drag",
    dragStart: "dragStart",
    dragStop: "dragStop",
    open: "open",
    overlayClick: "overlayClick",
    resizeStart: "resizeStart",
    resizeStop: "resizeStop",
    resizing: "resizing",
    visibleChange: "visibleChange"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c3,
  decls: 1,
  vars: 0,
  template: function DialogComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], DialogComponent.prototype, "footerTemplate", void 0);
__decorate([Template()], DialogComponent.prototype, "header", void 0);
__decorate([Template()], DialogComponent.prototype, "content", void 0);
DialogComponent = __decorate([ComponentMixins([ComponentBase])], DialogComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DialogComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-dialog',
      inputs: inputs$1,
      outputs: outputs$1,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childButtons: new ContentChild(ButtonsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    footerTemplate: [{
      type: ContentChild,
      args: ['footerTemplate']
    }],
    header: [{
      type: ContentChild,
      args: ['header']
    }],
    content: [{
      type: ContentChild,
      args: ['content']
    }]
  });
})();

/**
 * NgModule definition for the Dialog component.
 */
class DialogModule {}
DialogModule.ɵfac = function DialogModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DialogModule)();
};
DialogModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DialogModule
});
DialogModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DialogModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [DialogComponent, DialogButtonDirective, ButtonsDirective],
      exports: [DialogComponent, DialogButtonDirective, ButtonsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Dialog component with providers.
 */
class DialogAllModule {}
DialogAllModule.ɵfac = function DialogAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || DialogAllModule)();
};
DialogAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DialogAllModule
});
DialogAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, DialogModule], DialogModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DialogAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, DialogModule],
      exports: [DialogModule],
      providers: []
    }]
  }], null, null);
})();
const inputs = ['animation', 'closeDelay', 'container', 'content', 'cssClass', 'enableHtmlParse', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'height', 'htmlAttributes', 'isSticky', 'locale', 'mouseTrail', 'offsetX', 'offsetY', 'openDelay', 'opensOn', 'position', 'showTipPointer', 'target', 'tipPointerPosition', 'width', 'windowCollision'];
const outputs = ['afterClose', 'afterOpen', 'beforeClose', 'beforeCollision', 'beforeOpen', 'beforeRender', 'created', 'destroyed'];
const twoWays = [''];
/**
 * Represents the Angular Tooltip component that displays a piece of information about the target element on mouse hover.
 * ```html
 * <ejs-tooltip content='Tooltip content'>Show Tooltip</ejs-tooltip>
 * ```
 */
let TooltipComponent = class TooltipComponent extends Tooltip {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.containerContext.ngAfterContentChecked(this);
  }
};
TooltipComponent.ɵfac = function TooltipComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TooltipComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
TooltipComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TooltipComponent,
  selectors: [["ejs-tooltip"]],
  contentQueries: function TooltipComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.content = _t.first);
    }
  },
  inputs: {
    animation: "animation",
    closeDelay: "closeDelay",
    container: "container",
    content: "content",
    cssClass: "cssClass",
    enableHtmlParse: "enableHtmlParse",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    height: "height",
    htmlAttributes: "htmlAttributes",
    isSticky: "isSticky",
    locale: "locale",
    mouseTrail: "mouseTrail",
    offsetX: "offsetX",
    offsetY: "offsetY",
    openDelay: "openDelay",
    opensOn: "opensOn",
    position: "position",
    showTipPointer: "showTipPointer",
    target: "target",
    tipPointerPosition: "tipPointerPosition",
    width: "width",
    windowCollision: "windowCollision"
  },
  outputs: {
    afterClose: "afterClose",
    afterOpen: "afterOpen",
    beforeClose: "beforeClose",
    beforeCollision: "beforeCollision",
    beforeOpen: "beforeOpen",
    beforeRender: "beforeRender",
    created: "created",
    destroyed: "destroyed"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c3,
  decls: 1,
  vars: 0,
  template: function TooltipComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], TooltipComponent.prototype, "content", void 0);
TooltipComponent = __decorate([ComponentMixins([ComponentBase])], TooltipComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-tooltip',
      inputs: inputs,
      outputs: outputs,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    content: [{
      type: ContentChild,
      args: ['content']
    }]
  });
})();

/**
 * NgModule definition for the Tooltip component.
 */
class TooltipModule {}
TooltipModule.ɵfac = function TooltipModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TooltipModule)();
};
TooltipModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TooltipModule
});
TooltipModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [TooltipComponent],
      exports: [TooltipComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Tooltip component with providers.
 */
class TooltipAllModule {}
TooltipAllModule.ɵfac = function TooltipAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || TooltipAllModule)();
};
TooltipAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TooltipAllModule
});
TooltipAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, TooltipModule], TooltipModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TooltipModule],
      exports: [TooltipModule],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { ButtonsDirective, DialogAllModule, DialogButtonDirective, DialogComponent, DialogModule, TooltipAllModule, TooltipComponent, TooltipModule };
